import React, { useState, useEffect, useCallback } from "react";
import moment from "moment"
import { useHistory } from "react-router-dom";
import {
  Form,
  Select,
  Input,
  Button,
  Table,
  Divider,
  Modal,
  message
} from "antd";
import { subscribedTopic, getTopiclist } from '@/api/topic'
// import { dataTool } from "echarts";
const TopicSubscription: React.FC = () => {
  const [form] = Form.useForm();
  const [formSubscription] = Form.useForm();
  const history = useHistory();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [ topicId, setTopicId ] = useState('')
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [userslist, setuserslist] = useState([]);
  // const [optionvalue, setoptionvalue] = useState<any>();
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Topic Code",
      dataIndex: "topicCode",
      key: "topicCode",
      // render: (text: any) => text.name,
    },
    {
      title: "Topic名称",
      dataIndex: "topicName",
      key: "topicName",
      // align: "center" as "center",
    },
    {
      title: "开放订阅",
      dataIndex: "accessLevel",
      key: "accessLevel"
    },
    {
      title: "最近更新时间",
      dataIndex: "updateAt",
      key: "updateAt"
    },
    {
      title: "操作",
      dataIndex: "topicCode",
      // align: "center" as "center",
      render: (topicCode: any, record:any) => (
        <>
          <a onClick={()=>{
            history.push(`/topic-detail/${topicCode}`)
            
          }}>详情</a>
          {
            record.isSubscribe? null :
            <Divider type="vertical" />
          }
          {
            record.isSubscribe? null :
            <a onClick={()=>{
              setTopicId(topicCode)
              setSubscriptionModal(true)
            }}>订阅</a>
          }
        </>
      ),
    },
  ];
  useEffect(() => {
    getList();
  }, [pageParam]);
  const getList = useCallback(() => {
    setlistload(true);
    getTopiclist({
      ...pageParam,
      type: 0,
      topicCode: form.getFieldValue("topicCode"),
      topicName: form.getFieldValue("topicName"),
    }).then((res: any) => {
      setlistload(false);
      res.data = res.data ?  res.data.map((item:any) => {
        item.accessLevel = item.accessLevel === 1 ? 'public(公开授权订阅)' : 'private(不开放订阅权限)'
        item.updateAt = moment(item.updateAt).format('YYYY-MM-DD HH:mm:ss')
        return item
      }) : []
      setuserslist(res.data);
      setTotal(res.pagination?.total);
    });
  }, [pageParam]);
  const handleSubscription = () => {
    subscribedTopic({ 
      topicCode: topicId,
      subscriberType: formSubscription.getFieldValue("subscriptionType"),
      callbackUrl: formSubscription.getFieldValue("URL"),
      retryNum: formSubscription.getFieldValue("resetCount"),
      retryInterval: formSubscription.getFieldValue("resetTime"),
    }).then((res:any) => {
      formSubscription.resetFields()
      if(res.code===200){
        getList()
        message.success('订阅成功')
      } else {
        message.warn(res.message)
      }
    }).catch(() => {
      message.error("订阅失败!");
    });
    setSubscriptionModal(false)
  };

  const handleCancel = () => {
    formSubscription.resetFields();
    setSubscriptionModal(false)
  };
  const [ type, setType ] = useState(1)
  const changeType = (value:number) => {
    setType(value)
  }
  return (
    <div style={{ padding: "20px" }}>
            <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>Topic订阅</div>
      </div>
      <div style={{ height: 50, marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="topicCode" label="Topic code：">
            <Input placeholder="请输入Topic code：" />
          </Form.Item>
          <Form.Item name="topicName" label="Topic名称：">
            <Input placeholder="请输入Topic名称：" />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={userslist}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
        <Modal
          title="设置订阅"
          visible={subscriptionModal}
          onOk={handleSubscription}
          onCancel={handleCancel}
          okText="确定"
          cancelText="取消"
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={formSubscription}       
            initialValues={{
              subscriptionType: 1,
              URL: '',
              resetCount: 0,
              resetTime: 0
            }
          }>
          <Form.Item
            label="订阅方式"
            name="subscriptionType"
            rules={[{ required: true, message: "请选择订阅方式" }]}
          >
            <Select 
              placeholder="请选择开放订阅"
              style={{maxWidth: "200px"}}
              onChange={changeType}
              options={[
                {
                  value: 1,
                  label: 'Http',
                },
                {
                  value: 2,
                  label: 'WebSocket',
                }
              ]}>
            </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.subscriptionType !== currentValues.subscriptionType}>
              {({getFieldValue})=>
                getFieldValue("subscriptionType") === 1 ? (
                  <Form.Item  name="URL" label="回调URL" tooltip="仅支持Post请求方式，消息订阅的结果规则
                  请参阅开发规范" rules={[{ required: true, message: "请输入回调URL" }]}>
                  <Input placeholder="请输入接收消息的地址" style={{maxWidth: "200px"}}/>
                </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item
              label="重试次数"
              name="resetCount"
              rules={[{ required: true, message: "请输入重试次数" }]}
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <Input placeholder="0" style={{maxWidth: "70px", marginRight: "5px"}}/>最大不超过100次
              </div>
            </Form.Item>
            <Form.Item
              label="重试间隔时间"
              name="resetTime"
              rules={[{ required: true, message: "请输入重试间隔时间" }]}
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <Input placeholder="0" style={{maxWidth: "70px", marginRight: "5px"}}/>秒
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default TopicSubscription;
