import React, { useEffect, useState } from "react";
import DetailCard from "@/component/DetailCard";
import { Form, Col, Row, Button, Badge } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import {
  currentLimiting,
  currentFusingLimiting,
  authModes,
} from "../../../../pages/Service/PublishServe/utils-data/commonData";
import { getserviceManagedetail } from "../../../../api/serviceManagement";
import ParseRequestJsonTable from "../../../../pages/Service/Detail/components/parse-request-json-table";
const DetailRender: React.FC<any> = ({ children }) => {
  return (
    <>
      {children.map(
        (
          it:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <Col md={8} sm={24} key={index}>
            {it}
          </Col>
        )
      )}
    </>
  );
};

const InstanceTableListDetail: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [userInfo, setUserInfo] = useState<any>({});
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };
  useEffect(() => {
    getserviceManagedetail({
      code: state.code,
      dpAddress: state.dpAddress,
    }).then((res) => {
      setUserInfo(res.data);
    });
  }, []);
  return (
    <div className="detail-view">
      <div className="detail-module">
        <div className="title">基本信息</div>
        <DetailCard title={"实例基本信息"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="中枢实例名称">
                {userInfo?.nodeInfo?.name}
              </Form.Item>
              <Form.Item {...formLayout} label="中枢实例编码">
                {userInfo?.nodeInfo?.code}
              </Form.Item>
              <Form.Item {...formLayout} label="IP/域名:端口">
                {userInfo?.nodeInfo?.address}
              </Form.Item>
              <Form.Item {...formLayout} label="连接状态">
                <>
                  {userInfo?.nodeInfo?.connectState === 1 && (
                    <Badge color="#4CEFBE" text="连接正常" />
                  )}
                  {userInfo?.nodeInfo?.connectState === 0 && (
                    <Badge color="#FF5353" text="断开" />
                  )}
                </>
              </Form.Item>
              <Form.Item {...formLayout} label="实例描述">
                {userInfo?.nodeInfo?.description}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"服务基本信息"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="API名称">
                {userInfo.apiName}
              </Form.Item>
              <Form.Item {...formLayout} label="版本号">
                {userInfo.apiVersion}
              </Form.Item>
              <Form.Item {...formLayout} label="获取方式">
                {authModes[userInfo.authMode - 1] || ""}
              </Form.Item>
              <Form.Item {...formLayout} label="说明">
                {userInfo.apiDesc}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"安全配置信息"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="请求加签验签">
                {userInfo.serviceLimiting?.requestSign ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="响应加签验签">
                {userInfo.serviceLimiting?.responseSign ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="请求报文加密解密">
                {userInfo.serviceLimiting?.requestEncrypt ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="响应报文加密解密">
                {userInfo.serviceLimiting?.responseEncrypt ? "是" : "否"}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"性能设置"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="最大QPS">
                {userInfo.serviceLimiting?.maxQps}
              </Form.Item>
              <Form.Item {...formLayout} label="预计响应时间">
                {userInfo.serviceLimiting?.resTime}
              </Form.Item>
              <Form.Item {...formLayout} label="超时时间">
                {userInfo.serviceLimiting?.timeOut}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
      </div>
      <div className="detail-module">
        <div className="title">前端服务配置</div>
        <DetailCard title={"API接入配置"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="接入协议">
                {userInfo.accessProtocol}
              </Form.Item>
              <Form.Item {...formLayout} label="请求方式">
                {userInfo.requestMethod}
              </Form.Item>
              <Form.Item {...formLayout} label="请求路径">
                {userInfo.requestPath}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"入参设置"}>
          <ParseRequestJsonTable
            method={userInfo?.requestMethod || userInfo?.method}
            type={"request"}
            jsonData={(() => {
              let param: any = {};
              try {
                param = JSON.parse(userInfo?.metaJson || "{}");
              } catch (error) {}
              return param?.APIRequestStructure || {};
            })()}
            protocolIn={"rest"}
          />
        </DetailCard>
        <DetailCard title={"出参设置"}>
          <ParseRequestJsonTable
            method={userInfo?.requestMethod || userInfo?.method}
            type={"response"}
            jsonData={(() => {
              let param: any = {};
              try {
                param = JSON.parse(userInfo?.metaJson || "{}");
              } catch (error) {}

              return param?.APIResponseStructure || {};
            })()}
            protocolIn={userInfo.accessProtocol}
          />
        </DetailCard>
        <DetailCard title={"返回设置"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="是否开启Mock">
                {userInfo.isMock ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="Mock返回数据">
                {userInfo.mockJson}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            history.go(-1);
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
};

export default InstanceTableListDetail;
