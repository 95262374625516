// @ts-nocheck
import React, { useState } from "react";
import { Layout, Menu, Dropdown, Tooltip } from "antd";
import * as Icon from "@ant-design/icons";
import styles from "./index.module.scss";
import Routes from "../../routes";
import { useEffect } from "react";
import touxiang from "./images/touxiang.png";
import logo from "./images/logo.png";
import Tools from "../../util/tools";
import { TOKEN } from "../../constants";
import { getUserlogout } from "../../api/userlist";
import { WebAdminContext } from "../../context/WebAdminContext";
import { useHistory, Link, useRouteMatch, useLocation } from "react-router-dom";
import { getmanagegetInstance } from "../../api/interconnection";
import Brmb from "./Breadcrumb";
import yh from "../../assets/img/Vector.svg";
// import pluginicon from "../../assets/img/pluginicon.png"
const { Header, Content, Footer, Sider } = Layout;
export const storageItem = [
  "CURRENT",
  "systemPath",
  "pluginPath",
  "userlistPath",
  "minetPath",
  "MENULIST",
];

const Home = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const { SubMenu } = Menu;
  const { userInfo, rolelist } = React.useContext(WebAdminContext);
  const [tokens, setToken] = useState(false);
  const [menulist, setmenulist] = useState(false);
  const [current, setcurrent] = useState<string>("");
  const [systemPath, setsystemPath] = useState<string>("/systemlist");
  const [pluginPath, setpluginPath] = useState<string>("/pluginadmin");
  const [userlistPath, setuserlistPath] = useState<string>("/userlist");
  const [minetPath, setminetPath] = useState<string>("/personal/essential");
  const [collapsed, setcollapsed] = useState(false);
  const [version, setversion] = useState("");
  useEffect(() => {
    setcurrent(location.pathname);
  }, [location.pathname]);
  const showModal = () => {
    window.location.href = "/login";
  };
  useEffect(() => {
    getmanagegetInstance().then((res) => {
      console.log(res);
      setversion(res.data.kernelVersion);
    });
  }, []);
  //菜单
  const ren = () => {
    const adminRuoteMenu: {
      name: string;
      icon: any;
      path?: string;
      children?: any[];
    }[] = [
      // {
      //   name: "插件",
      //   path: "/plugins",
      //   icon: <Icon.AppstoreAddOutlined />,
      //   id: "1"
      // },
      // {
      //   name: "插件管理",
      //   path: "/pluginadmin",
      //   icon: <ApiOutlined />,
      //   id: "1",
      //   children: [
      //     {
      //       name: "插件中心",
      //       path: "/pluginadmin",
      //     },
      //     {
      //       name: "插件列表",
      //       path: "/pluginadmin/list",
      //     },
      //   ]
      // },
      {
        name: "用户中心",
        icon: <Icon.TeamOutlined />,
        // path: "/userlistall",
        id: "1",
        role: ["admin"],
        children: [
          {
            name: "成员管理",
            path: "/userlist",
            role: ["admin"],
            id: "2",
          },
          {
            name: "凭证管理",
            path: "/certificatemanagement",
            id: "4",
            role: ["admin"],
          },
        ],
      },
      {
        name: "系统设置",
        icon: <Icon.DeploymentUnitOutlined />,
        // path: "/systemsetup",
        id: "12",
        role: ["admin"],
        children: [
          {
            name: "黑白名单",
            icon: <Icon.UserSwitchOutlined />,
            path: "/bllist",
            id: "13",
            role: ["admin"],
          },
          {
            name: "网关开关设置",
            // key: "category",
            icon: <Icon.DesktopOutlined />,
            path: "/gatewayswitch",
            id: "18",
            role: ["admin", "user"],
          },
        ],
      },
      {
        name: "资源管理",
        icon: <Icon.SplitCellsOutlined />,
        // path: "/userlistal",
        id: "5",
        role: ["admin", "user"],
        children: [
          {
            name: "Connector插件",
            // key: "category",
            icon: <Icon.BoxPlotOutlined />,
            path: "/connector",
            id: "6",
            role: ["admin"],
          },
          {
            name: "服务管理",
            // key: "category",
            icon: <Icon.DesktopOutlined />,
            path: "/servicemanagement",
            id: "7",
            role: ["admin", "user"],
          },
          {
            name: "服务用例测试",
            // key: "category",
            icon: <Icon.DesktopOutlined />,
            path: "/servicetest",
            id: "9",
            role: ["admin", "user"],
          },
          {
            name: "聚合服务管理",
            // key: "category",
            icon: <Icon.DesktopOutlined />,
            path: "/aggregationserver",
            id: "9",
            role: ["admin", "user"],
          },
        ],
      },
      {
        name: "我的凭证",
        // key: "category",
        icon: <Icon.PicRightOutlined />,
        path: "/credentials",
        id: "10",
        role: ["admin", "user"],
      },
      {
        name: "运行可视化",
        // key: "category",
        icon: <Icon.DashboardOutlined />,
        id: "11",
        role: ["admin"],
        children: [
          {
            name: "服务健康监控",
            path: "/service/health",
            role: ["admin"],
            id: "12",
          },
          {
            name: "服务运行监控",
            path: "/servicemonitoring",
            role: ["admin"],
            id: "13",
          },
          {
            name: "调用记录",
            path: "/cnum",
            role: ["admin"],
            id: "14",
          },
          {
            name: "调用统计",
            path: "/callstatistics",
            role: ["admin"],
            id: "15",
          },
        ],
      },
      {
        name: "中枢互联",
        icon: <Icon.MacCommandOutlined />,
        // path: "",
        id: "16",
        role: ["admin", "user"],
        children: [
          {
            name: "互联实例管理",
            path: "/instancemanagement",
            role: ["admin", "user"],
            id: "17",
            parent: "/instance",
          },
          // {
          //   name: "互联资源",
          //   path: "/interconnectresoufce",
          //   role: ["admin"],
          //   id: "2",
          // },
          {
            name: "互联申请",
            path: "/interconnectionapplication",
            role: ["admin"],
            id: "18",
          },
          {
            name: "互联审批",
            path: "/approval",
            role: ["admin"],
            id: "19",
          },
        ],
      },
      // {
      //   name: "消息协同",
      //   icon: <Icon.ApartmentOutlined />,
      //   path: "/topic",
      //   id: "2",
      //   role: ["admin", "user"],
      //   children: [
      //     {
      //       name: "我的Topic",
      //       path: "/mytopic",
      //       role: ["admin", "user"],
      //       id: "2",
      //     },
      //     {
      //       name: "Topic订阅",
      //       path: "/topic-subscription",
      //       role: ["admin", "user"],
      //       id: "2",
      //     },
      //     {
      //       name: "消息查询",
      //       path: "/topic-select",
      //       role: ["admin", "user"],
      //       id: "2",
      //     },
      //   ],
      // },
      // {
      //   name: "类目管理",
      //   key: "category",
      //   icon: <Icon.UserDeleteOutlined />,
      //   path: "/category",
      //   id: "3",
      // },
      // {
      //   name: "运营监控",
      //   key: "category",
      //   icon: <Icon.UserDeleteOutlined />,
      //   path: "/monitoring",
      //   id: "4",
      // },
      // {
      //   name: "资源管理",
      //   path: "/resource/list",
      //   icon: <Icon.MailOutlined />,
      //   id: "2",
      //   children: [
      //     {
      //       name: "数据资源",
      //       icon: <Icon.VideoCameraOutlined />,
      //       path: "/resource/list",
      //     },
      //     {
      //       name: "申请列表",
      //       icon: <Icon.UploadOutlined />,
      //       path: "/apply",
      //     },
      //   ],
      // },
      // {
      //   name: "资源审批",
      //   icon: <Icon.CarryOutOutlined />,
      //   path: "/examine",
      //   id: "3",
      // },
    ];
    const menuList = [
      // {
      //   name: "用户管理",
      //   icon: <Icon.TeamOutlined />,
      //   path: "/userlist",
      //   id: "2",
      //   children: [
      //     {
      //       name: "成员管理",
      //       path: "/userlist",
      //     },
      //     {
      //       name: "权限组",
      //       path: "/permissions",
      //     },
      //   ],
      // },
      {
        name: "Connector插件",
        // key: "category",
        icon: <Icon.UserDeleteOutlined />,
        path: "/connector",
        id: "2",
      },
      {
        name: "我的凭证",
        // key: "category",
        icon: <Icon.UserDeleteOutlined />,
        path: "/credentials",
        id: "2",
      },
      {
        name: "服务管理",
        // key: "category",
        icon: <Icon.UserDeleteOutlined />,
        path: "/servicemanagement",
        id: "2",
      },
      // {
      //   name: "黑白名单",
      //   // key: "category",
      //   icon: <Icon.UserDeleteOutlined />,
      //   path: "/bllist",
      //   id: "2",
      // },
      // {
      //   name: "场景",
      //   icon: <Icon.PartitionOutlined />,
      //   path: "/scenelist",
      //   id: "6",
      // },
    ];
    // if (rolelist.name === "管理员") {
    return adminRuoteMenu;
    // } else if (rolelist.name === "普通用户") {
    //   return menuList;
    // }
  };
  //个人中心
  // const mineList = [
  //   {
  //     name: "个人中心",
  //     icon: <Icon.UserOutlined />,
  //     path: "/personal/essential",
  //     id: "my",
  //     children: [
  //       {
  //         name: "用户设置",
  //         icon: <Icon.UploadOutlined />,
  //         path: "/personal/essential",
  //       },
  //       {
  //         name: "操作日志",
  //         icon: <Icon.UploadOutlined />,
  //         path: "/personal/journal",
  //       },
  //       {
  //         name: "子账户",
  //         icon: <Icon.UploadOutlined />,
  //         path: "/personal/Subaccount",
  //       },
  //     ],
  //   },
  // ];

  useEffect(() => {
    if (Tools.getStorage("__token__")) {
      setToken(true);
    }
  }, []);

  const onCollapse = (collapsed: any) => {
    setcollapsed(collapsed);
  };
  // 退出登录
  const signout = () => {
    window.location.href = "/login";
    Tools.removeStorage(TOKEN);
    localStorage.removeItem(TOKEN);
    storageItem.forEach((item) => {
      Tools.removeStorage(item);
    });
    setToken(false);
    getUserlogout({}).then((res) => {
      if (res.code === 200) {
        // setToken(false)
      }
    });
  };
  // 点击logo
  const logoclick = () => {
    const hash = rolelist.name === "管理员" ? "/adminconsole" : "/connector";
    history.push(hash);
    Tools.setStorage("CURRENT", hash);
    setmenulist(false);
    Tools.setStorage("MENULIST", false);
  };
  // 头像下拉框
  const menu = (
    <Menu style={{ width: 80 }}>
      {/* <Menu.Item>
        <div onClick={personals}>个人中心</div>
      </Menu.Item> */}
      <Menu.Item>
        <div onClick={signout}>退出登录</div>
      </Menu.Item>
    </Menu>
  );

  //顶部菜单
  const handleClick = (e: any) => {
    // console.log('click ', e);
    // console.log(menulist);
    if (e.tag === "1") {
      setsystemPath("/resourceport");
      Tools.setStorage(storageItem[1], "/resourceport");
      Tools.setStorage("CURRENT", "/systemlist");
    } else if (e.tag === "2") {
      setsystemPath("/systemlist");
      Tools.setStorage("CURRENT", e.key);
      Tools.setStorage(storageItem[1], "/resourceport");
    } else {
      Tools.setStorage("CURRENT", e.key);
      setsystemPath("/systemlist");
      setpluginPath("/pluginadmin");
      setuserlistPath("/userlist");
      Tools.setStorage(storageItem[1], "/systemlist");
      Tools.setStorage(storageItem[2], "/pluginadmin");
      Tools.setStorage(storageItem[3], "/userlist");
    }
    history.push(e.key);
    let flag = false;
    ren()?.forEach((item) => {
      if (item.children && e.key === item.path) {
        flag = true;
      }
    });
    if (flag || e.tag === "1") {
      setmenulist(true);
      Tools.setStorage("MENULIST", true);
    } else {
      setmenulist(false);
      Tools.setStorage("MENULIST", false);
    }
  };
  return (
    <>
      <Layout
        style={{
          width: "100vw",
          height: "100%",
          marginTop: "0.1px",
          paddingTop: "0.1px",
        }}
      >
        {/* <div className={styles['header-box']}> </div> */}
        <Header
          className={styles["header-shadow"]}
          style={{
            background: "#fff",
          }}
        >
          <div>
            <Tooltip title={version}>
              <img
                onClick={logoclick}
                className={styles["logo-img"]}
                src={logo}
                alt=""
              />
            </Tooltip>
          </div>

          <div className={styles["menu-box"]}></div>
          <div className={styles["login"]}>
            {tokens ? (
              <div>
                <Dropdown overlay={menu} placement="bottom" arrow={true}>
                  <div className={styles["login-box"]}>
                    <img
                      className={styles["login-img"]}
                      src={touxiang}
                      alt=""
                    />
                    <div className={styles["login-name"]}>
                      {JSON.parse(Tools.getStorage("userInfo"))?.username}
                    </div>
                  </div>
                </Dropdown>
              </div>
            ) : (
              <div style={{ marginTop: 32 }} onClick={showModal}>
                登录
              </div>
            )}
          </div>
        </Header>

        <Layout style={{ height: "100%" }}>
          <Sider collapsible={collapsed} onCollapse={onCollapse}>
            <Menu
              mode="inline"
              defaultOpenKeys={["/userlistall"]}
              style={{
                height: "100%",
                paddingTop: 16,
                paddingBottom: 20,
                overflow: "auto",
              }}
              selectedKeys={[current]}
              onClick={(e) => {
                setcurrent(e.key);
              }}
            >
              {ren()?.map((item: any) => {
                //有子菜单，并且要路由匹配
                if (item.children) {
                  if (
                    item.role.indexOf(
                      JSON.parse(Tools.getStorage("userInfo"))?.roleCode
                    ) > -1
                  ) {
                    return (
                      <SubMenu
                        key={item.path}
                        icon={item.icon}
                        title={item.name}
                      >
                        {item.children.map((info: any) => {
                          if (
                            (info?.role || "").indexOf(
                              JSON.parse(Tools.getStorage("userInfo"))?.roleCode
                            ) > -1
                          ) {
                            return (
                              <Menu.Item key={info.path}>
                                <Link to={info.path}>{info.name}</Link>
                              </Menu.Item>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </SubMenu>
                    );
                  } else {
                    return "";
                  }
                } else {
                  if (
                    item.role.indexOf(
                      JSON.parse(Tools.getStorage("userInfo"))?.roleCode
                    ) > -1
                  ) {
                    return (
                      <Menu.Item key={item.path} icon={item.icon}>
                        <Link to={item.path}>{item.name}</Link>
                      </Menu.Item>
                    );
                  } else {
                    return "";
                  }
                }
              })}
            </Menu>
          </Sider>
          <Layout style={{ height: "100%" }}>
            <Content
              style={{
                padding: "20px 12px",
                paddingBottom: 0,
                borderRadius: "4px",
                height: "100%",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  margin: 0,
                  borderRadius: "4px",
                  height: "100%",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                <WebAdminContext.Provider
                  value={{ userInfo, rolelist, handleClick, setmenulist }}
                >
                  <Routes />
                </WebAdminContext.Provider>
              </div>
            </Content>
          </Layout>
        </Layout>
        {/* <Footer style={{ textAlign: "center" }}>2022 云栖工程院</Footer> */}
      </Layout>
    </>
  );
};
export default Home;
