import styled from 'styled-components'

import bgImg from '../../../assets/img/login-bg.png'
import bgImgWebp from '../../../assets/img/login-bg.webp'
import leftImg from '../../../assets/img/login-bg-left.png'
// import leftImgWebp from '../../../assets/img/login-bg-left.webp'

// 检查浏览器是否支持 webp
const isSupportWebp = function () {
  try {
    return (
      document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
    )
  } catch (err) {
    return false
  }
}

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${isSupportWebp() ? bgImgWebp : bgImg});
  background-size: 100% 100%;
  position: relative;
`

export const LoginWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 870px;
  height: 500px;
  background: linear-gradient(356deg, #ffffff 0%, #ffffff 97%);
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(25, 43, 112, 0.22);

  .login-left {
    display: inline-block;
    width: 435px;
    height: 100%;
    vertical-align: top;
    background-image: url(${leftImg});
    background-size: 100% 100%;
    border-radius: 10px 0 0 10px;
  }
  .login-right {
    display: inline-block;
    width: 435px;
    padding: 100px 32px;
    color: rgba(49, 57, 77, 1);
    vertical-align: top;
  }
  .tab-title {
    color: rgba(49, 57, 77, 0.7);
    font-weight: 500;
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Medium;
  }

  .welcome {
    color: #31394D;
    font-size: 26px;
    margin-bottom: 20px;
  }

  .ForgotPassword {
    position: absolute;
    top: -13px;
    right: 46px;
  }

  .ant-input-affix-wrapper {
    width: 88%;
    height: 48px;
    color: rgba(49, 57, 77, 1);
    background: rgba(149, 176, 230, 0.11);
    border-color: transparent;
    border-radius: 6px;
  }
  .ant-btn {
    width: 100%;
    height: 48px;
    font-size: 20px;
  }
`
