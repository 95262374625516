import actionType from "./index";

export const setPostbody = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.POSTBODY,
    playload
})
export const setPosquery = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.POSTQUERY,
    playload
})
export const setPosheaders = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.POSTHEADERS,
    playload
})
export const setPospath = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.POSTPATH,
    playload
})
export const setgetparams = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.GETPARAMS,
    playload
})
export const setgetheaders = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.GETHEADERS,
    playload
})
export const setgetpath = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.GETPATH,
    playload
})
export const setpostObjData = (playload) => ({
    type: actionType.POSTOBJ,
    playload
})
export const setgetObjData = (playload) => ({
    type: actionType.GETOBJ,
    playload
})