// import React from 'react'
import MyScence from "./MyScence"
import AllScence from "./AllScence"

export default function index() {
  return (
    <div style={{ background: "#f7f8fa" }}>
      <MyScence />
      <AllScence />
    </div>
  )
}
