import React from "react";
import styles from "./index.module.scss";
import { ReactComponent as ErrorImg } from "../../../assets/img/erro.svg";
import { ReactComponent as Info } from "../../../../src/assets/img/info.svg";
import { ReactComponent as Success } from "../../../assets/img/Success.svg";
const TypeComponent: React.FC<any> = (props: any) => {
  const { type } = props;
  const TypeRenderMethods = (data: number) => {
    if (data === 0) {
      return (
        <div
          className={styles["TypeComponent-type"]}
          style={{ background: "#FFF1E3" }}
        >
          <div>审批状态：</div>
          <Info />
          <div style={{marginLeft:"6px"}}>待审批</div>
        </div>
      );
    } else if (data === 1) {
      return (
        <div
          className={styles["TypeComponent-type"]}
          style={{ background: "#EBF5FF" }}
        >
          <div>审批状态：</div>
          <Success />
          <div style={{marginLeft:"6px"}}>审批通过</div>
        </div>
      );
    } else {
      return (
        <div
          className={styles["TypeComponent-type"]}
          style={{ background: "#FFE7E7" }}
        >
          <div>审批状态：</div>
          <ErrorImg />
          <div style={{marginLeft:"6px"}}>审批不通过</div>
        </div>
      );
    }
  };
  return (
    <div className={styles["TypeComponent-all"]}>{TypeRenderMethods(type)}</div>
  );
};

export default TypeComponent;
