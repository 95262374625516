import React, { useState, useEffect } from "react";
import {
  Space,
  Checkbox,
  Form,
  Select,
  Slider,
  Table,
  Input,
  Button,
  Modal,
  Badge,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  postgrayScalestrategy,
  putgrayScalerelease,
  putgrayScalestate,
  deletegrayScale,
  deletegrayscalestrategy,
} from "../../../../api/grayscale";

const GrayCreate: React.FC<any> = (props: any) => {
  const { data ,goback , refresh} = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { Option } = Select;
  const [seleValue, setseleValue] = useState(2);
  const [sliderData, setsliderData] = useState(0);
  const [renderMd, setrenderMd] = useState(1);
  const [ableType, setableType] = useState(false);
  const [dataSource, setdataSource] = useState<any>([
    {
      number: 0.1,
      location: "Query",
      paramName: "",
      matchRule: 1,
      paramValue: "",
      isPickOpposite: false,
    },
  ]);
  const [flowSource, setflowSource] = useState<any>("");
  const [paramAble,setparamAble] = useState(false)
  useEffect(() => {
    if (data.type !== undefined) {
      form.setFieldsValue({
        cltype: data.type,
      });
      setseleValue(data.type);
    } else {
      form.setFieldsValue({
        cltype: 2,
      });
      setseleValue(2);
    }
    if (data.content !== undefined) {
      if (data.type === 2) {
        let arr = JSON.parse(data.content);
        arr.map((item: any, index: any) => {
          form.setFields([
            {
              name: [`isPickOpposite${index}`],
              value: item.isPickOpposite,
            },
            {
              name: [`location${index}`],
              value: item.location,
            },
            {
              name: [`matchRule${index}`],
              value: item.matchRule,
            },
            {
              name: [`paramName${index}`],
              value: item.paramName,
            },
            {
              name: [`paramValue${index}`],
              value: item.paramValue,
            },
          ]);
        });
        setdataSource(arr);
        setableType(true);
      } else {
        let arr = JSON.parse(data.content);
        setsliderData(arr[0].percentage);
        form.setFieldsValue({ percentage: arr[0].percentage });
        setableType(true);
      }
    } else {
      setableType(false);
    }
    // console.log(props);
  }, [props]);
  const paramData = [
    {
      id:1,
      key:"Header",
      value:"x-centralsystem-client-ip",
      describe:"客户端请求ip，中枢将获取服务调用方客户端的来源ip并存入header"
    },
    {
      id:2,
      key:"Header",
      value:"x-centralsystem-client-access-key",
      describe:"中枢会从服务调用方的token中获取accessKey并存入header"
    }
  ]
  // useEffect(() => {
  //   console.log(dataSource);
  // }, [dataSource]);

  const columns: any = [
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>入参位置
        </div>
      ),
      dataIndex: "location",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`location${index}`}
              rules={[{ required: true, message: "请选择入参位置" }]}
            >
              <Select
                style={{ width: "100%" }}
                value={[text + ""]}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].location = e;
                  setdataSource(arr);
                }}
              >
                <Option value="Query">Query</Option>
                <Option value="Header">Header</Option>
              </Select>
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
      // fixed: "left",
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>入参名称
        </div>
      ),
      dataIndex: "paramName",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`paramName${index}`}
              rules={[{ required: true, message: "请填写入参名称" }]}
            >
              <Input
                placeholder="请输入参数key"
                style={{ width: "100%" }}
                value={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].paramName = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>匹配规则
        </div>
      ),
      dataIndex: "matchRule",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`matchRule${index}`}
              rules={[{ required: true, message: "请选择匹配规则" }]}
            >
              <Select
                style={{ width: "100%" }}
                value={[text + ""]}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].matchRule = e;
                  setdataSource(arr);
                }}
              >
                <Option value={1}>精确匹配等于</Option>
                <Option value={2}>包含于</Option>
                <Option value={3}>前缀等于</Option>
                <Option value={4}>后缀等于</Option>
              </Select>
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
      // fixed: "left",
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>参数值
        </div>
      ),
      dataIndex: "paramValue",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`paramValue${index}`}
              rules={[{ required: true, message: "请填写参数值" }]}
            >
              <Input
                // placeholder="请输入提醒文案"
                style={{ width: "100%" }}
                value={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].paramValue = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    {
      title: "取反",
      dataIndex: "isPickOpposite",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item label="" name={`isPickOpposite${index}`}>
              <Checkbox
                checked={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].isPickOpposite = e.target.checked;
                  setdataSource(arr);
                }}
              ></Checkbox>
            </Form.Item>
          </Form>
        );
      },
      width: 100,
      align: "center" as "center",
      fixed: "right",
    },
    {
      title: "操作",
      dataIndex: "number",
      render: (text: any, record: any, index: number) => {
        return (
          <>
            {index > 0 && (
              <Button
                type="link"
                onClick={() => handleDelete(text, index)}
                disabled={ableType}
              >
                删除
              </Button>
            )}
          </>
        );
      },
      width: 100,
      align: "center" as "center",
      fixed: "right",
    },
  ];
  const paramColumns:any = [
    {
      title: '入参位置',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: '入参名称',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: '说明',
      dataIndex: 'describe',
      key: 'describe',
    },
  ]
  //删除
  const handleDelete = (text: any, index: any) => {
    if (dataSource.length >= 1) {
      let arr = dataSource.filter((item: any) => item.number !== text);
      arr.map((item: any, index: any) => {
        form.setFields([
          {
            name: [`isPickOpposite${index}`],
            value: item.isPickOpposite,
          },
          {
            name: [`location${index}`],
            value: item.location,
          },
          {
            name: [`matchRule${index}`],
            value: item.matchRule,
          },
          {
            name: [`paramName${index}`],
            value: item.paramName,
          },
          {
            name: [`paramValue${index}`],
            value: item.paramValue,
          },
        ]);
      });
      setdataSource(arr);
    }
  };
  //添加
  const handleAdd = () => {
    const newData = {
      number: Math.random(),
      location: "Query",
      paramName: "",
      matchRule: 1,
      paramValue: "",
      isPickOpposite: false,
    };
    let arr = [...dataSource];
    let brr = [...arr, newData];
    brr.map((item: any, index: any) => {
      form.setFields([
        {
          name: [`isPickOpposite${index}`],
          value: item.isPickOpposite,
        },
        {
          name: [`location${index}`],
          value: item.location,
        },
        {
          name: [`matchRule${index}`],
          value: item.matchRule,
        },
        {
          name: [`paramName${index}`],
          value: item.paramName,
        },
        {
          name: [`paramValue${index}`],
          value: item.paramValue,
        },
      ]);
    });
    setdataSource(brr);
  };
  // 保存提交
  const Submit = () => {
    form.validateFields().then((res) => {
      postgrayScalestrategy({
        type: seleValue,
        id: data.id,
        content: seleValue === 2 ? JSON.stringify(dataSource) : flowSource,
      }).then((res) => {
        if (res.code === 200) {
          message.success("保存成功!");
          // history.go(-1);
          setableType(true)
          refresh(true)
        }
      });
    });
  };
  const Grayrelease = () => {
    confirm({
      title: "灰度发布提醒",
      icon: <ExclamationCircleOutlined />,
      content:
        "灰度发布启用后，服务请求将按照设置的灰度策略进行分流，是否继续操作？",
      onOk() {
        putgrayScalestate({
          id: data.id,
          state: 1,
        }).then((res) => {
          if (res.code === 200) {
            message.success("灰度发布成功!");
            history.go(-1);
          }
        });
      },
      onCancel() {},
    });
  };
  const Deletegray = () => {
    confirm({
      title: "删除灰度设置提醒",
      icon: <ExclamationCircleOutlined />,
      content: "服务当前的灰度配置信息将被删除，是否继续操作？",
      onOk() {
        deletegrayscalestrategy(data.id).then((res) => {
          if (res.code === 200) {
            message.success("已成功删除策略!");
            history.go(-1);
          }
        });
      },
      onCancel() {},
    });
  };
  const allBack = () => {
    confirm({
      title: "全量回滚提醒",
      icon: <ExclamationCircleOutlined />,
      content:
        "全量回滚将把所有请求打到当前服务正常版本，并删除当前灰度版本，确定继续操作吗？",
      onOk() {
        deletegrayScale(data.id).then((res) => {
          if (res.code === 200) {
            message.success("回滚成功!");
            history.go(-1);
          }
        });
      },
      onCancel() {},
    });
  };
  const allPush = () => {
    confirm({
      title: "全量发布提醒",
      icon: <ExclamationCircleOutlined />,
      content:
        "全量发布将把所有请求打到当前服务灰度版本，并将灰度版本替换为正常版本，确定继续操作吗？",
      onOk() {
        putgrayScalerelease({ id: data.id }).then((res) => {
          if (res.code === 200) {
            message.success("发布成功!");
            history.go(-1);
          }
        });
      },
      onCancel() {},
    });
  };
  return (
    <div>
      <Form form={form} layout="horizontal">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <Form.Item
              label="策略类型"
              name="cltype"
              style={{ width: "300px" }}
              rules={[{ required: true }]}
            >
              <Select
                value={seleValue}
                // defaultValue={seleValue}
                disabled={ableType}
                onChange={(e) => {
                  setseleValue(e);
                }}
                options={[
                  {
                    value: 2,
                    label: "按条件",
                  },
                  {
                    value: 1,
                    label: "按百分比",
                  },
                ]}
              />
            </Form.Item>
            {data.content !== undefined && (
              <Form.Item label="策略状态" style={{ marginLeft: "90px" }}>
                <>
                  {data.state === 2 && <Badge color="#E3E3E3" text="未启用" />}
                  {data.state === 1 && <Badge color="#4CEFBE" text="启用" />}
                </>
              </Form.Item>
            )}
          </div>
          {ableType && (
            <Form.Item>
              <a
                onClick={() => {
                  setableType(false);
                }}
              >
                修改灰度策略
              </a>
            </Form.Item>
          )}
        </div>
        {seleValue === 1 ? (
          <Form.Item
            label="流量百分比"
            rules={[{ required: true }]}
            name="percentage"
            style={{ width: "600px" }}
          >
            <Slider
              value={sliderData}
              disabled={ableType}
              onChange={(e) => {
                setsliderData(e);
                let arr = [{ percentage: 0 }];
                arr[0].percentage = e;
                setflowSource(JSON.stringify(arr));
              }}
            />
          </Form.Item>
        ) : (
          <div>
            <Form.Item label="条件参数">
              <span style={{color:"#86909C"}}>仅支持Query和Header参数作为灰度路由条件</span>
              <a style={{marginLeft:"10px"}} onClick={()=>{
                setparamAble(true)
              }}>查看系统参数</a>
            </Form.Item>
            <Form.Item label="条件配置">
              <Table
                // rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
              {ableType === false && (
                <Button
                  onClick={handleAdd}
                  // type="primary"
                  style={{ marginTop: 16, width: "100%" }}
                >
                  + 添加配置项
                </Button>
              )}
            </Form.Item>
          </div>
        )}
        <Modal visible={paramAble} title="查看系统参数" onCancel={()=>{
          setparamAble(false)
        }} width={1000} footer={[
          <Button key="back" onClick={()=>{
            setparamAble(false)
          }}>
            关闭
          </Button>
        ]}>
          <div>
          中枢在request请求域内置了以下参数，可以作为配置策略参数
          </div>
        <Table columns={paramColumns} dataSource={paramData} rowKey="id" style={{marginTop:"20px"}}/>
        </Modal>
        {ableType === false && (
          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button
                onClick={() => {
                  // history.go(-1);
                  if(data.content === undefined || data.content ===""){
                    goback(false)
                  }else{
                    setableType(true)
                    goback(true)
                  }
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={Submit}>
                保存
              </Button>
            </Space>
          </Form.Item>
        )}
        {data.content !== undefined && data.state === 2 && ableType && (
          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button onClick={Deletegray}>删除灰度配置</Button>
              <Button type="primary" onClick={Grayrelease}>
                灰度发布
              </Button>
            </Space>
          </Form.Item>
        )}
        {data.content !== undefined && data.state === 1 && ableType && (
          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button onClick={allBack}>全量回滚</Button>
              <Button type="primary" onClick={allPush}>
                全量发布
              </Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};
export default GrayCreate;
