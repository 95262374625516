import * as React from "react";
import { HTMLAttributes } from "react";
import cn from "classnames";
import "./index.scss";

interface PageCardProps extends HTMLAttributes<HTMLDivElement> {
  isResetTab?: boolean;
}
const PageCard: React.FC<PageCardProps> = ({
  className,
  children,
  isResetTab,
  ...rest
}) => {
  const prefixCls = "cbd-page-card";
  return (
    <div
      className={cn(className, `${prefixCls}-wrapper`, {
        "cbd-page-card-reset-tab": isResetTab,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
export default PageCard;
