//@ts-nocheck
import APIParamsRequestForm from "../../../../../component/APIParamsRequestForm";
import HttpParamsForm from "../../../../../component/HttpParamsForm";
import { DEFAULT_SCHEMA, queryArea, DEFAULT_JSON_AREA } from "../commonData";
import { isEmpty, debounce, values } from "lodash";
import DetailCard from "../../../../../component/DetailCard";
import { handleIndicatorImportJson } from "../utils";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, Form, Input, Button, Radio } from "antd";
import { PublishContext } from "@/pages/Service/PublishServe";
import { getConnectorDetail } from "../../../../../api/serviceManagement";

const RestArgInput = (props: any, ref: any) => {
  // let mockJson = {}
  const { TextArea } = Input;
  const { appApiId, currentStep } = props;
  const { state } = React.useContext(PublishContext);
  const { connectId } = state;
  const [form] = Form.useForm();
  const isEdit = !!appApiId;
  const { method = "get", initialValue, metaJson, typeer } = props;
  const defaultForm = queryArea();
  const [isCloseUpdate, setCloseUpdate] = useState(true);
  const [showInstruction, setShowInstruction] = useState(false);
  const [value4, setValue4] = useState("JSON");
  const options = [
    { label: "Json", value: "JSON" },
    { label: "FileStream", value: "FILESTREAM" },
  ];
  const originData = React.useRef(
    isEdit
      ? (() => {
          try {
            return JSON.parse(initialValue);
          } catch (e) {
            return {};
          }
        })()
      : {
          APIRequestStructure: {
            post: { type: "form", form: queryArea() },
            query: queryArea(),
            headers: [],
            form: [],
            path: [],
          },
          APIResponseStructure: DEFAULT_SCHEMA,
        }
  );
  const [_, setUpdate] = useState(1);
  // @ts-ignore
  const handleInitValue = (initialValue) => {
    let { APIRequestStructure, APIResponseStructure } = initialValue || {};
    const tipArr = [];
    let { post } = APIRequestStructure || {};
    const handleRequest = (arr: any[], tip: string) => {
      if (!Array.isArray(arr)) {
        return tip === "query" ? defaultForm : [];
      }
      if (!arr?.some((i) => Array.isArray(i.disableArr))) {
        if (arr?.some((i) => [""].includes(i.name))) {
          tipArr.push(tip);
        }
        return [...queryArea(), ...arr?.filter((i) => ![""].includes(i.name))];
      }
      return arr;
    };
    if (APIRequestStructure) {
      if (typeof APIRequestStructure !== "object") {
        APIRequestStructure = {};
      }
      APIRequestStructure.query = handleRequest(
        APIRequestStructure.query,
        "query"
      );
      if (post && post?.type === "form" && post?.form) {
        APIRequestStructure.post.form = handleRequest(
          APIRequestStructure.post?.form || [],
          "form"
        );
      } else if (post && post?.json) {
        const { areaCode, areaCodes, ...rest } = post?.json?.properties || {};
        const isOld = (area: { description: string }) =>
          area &&
          area?.description !==
            "用于按地区(如区/县、街道/镇)维度申请数据的字段";
        if (isOld(areaCode) || isOld(areaCodes)) {
          tipArr.push("json");
          if (APIRequestStructure?.post?.json?.properties) {
            // @ts-ignore
            APIRequestStructure.post.json.properties = {
              ...DEFAULT_JSON_AREA.properties,
              ...rest,
            };
          }
        }
      }
    } else {
      APIRequestStructure = {
        post: { type: "form", form: queryArea() },
        query: queryArea(),
        headers: [],
        form: [],
      };
    }
    // if (tipArr.length > 0) {
    //   Modal.info({ content: '入参中已有的areaCode、areaCodes参数名称，已被中枢作为用于按地区申请数据的保留数据(使用全国统一的区域、城乡代码)，如原参数代表其他业务含义，请赋予新的参数名。' });
    // }
    APIResponseStructure = isEmpty(APIResponseStructure)
      ? DEFAULT_SCHEMA
      : APIResponseStructure;
    return {
      APIRequestStructure,
      APIResponseStructure,
    };
  };

  useEffect(() => {
    if (!isEmpty(originData.current)) {
      const values = (() => {
        try {
          return JSON.parse(JSON.stringify(originData.current));
        } catch (e) {
          return {};
        }
      })();
      if (method === "get") {
        values?.APIRequestStructure?.post &&
          (values.APIRequestStructure.post = { type: {} });
      } else {
        const type = values?.APIRequestStructure?.post?.type;
        if (type)
          values!.APIRequestStructure = {
            ...(values?.APIRequestStructure || {}),
            post: { type, [type]: values?.APIRequestStructure?.post[type] },
          };
      }
      values?.APIRequestStructure?.path &&
        (values.APIRequestStructure.path =
          (originData.current || JSON.parse(metaJson))?.APIRequestStructure
            ?.path ?? []);
      props.onChange(JSON.stringify(values));
    }
  }, [_, method]);
  useEffect(() => {
    if (isEdit && initialValue !== "{}") {
      try {
        originData.current = handleInitValue(JSON.parse(initialValue));
        setUpdate((p) => ++p);
        setCloseUpdate((pre) => !pre);
      } catch (e) {}
    }
  }, [initialValue]);

  useEffect(() => {
    setValue4(state.frontendConfig.fileType)
    connectId &&
      getConnectorDetail({ id: connectId }).then((res) => {
        if (res.code == 200) {
          form.setFieldsValue({
            payloadDefinition: JSON.stringify(
              res.data?.payloadDefinition || {},
              null,
              2
            ),
            payloadDefinitionDescription:
              res.data?.payloadDefinitionDescription,
          });
        }
      });
  }, [connectId]);

  const onHeadersParamChange = (data: string) => {
    let parseData = [];
    try {
      parseData = JSON.parse(data);
    } catch (e) {}
    if (!parseData.some((i: { name: any }) => isEmpty(i.name))) {
      originData.current.APIRequestStructure.headers = parseData;
      setUpdate((p) => ++p);
    }
  };

  const onPathParamChange = (data: string) => {
    let parseData = [];
    try {
      parseData = JSON.parse(data);
    } catch (e) {}
    originData.current.APIRequestStructure.path = parseData;
    setUpdate((p) => ++p);
  };

  const handleData = (
    post: { [x: string]: any; [x: number]: {} | {}; type?: any },
    paramType = undefined
  ) => {
    if (originData.current.APIRequestStructure?.post) {
      const reqPost = originData.current.APIRequestStructure.post;
      if (paramType) {
        reqPost.type = paramType;
        reqPost[paramType] = reqPost[paramType] || post[paramType];
      } else {
        originData.current.APIRequestStructure.post = { ...reqPost, ...post };
      }
    } else {
      originData.current.APIRequestStructure = {
        ...originData.current.APIRequestStructure,
        post,
      };
    }
  };
  const onAPIParamDimensionChange = (
    data: string,
    post_type: string,
    paramType: string
  ) => {
    let parseData = (method || "get").toLocaleLowerCase() === "get" ? [] : {};
    try {
      if (paramType === "file" || paramType === "raw") {
        parseData = data;
      } else {
        parseData = JSON.parse(data);
      }
    } catch (e) {}
    if (
      (method || "get").toLocaleLowerCase() === "get" ||
      post_type === "post_query"
    ) {
      // @ts-ignore
      if (!parseData.some((i) => isEmpty(i.name))) {
        originData.current.APIRequestStructure.query = parseData;
        setUpdate((p) => ++p);
      }
    } else {
      const post = {
        type: paramType,
        [paramType]: parseData,
      };
      handleData(post);
      setUpdate((p) => ++p);
    }
  };

  const onAPIParamsChange = (data: string) => {
    let parseData = {};
    try {
      parseData = JSON.parse(data);
    } catch (e) {}
    originData.current.APIResponseStructure = parseData;
    setUpdate((p) => ++p);
  };

  const onParamTypeChange = (paramType: string | undefined) => {
    const post = {
      type: paramType,
      // @ts-ignore
      [paramType]:
        paramType === "form"
          ? defaultForm
          : paramType === "json"
          ? DEFAULT_JSON_AREA
          : {},
    };
    // @ts-ignore
    handleData(post, paramType);
  };

  const showViewInstruction = () => {
    setShowInstruction(true);
  };

  const handleCancel = () => {
    setShowInstruction(false);
  };

  const getDataPath: string = useMemo(() => {
    return JSON.stringify(
      JSON.parse(metaJson)?.APIRequestStructure?.path ?? []
    );
  }, [props.metaJson]);

  return (
    <div ref={ref}>
      <DetailCard title="入参设置">
        {typeer !== "jh" && (
          <span
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={showViewInstruction}
          >
            查看入参说明
          </span>
        )}

        {(!isEdit || !isEmpty(originData.current)) && (
          <APIParamsRequestForm
            isCloseUpdate={isCloseUpdate}
            showConstantVal={false}
            method={(method || "get").toLocaleLowerCase()}
            onParamTypeChange={onParamTypeChange}
            handleImportTransformJson={(json: any, type: any) =>
              handleIndicatorImportJson(json, type, DEFAULT_JSON_AREA)
            }
            dataHeaders={JSON.stringify(
              originData.current?.APIRequestStructure?.headers ?? []
            )}
            dataPath={getDataPath}
            onHeadersChange={debounce(onHeadersParamChange, 100)}
            onPathChange={debounce(onPathParamChange, 100)}
            data={originData.current.APIRequestStructure}
            onChange={debounce(onAPIParamDimensionChange, 100)}
            metaJson={metaJson}
          />
        )}
        <Modal
          title="查看入参说明"
          visible={showInstruction}
          onCancel={handleCancel}
          width={750}
          okButtonProps={{ disabled: true }}
          footer={[
            <Button type="primary" onClick={handleCancel}>
              关闭
            </Button>,
          ]}
        >
          <Form labelCol={{ span: 4 }} form={form}>
            <Form.Item name="payloadDefinition" label="入参schema定义">
              <TextArea rows={8} />
            </Form.Item>
            <Form.Item name="payloadDefinitionDescription" label="入参定义说明">
              <TextArea rows={8} />
            </Form.Item>
          </Form>
        </Modal>
      </DetailCard>
      <DetailCard title={"出参设置"}>
        <div style={{width:"100%",textAlign:"center"}}>
          <Radio.Group
            options={options}
            onChange={(e) => {
              setValue4(e.target.value);
              props.onType(e.target.value)
            }}
            value={value4}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        {value4 === "JSON" && (
          <>
            {(!isEdit || !isEmpty(originData.current)) && (
              <HttpParamsForm
                showConstantVal={false}
                isCloseUpdate={true}
                handleImportTransformJson={(json: any, type: any) =>
                  handleIndicatorImportJson(json, type, DEFAULT_SCHEMA)
                }
                data={JSON.stringify(originData.current.APIResponseStructure)}
                onChange={debounce(onAPIParamsChange, 500)}
              />
            )}
          </>
        )}
      </DetailCard>
    </div>
  );
};
export default React.forwardRef(RestArgInput);
