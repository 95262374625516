import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import {
  Form,
  Input,
  Select,
  Button,
  Table,
  Modal,
  message,
  Popconfirm,
  Tooltip
} from "antd";
// import { useParams } from "react-router-dom";
import { getApprovelist, Lexamine, postApprove } from "../../api/examine";
import dayjs from "dayjs";
const Examine: React.FC = () => {
  // const { id } = useParams<{ id: any }>();
  // const [total, setTotal] = useState<any>();
  const [form] = Form.useForm();
  const [formData] = Form.useForm();
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [listLoad, setlistLoad] = useState(true);
  const [billCode, setBillcode] = useState(null);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [list, setList] = useState<Lexamine[]>([]);
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "申请单号",
      dataIndex: "bill_code",
      key: "bill_code",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>{text}</div>
        </div>
      ),
    },
    {
      title: "申请方",
      dataIndex: "apply_user_info",
      key: "apply_user_info",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>{text.nick_name}</div>
        </div>
      ),
    },
    // {
    //   title: "审批人",
    //   dataIndex: "time",
    //   key: "time3",
    //   render: (record: any) => (
    //     <div>
    //       <div style={{ color: "#777777" }}>小明</div>
    //     </div>
    //   ),
    // },
    {
      title: "申请时间",
      dataIndex: "apply_time",
      key: "apply_time",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>
            {dayjs(new Date(text)).format("YYYY-MM-DD")}
          </div>
        </div>
      ),
    },
    // {
    //   title: "申请类型",
    //   dataIndex: "approve_status",
    //   key: "approve_status",
    //   render: (text: any, record: any) => (
    //     <div>
    //       <div style={{ color: "#777777" }}>
    //         {text == 0
    //           ? "全部"
    //           : [text == 1 ? "申请中" : [text == 2 ? "通过" : "拒绝"]]}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "审批备注",
      dataIndex: "remark",
      key: "remark",
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <div style={{ color: "#777777",maxWidth:"100px",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap" }}>{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "审批状态",
      dataIndex: "approve_status",
      key: "approve_status",
      render: (text: any, record: any) => (
          <div style={{ color: "#777777" }}>
            {text === 0
              ? <div> 全部 </div>
              : [text === 1 ? <div className={styles["approve-wait"]}> 申请中 </div> : [text === 2 ? <div className={styles["approve-pass"]}> 通过 </div> : <div className={styles["approve-reject"]}> 拒绝 </div>]]}
          </div>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) =>(
        // console.log(record);
        record.can_approve===false?"":
        <Popconfirm title="是否通过审批？" okText="通过" cancelText="不通过"
        onConfirm={() => handPass(record.bill_code)}
        onCancel={()=>{setDialog(true)
          setBillcode(record.bill_code)
        }}
        >
          <a>审批</a>
        </Popconfirm>
      ),
    },
  ];
  const option = [
    {
      id: "0",
      name: "全部",
    },
    {
      id: "1",
      name: "申请中",
    },
    {
      id: "2",
      name: "通过",
    },
    {
      id: "3",
      name: "拒绝",
    },
  ];

  // const exaClick = () => {
  //   setVisible(true);
  // };
  // const approveClick = (data: any) => {
  //   // setVisible(true);
  //   setBillcode(data);
  // };
  const handPass = (data:any) => {
    // setBillcode(data)
    postApprove({
      bill_code: data,
      approve_status: 2,
    }).then((res) => {
      console.log(res);
      if (res.code === 200) {
        setVisible(false);
        message.success("审批成功");
        checkList(null, null, null, 1, 10);
      }
    });
  };
  const handnoPass = () => {
    setDialog(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handlClose = () => {
    setDialog(false);
  };
  //重置
  const onReset = () => {
    form.resetFields();
    setlistLoad(true);
    checkList(null, null, null, 1, 10);
  };
  //查询
  const queryClick = () => {
    setlistLoad(true);
    checkList(
      form.getFieldValue("bill_code"),
      form.getFieldValue("approve_status"),
      form.getFieldValue("dp_address"),
      1,
      10
    );
  };
  const handleOk = () => {
    postApprove({
      bill_code: billCode,
      approve_status: 3,
      reason: formData.getFieldValue("reason"),
    }).then((res) => {
      if (res.code === 200) {
        setVisible(false);
        setDialog(false);
        message.success("提交成功");
        checkList(null, null, null, 1, 10)
      }
    });
  };
  //封装列表查询的方法
  const checkList = async (
    bill_code: any,
    approve_status: any,
    dp_address: any,
    page: any,
    page_size: any
  ) => {
    await getApprovelist({
      bill_code: bill_code,
      approve_status: approve_status,
      dp_address: dp_address,
      page: page,
      page_size: page_size,
    }).then((res) => {
      // console.log(res.data);
      if (res.code === 200) {
        setList(res.data);
        setlistLoad(false);
      }
    });
  };
  //列表
  useEffect(() => {
    checkList(null, null, null, pageParam.page, pageParam.pageSize);
  }, [pageParam.page, pageParam.pageSize]);
  return (
    <div style={{ width: "100%", height: "100%" }} className='centers-router'>
      <div className={styles["pre"]}>
        {/* <div className={styles["title"]}>Examine</div> */}
        <div>
          <Form  form={form} layout="inline">
            {/* <Row>
              <Col> */}
                <Form.Item label="申请单号" name="bill_code">
                  <Input></Input>
                </Form.Item>
              {/* </Col> */}
              {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="申请类型" name="approve_status">
                  <Select>
                    {option.map((item)=>{
                      return (<Option value={item.id}>{item.name}</Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
              {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}> */}
                <Form.Item label="申请类型" name="approve_status">
                  <Select style={{ width: 200 }}>
                    {option.map((item) => {
                      return <Option value={item.id} key={item.id}>{item.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              {/* </Col> */}
              {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}> */}
                <Form.Item label="dp_address" name="dp_address">
                  <Input></Input>
                </Form.Item>
              {/* </Col> */}
              {/* <Col offset={1}> */}
                <Button type="primary" onClick={queryClick}>
                  查询
                </Button>
                <Button style={{ marginLeft: "16px" }} onClick={onReset}>
                  重置
                </Button>
              {/* </Col> */}
            {/* </Row> */}
          </Form>
        </div>
        <div>
          
          <Table
            loading={listLoad}
            columns={columns}
            style={{ marginTop: "10px" }}
            dataSource={list}
            pagination={{
              // total: total,
              pageSize: pageParam.pageSize,
              current: pageParam.page,
              showQuickJumper: true,
              showSizeChanger: true,
              // showTotal: (total) => `共 ${total}条记录`,
              onChange: (page, size) => setPageParam({ page, pageSize: size }),
            }}
          />
        </div>
        <Modal
          visible={visible}
          title="请审批"
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handnoPass}>
              审批不通过
            </Button>,
            <Button key="submit" type="primary" onClick={handPass}>
              审批通过
            </Button>,
          ]}
        />
        <Modal
          onCancel={handlClose}
          visible={dialog}
          title="不通过原因"
          onOk={handleOk}
        >
          <Form form={formData}>
            <Form.Item label="原因" name="reason">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default Examine;
