const actionType = {
    //redux demo
    CALC: "CALC",
    CALC1: "CALC1",
    GETSYSTEMDATA: "GETSYSTEMDATA",
    //接口
    POSTBODY: "POSTBODY",
    POSTQUERY: "POSTQUERY",
    POSTHEADERS: "POSTHEADERS",
    POSTPATH: "POSTPATH",
    GETPARAMS: "GETPARAMS",
    GETHEADERS: "GETHEADERS",
    GETPATH: "GETPATH",
    GETOBJ: "GETOBJ",
    POSTOBJ: "POSTOBJ"
}
export default actionType