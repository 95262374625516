
import React, { useState, useEffect } from "react";
import { Form, Select, Input, Button, Table, Space, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import qs from 'qs';
import {
  getServiceType,
  getOtherServicList,
  deleteServiceItem,
  changeStatus
} from "../../api/serviceManagement";
import "./index.scss";
const ServiceFindList: React.FC = () => {
  const states = {
    "0": "已下架",
    "1": "已上架",
  }
  const { Option } = Select;
  const [form] = Form.useForm();
  const [typeList, setTypeList] = useState([]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const columns = [
    {
      title: '序号',
      dataIndex: 'dataIndex',
      width: 80,
      render: (text: any, record: any, index: any) => (pageParam.page - 1) * pageParam.pageSize + (index + 1),
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress"
    },
    {
      title: "API名称",
      dataIndex: "apiName"
    },
    {
      title: "版本号",
      dataIndex: "apiVersion",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: string) => {
        return text == '1' ? '已上架' : '已下架'
      }
    },
    {
      title: "后端服务类型",
      dataIndex: "connectorType",
    },
    {
      title: "最近更新时间",
      dataIndex: "updateTime"
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (id: number, record: any) => (
        <Space className="table-btn-view">
          <Link to={{
            pathname: '/service/detail',
            search: qs.stringify({
              id: record.id
            })
          }}><Button type="link">详情</Button></Link>
        </Space>
      )
    },
  ];

  useEffect(() => {
    getServiceTypeList();
    // getList();
  }, []);

  useEffect(() => {
    getList();
  }, [pageParam.page, pageParam.pageSize])

  const getServiceTypeList = () => {
    getServiceType().then((res) => {
      setTypeList(res?.data);
    });
  }

  const onRemove = (id: number) => {
    deleteServiceItem({ id }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功!");
        getList();
      }
    });
  }

  const onChangeStatus = (id: number, status: number) => {
    changeStatus({ id, status }).then((res) => {
      if (res.code === 200) {
        message.success(`${status == 1 ? '上架' : '下架'}成功!`);
        getList();
      }
    });
  }


  const getList = (values = {}) => {
    setlistload(true);
    // Object.keys(values).map(item => {
    //   values[item] = values[item] || undefined
    // })
    getOtherServicList({
      ...values,
      connectorType: form.getFieldValue("connectorType"),
      ...pageParam
    }).then((res: any) => {
      if (res.code == 200) {
        setlistload(false);
        setServiceList(res?.data);
        setTotal(res.pagination.total);
      }
    })
  }

  const onSearch = () => {
    form.validateFields().then((values) => {
      setPageParam({
        page: 1,
        pageSize: 10,
      });
      getList(values);
    })
  }

  const onReset = () => {
    form.resetFields();
    setPageParam({
      page: 1,
      pageSize: 10
    });
    getList();
  }

  return (
    <div style={{ padding: "20px" }} className="center-service-management">
      <div className="center-service-management_title">
        <div className="center-service-management_title_split"></div>
        <div>服务接口查找</div>
      </div>
      <div style={{ height: 50, marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="dpAddress" label="DataAddress">
            <Input allowClear placeholder="请输入DataAddress" style={{ width: 180 }} />
          </Form.Item>
          <Form.Item name="apiName" label="API名称">
            <Input allowClear placeholder="请输入API名称" style={{ width: 140 }} />
          </Form.Item>
          <Form.Item name="connectorType" label="后端服务类型">
            <Select allowClear placeholder="请选择" style={{ width: 180 }}>
              {typeList.map((item: any) => {
                return (
                  <Option value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button style={{ marginRight: "16px" }} type="primary" onClick={onSearch}>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={serviceList}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />

      </div>
    </div>
  );
};
export default ServiceFindList;
