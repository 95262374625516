//@ts-nocheck
import * as React from "react";
import { AllHTMLAttributes, useEffect, useState } from "react";
import { FormItemProps } from "antd/es/form";
import {
  Button,
  Form,
  message,
  Row,
  Select,
  Input,
  Switch,
  Tooltip,
} from "antd";
import { PublishContext } from "@/pages/Service/PublishServe";
import RestArgInput from "./component/restArgInput";
import "../index.scss";
import DetailCard from "../../../../component/DetailCard";
import qs from "qs";
import {
  addServiceThird,
  addServiceManage,
  editServiceManage,
} from "../../../../api/serviceManagement";
import { QuestionCircleOutlined } from "@ant-design/icons";
import AceEditor from "@/component/SchemaEditor/JSONSchemaEditor/components/AceEditor/AceEditor.js";

const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};
let timer: string | number | NodeJS.Timeout | null | undefined = null;
let mockJson: string | null = null;
let isFormatTrue = true;
type FrontendConfigProps = { appApiId: any } & AllHTMLAttributes<HTMLElement> &
  FormItemProps;
const FrontendConfig: React.FC<FrontendConfigProps> = (props) => {
  const { state, dispatch } = React.useContext(PublishContext);
  const { currentStep, frontendConfig, uuid, extraButton } = state;
  // console.log(frontendConfig);

  const [metaJson, setMetaJson] = useState<any>(
    JSON.stringify(frontendConfig.metaJson)
  );
  const [methodValue, setmethodValue] = useState<any>("GET");
  const [requestType,setrequestType] = useState<any>(1)
  const [switchChecked, setSwitchChecked] = useState(true);
  const [typeValue,settypeValue] = useState('JSON')
  const { appApiId } = props;
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const urlInfo = qs.parse(window.location.search.replace("?", ""));
  const handleInputEditor = (e: { text: any; jsonData: any; format: any }) => {
    isFormatTrue = e.format === true || e.text === "" ? true : false;
    if (!e.text === undefined || e.format !== true) return;
    window["mockJson"] = e.text;
    mockJson = e.text;
  };
  const onPreviousStep = () => {
    dispatch && dispatch({ type: "NEXT_STEP", payload: "backendConfig" });
  };
  useEffect(() => {
    const {
      accessProtocol,
      requestMethod,
      metaJson,
      isMock,
      mockJson,
      requestPath,
      reqAdapterScript,
      respAdapterScript,
      asyncFlag
    } = frontendConfig;
    form.setFieldsValue({
      accessProtocol,
      requestMethod,
      requestPath,
      metaJson,
      isMock,
      mockJson,
      reqAdapterScript,
      respAdapterScript,
      asyncFlag
    });
    setSwitchChecked(!!isMock);
    setmethodValue(requestMethod);
    setMetaJson(metaJson);
  }, [frontendConfig]);

  useEffect(() => {
    return () => {
      timer = null;
      mockJson = null;
    };
  }, []);

  //提交当前步骤表单数据
  const onNextStep = () => {
    form.validateFields().then((values) => {
      console.log(typeValue);
      
      if (!isFormatTrue) {
        message.error("请输入正确的Mock返回数据");
        return;
      }
      dispatch && dispatch({ type: "BEGIN_QUERY" });
      values["mockJson"] =
        typeof window["mockJson"] === "string" ? window["mockJson"] : undefined;
      addServiceThird({ uuid, metaJson, ...values ,fileType:typeValue}).then((res) => {
        if (res.code == 200) {
          if (window["mockJson"]) delete window["mockJson"];
          // appApiId ? editServiceManage({ id: appApiId, uuid }).then((res) => {
          //   if (res.code == 200) {
          //     dispatch && dispatch({ type: 'DISPATCH_SERVICE_SUCCESS', payload: res.data });
          //     dispatch && dispatch({ type: 'NEXT_STEP', payload: 'publishingService' });
          //   }
          // })
          //   : addServiceManage({ uuid }).then((res) => {
          //     if (res.code == 200) {
          //       dispatch && dispatch({ type: 'DISPATCH_SERVICE_SUCCESS', payload: res.data });
          //       dispatch && dispatch({ type: 'NEXT_STEP', payload: 'publishingService' });
          //     }
          //   })
          if (appApiId) {
            if (urlInfo.referer === "apiGray") {
              editServiceManage({ dpAddress: appApiId, uuid }).then((res) => {
                if (res.code == 200) {
                  dispatch &&
                    dispatch({
                      type: "DISPATCH_SERVICE_SUCCESS",
                      payload: res.data,
                    });
                  dispatch &&
                    dispatch({
                      type: "NEXT_STEP",
                      payload: "publishingService",
                    });
                }
              });
            } else {
              editServiceManage({ id: appApiId, uuid }).then((res) => {
                if (res.code == 200) {
                  dispatch &&
                    dispatch({
                      type: "DISPATCH_SERVICE_SUCCESS",
                      payload: res.data,
                    });
                  dispatch &&
                    dispatch({
                      type: "NEXT_STEP",
                      payload: "publishingService",
                    });
                }
              });
            }
          } else {
            addServiceManage({ uuid }).then((res) => {
              if (res.code == 200) {
                dispatch &&
                  dispatch({
                    type: "DISPATCH_SERVICE_SUCCESS",
                    payload: res.data,
                  });
                dispatch &&
                  dispatch({ type: "NEXT_STEP", payload: "publishingService" });
              }
            });
          }
        }
      });
    });
  };

  const onInputChange = (e: any) => {
    let value = e.currentTarget.value || "";
    let reg = /{([a-zA-Z0-9*%+-_.]+)}/g;
    let arr = [];
    let matched = null;
    while ((matched = reg.exec(value)) !== null) {
      arr.push(matched[1]);
    }
    let _frontendConfig = JSON.parse(JSON.stringify(frontendConfig));
    let json = JSON.parse(_frontendConfig.metaJson) || {};
    let new_arr: any[] = [];
    arr.map((item) => {
      new_arr.push({
        id: Number(Math.random().toString().substr(3, 3) + Date.now()).toString(
          36
        ),
        paramKey: item,
        paramMask: "",
        paramName: "",
        paramRequired: "必须",
        paramType: "string",
      });
    });
    json["APIRequestStructure"] = {
      ...(json["APIRequestStructure"] || {}),
      path: new_arr,
    };
    // json['APIRequestStructure']['path'] = new_arr
    _frontendConfig.metaJson = JSON.stringify(json);
    timer && clearTimeout(timer);
    timer = setTimeout(() => setMetaJson(_frontendConfig.metaJson), 800);
    // dispatch && dispatch({
    //   type: 'EDIT_PAGE_INFO', payload: {
    //     ...state,
    //     frontendConfig: _frontendConfig
    //   }
    // })
  };

  return (
    <div
      style={{ display: currentStep === "frontendConfig" ? "block" : "none" }}
    >
      <Form {...layout} form={form}>
        <div style={{ marginTop: 30 }}>
          <DetailCard title={"API接入配置"}>
            <div className="frontend-config-layout">
              <div className="frontend-config-box">
                <Form.Item
                  label={"接入协议"}
                  name="accessProtocol"
                  rules={[{ required: true, message: "请选择接入协议" }]}
                >
                  <Select>
                    <Option value="HTTP">http</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="frontend-config-box">
                <Form.Item
                  label={"请求方式"}
                  name="requestMethod"
                  rules={[{ required: true, message: "请选择请求方式" }]}
                >
                  <Select
                    onChange={(e) => {
                      setmethodValue(e);
                    }}
                  >
                    <Select.Option value="POST">post</Select.Option>
                    <Select.Option value="GET">get</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="frontend-config-box">
                <Form.Item
                  label={"请求模式"}
                  name="asyncFlag"
                  rules={[{ required: true, message: "请选择请求方式" }]}
                >
                  <Select
                    // onChange={(e) => {
                    //   setmethodValue(e);
                    // }}
                  >
                    <Select.Option value={0}>同步</Select.Option>
                    <Select.Option value={1}>异步</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="frontend-config-box tscl">
                <Form.Item
                  label={"请求路径"}
                  name="requestPath"
                  rules={[{ required: true, message: "请输入请求路径" }]}
                >
                  <Input
                    onChange={onInputChange}
                    addonAfter={
                      <div>
                        {
                          "请求Path可以包含请求参数，用{ }标识，比如/user/query/{areaCode}/{userId}，支持 * % + - _ . 等特殊字符"
                        }
                      </div>
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </DetailCard>
          <RestArgInput
            currentStep={currentStep}
            initialValue={frontendConfig.metaJson}
            metaJson={metaJson}
            method={methodValue}
            protocolType={frontendConfig.protocolIn}
            appApiId={appApiId}
            onChange={(val: any) => setMetaJson(val)}
            onType={(val:any)=>{
              console.log(val);
              settypeValue(val)
            }}
          />
          <DetailCard title={"返回设置"}>
            <div className="callback-layout">
              <Form.Item
                label={
                  <div>
                    是否开启Mock
                    <Tooltip
                      title={
                        "启用模拟返回，将直接将Mock返回数据作为出参进行响应，会在请求后端服务"
                      }
                    >
                      <QuestionCircleOutlined
                        style={{ marginLeft: "7px", color: "#165DFF" }}
                      />
                    </Tooltip>
                  </div>
                }
                name="isMock"
                initialValue={true}
                // rules={[{ required: true, message: '请选择开启Mock' }]}
              >
                <Switch
                  checked={switchChecked}
                  onChange={(value) => setSwitchChecked(value)}
                />
              </Form.Item>
              <Form.Item label={"Mock返回数据"} name="mockJson">
                <div>
                  <AceEditor
                    data={frontendConfig?.mockJson || mockJson || ""}
                    mode="json"
                    onChange={handleInputEditor}
                  />
                </div>
              </Form.Item>
            </div>
          </DetailCard>
          <DetailCard title={"协议适配"}>
            <Form.Item label="自定义请求协议适配" name={"reqAdapterScript"}>
              <TextArea
                showCount
                style={{ height: 120 }}
                // onChange={onChange}
                placeholder="请输入groovy脚本"
              />
            </Form.Item>
            <Form.Item label="自定义响应协议适配" name={"respAdapterScript"}>
              <TextArea
                showCount
                style={{ height: 120 }}
                // onChange={onChange}
                placeholder="请输入groovy脚本"
              />
            </Form.Item>
          </DetailCard>
        </div>
      </Form>
      <div className="publish-agent-action">
        {extraButton}
        <Button
          onClick={onPreviousStep}
          style={{ width: 100, marginRight: 16 }}
        >
          上一步
        </Button>
        <Button type={"primary"} onClick={onNextStep} style={{ width: 100 }}>
          发布
        </Button>
      </div>
    </div>
  );
};
export default FrontendConfig;
