import React from 'react'
import {Provider} from 'react-redux'
import App from './App.js'
import utils from './utils'
import moox from 'moox'
import schema from './models/schema'
import PropTypes from 'prop-types'

const JSONEditor = (config = {}) => {
  if (config.lang) utils.lang = config.lang;
  if (config.openValue){
    schema.state.open={...schema.state.open,...config.openValue}
  }
  const Model = moox({schema})
  if (config.format) {
    Model.__jsonSchemaFormat = config.format
  } else {
    Model.__jsonSchemaFormat = utils.format
  }

  if (config.mock) {
    Model.__jsonSchemaMock = config.mock
  }
  const store = Model.getStore();
  const Component = (props) => {
    return <Provider store={store} className="schema-wrapper">
      <App Model={Model} {...props} />
    </Provider>
  }

  Component.propTypes = {
    data: PropTypes.string,
    onChange: PropTypes.func,
    showEditor: PropTypes.bool,
    showDimensions: PropTypes.bool,
    showConstantVal: PropTypes.bool,
    handleImportJson: PropTypes.func,
    onChangeOpenValue:PropTypes.func
  }
  return Component;

}

export default JSONEditor;

