import React, { useEffect, useState, useCallback } from "react";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import axios from "axios";
import {ReactComponent as Img} from "../../assets/img/logo.svg"
import {
  Empty,
  Button,
  Modal,
  Input,
  Alert,
  Table,
  Divider,
  Form,
  message,
  Select,
  Descriptions,
  Tooltip,
} from "antd";
import {
  getcredentialdetails,
  postcredentialcreate,
  getdownloaddecode,
  getdownloaddeexamine,
  postuploaddecode,
  postuploadexamine,
} from "../../api/credential";
const Credentials: React.FC = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seeModalOpen, setseeModalOpen] = useState(false);
  const [flag, setflag] = useState(false);
  const [tabFlg, settabFlg] = useState(false);
  const [pzList, setpzList] = useState<any>();
  const [ipAble, setipAble] = useState(false);
  const [dialogtitle, setdialogtitle] = useState("");
  const [myList, setmyList] = useState<any>([]);
  const [xymysf, setxymysf] = useState("");
  const [xybw, setxybw] = useState("");
  const [xygy, setxygy] = useState("");
  const [bwgy, setbwgy] = useState("");
  const [qqyq, setqqyq] = useState("");
  const [qqbw, setqqbw] = useState("");
  const [qqmy, setqqmy] = useState(null);
  const [bwmy, setbwmy] = useState(null);
  const [dialogType, setdialogType] = useState("");
  const download = [
    {
      id: 2,
      type: "响应验签密钥",
      arithmetic: xymysf,
      descriptions: xygy,
      title:
        "中枢调用您提供的服务会将请求参数进行加签，并将加签信息传入您提供的服务。您在提供的服务中可对加签信息进行验签，以验证中枢发给您的信息是否被篡改",
    },

    {
      id: 2,
      type: "请求报文加密密钥(加密公钥)",
      arithmetic: xybw,
      descriptions: bwgy,
      title:
        "用户下载平台公钥，使用公钥对请求body加密",
    },
  ];
  const upload = [
    {
      id: bwmy,
      type: "请求验签密钥",
      arithmetic: bwmy,
      my: qqyq,
      descriptions: qqyq,
      title:
        "用户生成一对密钥，自己保存私钥，上传公钥。发起请求时，用私钥进行加签，中枢会使用用户上传的对应公钥进行验签",
    },
    {
      id: qqmy,
      type: "响应报文加密密钥(加密公钥)",
      arithmetic: qqmy,
      my: qqbw,
      descriptions: qqbw,
      title:
        "用户生成一对密钥，自己保存私钥，上传公钥。中枢会使用这个公钥对响应内容进行加密，用户收到加密报文后使用对应的私钥进行解密",
    },
  ];
  const handleOk = () => {
    form.validateFields().then((data) => {
      postcredentialcreate({
        name: data.name,
        description: data.description,
      }).then((res) => {
        if (res.code === 200) {
          setIsModalOpen(false);
          detailMethods();
          message.success("创建成功!");
        }
      });
    });
  };

  const handleCancel = () => {
    form.resetFields(); 
    setIsModalOpen(false);
  };
  const detailMethods = useCallback(() => {
    getcredentialdetails().then((res) => {
      if (res.code === 200) {
        setxygy(res.data.platformResponseExamineSecretKey);
        setxymysf(res.data.platformResponseExamineAlgorithm);
        setxybw(res.data.platformResponseDecodeAlgorithm);
        setbwgy(res.data.platformResponseDecodeSecretKey);
        setqqyq(res.data.requestExamineSecretKey);
        setqqbw(res.data.requestDecodeSecretKey);
        setqqmy(res.data.requestDecodeAlgorithm);
        setbwmy(res.data.requestExamineAlgorithm);
        if (res.data.secretKey === null) {
          settabFlg(false);
        } else {
          setpzList(res.data);
          settabFlg(true);
        }
      }
    });
  }, []);
  useEffect(() => {
    detailMethods();
  }, []);
  useEffect(() => {
    var ipt: any = document.getElementById("ipt");
    if (ipt !== null) {
      if (flag) {
        ipt.type = "text";
      } else {
        ipt.type = "password";
      }
    }
  }, [flag]);
  const columns = [
    {
      title: "密钥类型",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => (
        <>
          <span>{text}</span>
          <Tooltip title={record.title} trigger={["click", "hover"]}>
            <QuestionCircleOutlined
              // title={record.title}
              style={{ marginLeft: "7px", color: "#165DFF", cursor: "pointer" }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: "密钥算法",
      dataIndex: "arithmetic",
      key: "arithmetic",
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (id: string, record: any) => (
        <>
          <a onClick={() => dailogMethod("see", record)}>查看</a>
          <Divider type="vertical" />
          <a onClick={() => downloadKey(record)}>下载公钥</a>
        </>
      ),
    },
  ];
  const dailogMethod = (type: any, record: any) => {
    setdialogType(record.type);
    if (record.type === "请求验签密钥" || record.type === "响应验签密钥") {
      var brr = [
        {
          value: 1,
          label: "RAS",
        },
        {
          value: 2,
          label: "SM2",
        },
      ];
      setmyList(brr);
    } else {
      var arr = [
        {
          value: 1,
          label: "AES",
        },
        {
          value: 2,
          label: "SM4",
        },
      ];
      setmyList(arr);
    }
    if (type === "see") {
      setseeModalOpen(true);
      setipAble(true);
      setdialogtitle("查看公钥");
      form.setFieldsValue({
        mylx: record.type,
        mysf: record.arithmetic,
        gy: record.descriptions,
      });
    } else if (type === "uploadagain") {
      setseeModalOpen(true);
      setipAble(false);
      setdialogtitle("重新上传公钥");
      form.setFieldsValue({
        mylx: record.type,
        mysf: record.id,
        gy: record.descriptions,
      });
    } else {
      form.resetFields();
      form.setFieldsValue({
        mylx: record.type,
        // mysf:record.arithmetic,
        // gy:record.descriptions
      });
      setseeModalOpen(true);
      setipAble(false);
      setdialogtitle("上传公钥");
    }
  };
  const downloadKey = (id: any) => {
    console.log(id);

    if (id.type === "响应验签密钥") {
      axios({
        method: "GET",
        url: `/api/download/examine/key`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("__token__"),
        },
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          const blob = new Blob([res.data]);
          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = `${new Date()}.txt`; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    } else {
      axios({
        method: "GET",
        url: `/api/download/decode/key`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("__token__"),
        },
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          const blob = new Blob([res.data]);
          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = `${new Date()}.txt`; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    }
  };
  const columns_ = [
    {
      title: "密钥类型",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => (
        <>
          <span>{text}</span>
          <Tooltip title={record.title} trigger={["click", "hover"]}>
            <QuestionCircleOutlined
              // title={record.title}
              style={{ marginLeft: "7px", color: "#165DFF", cursor: "pointer" }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: "密钥算法",
      dataIndex: "id",
      // key: "id",
      render: (id: any, record: any) => (
        <>
          {id === 0 && record.type === "请求验签密钥" && ""}
          {id === 1 && record.type === "请求验签密钥" && "RSA"}
          {id === 2 && record.type === "请求验签密钥" && "SM2"}
          {id === 0 && record.type === "响应报文加密密钥(加密公钥)" && ""}
          {id === 1 && record.type === "响应报文加密密钥(加密公钥)" && "ASE"}
          {id === 2 && record.type === "响应报文加密密钥(加密公钥)" && "SM4"}
        </>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (id: any, record: any) => (
        <>
          {/* {id === 1 ? ( */}
          <div>
            {record.my == null ? (
              <a onClick={() => dailogMethod("upload", record)}>上传公钥</a>
            ) : (
              <div>
                <a onClick={() => dailogMethod("see", record)}>查看</a>
                <Divider type="vertical" />
                <a onClick={() => dailogMethod("uploadagain", record)}>
                  重新上传公钥
                </a>
              </div>
            )}
          </div>
          {/* ) : (
            <div>
              <a onClick={()=>dailogMethod("see",record)}>查看</a>
              <Divider type="vertical" />
              <a>重新上传公钥</a>
            </div>
          )} */}
        </>
      ),
    },
  ];
  const onFinish = () => {
    console.log(1);
    
    if (dialogtitle === "查看公钥") {
      setseeModalOpen(false);
    } else {
      if (dialogType === "请求验签密钥") {
        form.validateFields().then((data) => {
          postuploadexamine({
            algorithm: data.mysf,
            secretKey: data.gy,
          }).then((res) => {
            if (res.code === 200) {
              setseeModalOpen(false);
              message.success("上传成功");
              detailMethods();
            } else {
              message.error(res.message);
            }
          });
        });
      } else if (dialogType === "响应报文加密密钥(加密公钥)") {
        form.validateFields().then((data) => {
          postuploaddecode({
            algorithm: data.mysf,
            secretKey: data.gy,
          }).then((res) => {
            if (res.code === 200) {
              setseeModalOpen(false);
              message.success("上传成功");
              detailMethods();
            }
          });
        });
      } else {
        form.resetFields();
        setseeModalOpen(false);
      }
    }
  };
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>我的凭证</div>
        </div>
      </div>
      {tabFlg ? (
        <div>
          <div style={{ marginLeft: "10px", marginTop: "20px" }}>
            <div style={{ fontSize: "14px" }}>接入凭证信息</div>
            <div className={styles["allInfo"]}>
              <div>
                <span>凭证名称：</span>
                {pzList.name}
              </div>
              <div>
                <span>凭证说明：</span>
                {pzList.description}
              </div>
              <div>
                <span>
                  <span>AccessKey：</span>
                  <span style={{ color: "#4E5969" }}>{pzList.accessKey}</span>
                </span>
                <span style={{ marginLeft: "239px" }}>
                  <span style={{ color: "#4E5969" }}>SecretKey：</span>
                  <span style={{ color: "#4E5969" }}>
                    <input
                      type="password"
                      id="ipt"
                      style={{
                        border: "none",
                        background: "white",
                        width: "240px",
                      }}
                      value={pzList.secretKey}
                      disabled
                    />
                    {flag && (
                      <EyeOutlined
                        onClick={() => {
                          setflag(false);
                        }}
                      />
                    )}
                    {!flag && (
                      <EyeInvisibleOutlined
                        onClick={() => {
                          setflag(true);
                        }}
                      />
                    )}
                  </span>
                </span>
              </div>
              <div>
                <span>有效期：</span>
                {pzList.effectiveDate}~{pzList.expiryDate}
              </div>
            </div>
          </div>
          <Alert
            style={{ marginTop: "20px" }}
            message="AccessKey、SecretKey作为调用者身份标识，在调用中枢API时作为必传参数，AccessSecret只能被下载一次，请妥善保存。"
            type="info"
            showIcon
          />
          <div style={{ marginTop: "40px" }}>
            <div style={{ fontSize: "14px", marginBottom: "10px" }}>
              下载平台密钥
            </div>
            <Table columns={columns} dataSource={download} pagination={false} />
          </div>
          <div style={{ marginTop: "40px" }}>
            <div style={{ fontSize: "14px", marginBottom: "10px" }}>
              上传自建密钥
            </div>
            <Table
              columns={columns_}
              // loading={listload}
              dataSource={upload}
              pagination={false}
            />
          </div>
        </div>
      ) : (
        <div>
          <Empty
            style={{ marginTop: "10%" }}
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 180,
            }}
            description={
              <span>
                暂无凭证，点击{" "}
                <a
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  获取
                </a>
              </span>
            }
          ></Empty>
          <Modal
            title="获取凭证"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form name="wrap" labelCol={{ flex: "90px" }} form={form}>
              <Form.Item
                label="凭证名称"
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="凭证说明" name="description">
                <TextArea rows={4} />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
      <Modal
        title={dialogtitle}
        visible={seeModalOpen}
        onOk={onFinish}
        onCancel={() => {
          form.resetFields();
          setseeModalOpen(false);
        }}
      >
        <Form name="wrap" labelCol={{ flex: "90px" }} form={form}>
          <Form.Item label="密钥类型" name="mylx" rules={[{ required: true }]}>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item label="密钥算法" name="mysf" rules={[{ required: true }]}>
            <Select disabled={ipAble}>
              {myList.map((item: any) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="公钥" name="gy" rules={[{ required: true }]}>
            <TextArea rows={4} disabled={ipAble} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default Credentials;
