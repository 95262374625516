import { getClient, postClient } from "./requestClient";
import { GET_APPLY_LIST, POST_APPLY } from "./index";

//apply申请列表
export const getApplyList = async (params: any) =>
  await getClient(GET_APPLY_LIST, params);


//申请资源
export const postApply = async (params: any) =>
  await postClient(POST_APPLY, params);

