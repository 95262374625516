import React from "react";
import { Badge } from "antd";
import "../index.scss"
const BadgeComponent: React.FC<any> = (props: any) => {
  const { name, color, value } = props;
  return (
    <div style={{marginTop:"5px"}}>
      <Badge color={color} text={name} style={{borderRadius:0}}/>
      <span style={{fontWeight:500,marginLeft:"6px",fontSize:"18px"}}>{value}</span>
    </div>
  );
};

export default BadgeComponent;
