// @ts-nocheck
import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Steps, Button, message, Radio, Form, Input, Select } from "antd";
// import { DraggableArea } from "react-draggable-tags";
import "./index.module.scss";
const ResourceportCreate: React.FC = () => {
  const [current, setCurrent] = React.useState(0);
  const [value, setValue] = React.useState(1);
  // const [dataList, setdataList] = React.useState([]);
  const { Step } = Steps;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [formLate] = Form.useForm();
  const { Option } = Select;
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 6 },
  };
  const initialTags = [
    { id: 1, content: "apple" },
    { id: 2, content: "olive" },
    { id: 3, content: "banana" },
    { id: 4, content: "lemon" },
    { id: 5, content: "orange" },
    { id: 6, content: "grape" },
    { id: 7, content: "strawberry" },
    { id: 8, content: "cherry" },
    { id: 9, content: "peach" },
  ];
  const steps = [
    {
      title: "填写基本信息",
      content: "First-content",
      description: "创建接口信息",
    },
    {
      title: "协议关联字段",
      content: "Second-content",
      description: "输入详细的渠道内容",
    },
    {
      title: "完成创建",
      content: "Last-content",
      description: "创建成功",
    },
  ];
  const itemRender = ({ tag, index }: any) => {
    return (
      <div className={styles["tag"]}>
        {tag.id}
        {index}
      </div>
    );
  };
  const changeMove = (tags: any) => {
    console.log(tags);
  };
  //渲染协议类型
  const mainApply = (data: any) => {
    switch (data) {
      case 1:
        return (
          <>
            <Form form={formLate} {...layout}>
              <Form.Item
                label="接口地址"
                name="url"
                rules={[{ required: true, message: "请填写接口地址" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Endpoint"
                name="endpoint"
                rules={[{ required: true, message: "请填写Endpoint" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="IsHTTPS"
                name="isHTTPS"
                rules={[{ required: true, message: "请填写IsHTTPS" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                //   onChange={handleChange}
                ></Select>
              </Form.Item>

              <Form.Item
                label="请求方式"
                name="post"
                rules={[{ required: true, message: "请选择请求方式" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                //   onChange={handleChange}
                >
                  <Option value="post">POST</Option>
                  <Option value="get">GET</Option>
                  <Option value="delete">DELETE</Option>
                  <Option value="put">PUT</Option>
                </Select>
              </Form.Item>
            </Form>
            {/* <div className={styles["Simple"]}>
                  <DraggableArea
                  isList
                    tags={initialTags}
                    render={itemRender}
                    onChange={changeMove}
                  />
                </div> */}
          </>
        );
      case 2:
        return (
          <>
            <Form form={formLate} {...layout}>
              <Form.Item
                label="数据库类型"
                name="databasetype"
                rules={[{ required: true, message: "请选择数据库类型" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                //   onChange={handleChange}
                >
                  <Option value="SQL Server">SQL Server</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="数据库版本"
                name="databaseversions"
                rules={[{ required: true, message: "请填写数据库版本" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="数据库名称"
                name="databasename"
                rules={[{ required: true, message: "请填写数据库名称" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="用户名"
                name="username"
                rules={[{ required: true, message: "请填写用户名" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: "请填写密码" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="数据表"
                name="datatable"
                rules={[{ required: true, message: "请填写数据表" }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </>
        );
      case 3:
        return (
          <>
            <Form form={formLate} {...layout}>
              <Form.Item
                label="消息中间件类型"
                name="messagetype"
                rules={[{ required: true, message: "请选择消息中间件类型" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                //   onChange={handleChange}
                >
                  <Option value="kafka">kafka</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="数据库版本"
                name="databaseversions"
                rules={[{ required: true, message: "请填写数据库版本" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="数据库主题"
                name="databasetheme"
                rules={[{ required: true, message: "请填写数据库主题" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="消息格式"
                name="messagetype"
                rules={[{ required: true, message: "请选择消息中间件类型" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                //   onChange={handleChange}
                >
                  <Option value="gsname">格式名称</Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        );
      default:
        return <></>;
    }
  };
  //渲染步骤条
  const tabList = (data: any, value: any) => {
    switch (data) {
      case 0:
        return (
          <>
            <Form form={form} {...layout}>
              <Form.Item
                label="接口名称"
                name="protname"
                rules={[{ required: true, message: "请填写接口名称" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="接口编号"
                name="protnumber"
                rules={[{ required: true, message: "请填写接口编号" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="接口描述"
                name="protdescribe"
                rules={[{ required: true, message: "请填写接口描述" }]}
              >
                <TextArea
                  rows={4}
                  placeholder="请输入接口介绍，最多不超过200字。"
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item
                label="所属类别"
                name="category"
                rules={[{ required: true, message: "请选择类别" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                //   onChange={handleChange}
                >
                  <Option value="全部">全部</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="所属类别"
                name="checkbox"
                rules={[{ required: true, message: "请选择类别" }]}
              >
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>HTTP</Radio>
                  <Radio value={2}>database</Radio>
                  <Radio value={3}>message</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </>
        );
      case 1:
        return <>{mainApply(value)}</>;
      default:
        return <></>;
    }
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onChange = (e: any) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };
  useEffect(() => {
    form.setFieldsValue({
      checkbox: value,
    });
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <div className={styles["title"]}>创建数据资源</div>
      <div className={styles["bottom"]}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step
              key={item.title}
              title={item.title}
              description={
                <div className={styles["twiceTitle"]}>{item.description}</div>
              }
            />
          ))}
        </Steps>
        <div className={styles["steps-content"]}>
          {tabList(current, value)}
          {/* <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>HTTP</Radio>
            <Radio value={2}>database</Radio>
            <Radio value={3}>message</Radio>
          </Radio.Group> */}
        </div>
        <div className={styles["steps-action"]}>
          {current === 0 && (
            <Button type="primary" onClick={() => next()}>
              下一步
            </Button>
          )}
          {current === 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              提交
            </Button>
          )}
          {current === 1 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              上一步
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ResourceportCreate;
