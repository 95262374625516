import React, { useState } from "react";
import styles from "./index.module.scss";
import Menulist from "./component/menuList";
import DetailList from "./component/detailList";
const Category: React.FC = () => {
  const [childId, setchildId] = useState("");
  const [childName, setchildename] = useState("");
  const getdata = (msg: any, name: any) => {
    // console.log(msg);

    setchildename(name);
    setchildId(msg);
  };
  return (
    <div className={styles["all"]}>
      <div className={styles["menu"]}>
        <Menulist getdata={getdata} />
      </div>
      <div className={styles["details"]}>
        <DetailList data={childId} name={childName} />
      </div>
    </div>
  );
};
export default Category;
