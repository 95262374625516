import { Form, Input, Button, message, Space } from 'antd';
import { postUsercreate } from '../../../api/userlist'
import { useHistory } from "react-router-dom";
const Usercreat = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const onFinish = (values: any) => {
        // console.log(values);
        postUsercreate({ nick_name: values.nickname, password: values.password, user_name: values.nickname }).then((res) => {
            // console.log(res);
            if (res.code === 200) {
                message.success('Successfully creating a User');
                window.location.href = '/userlist';
            } else {
                message.error('Failed to create a user');
            }
        })

    };

    return (
        <div className='centers-router'>
            <Form form={form} name="control-hooks" onFinish={onFinish} style={{ marginTop: 20 }}>
                <Form.Item name="username" label="username" rules={[{ required: true }]}

                >
                    <Input style={{ width: 500 }} />
                </Form.Item>
                <Form.Item name="password" label="password" rules={[
                    {
                        required: true,
                        message: '',
                    },
                    {
                        validator: (_, value, callback) => {
                            const reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/)
                            if (!reg.test(value)) {
                                callback('请输入密码，密码由大写字母、小写字母和数字组成，长度为6 ~ 12个字符。')
                            }
                            callback()
                        },
                    },
                ]}>
                    <Input style={{ width: 500 }} />
                </Form.Item>
                <Form.Item name="nickname" label="nickname" rules={[{ required: true }]}>
                    <Input style={{ width: 500 }} />
                </Form.Item>
                <Space>
                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>

                </Form.Item>
                <Form.Item >
                    <Button htmlType="submit" onClick={() => history.push('/userlist')}>
                        返回
                    </Button>
                </Form.Item>
                </Space>
            </Form>
        </div>
    );
};
export default Usercreat