import { getClient, postClient } from "./requestClient";
import {
  GET_node_list,
  POST_grant_authorization,
  GET_manage_getInstance,
  POST_grant_approval,
  GET_all_listByCode,
  POST_node_create,
  POST_applyDetail,
  GET_grant_approval,
  GET_approvalList,
  POST_centralInterconnection_delete,
  GET_centralInterconnection_log,
  GET_centralInterconnection_statistics,
  POST_centralInterconnection_upload,
  GET_centralInterconnection_moban,
  GET_centralInterconnection_getUrl,
  GET_downLoad_enclosure,
  POST_delete_applyInfo
} from "./index";

export const postdeleteapplyInfo = async (params: any) =>
  await postClient(POST_delete_applyInfo, params);

export const getdownLoadenclosure = async (params: any) =>
  await getClient(GET_downLoad_enclosure, params);

export const getcentralInterconnectiongetUrl = async (params: any) =>
  await getClient(GET_centralInterconnection_getUrl, params);

export const getcentralInterconnectionmoban = async (params: any) =>
  await getClient(GET_centralInterconnection_moban, params);
export const postcentralInterconnectionupload = async (params: any) =>
  await postClient(POST_centralInterconnection_upload, params);
  
export const getnodelist = async (params: any) =>
  await getClient(GET_node_list, params);

  export const getcentralInterconnection = async (params: any) =>
  await getClient(GET_centralInterconnection_statistics, params);

export const getapprovalList = async (params: any) =>
  await getClient(GET_approvalList, params);

export const getgrantapproval = async (params: any) =>
  await getClient(GET_grant_approval, params);

export const postcentralInterconnectiondelete = async (params: any) =>
  await postClient(POST_centralInterconnection_delete, params);

export const getapplyDetail = async (params: any) =>
  await getClient(POST_applyDetail, params);

  export const getcentralInterconnectionlog = async (params: any) =>
  await getClient(GET_centralInterconnection_log, params);

export const postnodecreate = async (params: any) =>
  await postClient(POST_node_create, params);

export const getalllistByCode = async (params: any) =>
  await postClient(GET_all_listByCode, params);

export const postgrantapproval = async (params: any) =>
  await postClient(POST_grant_approval, params);

export const getmanagegetInstance = async () =>
  await getClient(GET_manage_getInstance);

  export const postgrantauthorization = async (params: any) =>
  await postClient(POST_grant_authorization, params);
