import React, { Component } from 'react'
import { TreeSelect } from 'antd'
export default class SelectTree extends Component {
  static defaultProps = {
    parentId:null
  }
  replace = (data, fieldMapping) => {
    fieldMapping = fieldMapping||{}
    return data.map(p => {
      return Object.assign({}, p, {
        title: fieldMapping.title ? p[fieldMapping.title] : p.title,
        value: fieldMapping.value ? p[fieldMapping.value] : p.value,
        key: p.id,
        parentId: fieldMapping.parentId ? p[fieldMapping.parentId] : p.parentId
      })

    })
  }
  parseTree(id, data, leafs) {
    let { fieldMapping } = this.props
    let dataSource = this.replace(data, fieldMapping)
  
    if (data.length > 0) {
      const parent = this.findChildren(id, dataSource, leafs)
      return parent
    } else {
      return []
    }
  }
  findChildren = (id, data, leafs) => {
    let arr = []
    for (let i = 0; i < data.length; i++) {
      const datum = data[i]
      if (datum.parentId === id) {
        datum.children = this.findChildren(datum.id, data, leafs)
        arr.push(datum)
      }
    }

    for (let j = 0; j < leafs.length; j++) {
      const leaf = leafs[j]
      if (leaf.flowCatalogId === id) {
        leaf.isLeaf = true
        arr.push(leaf)
      }
    }

    return arr
  }
  render() {
    const { treeData } = this.props
    const dataSource = this.parseTree(this.props.parentId, treeData, [])
    return (
      <TreeSelect
        {...this.props}
        treeData={dataSource}
      />
    )
  }
}
