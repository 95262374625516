import React, { useEffect } from "react";
import { Badge, Statistic } from "antd";
const Titbar: React.FC<any> = (props) => {
  const { data } = props;
  return (
    <div style={{textAlign:"center"}}>
      <Badge key={data.id} color={data.color} text={data.name} />
      <Statistic value={data.data} style={{ marginTop: "10px" }} />
    </div>
  );
};
export default Titbar;
