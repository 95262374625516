import React, { useEffect, useState, useCallback } from 'react';
import styles from "./index.module.scss";
import * as echarts from 'echarts';
import { Button, Checkbox, Input, Divider, Menu, Dropdown, Modal, Form, message, Pagination } from 'antd';
import {
  SearchOutlined,
  DownOutlined,
  LineChartOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { getpluginslist, postpluginsinstall, getpluginsinstalllist, postpluginsuninstall, postpluginsupdata } from '../../api/plugins'
import { pluginslists, pluginsinstalllist, Ipod } from '../../api/plugins'
const Plugins: React.FC = () => {
  const [form] = Form.useForm();
  const [indeterminate, setIndeterminate] = useState(false); //未全部选中
  const [checkAll, setCheckAll] = React.useState(false);
  const [checkedvlist, setcheckedvlist] = useState<string[]>([]);
  const [itemName, setItemName] = useState('');
  const [indexs, setindex] = useState('');
  const [itemdata, setItemdata] = useState<any>({});
  const [isModalVisible, setisModalVisible] = useState(false);
  const [checkOne, setcheckOne] = useState(false);
  const [isFormVisible, setisFormVisible] = useState(false);
  const [installation, setinstallation] = useState(false);
  const [clickType, setclickType] = useState(null);
  const [pluginslist, setpluginslist] = useState<pluginslists[]>([])
  const [pluginsinstalllist, setpluginsinstalllist] = useState<pluginsinstalllist[]>([])
  const [totaltalllist, setTottalllist] = useState(0);
  const [pageParams, setPageParams] = useState({
    page: 1,
    pageSize: 8,
  });
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 8,
  });
  const [totallist, setTotallist] = useState(0);
  // 已安装插件列表
  const gainpluginsinstalllist = useCallback(() => {
    // console.log(installation);

    getpluginsinstalllist({ page: pageParams.page, page_size: pageParams.pageSize }).then((res: any) => {
      if (res.code === 200) {
        setpluginsinstalllist(res.data.install_plugin_list)
        setTottalllist(res.paginate.total);
      }
    })
  }, [pageParams.page, pageParams.pageSize]);
  useEffect(() => {
    gainpluginsinstalllist()
  }, [pageParams.page, pageParams.pageSize])

  // 插件市场列表
  const gainpluginslist = useCallback(() => {
    getpluginslist({ page: pageParam.page, page_size: pageParam.pageSize }).then((res: any) => {
      if (res.code === 200) {
        setpluginslist(res.data.pluginList)
        setTotallist(res.paginate.total);
      }
    })
  }, [pageParam.page, pageParam.pageSize]);
  useEffect(() => {
    gainpluginslist()
  }, [pageParam.page, pageParam.pageSize])

  // 安装
  const install = (id: string) => {
    setinstallation(true)
    postpluginsinstall({ id }).then((res) => {
      if (res.code == 200) {
        message.success('install successfully');
        gainpluginsinstalllist()
        gainpluginslist()
        setinstallation(false)
      }
    })
  }
  const installationing = (id: any) => {
    const index = pluginslist.findIndex((item: any) => item.id === id);
    setindex((index).toString())
  }


  //选择卸载
  const selective = () => {
    if (checkAll) {
      postpluginsuninstall({ pluginNames: pluginsinstalllist?.map((i) => (i.name)) }).then((res) => {
        if (res.code === 200) {
          setCheckAll(false)
          message.success('Uninstall the success');
          gainpluginsinstalllist()
          gainpluginslist()
        } else {
          message.error('This is an error message');
        }
      })
    } else {
      postpluginsuninstall({ pluginNames: checkedvlist }).then((res) => {
        if (res.code === 200) {
          setCheckAll(false)
          message.success('Uninstall the success');
          gainpluginsinstalllist()
          gainpluginslist()
        } else {
          message.error('This is an error message');
        }
      })
    }

  }
  const menu = (pod: Ipod[]) => {
    return (
      <Menu>
        {pod?.map((item: Ipod,index) => (
          <Menu.Item key={index} style={item.status === "Running" ? { backgroundColor: '#65C038', color: 'white' } : { backgroundColor: '#FF5A5A', color: 'white' }}>{item.name}</Menu.Item>
        ))}
      </Menu>
    )
  }

  const fitness = ['', 'approve-pass', 'approve-yellow', 'approve-reject']
  // 已安装组件事件
  const tjClick = (data: any, type: any) => {
    if (type == 0) {
      setisModalVisible(true);
    } else {
      setisFormVisible(true);
    }
    setItemdata(data)
    setItemName(data.name);
    setclickType(type);
  };
  const handleOk = () => {
    if (clickType == 0) {
      setisModalVisible(false);
    } else if (clickType == 1) {
      //更改插件pod数量
      setisFormVisible(false);
      postpluginsupdata({ count: Number(form.getFieldValue("changepod")), plugin_name: itemdata.name }).then((res) => {
        if (res.code === 200) {
          message.success('update successfully');
          gainpluginsinstalllist()
        } else {
          message.error('This is an error message');
        }
      })
    } else if (clickType == 2) {
      //卸载插件
      setisFormVisible(false);
      postpluginsuninstall({ pluginNames: [itemdata.name] }).then((res) => {
        if (res.code === 200) {
          message.success('Uninstall the success');
          gainpluginsinstalllist()
          gainpluginslist()
        } else {
          message.error('This is an error message');
        }
      })
    }
  };

  const handleCancel = () => {
    if (clickType == 0) {
      setisModalVisible(false);
    } else {
      setisFormVisible(false);
    }
  };

  //全选
  const onCheckAllChange = (e: any) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setcheckOne(true);
    } else if (!e.target.checked) {
      setcheckOne(false);
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  // 单选
  const changMade = (checkedValues: any) => {
    console.log('checked = ', checkedValues);
    setcheckedvlist(checkedValues)
    setCheckAll(checkedValues.length === pluginsinstalllist.length)
    if (checkedValues.length === pluginsinstalllist.length) {
      setcheckOne(true);
    } else {
      setcheckOne(false);
    }
  } 
  const onChange = (e: any) => {
    console.log(e.target);
    setcheckOne(e.target.checked);
    setCheckAll(e.target.checked);
  }
  // const changMade = useCallback(
  //   (e) => {
  //     console.log(e.target);
  //     setcheckOne(e.target.checked);
  //     if (e.target.value && e.target.checked) {
  //       option.current.push(e.target.value);
  //     } else if (e.target.value && !e.target.checked) {
  //       removeArray(option.current, e.target.value);
  //     }
  //     setIndeterminate(!!option.current.length && option.current.length < pluginsinstalllist.length);
  //     setCheckAll(option.current.length === pluginsinstalllist.length);
  //   },
  //   [pluginsinstalllist],
  // );
  //统计
  // const removeArray = (arr: any, val: any) => {
  //   for (var i = 0; i < arr.length; i++) {
  //     if (arr[i] == val) {
  //       arr.splice(i, 1);

  //       break;
  //     }
  //   }
  // };
  useEffect(() => {
    var chartDom = document.getElementById('statisticsCharts');
    if (chartDom != null) {
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
          },
        ],
      };
      myChart.setOption(option);
    }
  }, [isModalVisible == true && clickType == 0]);

  return (
    <div style={{ width: '100%', height: '100%' ,background:'#f0f2f5'}}>
      <div className={styles['installeds']}>
        <div style={{ height: '10%' }}>
          <div className={styles.installed}>
            <div className={styles.title}>已安装</div>
            <div>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                全选
              </Checkbox>
              <Button type="primary" onClick={selective}>卸载</Button>
            </div>
          </div>
          <div></div>
        </div>
        {checkAll ? <div className={styles.element}>
          {pluginsinstalllist?.map((item: any) => {
            return (
              <div className={styles.element_piece} key={item.id}>
                <div className={styles.element_piece_title}>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                    <div className={styles[fitness[item.state]]}></div>
                    <div className={styles.pluginName}>{item.name}</div>
                    <Dropdown overlay={menu(item.pod)}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                        style={{ color: '#8591aa', marginLeft: '5.54px' }}
                      >
                        <DownOutlined />
                      </a>
                    </Dropdown>
                  </div>
                  <Checkbox onChange={onChange} value={item.name} checked={checkOne}></Checkbox>
                </div>
                <Divider style={{ margin: '10px 0px' }} />
                <div className={styles.element_piece_bottom}>
                  <LineChartOutlined
                    className={styles.iconFeature}
                    title="统计"
                    onClick={() => tjClick(item, 0)}
                  />
                  <Divider type="vertical" />
                  <EditOutlined
                    className={styles.iconFeature}
                    title="变更"
                    onClick={() => tjClick(item, 1)}
                  />
                  <Divider type="vertical" />
                  <DeleteOutlined
                    className={styles.iconFeature}
                    title="卸载"
                    onClick={() => tjClick(item, 2)}
                  />
                </div>
              </div>
            );
          })}
        </div> : <Checkbox.Group className={styles.element} onChange={changMade}>
          {pluginsinstalllist?.map((item: any) => {
            return (
              <div className={styles.element_piece} key={item.id}>
                <div className={styles.element_piece_title}>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                    <div className={styles[fitness[item.state]]}></div>
                    <div className={styles.pluginName}>{item.name}</div>
                    <Dropdown overlay={menu(item.pod)}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                        style={{ color: '#8591aa', marginLeft: '5.54px' }}
                      >
                        <DownOutlined />
                      </a>
                    </Dropdown>
                  </div>
                  <Checkbox value={item.name} ></Checkbox>
                </div>
                <Divider style={{ margin: '10px 0px' }} />
                <div className={styles.element_piece_bottom}>
                  <LineChartOutlined
                    className={styles.iconFeature}
                    title="统计"
                    onClick={() => tjClick(item, 0)}
                  />
                  <Divider type="vertical" />
                  <EditOutlined
                    className={styles.iconFeature}
                    title="变更"
                    onClick={() => tjClick(item, 1)}
                  />
                  <Divider type="vertical" />
                  <DeleteOutlined
                    className={styles.iconFeature}
                    title="卸载"
                    onClick={() => tjClick(item, 2)}
                  />
                </div>
              </div>
            );
          })}
        </Checkbox.Group>}

        <div className={styles['pagination-box']}>
          <Pagination
            className={styles['pagination']}
            total={totaltalllist}
            pageSize={pageParams.pageSize}
            current={pageParams.page}
            showSizeChanger={true}
            showTotal={(total) => `共 ${total}条记录`}
            onChange={(page, size) => setPageParams({ page, pageSize: size })} />
        </div>
        <Modal
          title={itemName}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ height: '300px' }}
        >
          <div id="statisticsCharts" style={{ height: '100%', width: '100%' }}></div>
        </Modal>
        <Modal title={itemName} visible={isFormVisible} onOk={handleOk} onCancel={handleCancel}>
          <Form form={form} style={{ height: '100%' }}>
            {clickType == '1' ? (
              <Form.Item label="变更http副本数量：" name="changepod" rules={[
                {
                  validator: (_, value, callback) => {
                    const reg = new RegExp(/^(\d(\.\d{1,2})?|10)$/)
                    if (!reg.test(value)) {
                      callback('请输入数字0~10')
                    }
                    callback()
                  },
                },
              ]}>
                <Input />
              </Form.Item>
            ) : (
              ''
            )}
            {clickType == '2' ? <div>确定要卸载"{itemName}"插件吗？</div> : ''}
          </Form>
        </Modal>
      </div>
      <div className={styles['marketplace']} >
        <div className={styles['addition']}>
          <div className={styles.title} style={{ float: 'left' }}>添加插件</div>
          <Input prefix={<SearchOutlined />} className={styles['inpute']} />
        </div>
        <div className={styles.element} style={{ width: '100%', height: '90%', overflowY: 'auto' }}>
          {pluginslist.map((item, index) => {
            return (
              <div key={item.id} className={styles.pre} style={{ width: '23%', margin: '10px' }}>
                <div className={styles.pluginTitle}>{item.name}</div>
                <div style={{ marginTop: '10px' }} className={styles.omit} title={item.release_date}>
                  {item.release_date}
                </div>
                <div style={{ height: '60px' }}>
                  <Divider style={{ margin: '16px 0px' }} />
                  <div onClick={() => (installationing(item.id))} >
                    {
                      item.status === 1 ?
                        <Button type="link" block >
                          已安装
                        </Button> : <div> {indexs === (index).toString() ? <div> {
                          installation ? <Button type="link" loading block>
                            正在安装
                          </Button> : <Button type="link" block onClick={() => { install(item.id) }}>
                            安装
                          </Button>}</div> : <Button type="link" block onClick={() => { install(item.id) }}>
                          安装
                        </Button>}</div>
                    }
                  </div>

                </div>
              </div>
            );
          })}
        </div>
        <div className={styles['pagination-box']}>
          <Pagination
            className={styles['pagination']}
            total={totallist}
            pageSize={pageParam.pageSize}
            current={pageParam.page}
            showSizeChanger={true}
            showTotal={(total) => `共 ${total}条记录`}
            onChange={(page, size) => setPageParam({ page, pageSize: size })} />
        </div>
      </div>
    </div >
  );
};
export default Plugins;
