import { getClient, postClient } from "./requestClient";
import { POST_APPROVE, GET_APPROVELIST } from "./index";

//审批资源
export const postApprove = async (params: any) =>
  await postClient<Postexamine>(POST_APPROVE, params);

//审批资源
export const getApprovelist = async (params: any) =>
  await getClient<Lexamine[]>(GET_APPROVELIST, params);

export interface Lexamine {
  bill_code: number;
  remark: string;
  approve_status: number;
  apply_time: number;
  process: {
    reason: string;
  }[];
  apply_user_info: {
    nick_name: string;
  };
}
export interface Postexamine {
    approve_status:number,
    bill_code:number,
    reason:string
  }