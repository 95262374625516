import * as React from "react";
import { useEffect, useState, PropsWithChildren } from "react";
import DetailCard from "@/component/DetailCard";
import { Form, Col, Row, Button, Input, Radio } from "antd";
import qs from "qs";
import ParseRequestJsonTable from "./components/parse-request-json-table";
import {
  detailServiceItem,
  getConnectorList,
  getConnectInfo,
} from "@/api/serviceManagement";
import { getprotocollist } from "../../../api/connector";
import {
  currentLimiting,
  currentFusingLimiting,
  authModes,
} from "../PublishServe/utils-data/commonData";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { FormDataProps } from "../PublishServe/BackendConfig/component/form-tool";
const keys = ["One", "Two", "Three"];
type Props = {};
const DetailRender: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const data: any =
    Object.prototype.toString.call(children) === "[object Array]"
      ? children
      : [children];
  return (
    <>
      {data.map(
        (
          it:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <Col md={8} sm={24} key={index}>
            {it}
          </Col>
        )
      )}
    </>
  );
};

const DetailServe: React.FC = () => {
  const params = qs.parse(window.location.search.replace("?", ""));
  const [userInfo, setUserInfo] = useState<any>({});
  const [connectList, setConnectList] = useState<any>({});
  const [protocol, setProtocol] = useState<any>({});
  const [dataList, setDataList] = useState<FormDataProps[]>([]);
  const [value4, setValue4] = useState("JSON");
  const history = useHistory();
  const { TextArea } = Input;
  const options = [
    { label: "Json", value: "JSON" },
    { label: "FileStream", value: "FILESTREAM" },
  ];
  useEffect(() => {
    detailServiceItem({ id: params.id }).then((res) => {
      let route: any;
      try {
        route = JSON.parse(res.data.serviceManage.route || "{}");
      } catch (e) {}
      setUserInfo({
        ...res.data.serviceLimiting,
        ...res.data.serviceManage,
        route: route,
      });
      setValue4(res.data.serviceManage.fileType);
      getConnectInfo({ id: res?.data?.serviceManage?.connectorId || "" }).then(
        (res) => {
          setDataList(res.data || []);
        }
      );
    });
    getprotocollist().then((res) => {
      if (res.code == 200) {
        let obj: any = {};
        res.data.map((item: { id: string | number; name: any }) => {
          obj[item.id] = item.name;
        });
        setProtocol(obj);
      }
    });
    getConnectorList({
      page: 1,
      pageSize: 1000,
    }).then((res) => {
      if (res.code == 200) {
        let obj: any = {};
        res.data.map((item: { id: string | number; name: any }) => {
          obj[item.id] = item.name;
        });
        setConnectList(obj);
      }
    });
  }, []);
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <div className="detail-view">
      <div className="detail-module">
        <div className="title">基本信息</div>
        <DetailCard title={"基本信息"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="API名称">
                {userInfo.apiName}
              </Form.Item>
              <Form.Item {...formLayout} label="版本号">
                {userInfo.apiVersion}
              </Form.Item>
              <Form.Item {...formLayout} label="获取方式">
                {authModes[userInfo.authMode - 1] || ""}
              </Form.Item>
              <Form.Item {...formLayout} label="说明">
                {userInfo.apiDesc}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"安全配置信息"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="请求加签验签">
                {userInfo.requestSign ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="响应加签验签">
                {userInfo.responseSign ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="请求报文加密解密">
                {userInfo.requestEncrypt ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="响应报文加密解密">
                {userInfo.responseEncrypt ? "是" : "否"}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"性能设置"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="最大QPS">
                {userInfo.maxQps}
              </Form.Item>
              <Form.Item {...formLayout} label="预计响应时间">
                {userInfo.resTime}
              </Form.Item>
              <Form.Item {...formLayout} label="超时时间">
                {userInfo.timeOut}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"流量控制"}>
          <div className="detail-content">
            {["一", "二", "三"].map(
              (item, index) =>
                userInfo[Object.keys(currentLimiting(index))[0]] && (
                  <div>
                    {item}级限流：1分钟内平均响应时间介于
                    <span>
                      {userInfo[Object.keys(currentLimiting(index))[0]]}
                    </span>
                    ms到
                    <span>
                      {userInfo[Object.keys(currentLimiting(index))[1]]}
                    </span>
                    ms时，QPS降低到
                    <span>
                      {userInfo[Object.keys(currentLimiting(index))[2]]}
                    </span>
                    %
                  </div>
                )
            )}
          </div>
        </DetailCard>
        <DetailCard title={"熔断控制"}>
          <div className="detail-content">
            {["一"].map(
              (item, index) =>
                userInfo[Object.keys(currentFusingLimiting(index))[0]] && (
                  <div>
                    {item}级熔断：在
                    <span>
                      {userInfo[Object.keys(currentFusingLimiting(index))[0]]}
                    </span>
                    分钟内，累计错误次数达到
                    <span>
                      {userInfo[Object.keys(currentFusingLimiting(index))[1]]}
                    </span>
                    {userInfo[`numOrPct${keys[index]}`] === "num" ? "次" : "%"}
                    ，熔断
                    <span>
                      {userInfo[Object.keys(currentFusingLimiting(index))[2]]}
                    </span>
                    秒
                  </div>
                )
            )}
          </div>
        </DetailCard>
      </div>
      <div className="detail-module">
        <div className="title">后端服务配置</div>
        <DetailCard title={"后端服务配置"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="后端接入Connector类型">
                {userInfo.connectorType}
              </Form.Item>
              <Form.Item {...formLayout} label="后端接入Connector">
                {userInfo.connectorType}
              </Form.Item>
              <Form.Item {...formLayout} label="后端服务IP地址端口">
                {Object.prototype.toString.call(userInfo.dataSystem) ===
                "[object Array]"
                  ? userInfo.dataSystem.join(",")
                  : userInfo.dataSystem}
              </Form.Item>
              <Form.Item {...formLayout} label="启用TLS">
                {userInfo.useTls ? "是" : "否"}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"后端连接配置"}>
          <Row>
            <DetailRender>
              {dataList.map((item) => (
                <Form.Item {...formLayout} label={item?.name}>
                  {userInfo?.["route"][item?.key] || ""}
                </Form.Item>
              ))}
            </DetailRender>
          </Row>
        </DetailCard>
      </div>
      <div className="detail-module">
        <div className="title">前端服务配置</div>
        <DetailCard title={"API接入配置"}>
          <Row>
            <DetailRender>
              
              <Form.Item {...formLayout} label="接入协议">
                {userInfo.accessProtocol}
              </Form.Item>
              <Form.Item {...formLayout} label="请求方式">
                {userInfo.requestMethod}
              </Form.Item>
              <Form.Item {...formLayout} label="请求路径">
                {userInfo.requestPath}
              </Form.Item>
              <Form.Item {...formLayout} label="请求模式">
                {userInfo.asyncFlag===0?'同步':'异步'}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"入参设置"}>
          <ParseRequestJsonTable
            method={userInfo?.requestMethod || userInfo?.method}
            type={"request"}
            jsonData={(() => {
              let param: any = {};
              try {
                param = JSON.parse(userInfo?.metaJson || "{}");
              } catch (error) {}
              return param?.APIRequestStructure || {};
            })()}
            protocolIn={"rest"}
          />
        </DetailCard>
        <DetailCard title={"出参设置"}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Radio.Group
              disabled={true}
              options={options}
              value={value4}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          {userInfo.fileType !== "FILESTREAM" ? (
            <ParseRequestJsonTable
              method={userInfo?.requestMethod || userInfo?.method}
              type={"response"}
              jsonData={(() => {
                let param: any = {};
                try {
                  param = JSON.parse(userInfo?.metaJson || "{}");
                } catch (error) {}

                return param?.APIResponseStructure || {};
              })()}
              protocolIn={userInfo.accessProtocol}
            />
          ) : (
            <></>
          )}
        </DetailCard>
        <DetailCard title={"返回设置"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="是否开启Mock">
                {userInfo.isMock ? "是" : "否"}
              </Form.Item>
              <Form.Item {...formLayout} label="Mock返回数据">
                {/* {userInfo.mockJson} */}
                <TextArea rows={4} disabled value={userInfo.mockJson} />
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
        <DetailCard title={"协议适配"}>
          <Row>
            <DetailRender>
              <Form.Item {...formLayout} label="协议">
                {/* {userInfo.isMock ? '是' : '否'} */}
                {userInfo.connectorType + "——>" + userInfo.accessProtocol}
              </Form.Item>
              <Form.Item {...formLayout} label="自定义请求协议适配">
                {userInfo.reqAdapterScript}
              </Form.Item>
              <Form.Item {...formLayout} label="自定义响应协议适配">
                {userInfo.respAdapterScript}
              </Form.Item>
            </DetailRender>
          </Row>
        </DetailCard>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            history.push({ pathname: "/servicemanagement" });
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
};

export default DetailServe;
