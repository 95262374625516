import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Table, Divider, Popconfirm ,Select } from "antd";
const InterconnectResouFce: React.FC = () => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "DataAddress",
      dataIndex: "ip",
      key: "ip",
      // render: (text: any) => text.name,
    },
    {
      title: "API名称",
      dataIndex: "remark",
      key: "remark",
      // align: "center" as "center",
    },
    {
      title: "版本号",
      dataIndex: "createAt",
    },
    {
      title: "鉴权模式",
      dataIndex: "createAt",
    },
    {
      title: "API状态",
      dataIndex: "createAt",
    },
    {
      title: "后端服务类型",
      dataIndex: "createAt",
    },
    {
      title: "最后发布时间",
      dataIndex: "createAt",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any,record:any) => (
        <>
          <a
            onClick={() => {
              history.push({
                pathname: `/servicetest/detail`,
                state: { age: 20 },
              });
            }}
          >
            详情
          </a>
        </>
      ),
    },
  ];
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>互联资源</div>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="用例名称" label="DataAddress">
            <Input placeholder="请输入用例名称" />
          </Form.Item>
          <Form.Item name="用例名称" label="后端服务类型">
            <Select style={{width:"127px"}} placeholder="请选择"/>
          </Form.Item>
          <Form.Item name="用例名称" label="API状态" >
          <Select style={{width:"127px"}} placeholder="请选择"/>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              //   onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          // loading={listload}
          // dataSource={userslist}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};

export default InterconnectResouFce;
