// import React from "react"
// import { useCallback } from "react";
import { Tabs, Button } from "antd";
import MyTable from "./component/MyTable";
import "./index.scss";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useState } from "react";
// import {getinterfacedetail} from "../../api/interface"
const { TabPane } = Tabs;

const Port = () => {
  const [tabKeys, settabKeys] = useState<any>(0);
  const history = useHistory();
  function callback(key: any) {
    // console.log(key);
    settabKeys(key)
  }
  return (
    <div className="port">
      <Button
        className="addPort"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          history.push(`/resource/detail/${'create'}/${'create'}`);
        }}
      >
        创建接口
      </Button>
      <Tabs defaultActiveKey="0" onChange={callback}>
        <TabPane tab="所有接口" key="0" />
        <TabPane tab="我的接口" key="1" />
      </Tabs>
      <MyTable tag="all" tabKeys={tabKeys} />
    </div>
  );
};
export default Port;
