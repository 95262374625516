// import logo from './logo.svg';
import { useEffect, useState, Suspense } from "react";
import "./App.css";
import "antd/dist/antd.css";
import Home from "./pages/Home/index";
import { REFRESH_TIME, REFRESH_TOKEN, TOKEN } from "./constants";
import Tools from "./util/tools";
import { postUserrefreshtoken } from "./api/userlist";
import { Route, Redirect ,withRouter} from "react-router-dom";
import Login from "./component/Layout/Login/index";
import { getUserinfo } from "./api/userlist";
import { WebAdminContext, UserInfoType } from "./context/WebAdminContext";
import { IRroleloginlist, getuserinfo } from './api/userlist'
function App() {
  const [userInfo, setuserInfo] = useState<UserInfoType>({
    AccountId: "",
    LoginName: "",
    Token: "",
    NickName: "",
    CreatedAt: 0
  });
  const [rolelist, setrolelist] = useState<IRroleloginlist>({ id: '', name: '' })
  const [pubId, setpubId] = useState<any>([]);
  useEffect(() => {
    // setlgToken(Tools.getStorage(TOKEN))
    // if (Tools.getStorage(TOKEN)) {
    //   getUserinfo({}).then((res) => {
    //     if (res.code === 200) {
    //       // console.log(res);
    //       setuserInfo(res.data);
    //       getuserinfo({ id: res.data.AccountId }).then((res) => {
    //         setrolelist(res.data.user_role)
    //       })
          // getuserRole({ userId: res.data.AccountId }).then((res) => {
          // setrolelist(res.data.role)
          // console.log(res.data.roleList || []);
          // })
    //     }
    //   });
    // }


    // 定时检测并刷新 Token
    let timer: NodeJS.Timeout;
    timer = setInterval(() => {
      let info:any = Tools.getStorage("userInfo")
      const refreshToken = JSON.parse(info).refreshToken;
      // 过期时间戳（秒）
      // const refreshTime = parseInt(String(Tools.getStorage(REFRESH_TIME)));
      if (refreshToken) {
        // 判断过期时间是否小于 5 分钟
        // if (refreshTime * 1000 - new Date().valueOf() < 5 * 60 * 1000) {
          // 更新Token
          postUserrefreshtoken({ refreshToken: refreshToken })
            .then((res: any) => {
              clearInterval(timer);
              if (res.code === 200) {
                Tools.setStorage(TOKEN, res.data.access_token);
                Tools.setStorage(REFRESH_TOKEN, res.data.refreshToken);
                // Tools.setStorage(REFRESH_TIME, res.data.expires_at);
              } else {
                Tools.removeStorage(TOKEN);
                // Tools.removeStorage(REFRESH_TIME);
                Tools.removeStorage(REFRESH_TOKEN);
                window.location.href = "/login";
              }
            })
            .catch((e: any) => {
              clearInterval(timer);
              Tools.removeStorage(TOKEN);
              Tools.removeStorage(REFRESH_TIME);
              Tools.removeStorage(REFRESH_TOKEN);
              window.location.href = "/login";
            });
        // }
      }
    }, 300*15 * 1000); // 30 秒检查一次是否过期
  }, []);

  return (
    <WebAdminContext.Provider value={{ userInfo, rolelist, pubId, setpubId }}>
      <Suspense fallback={<div>loading...</div>}>
        <Route path="/login" component={Login} exact />
        {Tools.getStorage(TOKEN) ? <Route component={Home} exact /> : <Redirect to="/login" />}
      </Suspense>
    </WebAdminContext.Provider>
  );
}
export default withRouter(App);
