import React, {Dispatch, SetStateAction}from "react";

export interface  UserInfoType{
    AccountId:string,
    LoginName:string,
    Token:string
    CreatedAt:number,
    NickName:string
  }
  interface IRroleloginlist {
    id: string,
    name: string
  }

export interface WebsiteContextType {
  rolelist:IRroleloginlist
  userInfo: Partial<UserInfoType>;
  pubId?:{
    id:string
    visible:boolean
  }[]
  // setpubId?:()=>void
  setpubId?: Dispatch<SetStateAction<string>>;
  handleClick?:any
}

export const WebAdminContext = React.createContext<WebsiteContextType>({userInfo: {},rolelist:{id:'',name:''}})