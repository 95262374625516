export const metaJson = {
  APIRequestStructure: {
    post: {
      type: "form",
      form: [],
      //   type: "json",
      //   json: {
      //     type: "object",
      //     properties: {},
      //     disabled: {
      //       name: true,
      //       type: true,
      //       del: true,
      //       set: true,
      //     },
      //   },
      // type: "json",
      // json: {
      //   type: "object",
      //   disabled: { name: true, type: true, del: true, set: true, add: true },
      //   properties: {},
      // },
    },
    query: [
      // {
      //   id: "1ged22albo5",
      //   name: "TIME",
      //   required: 0,
      //   example: "",
      //   mask: "",
      //   disableArr: [],
      //   defaultLine: true,
      // },
    ],
    headers: [],
    form: [
      // {
      //   id: "1ged22albo5",
      //   name: "TIME",
      //   required: 0,
      //   example: "",
      //   mask: "",
      //   disableArr: [],
      //   defaultLine: true,
      // },
    ],
  },
  APIResponseStructure: {
    type: "object",
    disabled: { name: true, type: true, del: true, set: true, add: true },
    properties: {
      code: {
        type: "string",
        disabled: {
          name: true,
          type: true,
          add: true,
          del: true,
          set: true,
          dimension: true,
        },
      },
      message: {
        type: "string",
        disabled: {
          name: true,
          type: true,
          add: true,
          del: true,
          set: true,
          dimension: true,
        },
      },
      success: {
        type: "boolean",
        disabled: {
          name: true,
          type: true,
          add: true,
          del: true,
          set: true,
          dimension: true,
        },
      },
      data: {
        type: "object",
        properties: {
          // address: { type: "string", title: "参数名称", description: "备注" },
        },
        disabled: {
          name: true,
          type: true,
          del: true,
          set: true,
          add: true,
          // dimension: true,
        },
        // required: ["address"],
        required: [],
      },
    },
    required: ["code", "message", "success", "data"],
  },
};
