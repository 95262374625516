import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Input, Form, Col, Select, Button, Table } from "antd";
import { getApplyList } from "../../api/apply";
import dayjs from "dayjs";
const Apply: React.FC = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  // const [total, setTotal] = useState(0);
  const [listLoad, setlistLoad] = useState(true);
  const [applyList, setapplyList] = useState<any>([]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  // const layout = {
  //   labelCol: { span: 8 },
  //   wrapperCol: { span: 16 },
  // };
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "申请单号",
      dataIndex: "bill_code",
      key: "bill_code",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>{text}</div>
        </div>
      ),
    },
    {
      title: "dp_address",
      dataIndex: "dp_address",
      key: "dp_address",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>{text}</div>
        </div>
      ),
    },

    {
      title: "申请类型",
      dataIndex: "approve_status",
      key: "approve_status",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>
            {text === 0
              ? "全部"
              : [text === 1 ? "申请中" : [text === 2 ? "通过" : "拒绝"]]}
          </div>
        </div>
      ),
    },
    {
      title: "审批备注",
      dataIndex: "remark",
      key: "remark",
      render: (text: string, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>{text}</div>
        </div>
      ),
    },
    {
      title: "申请人",
      dataIndex: "apply_user_info",
      key: "apply_user_info",
      render: (text: any, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>{text.nick_name}</div>
        </div>
      ),
    },
    {
      title: "申请时间",
      dataIndex: "apply_time",
      key: "apply_time",
      render: (text: number, record: any) => (
        <div>
          <div style={{ color: "#777777" }}>
            {dayjs(new Date(text)).format("YYYY-MM-DD")}
          </div>
        </div>
      ),
    },
    // {
    //     title: 'Action',
    //     key: 'action',
    //     render: () => (
    //         // <Space size="middle">
    //             <a>申请</a>
    //             // <Divider type='vertical' />
    //             // <a>更多<DownOutlined style={{ fontSize: "12px" }} /></a>
    //         // </Space>
    //     ),
    // }
  ];
  const option = [
    {
      id: "0",
      name: "全部",
    },
    {
      id: "1",
      name: "申请中",
    },
    {
      id: "2",
      name: "通过",
    },
    {
      id: "3",
      name: "拒绝",
    },
  ];


  //列表请求
  const apply = async (
    approve_status: any,
    bill_code: any,
    dp_address: any,
    page: any,
    page_size: any
  ) => {
    await getApplyList({
      approve_status: approve_status,
      bill_code: bill_code,
      dp_address: dp_address,
      page: page,
      page_size: page_size,
    }).then((res) => {
      if (res.code === 200) {
        setapplyList(res.data);
        setlistLoad(false);
      }
    });
  };
  //查询按钮
  const checkClick = () => {
    setlistLoad(true);
    apply(
      form.getFieldValue("approve_status"),
      form.getFieldValue("bill_code"),
      form.getFieldValue("dp_address"),
      1,
      10
    );
  };
  const onReset = () => {
    form.resetFields();
    setlistLoad(true);
    apply(null, null, null, 1, 10);
  };
  useEffect(() => {
    apply(null, null, null, 1, 10);
  }, [pageParam.page, pageParam.pageSize]);
  return (

    <div style={{ width: "100%", height: "100%" }} className='centers-router'>
      <div className={styles["pre"]}>
        {/* <div className={styles["title"]}>Apply</div> */}
        <div>
          <Form form={form} layout="inline">
            {/* <Row>
              <Col xs={{ span: 5}} > */}
                <Form.Item label="申请单号" name="bill_code">
                  <Input></Input>
                </Form.Item>
              {/* </Col> */}
              {/* <Col xs={{ span: 5}} > */}
                <Form.Item label="申请类型" name="approve_status">
                  <Select style={{ width: 200 }}>
                    {option.map((item) => {
                      return (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              {/* </Col> */}
            {/* </Row> */}
            {/* <Row>
              <Col xs={{ span: 5}} lg={{ span: 5}}> */}
                <Form.Item label="申请人" name="username">
                  <Input></Input>
                </Form.Item>
              {/* </Col> */}
              {/* <Col xs={{ span: 5}} lg={{ span: 5}}> */}
                <Form.Item label="dp_address" name="dp_address">
                  <Input></Input>
                </Form.Item>
              {/* </Col> */}
              <Col style={{ marginLeft: "12px" }}>
              <Button type="primary" onClick={checkClick}>
                查询
              </Button>
              </Col>
              <Col  style={{ marginLeft: "12px" }}>
              <Button onClick={onReset}>重置</Button>
              </Col>
            {/* </Row> */}
          </Form>
        </div>
        <div>
          <Table
            loading={listLoad}
            columns={columns}
            style={{ marginTop: "10px" }}
            dataSource={applyList}
            pagination={{
              // total: total,
              pageSize: pageParam.pageSize,
              current: pageParam.page,
              showQuickJumper: true,
              showSizeChanger: true,
              // showTotal: (total) => `共 ${total}条记录`,
              onChange: (page, size) => setPageParam({ page, pageSize: size }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Apply;
