// @ts-nocheck
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import {
  getSystemlist, getMySystemlist,
  getPlatformlist, getMyPlatformlist,
  getSystemdelete, getPlatformdelete,
  getSystemPlatformlist
} from "@api/scenario";
import { Table, message, Input, Modal, Button } from "antd";
import EditSystem from "../EditSystem";
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import Tools from "@util/tools";
import "./index.scss";
const { confirm } = Modal;

function Index(props: any, ref) {
  const editRef = useRef<any>()
  const [listData, setlistData] = useState<any[]>();
  const [listload, setListload] = useState(true);
  const [activeIndex, setactiveIndex] = useState("1");
  const [first, setfirst] = useState("0");//设置第一次加载调用所以方法
  const [total, setTotal] = useState<number>(0);
  const [inpval, setinpval] = useState<any>('');
  const [flag, setflag] = useState<any>(true);
  const [btnData, setbtnData] = useState<any>([
    // { id: "3", text: "全部", data: "0" },
    { id: "1", text: "系统", data: "0" },
    { id: "2", text: "平台", data: "0" },
  ]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });

  //所有系统/平台列表
  const systemPlatformList = async () => {
    setListload(true)
    //我的
    const res = await getSystemPlatformlist({
      page: pageParam.page,
      page_size: pageParam.pageSize,
      name: inpval
    })
    if (res.code === 200) {
      setListload(false)
      let list = btnData.map(item => {
        if (item.id === "3") {
          item.data = res.data.total
        }
        return item
      })
      setbtnData(list)
      let index = Tools.getStorage("activeIndex")
      //新增的时候activeIndex会默认变为"1"所以要缓存一下
      if (index === "3") {
        setlistData(res.data.stat_system_platforms)
        setTotal(res.data.total)
      }
    }
  }

  //系统列表
  const systemList = async () => {
    setListload(true)
    let res = null;
    // console.log(props);
    if (props.tag === "1") {
      res = await getSystemlist({
        page: pageParam.page,
        page_size: pageParam.pageSize,
        name: inpval
      })
    } else {
      res = await getMySystemlist({
        page: pageParam.page,
        page_size: pageParam.pageSize,
        name: inpval
      })
    }
    if (res.code === 200) {
      setListload(false)
      let list = btnData.map(item => {
        if (item.id === "1") {
          item.data = res.data.total
        }
        return item
      })
      setbtnData(list)
      let index = Tools.getStorage("activeIndex")
      //新增的时候activeIndex会默认变为"1"所以要缓存一下
      if (index === "1") {
        setlistData(res.data.systems)
        setTotal(res.data.total)
      }
    }
  }
  //平台列表
  const platformList = async () => {
    setListload(true)
    let res = null;
    if (props.tag === "1") {
      res = await getPlatformlist({
        page: pageParam.page,
        page_size: pageParam.pageSize,
        name: inpval
      })
    } else {
      res = await getMyPlatformlist({
        page: pageParam.page,
        page_size: pageParam.pageSize,
        name: inpval
      })
    }
    if (res.code === 200) {
      setListload(false)
      let list = btnData.map(item => {
        if (item.id === "2") {
          item.data = res.data.total
        }
        return item
      })
      setbtnData(list)
      let index = Tools.getStorage("activeIndex")
      //新增的时候activeIndex会默认变为"1"所以要缓存一下
      if (index === "2") {
        setlistData(res.data.platforms)
        setTotal(res.data.total)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    systemList,
    platformList,
    systemPlatformList,
  }))

  useEffect(() => {
    setinpval("")
    if (first === "0") {
      systemList()
      platformList()
      // systemPlatformList()
    } else if (first === "1") {
      systemList()
    } else if (first === "2") {
      platformList()
    } else if (first === "3") {
      // systemPlatformList()
    }
  }, [pageParam, activeIndex, flag]);

  useEffect(() => {
    systemList()
    platformList()
  }, [props.tag]);

  function deleteConfirm(record: any) {
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: record.protocol ? `该系统下仍有接口处在上线状态，是否直接删除系统下的所有的接口？` : '该平台下仍有系统关联，是否直接删除平台从属所有的系统？',
      onOk() {
        if (record.protocol) {
          getSystemdelete({ id: record.id }).then(res => {
            if (res.code === 200) {
              message.success("删除成功")
              systemList()
            }
          })
        } else {
          getPlatformdelete({ id: record.id }).then(res => {
            if (res.code === 200) {
              message.success("删除成功")
              platformList()
            }
          })
        }
      },
      onCancel() {
        console.log('Cancel');
      },
      okText: "确定",
      cancelText: "取消"
    });
  }

  const columns: any = [
    {
      title: "系统/平台名称",
      dataIndex: "name",
    },
    {
      title: activeIndex === "2" ? "类目" : "所属类目/平台",
      dataIndex: activeIndex === "1" ? "category_name" : "category_names",
      render: (text) => {
        if (activeIndex === "1") {
          return text
        } else {
          return text?.join(", ")
        }
      }
    },
    {
      title: "创建时间",
      dataIndex: "createAt",
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm')
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: any) => {
        let status = "已建"
        let clnm = "approve-pass"
        if (text === 0) {
          status = "未建"
          clnm = "approve-reject"
        } else if (text === 1) {
          status = "建设中"
          clnm = "approve-pending"
        }
        return <div className={`approve ${clnm}`}>{status}</div>
      }
    },
    props.tag === "2" ? {
      title: "操作",
      dataIndex: "id",
      render: (id: string, record: any) => {
        return (
          <>
            <Button type="link" onClick={() => {
              // console.log(record);
              editRef.current.seteditVisible(true)
              editRef.current.setinitForm(record)
              editRef.current.setFieldsValue(record)
            }}>编辑</Button>
            <Button type="link" style={{ marginLeft: "12px" }} onClick={() => deleteConfirm(record)}>删除</Button>
          </>
        );
      },
    } : {},
  ];

  const onSearch = () => {
    if (activeIndex === "1" && inpval) {
      systemList()
    } else if (activeIndex === "2" && inpval) {
      platformList()
    }
    // else if (activeIndex === "3" && inpval) {
    //   systemPlatformList()
    // }
  }

  return (
    <div className="allsystem">
      <div className="searchbar">
        {btnData.map((item: any) =>
          <Button type="link"
            key={item.id}
            onClick={() => {
              setactiveIndex(item.id);
              setfirst(item.id);
              Tools.setStorage("activeIndex", item.id)
              setPageParam({ page: 1, pageSize: 10, })
            }}
            className={activeIndex === item.id ? "active" : ""}
          >{item.text}({item.data})</Button>
        )}
        <div className="inptbar">
          <Input
            placeholder="搜索"
            suffix={<SearchOutlined className="search" onClick={onSearch} />}
            className="inpt"
            value={inpval}
            onChange={(e) => { setinpval(e.target.value) }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                onSearch()
              }
            }}
          />
          <Button icon={<ReloadOutlined />} className="reset"
            onClick={() => {
              setinpval("")
              setflag(!flag)
            }}
          >
            重置
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={listData}
        loading={listload}
        pagination={{
          total,
          pageSize: pageParam.pageSize,
          current: pageParam.page,
          showSizeChanger: true,//大于 50 条时会默认显示 size 切换器以提升用户交互体验
          showTotal: (total) => `共 ${total}条记录`,
          onChange: (page, size) => setPageParam({ page, pageSize: size }),
        }}
      />
      <EditSystem props={{ tag: "all", activeIndex, systemList, platformList, systemPlatformList }} ref={editRef} />
    </div>
  );
}
export default forwardRef(Index)