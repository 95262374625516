import { postClient, getClient, deleteClient, putClient } from "./requestClient";
import { GET_Topic_List, POST_Topic, GET_TOPIC_Detail, POST_Subscribed_Topic, GET_Msg_Detail,   GET_Send_Msg, DELETE_Sub, GET_Consumed_Msg, GET_Msg_Record, PUT_Topic, GET_Sub_Info } from "./index"

export interface ResponseType<T> {
  code: number;
  message: string;
  data: T;
  paginate?: {
      page: number;
      page_size: number;
      total: number;
      total_pages: number;
  };
}

//Topic列表
export const getTopiclist = async (params: any) =>
  await getClient<any>(GET_Topic_List, params);

//发布Topic
export const postTopicCreate = async (params: any) =>
  await postClient<any>(POST_Topic, params);

//编辑Topic
export const putTopicEdit = async (params: any) =>
  await putClient<any>(`${PUT_Topic}/${params.id}`, params);

//获取Topic详情信息
export const getTopicDetail = async (params:any) => 
  await getClient<any>(`${GET_TOPIC_Detail}/${params.id}`);

//获取Topic订阅信息
export const getTopicSubInfo = async (params:any) => 
  await getClient<any>(`${GET_Sub_Info}/${params.id}`);

//订阅Topic
export const subscribedTopic = async (params:any) => 
  await postClient(POST_Subscribed_Topic, params)

//取消订阅
export const unsubTopic = async (params:any) => 
  await deleteClient(DELETE_Sub, params)


//获取消息详情
export const getMsgDetail =async (params:any) => 
  await getClient(`${GET_Msg_Detail}/${params.id}`)


//获取发送的消息
export const getMsgSendedList =async (params:any) => 
  await getClient(GET_Send_Msg, params)


//获取消费的信息
export const getMsgConsumedList =async (params:any) => 
  await getClient(GET_Consumed_Msg, params)


//获取消息轨迹
export const getMsgRecord = async (params:any) => 
  await getClient(`${GET_Msg_Record}/${params.id}/records`)
