import { postClient, getClient } from "./requestClient";
import {
  GET_connector_list,
  POST_connector_romove,
  GET_protocol_list,
  POST_connector_create,
  POST_connector_detail,
  POST_connector_update,
  GET_connector_log,
  GET_protocol_onlineConnector,
  connector_protocol_list
} from "./index";

export const getprotocol = async () =>
  await getClient<any>(connector_protocol_list);

export const getconnectorlist = async (params: any) =>
  await getClient<any>(GET_connector_list, params);

export const postconnectorromove = async (params: any) =>
  await postClient<any>(POST_connector_romove, params);

export const getprotocollist = async () =>
  await getClient<any>(GET_protocol_list);

export const postconnectorcreate = async (params: any) =>
  await postClient<any>(POST_connector_create, params);

  export const postconnectordetail = async (params: any) =>
  await getClient<any>(POST_connector_detail, params);

  export const postconnectorupdate = async (params: any) =>
  await postClient<any>(POST_connector_update, params);

  export const getconnectorlog = async (params: any) =>
  await getClient<any>(GET_connector_log, params);

  export const getonlineConnector= async (params: any) =>
  await getClient<any>(GET_protocol_onlineConnector, params);
