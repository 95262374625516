import React, { useState , useEffect ,useCallback} from "react";
import { Breadcrumb, Form, Select, Button, Input, Table , Badge , Modal} from "antd";
import { useHistory , useLocation} from "react-router-dom";
import {getloglist} from "../../../api/ServiceHealth"
import moment from "moment";
const ServiceHealthDetail: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataSource,setdataSource] = useState([])
  const {state} = useLocation<any>()
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "检测时间",
      dataIndex: "createTime",
      key: "createTime",
      render:(text:any)=><>{moment(text*1000).format('YYYY-MM-DD HH:mm:ss')}</>
    },
    {
      title: "状态",
      dataIndex: "healthState",
      key: "healthState",
      render: (text: any, record: any) => {
        return (
          <div className="nameBar">
            {text === 0 && <Badge color="#FF5353" text="异常" />}
            {text === 1 && <Badge color="#4CEFBE" text="正常" />}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "healthState",
      key: "healthState",
      render: (text: any, record: any, index: number) => (
        text===0?<Button
        type="link"
          onClick={()=>error(record.errMsg)}
      >
        详情
      </Button>:<></>
      ),
    },
  ];
  const error = (text:any) => {
    Modal.error({
      title: '服务健康监测异常',
      content: text,
      okText:"确定"
    });
  };
  const logList = useCallback(()=>{
    setlistload(true)
    getloglist({
      dpaddress:state.dpAddress,
      page:pageParam.page,
      pageSize:pageParam.pageSize,
      healthState:form.getFieldValue("healthState")
    }).then((res:any)=>{
      if(res.code===200){
        setlistload(false)
        setdataSource(res.data)
        setTotal(res.pagination.total)
      }
    })
  },[state, pageParam.page, pageParam.pageSize])
  useEffect(()=>{
    logList()
  },[logList])
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#f7f8fa",
        borderRadius: "4px",
      }}
    >
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push("/service/health");
            }}
          >
            服务健康监控
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>检测历史</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          marginTop: "20px",
          width: "100%",
          background: "#fff",
          padding: "20px",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>检测历史</div>
        </div>
        <div style={{ marginLeft: "12px", width: "100%", height: "100%" }}>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div>
              <span style={{ color: "#86909C" }}>DataAddress</span>
              <span style={{ marginLeft: "10px" }}>{state.dpAddress}</span>
            </div>
            <div style={{ marginLeft: "100px" }}>
              <span style={{ color: "#86909C" }}>API名称</span>
              <span style={{ marginLeft: "10px" }}>{state.apiName}</span>
            </div>
          </div>
          <Form
            form={form}
            layout="inline"
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            {/* <Form.Item name="ip" label="DataAddress">
              <Input placeholder="请输入DataAddress" />
            </Form.Item> */}
            <Form.Item name="healthState" label="健康状态">
              <Select style={{ width: 150 }} placeholder="请选择">
                <Option value={0}>异常</Option>
                <Option value={1}>正常</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: "16px" }}
                type="primary"
                  onClick={logList}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setPageParam({
                    page: 1,
                    pageSize: 10,
                  });
                  logList()
                }}
              >
                重置
              </Button>
            </Form.Item>
          </Form>
          <Table
            columns={columns}
            loading={listload}
            dataSource={dataSource}
            pagination={{
              total,
              pageSize: pageParam.pageSize,
              current: pageParam.page,
              showSizeChanger: true,
              showTotal: (total) => `共 ${total}条记录`,
              onChange: (page, size) => setPageParam({ page, pageSize: size }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ServiceHealthDetail;
