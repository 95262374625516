import { getClient, postClient } from "./requestClient";
import { GET_record_list , GET__daily_list , GET_record_detail ,GET_daily_details} from "./index";

export const getrecordlist = async (params: any) =>
await getClient<any>(GET_record_list, params);

export const getdailylsit = async (params: any) =>
await getClient<any>(GET__daily_list, params);

export const getrecorddetail = async (params: any) =>
await getClient<any>(GET_record_detail, params);

export const getdailydetails = async (params: any) =>
await getClient<any>(GET_daily_details, params);
