/**
 * 公共常量文件
 */

// Token 相关
export const TOKEN = "__token__";
export const REFRESH_TOKEN = "__refreshToken__";
export const REFRESH_TIME = "__refreshTime__";

// export default {};
