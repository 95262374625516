import actionType from "./index";
import { getSystemlist } from '../api/scenario'

export const setN = (playload) => ({
    // const type = playload === "+" ? actionType.ADD : actionType.REDUCE
    type: actionType.CALC,
    playload
})
export const setM = (playload) => {
    // console.log(playload);
    return {
        type: actionType.CALC1,
        playload
    }
}

//初始化列表
const initList = (playload) => ({
    type: actionType.GETSYSTEMDATA,
    playload
})

//异步初始化列表
export const asyncInitList = (playload) => {
    return async (dispatch) => {
        const res = await getSystemlist(playload);
        if (res.code === 200) {
            dispatch(initList(res.data));
            return res
        }
    }
}
