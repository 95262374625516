import { Form, Input, Button, message } from "antd";
import { useState } from "react";
import { postUserlogin } from "../../../api/userlist";
import Tools from "../../../util/tools";
import { TOKEN, REFRESH_TOKEN } from "../../../constants/index";
import * as El from "./style";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { getUserinfo, getuserinfo } from "../../../api/userlist";
const Login = (props: any) => {
  const [btnLoad, setbtnLoad] = useState(false);
  const onFinish = (values: any) => {
    setbtnLoad(true);
    Tools.removeStorage(TOKEN);
    postUserlogin({ username: values.user_name, password: values.password })
      .then((res: any) => {
        console.log(res);
        if (res.code === 200) {
          Tools.setStorage(TOKEN, res.data.accessToken);
          Tools.setStorage("userInfo", res.data);
          localStorage.setItem(TOKEN, res.data.accessToken);
          setbtnLoad(false);
          // getUserinfo({}).then(res => {
          //   console.log(res);
          //   if (res.code === 200) {
          //     getuserinfo({ id: res.data.AccountId }).then((res) => {
          //       console.log(res);
          //       // window.location.href = `/${res.data.redirect_url}`;
          //       if (res.data.user_role.name === "管理员") {
          if (res.data.roleCode === "user") {
            window.location.href = "/credentials";
          } else {
            window.location.href = "/userlist";
          }
          //       } else {
          //         window.location.href = "/connector";
          //       }
          //     })
          //   }
          // })
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data);
          setbtnLoad(false);
        } else {
          setbtnLoad(false);
          message.error("接口请求超时！");
        }
      });
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <El.Wrapper>
        <El.LoginWrapper>
          <div className="login-left" />
          <div className="login-right">
            <div className="welcome">欢迎登录</div>
            <Form onFinish={onFinish}>
              <Form.Item
                name="user_name"
                label=""
                rules={[{ required: true, message: "请输入用户名" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="请输入用户名"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label=""
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="请输入密码"
                />
              </Form.Item>
              <div style={{ position: "relative", marginBottom: "45px" }}>
              </div>
              <Form.Item
                wrapperCol={{ offset: 8, span: 16 }}
                style={{ marginRight: 350 }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: 320, borderRadius: "8px" }}
                  className="ant-btn"
                  loading={btnLoad}
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </El.LoginWrapper>
      </El.Wrapper>
    </div>
  );
};
export default Login;
