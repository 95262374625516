import React, { useEffect } from "react";
import { Badge, Divider } from "antd";
import styles from "../index.module.scss";
import * as echarts from "echarts";
import moment from "moment";
import { getservicestatistics } from "../../../api/ServiceHealth";
const CallStatisticsCharts: React.FC = () => {
  useEffect(() => {
    getservicestatistics().then((res) => {
      if (res.code === 200) {
        var chartDom = document.getElementById("charts");
        if (chartDom !== null) {
          var myChart = echarts.init(chartDom);
          var option;
          let data: any = [];
          res.data.x.map((item: any, index: any) => {
            data.push([item, res.data.y[index]]);
          });
          option = {
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "15%",
              // bottom: "35%",
              top: "7%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              // data:res.data.x
            },
            yAxis: {
              type: "value",
              boundaryGap: [0, "100%"],
              // data:res.data.y
            },
            dataZoom: [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
              {
                start: 0,
                end: 100,
              },
            ],
            series: [
              {
                type: "line",
                symbol: "none",
                smooth: true,
                areaStyle: {},
                data: data,
              },
            ],
          };

          option && myChart.setOption(option);
        }
      }
    });
  }, []);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className={styles["acc"]}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Badge
          color="#165DFF"
          text="服务调用统计"
          style={{ borderRadius: "50%" }}
        />
        <div
          style={{
            padding: "4px 8px",
            border: "1px solid #165DFF",
            color: "#165DFF",
          }}
        >
          24小时
        </div>
      </div>
      <Divider />
      <div id="charts" style={{ width: "100%", height: "80%" }}></div>
    </div>
  );
};

export default CallStatisticsCharts;
