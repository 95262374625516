import * as React from "react";
import { HTMLAttributes } from "react";
import cn from 'classnames';
import './index.scss';

export type DetailCardProps = {
  title?: string | React.ReactNode;
  extra?: React.ReactNode;
  isCoverFormStyle?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>
const DetailCard: React.FC<DetailCardProps> = (props) => {
  const prefixCls = 'cbd-detail-card'
  const { className, children, title, isCoverFormStyle = false, extra, ...rest } = props
  return <div className={cn(className, `${prefixCls}-wrapper`)} {...rest}>
    <div className={`${prefixCls}-title`}>
      {title && <span className={`${prefixCls}-title-text`}>{title}</span>}
      <div>{extra}</div>
    </div>
    <div className={cn(`${prefixCls}-body`, { 'reset-form-color': isCoverFormStyle })}>
      {children}
    </div>
  </div>
}
export default DetailCard;