import { postClient ,getClient} from "./requestClient";
import {POST_CATEGORY_CREATE , GET_CATEGORY_SEARCH ,POST_CATEGORY_MODIFY ,GET_CATEGORY_DELETE,GET_PLANTFORM_LIST} from "./index"

//创建类目
export const postCategory = async (params: any) =>
  await postClient<any>(POST_CATEGORY_CREATE, params);

  //类目菜单列表
export const getCategorylist = async (params: any) =>
await getClient<any>(GET_CATEGORY_SEARCH, params);

//更新or编辑类目
export const postCategorymodify = async (params: any) =>
  await postClient<any>(POST_CATEGORY_MODIFY, params);

//删除类目
export const getCategorydelete = async (params: any) =>
await getClient<any>(GET_CATEGORY_DELETE, params);

//类目右侧表格
export const getplantformlist = async (params: any) =>
await getClient<any>(GET_PLANTFORM_LIST, params);