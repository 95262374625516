import React, { useState, useEffect, useCallback } from "react";
import moment from 'moment'
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Table,
  DatePicker,
  Space,
  Tabs,
} from "antd";
import { getMsgSendedList, getMsgConsumedList } from "@/api/topic"
const TopicSelect: React.FC = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [timeSelect, setTimeSelect] = useState({
    start: 0,
    end: 0
  })
  const history = useHistory();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [msgList, setMsgList] = useState([]);
  const [nowType, setnowType] = useState<any>();
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Message Code",
      dataIndex: "msgCode",
      key: "msgCode",
      // align: "center" as "center",
    },
    {
      title: "Topic Code",
      dataIndex: "topicCode",
      key: "topicCode",
      // render: (text: any) => text.name,
    },
    // {
    //   title: "订阅总数",
    //   dataIndex: "count",
    //   key: 'count'
    // },
    {
      title: "发布时间",
      dataIndex: "saveAt",
      key: 'saveAt'
    },
    {
      title: "最近消费时间",
      dataIndex: "sendAt",
      key: 'sendAt'
    },
    {
      title: "操作",
      dataIndex: "msgCode",
      width: 100,
      // align: "center" as "center",
      render: (record: any) => (
        <>
          <a onClick={()=>{
            history.push(`/message-info/${record}`)
          }}>查看消息</a>
        </>
      ),
    },
  ];
  useEffect(() => {
    setnowType(1);
  }, []);
  useEffect(() => {
    if (nowType) {
      getList();
    }
  }, [pageParam, nowType]);
  const getList = useCallback(() => {
    setlistload(true);
    if(nowType == 1) {
      getMsgSendedList({
        ...pageParam,
        messageCode: form.getFieldValue("messageCode"),
        topicCode: form.getFieldValue("topicCode"),
        start: timeSelect.start,
        end: timeSelect.end
      }).then((res: any) => {
        setlistload(false);
        res.data = res.data ? res.data.map((item:any) => {
          item.saveAt = moment(item.saveAt).format('YYYY-MM-DD HH:mm:ss')
          item.sendAt = item.sendAt ? moment(item.sendAt).format('YYYY-MM-DD HH:mm:ss') : '-'
          return item
        }) : []
        setMsgList(res.data);
        setTotal(res.pagination.total);
      });
    } else {
      getMsgConsumedList({
        ...pageParam,
        messageCode: form.getFieldValue("messageCode"),
        topicCode: form.getFieldValue("topicCode"),
        start: timeSelect.start,
        end: timeSelect.end
      }).then((res: any) => {
        setlistload(false);
        res.data = res.data ? res.data.map((item:any) => {
          item.saveAt = moment(item.receiveAt).format('YYYY-MM-DD HH:mm:ss')
          item.sendAt = item.sendAt ? moment(item.sendAt).format('YYYY-MM-DD HH:mm:ss') : '-'
          item.count = 0
          return item
        }) : []
        setMsgList(res.data);
        setTotal(res.pagination.total);
      });
    }
  }, [pageParam, nowType]);
  const resetParams = () => {
    setPageParam({
      page: 1,
      pageSize: 10
    })
    setTimeSelect({
      start: 0,
      end: 0
    })
    form.resetFields();
  }
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>消息查询</div>
      </div>
      <div
        style={{
          marginTop: "20px",
          fontSize: "20px",
          width: "100%",
        }}
      >
        <Tabs
          activeKey={nowType + ""}
          onChange={(e) => {
            setnowType(e);
            resetParams()
          }}
        >
          <Tabs.TabPane tab="我发送的" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="我消费的" key="2"></Tabs.TabPane>
        </Tabs>
      </div>
      <div style={{ height: 50, marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="messageCode" label="Message code：">
            <Input placeholder="请输入Message code" />
          </Form.Item>
          <Form.Item name="topicCode" label="Topic code：">
            <Input placeholder="请输入Topic code" />
          </Form.Item>
          <Form.Item name="time" label="时间范围：">
            <Space direction="vertical" size={12}>
              <RangePicker  showTime style={{ maxWidth: "280px" }}
                onChange={date=>{
                  timeSelect.start = moment(date?.[0]).valueOf()
                  timeSelect.end = moment(date?.[1]).valueOf()
                }}
              />
            </Space>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={
                resetParams
              }
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={msgList}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};
export default TopicSelect;
