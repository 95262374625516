import React, { useState, useCallback, useEffect } from "react";
import {
  Breadcrumb,
  Form,
  Select,
  Button,
  Input,
  Table,
  Badge,
  Modal,
  message,
  DatePicker,
} from "antd";
import { postcredentiallist, postretardedexpiry } from "../../api/credential";
import styles from "./index.module.scss";
import "./index.scss";
import moment from "moment";
const CertificateManagement: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [mdForm] = Form.useForm();
  const { Option } = Select;
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(false);
  const [total, setTotal] = useState(0);
  const [tabData, settabData] = useState([]);
  const [visible, setvisible] = useState(false);
  const [userId, setuserId] = useState<any>(null);
  const [seleVal, setseleVal] = useState(0);
  const [timeType, settimeType] = useState(0);
  const [status, setstatus] = useState<any>();
  const [starttime, setstarttime] = useState<any>();
  const [endtime, setendtime] = useState<any>();
  const columns = [
    {
      title: "序号",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "用户名",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "AccessKey",
      dataIndex: "accessKey",
      key: "accessKey",
    },
    {
      title: "失效时间",
      dataIndex: "accessKey",
      key: "accessKey",
      render: (text: any, record: any) => (
        <>{record.effectiveDate + "~" + record.expiryDate}</>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      align: "center" as "center",
      render: (text: any) => (
        <div>
          {text === 1 && <Badge color="#4CEFBE" text="正常" />}
          {text === 2 && <Badge color="#FF5353" text="即将过期" />}
          {text === 3 && <Badge color="#E7E7E7" text="已过期" />}
        </div>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (id: string, record: any, index: number) => (
        <Button
          type="link"
          onClick={() => {
            setvisible(true);
            setuserId(record.accessKey);
            setstatus(record.status);
            setstarttime(record.effectiveDate);
            setendtime(record.expiryDate);
            mdForm.setFieldsValue({
              username: record.username,
              time: record.effectiveDate + "-" + record.expiryDate,
            });
          }}
        >
          延期
        </Button>
      ),
    },
  ];
  //时间转换的方法
  const timeData = (data: any) => {
    var timer: any = [];
    if (data === 1) {
      timer[0] = moment(starttime).format("x");
      timer[1] = moment(endtime).add(1, "y").endOf("day").format("x");
      return timer;
    } else if (data === 2) {
      timer[0] = moment(starttime).format("x");
      timer[1] = moment(endtime).add(2, "y").endOf("day").format("x");
      return timer;
    } else if (data === 3) {
      timer[0] = moment(starttime).format("x");
      timer[1] = moment(endtime).add(3, "y").endOf("day").format("x");
      return timer;
    } else {
      timer[0] = moment(starttime).format("x");
      timer[1] = moment(endtime).endOf("day").format("x");
      return timer;
    }
  };
  const dataList = useCallback(() => {
    setlistload(true);
    postcredentiallist({
      username:
        form.getFieldValue("username") === ""
          ? undefined
          : form.getFieldValue("username"),
      page: pageParam.page,
      pageSize: pageParam.pageSize,
      ak:
        form.getFieldValue("accessKey") === ""
          ? undefined
          : form.getFieldValue("accessKey"),
      status: seleVal,
    }).then((res: any) => {
      if (res.code === 200) {
        setlistload(false);
        settabData(res.data);
        setTotal(res.pagination.total);
      }
    });
  }, [form, pageParam.page, pageParam.pageSize, seleVal]);
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    //
    if (status === 3) {
      return current && current < moment().subtract(1, "days");
    } else {
      return current && current < moment().endOf("day");
    }
  };
  useEffect(() => {
    dataList();
  }, [pageParam]);
  const expiry = () => {
    mdForm.validateFields().then((res) => {
      postretardedexpiry({
        accessKey: userId,
        effectiveDate: timeData(timeType)[0] * 1,
        expiryDate: timeData(timeType)[1] * 1,
      }).then((respone) => {
        if (respone.code === 200) {
          message.success("延期成功!");
          mdForm.resetFields();
          setvisible(false);
          setPageParam({
            page: 1,
            pageSize: 10,
          });
        }
      });
    });
  };
  return (
    <div className="certificate-management-wrap">
      <div className={styles["Certificate-Management"]}>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>凭证管理</div>
        </div>
        <div style={{ marginLeft: "12px" }}>
          <Form
            form={form}
            layout="inline"
            style={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <Form.Item
              name="username"
              label="用户名"
              className={styles["Certificate-Management-Modal"]}
            >
              <Input
                placeholder="请输入用户名"
                style={{ color: "rgba(0, 0, 0, 1)" }}
              />
            </Form.Item>
            <Form.Item
              name="accessKey"
              label="AccessKey"
              className={styles["Certificate-Management-Modal"]}
            >
              <Input
                placeholder="请输入AccessKey"
                style={{ color: "rgba(0, 0, 0, 1)" }}
              />
            </Form.Item>
            {/* <Form.Item name="type" label="状态"> */}
            <div style={{}}>
              <span>状态 : </span>
              <Select
                style={{ width: 150 }}
                placeholder="请选择"
                value={seleVal}
                onChange={(e: any) => {
                  setseleVal(e);
                }}
              >
                <Option value={0}>全部</Option>
                <Option value={1}>正常</Option>
                <Option value={2}>即将过期</Option>
                <Option value={3}>已过期</Option>
              </Select>
            </div>
            {/* </Form.Item> */}
            <Form.Item>
              <Button
                style={{ marginRight: "16px", marginLeft: "16px" }}
                type="primary"
                onClick={dataList}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setPageParam({
                    page: 1,
                    pageSize: 10,
                  });
                  setseleVal(0);
                  dataList();
                }}
              >
                重置
              </Button>
            </Form.Item>
          </Form>
          <Modal
            title={"凭证延期"}
            visible={visible}
            width={800}
            onCancel={() => {
              setvisible(false);
              mdForm.resetFields();
              settimeType(0);
            }}
            onOk={expiry}
            okText="确定"
            cancelText="取消"
          >
            <Form
              form={mdForm}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item name="username" label="用户名">
                <Input bordered={false} disabled={true} />
              </Form.Item>
              <Form.Item name="time" label="凭证有效期">
                <Input bordered={false} disabled={true} />
              </Form.Item>
              <Form.Item
                name="datatime"
                label="延期年限"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: 250 }}
                  placeholder="请选择"
                  onChange={(e) => {
                    settimeType(e);
                  }}
                >
                  <Option value={1}>1年</Option>
                  <Option value={2}>2年</Option>
                  <Option value={3}>3年</Option>
                  <Option value={4}>自定义</Option>
                </Select>
              </Form.Item>
              {timeType === 4 && (
                <Form.Item
                  name="customtime"
                  label="自定义时间"
                  rules={[{ required: true }]}
                >
                  <RangePicker
                    style={{ width: 250 }}
                    disabledDate={disabledDate}
                    onChange={(date, dateString) => {
                      setstarttime(dateString[0]);
                      setendtime(dateString[1]);
                    }}
                  />
                </Form.Item>
              )} 
            </Form>
          </Modal>
          <Table
            columns={columns}
            loading={listload}
            dataSource={tabData}
            pagination={{
              total,
              pageSize: pageParam.pageSize,
              current: pageParam.page,
              showSizeChanger: true,
              showTotal: (total) => `共 ${total}条记录`,
              onChange: (page, size) => setPageParam({ page, pageSize: size }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CertificateManagement;
