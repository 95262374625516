import { getClient, postClient } from "./requestClient";
import {
  GET_SCENARIO_LIST,
  GET_SCENARIO_DETAIL,
  POST_CREATE_SCENE,
  POST_MODIFY_SCENE,
  GET_CREATE_CATEGORY,
  POST_CREATE_SYSTEM,
  POST_CREATE_RESOURCE,
  GET_SYSTEM_LIST,
  POST_SYSTEM_MODIFY,
  GET_HEALTH_CHECK,
  GET_SCENARIO_DELETE,
  GET_SYSTEM_DELETE,
  POST_CREATE_PLATFORM,
  POST_PLATFORM_MODIFY,
  GET_PLATFORM_LIST,
  GET_PLATFORM_DELETE,
  GET_MYSYSTEM_LIST,
  GET_MYPLATFORM_LIST,
  GET_SYSTEM_PROTOCOL,
  GET_SYSTEMPLATFORM_LIST
} from "./index";

//删除系统
export const getSystemdelete = async (params: any) =>
  await getClient<any>(GET_SYSTEM_DELETE, params);
//删除平台
export const getPlatformdelete = async (params: any) =>
  await getClient<any>(GET_PLATFORM_DELETE, params);
//场景列表
export const getScenariolist = async (params: any) =>
  await getClient<Scenario[]>(GET_SCENARIO_LIST, params);

//场景详情
export const getScenariodetail = async (params: any) =>
  await getClient<any>(GET_SCENARIO_DETAIL, params);
export interface Scenario {
  // id:string,

  description: string,
  id: string,
  name: string

}
//删除场景
export const getScenariodelete = async (params: any) =>
  await getClient<any>(GET_SCENARIO_DELETE, params);
//场景创建
export const postScenarioCreate = async (params: any) =>
  await postClient<any>(POST_CREATE_SCENE, params);
//场景修改
export const postScenarioModify = async (params: any) =>
  await postClient<any>(POST_MODIFY_SCENE, params);
//获取系统平台类别
export const getCreateCategry = async (params: any) =>
  await getClient<any>(GET_CREATE_CATEGORY, params);
//系统创建
export const postCreateSystem = async (params: any) =>
  await postClient<any>(POST_CREATE_SYSTEM, params);
//创建平台
export const postCreatePlatform = async (params: any) =>
  await postClient<any>(POST_CREATE_PLATFORM, params);
//创建接口资源
export const postCreateresource = async (params: any) =>
  await postClient<any>(POST_CREATE_RESOURCE, params);

//系统列表
export const getSystemlist = async (params: any) =>
  await getClient<any>(GET_SYSTEM_LIST, params);
//我的系统列表
export const getMySystemlist = async (params: any) =>
  await getClient<any>(GET_MYSYSTEM_LIST, params);
//平台列表
export const getPlatformlist = async (params: any) =>
  await getClient<any>(GET_PLATFORM_LIST, params);
//我的平台列表
export const getMyPlatformlist = async (params: any) =>
  await getClient<any>(GET_MYPLATFORM_LIST, params);
//所有系统/平台列表
export const getSystemPlatformlist = async (params: any) =>
  await getClient<any>(GET_SYSTEMPLATFORM_LIST, params);
//更新系统
export const postSystemmodify = async (params: any) =>
  await postClient<any>(POST_SYSTEM_MODIFY, params);
//更新平台
export const postPlatformmodify = async (params: any) =>
  await postClient<any>(POST_PLATFORM_MODIFY, params);
  //健康检测
export const getHealthcheck = async (params: any) =>
await getClient<any>(GET_HEALTH_CHECK, params);
  //协议列表
  export const getsystemprotocol = async (params: any) =>
  await getClient<any>(GET_SYSTEM_PROTOCOL, params);