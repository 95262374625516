import { Route } from "react-router-dom";
// import Layout from "./component/Layout/index";
import Apply from "./pages/Apply/index";
import Examine from "./pages/Examine/index";
import Userlist from "./pages/Userslist";
import Usercreate from "./pages/Userslist/Create/index";
import ResourceDetail from "./pages/Resource/Detail";
import Plugins from "./pages/Plugins";
import Applyresource from "./pages/Applyresource";
import Scene from "./pages/Scene";
import Scenelist from "./pages/Scenelist/index";
import UseConsole from "./pages/UseConsole";
import X6 from "./pages/X6/index";
import SystemList from "./pages/systemList";
import Essential from "./pages/Personal/Essential";
import Journal from "./pages/Personal/Journal";
import Subaccount from "./pages/Personal/Subaccount";
import Permissions from "./pages/Permissions";
import Category from "./pages/Category";
import PluginAdmin from "./pages/PluginAdmin/plugin";
import PluginList from "./pages/PluginAdmin/pluginList";
import Monitoring from "./pages/Monitoring";
import ResourcePort from "./pages/ResourcePort";
import ResourcePortCreate from "./pages/UseConsole/Port/create";
import InterfaceVersion from "./pages/ResourcePort/versions";
import AdminConsole from "./pages/AdminConsole";
import Connector from "./pages/Connector";
import Credentials from "./pages/Credentials";
import Servicemanagement from "./pages/Servicemanagement";
import ServiceFindList from "./pages/ServiceFindList";
import Bllist from "./pages/Bllist";
import MyTopicList from "./pages/MyTopic";
import TopicSubscription from "./pages/TopicSubscription";
import TopicSelect from "./pages/TopicSelect";
import MessageInfo from "./pages/TopicSelect/MessageInfo";
import PublishServe from "./pages/Service/PublishServe";
import DetailServe from "./pages/Service/Detail";
import ConnectorDetail from "./pages/Connector/Detail";
import OnlineConnector from "./pages/Connector/OnlineConnector";
import CreateTopic from "./pages/MyTopic/Create";
import TopicDetail from "./pages/MyTopic/Detail";
import ServiceHealth from "./pages/ServiceHealth/index";
import CertificateManagement from "./pages/CertificateManagement";
import ServiceHealthDetail from "./pages/ServiceHealth/Detail";
import GatewaySwitch from "./pages/GatewaySwitch";
import ServiceMonitoring from "./pages/ServiceMonitoring";
import InterconnectResouFce from "./pages/InterconnectResouFce";
import Cnum from "./pages/Cnum";
import CnumDetail from "./pages/Cnum/Detail/CnumDetail";
import CallStatistics from "./pages/CallStatistics/index";
import CallStatisticsDetail from "./pages/CallStatistics/Detail";
import ServiceTest from "./pages/ServiceTest";
import ServiceTestDetail from "./pages/ServiceTest/Detail";
import InterconnectionApplication from "./pages/InterconnectionApplication";
import InstanceManagement from "./pages/InstanceManagement";
import InstanceTableList from "./pages/InstanceManagement/TableList";
import ApplyRender from "./pages/InstanceManagement/Detail";
import ApprovalDetail from "./component/ApplyDetail"
import Approval from "./pages/Approval"
import InstanceTableListDetail from "./pages/InstanceManagement/TableList/Detail"
import GrayLevel from "./pages/Servicemanagement/GrayLevel";
import Aggregation from "./pages/Aggregation"
import AddServe from "./pages/Aggregation/AddServe"
import AggregationDetail from "./pages/Aggregation/Detail"
import { Switch } from "react-router-dom";
const pages = [
  {
    path: "/userlist",
    component: Userlist,
  },
  {
    path: "/adminconsole",
    component: AdminConsole,
  },
  {
    path: "/resource/detail/:id/:type",
    component: ResourceDetail,
  },
  {
    path: "/userlists/create",
    component: Usercreate,
  },
  {
    path: "/apply",
    component: Apply,
  },
  {
    path: "/examine",
    component: Examine,
  },
  {
    path: "/applyresource/:id",
    component: Applyresource,
  },
  {
    path: "/scene/:id",
    component: Scene,
  },
  {
    path: "/plugins",
    component: Plugins,
  },
  {
    path: "/scenelist",
    component: Scenelist,
  },
  {
    path: "/useconsole",
    component: UseConsole,
  },
  {
    path: "/x6",
    component: X6,
  },
  {
    path: "/systemlist",
    component: SystemList,
  },

  {
    path: "/personal/essential",
    component: Essential,
  },
  {
    path: "/personal/journal",
    component: Journal,
  },
  {
    path: "/personal/subaccount",
    component: Subaccount,
  },
  {
    path: "/permissions",
    component: Permissions,
  },
  {
    path: "/category",
    component: Category,
  },
  {
    path: "/pluginadmin",
    component: PluginAdmin,
  },
  {
    path: "/pluginadmin/list",
    component: PluginList,
  },
  {
    path: "/monitoring",
    component: Monitoring,
  },
  {
    path: "/resourceport",
    component: ResourcePort,
  },
  {
    path: "/resourceportcreate",
    component: ResourcePortCreate,
  },
  {
    path: "/interfaceversion/:id",
    component: InterfaceVersion,
  },
  {
    path: "/connector",
    component: Connector, //Connector插件OnlineConnector
  },
  {
    path: "/OnlineConnector",
    component: OnlineConnector, //OnlineConnector在线
  },
  {
    path: "/connectordetail",
    component: ConnectorDetail, //Connector插件新增or编辑
  },
  {
    path: "/topic/:id",
    component: CreateTopic, //Topic注册or编辑
  },
  {
    path: "/topic-detail/:id",
    component: TopicDetail, //Topic详情
  },
  {
    path: "/credentials",
    component: Credentials, //我的凭证
  },
  {
    path: "/servicemanagement",
    component: Servicemanagement, //服务管理
  },
  {
    path: "/bllist",
    component: Bllist, //黑白名单
  },
  {
    path: "/mytopic",
    component: MyTopicList, //我的消息
  },
  {
    path: "/topic-subscription",
    component: TopicSubscription, //Topic订阅
  },
  {
    path: "/topic-select",
    component: TopicSelect, //消息查询
  },
  {
    path: "/message-info/:id",
    component: MessageInfo, //查看消息
  },
  {
    path: "/service/publish-serve",
    component: PublishServe, //服务编排
  },
  {
    path: "/service/health",
    component: ServiceHealth, //服务健康监控
  },
  {
    path: "/certificatemanagement",
    component: CertificateManagement, //凭证管理
  },
  // {
  //   path: "/servicehealthdetail",
  //   component: ServiceHealthDetail,
  // },
  //服务详情
  {
    path: "/service/detail",
    component: DetailServe,
  },
  //服务查找
  {
    path: "/service/findlist",
    component: ServiceFindList,
  },
  { path: "/servicehealthdetail", component: ServiceHealthDetail },
  {
    path: "/gatewayswitch",
    component: GatewaySwitch, //网管开关设置ServiceMonitoring
  },
  {
    path: "/servicemonitoring",
    component: ServiceMonitoring, //服务运行监控
  },
  {
    path: "/interconnectresoufce",
    component: InterconnectResouFce, //互联资源
  },
  {
    path: "/cnum",
    component: Cnum, //调用记录
  },
  {
    path: "/cnumdetail",
    component: CnumDetail, //调用记录交易详情
  },
  {
    path: "/callstatistics",
    component: CallStatistics, //调用统计
  },
  {
    path: "/callstatisticsdetail",
    component: CallStatisticsDetail, //调用统计查看明细
  },
  {
    path: "/servicetest",
    component: ServiceTest, //服务用例测试
  },
  {
    path: "/servicetest/detail",
    component: ServiceTestDetail, //服务用例测试详情
  },
  {
    path: "/interconnectionapplication",
    component: InterconnectionApplication, //互联申请
  },
  {
    path: "/instancemanagement",
    component: InstanceManagement, //互联实例管理
  },
  {
    path: "/instancemanagementDetail",
    component: InstanceTableList, //互联资源
  },
  {
    path: "/applyrender",
    component: ApplyRender, //互联申请页
  },
  {
    path: "/approvaldetail",
    component: ApprovalDetail, //审批详情
  },
  {
    path: "/approval",
    component: Approval, //审批列表
  },
  {
    path: "/instancetableListdetail",
    component: InstanceTableListDetail, //查看资源(对外)详情
  },
  {
    path: "/graylevel",
    component: GrayLevel, //查看资源(对外)详情
  },
  {
    path: "/aggregationserver",
    component: Aggregation, //聚合服务管理
  },
  {
    path: "/aggregationadd",
    component: AddServe, //聚合服务管理
  },
  {
    path: "/AggregationDetail",
    component: AggregationDetail, //聚合服务管理
  }
];

const Routes = () => {
  return (
    <>
      {pages.map((i: any) => (
        <Switch key={i.path}>
          <Route
            path={i.path}
            key={i.path}
            // render={() => <Layout>{i.component}</Layout>}
            component={i.component}
            exact
          />
        </Switch>
      ))}
    </>
  );
};
export default Routes;
