import { Progress } from './progress'

let progressInstance: any = 0;
const getProgress = () => {
  progressInstance = progressInstance || Progress()
}

export const progress = {
  start() {
    getProgress()
  },
  done() {
    if (progressInstance) {
      progressInstance.destroy()
      progressInstance = null
    }
  }
}