// @ts-nocheck
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  // useRef,
} from "react";
// 导入
// import PubSub from "pubsub-js";
import { WebAdminContext } from "../../../context/WebAdminContext";
import {
  Modal,
  Input,
  Form,
  Select,
  Table,
  Button,
  message,
  Tag,
  Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./index.scss";
import { datasource } from "../interface";
import { getSystemlist } from "../../../api/scenario";
// import {} from "../../../api/resource"
import { getResourceList, postResourceCreate } from "../../../api/resource";
// import { useHistory } from "react-router-dom";
const { Option } = Select;
interface SourceData {
  systemInfo: {
    category: string;
    name: string;
  };
}
const Index = (props: any, ref: any) => {
  // console.log(props);
  const { pubId } = React.useContext(WebAdminContext);
  console.log(pubId);

  // const [pubId, setpubId] = useState<string>("");
  // const numRef = useRef<string>(pubId);
  const [total, setTotal] = useState<number>(0);
  const [form] = Form.useForm();
  const [formlate] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false); //弹框状态
  const [tableData, setTableData] = useState<datasource[]>([]); //表格数据
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]); //表格已选择
  const [option, setOption]: any = useState();
  const [addVisible, setaddVisible] = useState(false);
  const [listload, setListload] = useState(true);
  const [listData, setListData] = useState<any>([]);
  const [selecArr, setselectArr] = useState<any>([]);
  const [pageParams, setPageparams] = useState({
    page: 1,
    pageSize: 5,
  });
  //表单布局
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  // const history = useHistory();
  //useRef子传父
  useImperativeHandle(ref, () => ({
    setIsModalVisible,
    setSelectedRowKeys,
    setTableData,
    setOption,
    form,
    tableData,
  }));

  const getData = () => {
    let arr: any = [...tableData];
    arr = JSON.stringify(arr);
    arr = JSON.parse(arr);
    // console.log(arr);
    let id, name, color;
    if (option) {
      id = option.id;
      name = option.name;
      color = option.bgColor;
    } else {
      id = "";
      name = "";
      color = "";
    }
    let obj1 = {
      id,
      name,
      color,
      dataSource: arr,
    };

    let dataSource = [];
    arr.forEach((item: any) => {
      item.color = color;
      dataSource.push({
        category: item.systemInfo.category,
        dp_address: item.systemInfo.domain,
        system_id: item.systemInfo.name,
        status: item.systemInfo.healthState, //是否连接中枢
        constructState: item.systemInfo.constructState, //系统平台状态
        platform: item.systemInfo.platform,
        id: item.id,
        name: item.name,
        key: item.created_at,
        systemId: item.system,
        healthState: item.healthState, //接口健康状态
      });
    });
    // console.log(dataSource);

    let obj = {
      id,
      name,
      color,
      dataSource,
    };

    // console.log(obj);
    // console.log(obj1);
    props.onOk(obj, obj1);
  };

  //确认
  const handleOk = () => {
    getData();
    setTableData([]);
    // settreeData([])
    setSelectedRowKeys([]);
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "接口名称",
      dataIndex: "name",
      align: "center" as "center",
      render: (text: any) => (
        <Tooltip title={text}>
          <div>{text}</div>
        </Tooltip>
      ),
      onCell: () => {
        return {
          style: {
            maxWidth: 100,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        };
      },
    },
    {
      title: "接口描述",
      dataIndex: "description",
      align: "center" as "center",
      render: (text: any) => (
        <Tooltip title={text}>
          <div>{text}</div>
        </Tooltip>
      ),
      onCell: () => {
        return {
          style: {
            maxWidth: 100,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        };
      },
    },
    {
      title: "系统",
      align: "center" as "center",
      render: (record: SourceData) => (
        <Tooltip title={record.systemInfo.name}>
          <div>{record.systemInfo.name}</div>
        </Tooltip>
      ),
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        };
      },
    },
    {
      title: "平台",
      align: "center" as "center",
      render: (record: SourceData) => (
        <Tooltip title={record.systemInfo.platform}>
          <div>{record.systemInfo.platform}</div>
        </Tooltip>
      ),
    },
    {
      title: "健康状态",
      dataIndex: "healthState",
      align: "center" as "center",
      render: (text: number) =>
        text === 2 ? (
          <div className="approve-pass"> health </div>
        ) : (
          <div className="approve-reject"> unhealth </div>
        ),
    },
    {
      title: "类别",
      align: "center" as "center",
      render: (record: SourceData) => (
        <div>{categoryMethod(record.systemInfo.category)}</div>
      ),
    },
  ];
  //tag关闭
  const handleClose = (info: any) => {
    let list = tableData.filter((item) => item.id !== info.id);
    let arr = list.map((item) => item.created_at);
    setTableData(list);
    setSelectedRowKeys(arr);
  };
  //表格选择
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelect: (record, selected, all) => {
      // console.log(record, selected, all)
      let arr = [];
      let brr = [];
      if (selected) {
        // 如果选中,加入selectedRowKeys
        if (selectedRowKeys.length) {
          arr = Array.from(new Set([...selectedRowKeys, record.key]));
          brr = Array.from(new Set([...tableData, record]));
        } else {
          arr = [record.key];
          brr = [record];
        }
      } else {
        // 如果未选中,删除这一项数据
        arr = selectedRowKeys.filter((item) => item !== record.key);
        brr = tableData.filter((item) => item.id !== record.id);
      }
      setSelectedRowKeys(arr);
      setTableData(brr);
    },
  };
  //新建接口确认
  const handleClick = () => {
    formlate.validateFields().then(async (res) => {
      // console.log(res);
      const { code } = await postResourceCreate({
        description: res.description,
        name: res.name,
        system: res.system,
        healthState: 1,
      });
      if (code === 200) {
        message.success("新增成功");
        formlate.resetFields();
        setaddVisible(false);
        if (!props.idvalue.id) {
          setIsModalVisible(true);
          allResourcelist();
          return;
        }
        getResourceList({
          page: 1,
          page_size: 1000,
        }).then((res) => {
          let a: any = res.data[res.data.length - 1];
          let obj = {
            id: "",
            name: "",
            color: "",
            // type: "port",
            dataSource: [
              {
                category: a.systemInfo.category,
                dp_address: a.systemInfo.domain,
                system_id: a.systemInfo.name,
                status: a.systemInfo.healthState,
                constructState: a.systemInfo.constructState, //系统平台状态
                platform: a.systemInfo.platform,
                id: a.id,
                name: a.name,
                key: a.created_at,
                systemId: a.system,
                healthState: a.healthState,
              },
            ],
          };
          props.onCreatePort(obj);
          // console.log(a);
          // console.log(obj);
        });
      }
    });
    props.setidvalue({ id: "", val: false });
  };
  //获取接口列表
  const allResourcelist = useCallback(async () => {
    await setListload(true);
    const { data, code, paginate } = await getResourceList({
      name: form.getFieldValue("name"),
      system: form.getFieldValue("system"),
      page: pageParams.page,
      page_size: pageParams.pageSize,
    });
    if (code === 200) {
      setListload(false);
      setTotal(paginate.total);
      if (data != null) {
        let a = data.map((item: any, index) => {
          item.key = item.created_at;
          // item.key = item.id;
          return item;
        });
        // console.log(a);
        setListData(a);
      } else {
        setListData([]);
      }
    }
  }, [pageParams.page, pageParams.pageSize]);

  const selectList = useCallback(async () => {
    const { data, code } = await getSystemlist({
      page: 1,
      page_size: 1000,
    });
    if (code === 200) {
      setselectArr(data ? data.systems : []);
    }
  }, []);
  const categoryMethod = (data: any) => {
    switch (data) {
      case "001":
        return "国家";
      case "002":
        return "省级";
      case "003":
        return "市级";
    }
  };
  const checkList = async () => {
    setPageparams({
      page: 1,
      pageSize: 5,
    })
    setListload(true);
    const { data, code, paginate } = await getResourceList({
      name: form.getFieldValue("name"),
      system: form.getFieldValue("system"),
      page: 1,
      page_size: 5,
    });
    if (code === 200) {
      setListload(false);
      setTotal(paginate.total);
      if (data != null) {
        let a = data.map((item: any, index) => {
          item.key = item.created_at;
          return item;
        });
        setListData(a);
      } else {
        setListData([]);
      }
    }
  };
  const restList = async () => {
    setListload(true);
    setPageparams({
      page: 1,
      pageSize: 5,
    });
    form.resetFields();
    const { data, code } = await getResourceList({
      page: 1,
      page_size: 10,
    });
    if (code === 200) {
      setListload(false);
      if (data != null) {
        let a = data.map((item: any, index) => {
          item.key = item.created_at;
          return item;
        });
        setListData(a);
      } else {
        setListData([]);
      }
    }
  };

  useEffect(() => {
    if (isModalVisible === true) {
      allResourcelist();
      selectList();
    }
  }, [allResourcelist, isModalVisible, selectList, tableData]);
  useEffect(() => {
    // PubSub.subscribe("频道", (msg, data) => {
    //   selectList();
    //   console.log(data);
    //   setaddVisible(true);
    //   setpubId(data);
    //   numRef.current = data
    // });
    // if(pubId){
    selectList();
    if (props.idvalue.id) {
      setaddVisible(props.idvalue.val);
      formlate.setFieldsValue({ system: props.idvalue.id });
      selectList();
    }
    // }
  }, [props.idvalue, selectList]);

  return (
    <div>
      {isModalVisible && (
        <Modal
          title="关联接口"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={() => {
            setSelectedRowKeys([]);
            setTableData([]);
            // settreeData()
            form.resetFields();
            setIsModalVisible(false);
          }}
          width={800}
          okText="确定"
          cancelText="取消"
          className="sceneModal"
        >
          <Form layout="inline" form={form}>
            <Form.Item label="所属部门" name="departments">
              <Select placeholder="全部" style={{ width: 120 }}>
                <Option value="0">全部</Option>
                <Option value="1">市级</Option>
                <Option value="2">省级</Option>
                <Option value="3">国家</Option>
              </Select>
            </Form.Item>
            <Form.Item label="系统平台" name="system">
              <Select placeholder="全部" style={{ width: 120 }}>
                {selecArr?.map((item: any) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {categoryMethod(item.category)}/{item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="" name="name">
              <Input
                placeholder="请输入接口名称"
                suffix={<SearchOutlined />}
                style={{ width: "160px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={checkList}>
                查询
              </Button>
              <Button
                htmlType="button"
                style={{ marginLeft: "10px" }}
                onClick={restList}
              >
                重置
              </Button>
            </Form.Item>
          </Form>
          <div style={{ marginTop: "20px" }}>
            {tableData.map((item: any) => (
              <Tag
                closable
                onClose={() => handleClose(item)}
                key={item.id}
                style={{ marginBottom: "10px" }}
              >
                {item.name}
              </Tag>
            ))}
          </div>
          <div className="checkwarp">
            <div className="checkbar">
              <div style={{ width: "100%", textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    // history.push("/resource/create");
                    setaddVisible(true);
                    setIsModalVisible(false);
                  }}
                >
                  新建接口
                </Button>
              </div>
              {/* <Checkbox.Group options={options} value={checkValue} onChange={onChange} /> */}
              <Table
                scroll={{ y: 400 }}
                style={{ marginTop: "10px" }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={listData}
                loading={listload}
                pagination={{
                  total,
                  showTotal: (total) => `共 ${total}条记录`,
                  pageSize: pageParams.pageSize,
                  current: pageParams.page,
                  // showQuickJumper: false,
                  showSizeChanger: true,
                  onChange: (page, size) =>
                    setPageparams({ page, pageSize: size }),
                  pageSizeOptions: ["5", "10", "20", "50", "100"],
                }}
              />
            </div>
          </div>
        </Modal>
      )}
      {addVisible &&<Modal
        title="新建接口"
        onOk={handleClick}
        onCancel={() => {
          props.setidvalue({ id: "", val: false });
          setaddVisible(false);
          // setIsModalVisible(true);
          formlate.resetFields();
          console.log(addVisible);
        }}
        width={800}
        okText="保存"
        cancelText="取消"
        className="sceneModal"
        visible={addVisible}
      >
        <Form
          {...formItemLayout}
          form={formlate}
          initialValues={{ system: props.idvalue.id }}
        >
          <Form.Item
            label="名称"
            name="name"
            rules={[{ required: true, message: "请输入接口名称" }]}
          >
            <Input placeholder="请输入接口名称" style={{ width: "300px" }} />
          </Form.Item>
          <Form.Item label="描述" name="description">
            <Input placeholder="请输入接口描述" style={{ width: "300px" }} />
          </Form.Item>
          <Form.Item
            label="系统平台"
            name="system"
            rules={[{ required: true, message: "请选择系统平台" }]}
          >
            <Select
              placeholder="全部"
              style={{ width: "300px" }}
            // defaultValue={pubId.id}
            // value={pubId.id}
            >
              {selecArr?.map((item: any) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {categoryMethod(item.category)}/{item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>}
    </div>
  );
};
export default forwardRef(Index);
