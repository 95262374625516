//请求
import axios,{ AxiosResponse, AxiosError } from "axios";
import { Modal } from "antd";
import Tools from "../util/tools";
import { TOKEN } from "../constants";
import { storageItem } from "../pages/Home";
import { message } from "antd";
import { progress } from "@component/Progress";
const { confirm } = Modal;
let flag = true;
const infoModal = (message: string) => {
  if (!flag) return "";
  confirm({
    title: message,
    onOk() {
      // console.log("确定");
      Tools.removeStorage(TOKEN);
      storageItem.forEach((item) => {
        Tools.removeStorage(item);
      });
      window.location.href = "/login";
    },
    onCancel() {
      // console.log("取消");
    },
  });
  return "";
};
const instance = axios.create({
  responseType: "json",
  timeout: 5000,
  // transformResponse: [
  //   function (result: any) {
  //     const type = Object.prototype.toString.call(result).slice(8, -1);
  //     const data = type === "String" ? JSON.parse(result) : result;
  //     if (data?.code === 401) {
  //       const errorMsg = "登录超时，请重新登录";
  //       infoModal(errorMsg);
  //       flag = false;
  //     } else if (data?.code === 500) {
  //       message.error(data?.message);
  //     }
  //     return data;
  //   },
  // ],
});
// 发送多个请求时，发送第一个请求时执行start()，最后一个请求结束时执行done()
instance.interceptors.request.use((config: any) => {
  // 发送请求
  // progress.start();
  config.headers["Authorization"] = Tools.getStorage(TOKEN)
    ? `Bearer ${Tools.getStorage(TOKEN)} `
    : "";
  return config;
}, err=>{
  // 在请求错误的时候的逻辑处理
  return Promise.reject(err)
});
instance.interceptors.response.use(
  (response: any) => {
    // console.log(response);
    if(response.data.code!==200){
      message.error(response.data.message)
    }
    // 请求接收
    progress.done();
    // console.log(response);
    return response;
  },
  (error) => {
    const err = JSON.parse(JSON.stringify(error))
    if (err?.status === 401) {
            const errorMsg = "登录超时，请重新登录";
            infoModal(errorMsg);
            flag = false;
          } else if (err?.code === 500) {
            message.error(err?.message);
          }
    return Promise.reject(error);
    // Promise.reject(JSON.parse(JSON.stringify(error)))
    //   .then((res: any) => {})
    //   .catch((error) => {
    //     console.log(error);

    //     // message.error(error?.response?.data);//标记
    //     if (error?.response?.status === 401) {
    //       infoModal("登陆超时,请重新登录！");
    //     }
    //     return error;
    //   });
  }
);

export interface ResponseType<T> {
  code: number;
  message: string;
  data: T;
  paginate?: {
    page: number;
    page_size: number;
    total: number;
    total_pages: number;
  };
}

export const postClient = async <T>(
  url: string,
  params = {}
): Promise<ResponseType<T>> => {
  try {
    const { data, config } = await instance.post(url, params);
    data.xhrDetail = config
    // console.log(data,config);
    
    return data;
  } catch (error) {
    throw error;
  }
};
export const putClient = async <T>(
  url: string,
  params = {}
): Promise<ResponseType<T>> => {
  try {
    const { data } = await instance.put(url, params);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteClient = async (url: string, params = {}) => {
  try {
    const { data } = await instance.delete(url, { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const getClient = async <T>(
  url: string,
  params = {}
): Promise<ResponseType<T>> => {
  try {
    const { data } = await instance.get(url, { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export default instance;
