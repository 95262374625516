import React,{useEffect,useState} from "react";
import { useParams, useHistory ,useLocation} from "react-router-dom";
import { Breadcrumb , Table} from "antd";
import { getconnectorlog , getonlineConnector} from "../../../api/connector";
const OnlineConnector: React.FC = () => {
  const { name } = useParams<any>();
  const {state} = useLocation<any>()
  const history = useHistory();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [total, setTotal] = useState(0);
  const [listdata, setlistdata] = useState([]);
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "插件编码",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "插件名称",
      dataIndex: "protocol",
      key: "protocol",
    },
    {
      title: "IP端口",
      dataIndex: "endpoint",
      key: "endpoint",
    },
    {
      title: "检测时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    // {
    //   title: "操作",
    //   dataIndex: "id",
    //   render: (text: any,record:any) => (
    //     <>
    //       <a onClick={()=>{
    //         history.push(`/connector/${text}`)
    //       }}>修改</a>
    //       <Divider type="vertical" />
    //       <Popconfirm
    //         title="您确定要删除该条记录?"
    //         onConfirm={() => Remove(text)}
    //         okText="是"
    //         cancelText="否"
    //       >
    //         <a>删除</a>
    //       </Popconfirm>
    //     </>
    //   ),
    // },
  ];
  useEffect(()=>{
    setlistload(true)
    getconnectorlog({
      ...pageParam,
      protocol:state.protocol,
      code:state.code,
      version:""
    }).then((res:any)=>{
      setlistload(false)
      setlistdata(res.data)
      setTotal(res.pagination.total)
    })
  },[name, pageParam])
  return (
    <div style={{ background: "#f7f8fa", height: "100%" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a onClick={()=>{
            history.push("/connector")
          }}>Connector插件</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Connector检测记录</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{background:"#fff"}}>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={listdata}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
      </div>
    </div>
  );
};

export default OnlineConnector;
