import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import * as Icon from "@ant-design/icons";
import Menu from "./menu/menu"
import LeftScene from "./components/left";
import BottomScene from "./components/bottom";
import RightScene from "./components/right";
import CreateSystem from "./createSystem/createSystem";
import Shared from "./shared/Shared"
import img from "../../assets/img/4.png";
import img_1 from "../../assets/img/full4.png";
import img_2 from "../../assets/img/stp4.png";
import img_3 from "../../assets/img/six.png";
import img_4 from "../../assets/img/fullsix.png";
import Modal from "./modal";
import { Input, Button, message } from "antd";
import { dataSource, pagedata } from "./interface"
import {
  useParams,
  useHistory
} from "react-router-dom";
import {
  getScenariodetail,
  postScenarioModify, postScenarioCreate
} from "../../api/scenario"
const { TextArea } = Input;

const Scene: React.FC = () => {
  // #region
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const Ico = "PlusOutlined";
  const childRef = useRef<any>();
  // const menuRef = useRef<any>();
  const [idvalue, setidvalue] = useState<any>({
    id: "",
    val: false,
  })
  const [datasource, setDatasource] = useState<dataSource[]>([]);
  const [datatable, setdatatable] = useState<any[]>([]);//回显需要用的数据
  const [InputValue, setInputValue] = useState("");
  const [pageData, setPageData] = useState<pagedata[]>();
  const [val, setVal] = useState<string | number>(0);
  const [createSceneFlag, setCreateSceneFlag] = useState(false);
  const [createSystemFlag, setCreateSystemFlag] = useState(false);
  // #endregion
  const color: string[] = [
    "#EB7E65",
    "#FFD84C",
    "#7EAB55",
    "#73DEB3",
    "#73A0FA",
    "#9475CD",
    "#7585A2",
    "#F1B134",
    "#eb58b2",
  ];

  //场景详情
  const getData = () => {
    if (id !== "create") {
      getScenariodetail({ id: id }).then(res => {
        // console.log(res);
        if (res.code === 200) {
          let list = JSON.parse(JSON.stringify(res.data.scenario.applications))
          list.map((item: any, i: number) => {
            if (item.color) {
              item.id = i
            }
          })
          // console.log(list);
          setdatatable(list)
          setInputValue(res.data.scenario.name);
          let arr: any = []
          res.data.scenario.applications.forEach((item: any, index: any) => {

            if (item.color) {
              arr.push({
                bgColor: item.color,
                id: index,
                name: item.name,
              });
              item.id = index;
            }
            item.dataSource.forEach((info: any, i: any) => {

              info.category = info.systemInfo.category;
              info.dp_address = info.systemInfo.domain;
              info.system_id = info.systemInfo.name;
              info.status = info.systemInfo.healthState;//是否连接中枢
              info.constructState = info.systemInfo.constructState;//系统平台状态
              info.platform = info.systemInfo.platform;
              info.systemId = info.system;
              info.key = info.created_at;
              // info.key = item.id;

              // delete info.healthState
              // delete info.id
              // delete info.name
              delete info.description
              delete info.system
              delete info.systemInfo

            })
          })
          // console.log(arr);
          setDatasource(arr);
          // console.log(res.data.scenario.applications);
          setPageData(res.data.scenario.applications)
        }
      })
    }
  }
  const changeSearch = (value: any) => {
    setidvalue(value)
  }
  //创建/修改场景 保存
  const createScenario = () => {
    if (!InputValue) {
      message.warn("请输入场景名称")
      return
    }

    let flag = false
    let arr: any = pageData ? [...pageData] : [];
    arr = JSON.stringify(arr);
    arr = JSON.parse(arr);
    arr.forEach((item: any) => {
      delete item.id;
      item.dataSource.forEach((item1: any) => {
        if (item1.systemId === undefined || item1.id === undefined) {
          flag = true
        }
        item1.dataSourceId = item1.id
        delete item1.key;
        delete item1.status;
        delete item1.category
        delete item1.dp_address
        delete item1.system_id
        delete item1.id
        delete item1.name
        delete item1.flagId
        delete item1.healthState
      })
    })
    // console.log(arr);
    // console.log(flag);
    if (flag || !arr.length) {
      message.warn("请先关联接口")
      return
    }

    if (id && id !== "create") {
      postScenarioModify({
        id,
        name: InputValue,
        applications: arr
      }).then(res => {
        // console.log(res);
        if (res.code === 200) {
          message.success("更新成功")
        }
      })
    } else {
      postScenarioCreate({
        name: InputValue,
        applications: arr
      }).then(res => {
        // console.log(res);
        if (res.code === 200) {
          message.success("创建成功")
          history.push(`/scenelist`)
        }
      })
    }
  }

  //重置
  const onReset = () => {
    setPageData([]);
    setdatatable([]);
    setDatasource([]);
  }

  //随机不重复颜色
  const colorCheck = () => {
    let arr: any = []
    let crr = datasource.map((item) => item.bgColor)
    color.forEach((item) => {
      if (!crr.includes(item)) {
        arr.push(item)
      }
    })
    let i = Math.round(Math.random() * (arr.length - 1))
    return { i, arr }
  }

  //新建色块
  const applyCreat = () => {
    if (!InputValue) {
      message.info("请先输入场景名称")
      return
    }

    let id = 0;
    let i = 0
    if (datasource?.length) {//id不重复
      let x = datasource.map((item) => item.id).sort((a: number, b: number) => b - a)
      id = x[0] + 1
    }
    i = colorCheck().i
    // console.log(i);
    let arr = [];
    arr.push({
      bgColor: colorCheck().arr[i],
      id,
      name: "",
    });
    let a = datasource ? [...datasource] : [];
    setDatasource([...a, ...arr]);
  };

  //添加的确定按钮的方法
  const onok = (name: any, v: any) => {
    let dataname = name === "pageData" ? pageData : datatable
    let a = dataname ? [...dataname] : [];
    // console.log(dataname);
    // console.log(v);
    let flag = true
    if (dataname) {
      a = dataname.map((item: any) => {
        if (v.id === item.id) { //修改，如果id一样就替换掉
          console.log(111);
          item = v
          flag = false
        }
        if (item.id === undefined) {
          item.dataSource.forEach((item1: any, i: number) => {
            v.dataSource.forEach((info: any) => {
              if (item1.systemId === info.systemId) {
                console.log(222);
                item = v
                flag = false
              } else if (item1.id === info.id) {
                console.log(333);
                // item.dataSource.splice(i, 1)
                item1 = info
                flag = false
              }
            })
          })
        }
        return item
      })
    }
    //去重
    let arr: any = new Set(a)
    arr = [...arr]
    // console.log(a);
    // console.log(arr);
    if (name === "pageData") {
      flag ? setPageData([...arr, v]) : setPageData([...arr])
    } else {
      flag ? setdatatable([...arr, v]) : setdatatable([...arr])
    }
  }

  //添加的确定按钮事件
  const onOk = (v: pagedata, val: any) => {
    // console.log(v);
    // console.log(val);
    onok("pageData", v)
    onok("datatable", val)
    childRef.current.form.resetFields()
  };
  //删除
  const onClose = (info: any) => {
    let newData = datasource.filter((item) => item.id !== info.id)
    setDatasource([...newData])
    if (pageData) {
      let newpageData = pageData.filter((item) => item.id !== info.id)
      let newpageData1 = datatable.filter((item) => item.id !== info.id)
      setPageData([...newpageData])
      setdatatable([...newpageData1])
    }
  }

  const sceneName = (e: any) => {
    setInputValue(e.target.value);
  };
  //色块名字
  const onEditor = (id: number, val: number | string) => {
    datasource.map((item) => {
      if (id === item.id) {
        item.name = val;
      }
      return item;
    });
    setVal(val)
    setDatasource(datasource);
  };

  //创建系统平台
  const onCreateSystemOk = (val: pagedata) => {
    let a = pageData ? [...pageData] : [];
    setPageData([...a, val])
    setCreateSystemFlag(false)
    // console.log(val);
    // console.log(a);
  }

  //直接新建接口
  const onCreatePort = (val: pagedata) => {
    // console.log(val);
    // console.log(pageData);
    let arr: any = [];
    let flag = true
    let flag1 = false
    if (pageData) {
      arr = pageData.map((item: pagedata) => {
        if (item.color === "system") { //第一种情况，新建完系统再添加接口
          item.dataSource.forEach((item1: any) => {
            val.dataSource.forEach((info: any) => {
              if (item1.systemId === info.systemId) {
                item = val;
                // console.log(111);
              }
            })
          })
        } else if (item.color === "") {//第二种情况，有无颜色的接口再添加接口
          // console.log(222);
          flag = false
          item.dataSource.push(val.dataSource[0])
        }
        return item;
      })
    }
    if (pageData && flag) {//如果存在第二种情况就不执行
      pageData.forEach((item: pagedata) => {
        if (item.color !== "") {//第三种情况，存在有颜色的接口再添加接口
          item.dataSource.forEach((item1: any) => {
            val.dataSource.forEach((info: any) => {
              if (item1.systemId === info.systemId) {
                // console.log(333);
                flag1 = true
              }
            })
          })
        }
      })
    }
    // setPageData(arr)
    flag1 ? setPageData([...arr, val]) : setPageData([...arr])
    // console.log(arr);
  }

  //数据回显
  const dataEcho = (item: any) => {
    // console.log(item);
    // console.log(datatable);
    childRef.current.setIsModalVisible(true)
    let arr = datatable?.filter((info) => info.id === item.id)[0]?.dataSource
    let brr = pageData?.filter((info) => info.id === item.id)[0]?.dataSource?.map((info: any) => info.key)
    arr = arr ? arr : []
    brr = brr ? brr : []
    // console.log(arr);
    // console.log(brr);
    //数据回显
    childRef.current.setTableData(arr)
    childRef.current.setSelectedRowKeys(brr)
    childRef.current.setOption(item)
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: 820,
        position: "relative",
      }}
      className='centers-router'
    >
      <div style={{ textAlign: "right" }}>
        <Shared />
        <Button
          type="primary"
          style={{ marginRight: "8px", background: "#165DFF" }}
          onClick={createScenario}
        >
          保存
        </Button>
        <Button onClick={onReset}>重置</Button>
      </div>
      {(id !== "create" || createSceneFlag) && <div className={styles["sceneBox"]}>
        <div className={styles["sceneBoxtitle"]}>
          <input
            placeholder="请输入场景名称"
            value={InputValue}
            onChange={(e) => sceneName(e)}
          />
        </div>
        <div
          className={styles["avatar-warp"]}
        // style={{
        //   marginRight: datasource?.length ? "" : "22px",
        //   justifyContent: datasource?.length ? "" : "space-evenly",
        // }}
        >
          {datasource.length ? datasource.map((item, i) => {
            return <div
              className={styles["avatar-item"]}
              style={{ backgroundColor: item.bgColor }}
              key={i + 1}
              onClick={() => {
                if (item.name) {
                  dataEcho(item)
                } else {
                  message.info("请先输入名字")
                }
              }}
            >
              <Icon.CloseOutlined className={styles["close"]} onClick={(e) => { e.stopPropagation(); onClose(item) }} />
              <TextArea
                style={{ lineHeight: "18px", color: "#fff" }}
                bordered={false}
                autoSize
                placeholder="请输入名字"
                value={item.name}
                onChange={(e) => {
                  onEditor(item.id, e.target.value)
                }}
                onClick={(e) => { e.stopPropagation() }}
              />
            </div>
          }) : ""}
          {datasource?.length < 9 && <div className={styles["avatar-add"]} onClick={applyCreat}>
            {React.createElement(Icon[Ico])}
            <div className={styles["creat"]}>创建场景应用</div>
          </div>
          }
        </div>
      </div>}
      <div className={styles["explain"]}>
        <Modal ref={childRef} onOk={onOk}
          val={val}
          idvalue={idvalue}
          onCreatePort={onCreatePort}
          setidvalue={setidvalue}
        />
        <div style={{ marginLeft: "12px", marginTop: "6px" }}>图标说明：</div>
        <div className={styles["explainIcon"]}>
          <div style={{ background: "#3f5b98", color: "white" }}>
            系统平台(已建)
          </div>
          <div className={styles["besideIcon"]}>
            <img src={img_1} alt="" />
            <span>便民措施 [系统联通]</span>
          </div>
          <div
            className={styles["bkg"]}
            style={{
              color: "#3f5b98",
              border: " 1px solid #3f5b98",
              boxSizing: "border-box",
            }}
          >
            系统平台(在建)
          </div>
          <div className={styles["besideIcon"]}>
            <img src={img} alt="" />
            <span>便民措施 [暂未联通]</span>
          </div>
          <div
            style={{
              background: "#fff",
              color: "#3f5b98",
              border: " 1px solid #3f5b98",
              boxSizing: "border-box",
            }}
          >
            系统平台(未建)
          </div>
          <div className={styles["besideIcon"]}>
            <img src={img_2} alt="" />
            <span>便民措施 [手动联通]</span>
          </div>
          <div className={styles["besideIcon"]}>
            <img src={img_4} alt="" />
            <span>中枢系统 [已联通]</span>
          </div>
          <div className={styles["besideIcon"]}>
            <img src={img_3} alt="" />
            <span>中枢系统 [未联通]</span>
          </div>
        </div>
      </div>
      <div style={{
        position: "absolute",
        left: "0px",
        height: "100%",
        width: "7%",
        top: "0",
      }}>
        <Menu props={{
          setCreateSceneFlag,
          setCreateSystemFlag,
          createSceneFlag,
          id,
          InputValue
        }} />
      </div>
      <div
        style={{
          position: "absolute",
          left: "14%",
          height: "70%",
          width: "33%",
          top: "10%",
        }}
      >
        <LeftScene data={pageData} changeSearch={changeSearch} />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          height: "52%",
          width: "33%",
          left: "39%",
          right: "0",
          // margin: "0 auto",
        }}
      >
        <BottomScene data={pageData} changeSearch={changeSearch} />
      </div>
      <div
        style={{
          position: "absolute",
          right: "0",
          height: "70%",
          width: "28%",
          top: "10%",
        }}
      >
        <RightScene data={pageData} changeSearch={changeSearch} />
      </div>
      <CreateSystem props={{
        createSystemFlag,
        setCreateSystemFlag,
        onCreateSystemOk,
      }} />
    </div>
  );
};
export default Scene;
