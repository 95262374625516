import React from "react";
import { Badge, Switch } from "antd";
const SwitchTag: React.FC<any> = (props: any) => {
  const { text, type, checked ,method,disable} = props;
  
  return (
    <div style={{ display: "flex", marginLeft: "12px" }}>
      {(type === "top"||type === "title") && <Badge color={"#165DFF"} />}
      <div>{text}</div>
      {type !== "title" && (
        <Switch style={{ marginLeft: "20px" }} checked={checked} onChange={(e)=>{
          method(e)
          
        }} disabled={disable}/>
      )}
    </div>
  );
};

export default SwitchTag;
