//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Radio, Tabs } from "antd";
import PostHttp from "./jsonComponent/postHttp";
import PostQuery from "./jsonComponent/postQuery";
import PostHeaders from "./jsonComponent/postHeaders";
import PostPath from "./jsonComponent/postPath";
import GetParams from "./jsonComponent/getParams";
import GetHeaders from "./jsonComponent/getHeader";
import GetPath from "./jsonComponent/getPath";
import "./index.scss";
const ItnterfaceMethod: React.FC = (props: any) => {
  // 请求方式对应的参数形式
  // const MethodTypes = {
  const { TabPane } = Tabs;
  const [postValue, setpostValue] = useState("Body");
  const [getValue, setgetValue] = useState("Params");
  const post = [
    {
      label: "Body",
      value: "Body",
    },
    {
      label: "Query",
      value: "Query",
    },
    {
      label: "Headers",
      value: "Headers",
    },
    {
      label: "Path",
      value: "Path",
    },
  ];
  const get = [
    {
      label: "Params",
      value: "Params",
    },
    {
      label: "Headers",
      value: "Headers",
    },
    {
      label: "Path",
      value: "Path",
    },
  ];
  const callback = () => {};
  // };
  const postMethodChange = (e: any) => {
    console.log(e.target.value);

    setpostValue(e.target.value);
  };
  const getMethodChange = (e: any) => {
    setgetValue(e.target.value);
  };
  useEffect(() => {
    console.log(props.methodValue);
  }, [props.methodValue]);
  return (
    <div style={{ margin: "0 auto" }} className="jsonScma">
      {props.methodValue === "post" ? (
        <Tabs
          defaultActiveKey="Body"
          onChange={callback}
          style={{ margin: " 0 auto" }}
        >
          <TabPane tab="Body" key="Body">
            <PostHttp />
          </TabPane>
          <TabPane tab="Query" key="Query">
            <PostQuery />
          </TabPane>
          <TabPane tab="Headers" key="Headers">
            <PostHeaders />
          </TabPane>
          <TabPane tab="Path" key="Path">
            <PostPath />
          </TabPane>
        </Tabs>
      ) : (
        // <Radio.Group
        //   // value={post}
        //   onChange={postMethodChange}
        //   value={postValue}
        //   buttonStyle="solid"
        //   size="middle"
        // >
        //   {post.map((radioItem: any) => {
        //     return (
        //       <Radio.Button key={radioItem.value} value={radioItem.value}>
        //         {radioItem.value}
        //       </Radio.Button>
        //     );
        //   })}
        // </Radio.Group>
        // <Radio.Group
        //   // value={get}
        //   onChange={getMethodChange}
        //   value={getValue}
        //   buttonStyle="solid"
        //   size="middle"
        // >
        //   {get.map((radioItem: any) => {
        //     return (
        //       <Radio.Button key={radioItem.value} value={radioItem.value}>
        //         {radioItem.value}
        //       </Radio.Button>
        //     );
        //   })}
        // </Radio.Group>
        <Tabs
          defaultActiveKey="Body"
          onChange={callback}
          style={{ margin: " 0 auto" }}
        >
          <TabPane tab="Params" key="Params">
            <GetParams />
          </TabPane>
          <TabPane tab="Headers" key="Headers">
            <GetHeaders />
          </TabPane>
          <TabPane tab="Path" key="Path">
            <GetPath />
          </TabPane>
        </Tabs>
      )}
      {/* {
          postValue=="Body"&& props.methodValue === "post" &&<PostHttp/>
      }
      {
          postValue=="Query"&& props.methodValue === "post" &&<PostQuery/>
      } */}
    </div>
  );
};
export default ItnterfaceMethod;
