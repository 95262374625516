// @ts-nocheck
import { useEffect, useState, useCallback } from "react";
import { Popconfirm, Table } from "antd";
// import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  getUserlist,
  postUserdisable,
  postUserenable,
  getRolelist,
  postroleoperate,
  postUsercreate,
  getuserinfo,
  deleteUser,
  putUser,
} from "../../api/userlist";
import { IRolelist, getSubuserdelete } from "../../api/userlist";
import {
  // getpglistbyroleid,
  getpermissionGroup,
} from "../../api/permissionGroup";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Avatar,
  Space,
  Modal,
  Switch,
} from "antd";
// import { TOKEN } from "../../constants";
// import Tools from "../../util/tools";
import styles from "./index.module.scss";
const Userlist = () => {
  const [userslist, setuserslist] = useState<any>([]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [total, setTotal] = useState(0);
  const [rolelist, setrolelist] = useState<IRolelist[]>([]);
  const [userlistid, setuserlistid] = useState<string>("");
  // const [roleid, setroleid] = useState<string>("");
  // const [roleList, setroleList] = useState<any>([]);
  const [form] = Form.useForm();
  const [formArry] = Form.useForm();
  const [formLate] = Form.useForm();
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setvisible] = useState(false);
  const [permissionList, setpermissionList] = useState([]);
  // const PromptFalse =
  //   "该操作将禁用用户，被禁用后用户无法登录网站进行任何操作。是否禁用?";
  // const PromptTrue =
  //   "该操作将启用用户，被启用后用户可登录网站进行已授权的操作。是否启用?";
  // 用户列表
  const userlistData = useCallback(
    (page, pageSize, usename?) => {
      setlistload(true);
      getUserlist({
        username: usename===""?undefined:usename,
        page: page,
        pageSize: pageSize,
      }).then((res: any) => {
        if (res.code === 200) {
          setuserslist(res.data);
          setTotal(res.pagination.total);
          setlistload(false);
        }
      });
    },
    [form]
  );
  const onChange = (e: any) => {
    putUser({
      id:e.accountId,
      state:e.state===1?2:1
    }).then((res)=>{
      if(res.code===200){
        userlistData(
          pageParam.page,
          pageParam.pageSize,
          form.getFieldValue("username"),
          form.getFieldValue("pg_id")
        );
      }
    })
    // if (e.status === 1) {
    //   postUserdisable({ id: e.id }).then((res: any) => {
    //     if (res.code === 200) {
    //       userlistData(1, 10);
    //       message.success("修改成功");
    //     }
    //   });
    // } else {
    //   postUserenable({ id: e.id }).then((res: any) => {
    //     if (res.code === 200) {
    //       userlistData(1, 10);
    //       message.success("修改成功 ");
    //     }
    //   });
    // }
  };
  useEffect(() => {
    userlistData(
      pageParam.page,
      pageParam.pageSize,
      form.getFieldValue("username"),
      form.getFieldValue("pg_id")
    );
    // getRolelist({}).then((res) => {
    //   if (res.code === 200) {
    //     setrolelist(res.data || []);
    //   }
    // });
    // getpermissionGroup().then((res) => {
    //   if (res.code === 200) {
    //     setpermissionList(res.data);
    //   }
    // });
  }, [userlistData, pageParam.page, pageParam.pageSize, form]);

  // const accesslist = ["/", "接入方", "运营"];
  const confirm = async (e: any) => {
    const { code } = await deleteUser(e.accountId);
    if (code === 200) {
      userlistData(1, 10);
      setPageParam({
        page: 1,
        pageSize: 10,
      });
      message.success("操作成功");
    }
  };
  const cancel = (e: any) => { };
  const columns = [
    {
      title: "用户名",
      dataIndex: "username",
      key: "username",
      render: (text: any, record: any) => {
        return (
          <div className="nameBar">
            {/* <div>
              <Avatar
                className="img"
                src="http://192.168.12.156:32002/api/oss/file/avatar.png"
              // style={{ marginLeft: "30px" }}
              />
            </div> */}
            <span className="text">{text}</span>
            {/* <div className="icon">子</div> */}
          </div>
        );
      },
      // align: "center" as "center",
    },
    {
      title: "用户名",
      dataIndex: "roleCode",
      key: "roleCode",
      render: (text: any, record: any) => {
        return (
          <div className="nameBar">
            {text==="admin"&&"管理员"}
            {text==="user"&&"普通用户"}
          </div>
        );
      },
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      // align: "center" as "center",
    },
    {
      title: "最后登录时间",
      dataIndex: "lastLoginTime",
      key: "lastLoginTime",
      align: "center" as "center",
      render: (text: any) => (text?moment(text).format("YYYY-MM-DD HH:mm:ss"):""),
    },
    // {
    //   title: "创建人",
    //   dataIndex: "create_user_info",
    //   render: (text: any) => <>{text.nick_name}</>,
    //   // align: "center" as "center",
    // },
    {
      title: "状态",
      dataIndex: "status",
      render: (s: any, record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {record.state === 1 ? (
            <div className={styles["approve-pass"]}> 启用 </div>
          ) : (
            <div className={styles["approve-reject"]}> 禁用 </div>
          )}
          <Switch
            checked={record.state === 1 ? true : false}
            onChange={() => onChange(record)}
          ></Switch>
        </div>
      ),
      // align: "center" as "center",
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (id: string, record: any) => (
        <Space>
          {/* <span
            onClick={() => {
              showModal(id);
            }}
            style={{ color: "#4091f7", cursor: "pointer", marginLeft: 10 }}
          >
            编辑
          </span> */}
          <Popconfirm
            title="您确定要删除本条记录吗?"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="是"
            cancelText="否"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const reset = () => {
    form.resetFields();
    userlistData(1, 10);
    setPageParam({
      page: 1,
      pageSize: 10,
    });
  };
  const search = () => {
    setPageParam({
      page: 1,
      pageSize: 10,
    });
    userlistData(
      1,
      10,
      form.getFieldValue("username"),
      form.getFieldValue("pg_id")
    );
  };

  // 编辑
  const showModal = (id: string) => {
    setIsModalVisible(true);
    setuserlistid(id);
    // getuserinfo({
    //   id: id
    // }).then((res: any) => {
    //   if (res.code === 200) {
    //     formArry.setFieldsValue({
    //       roleid: res.data.user_role.id,
    //       nick_name: res.data.username,
    //       email: res.data.email,
    //       permission_group_ids: dataArry(res.data.user_permission_groups)
    //     })
    //   }
    // })
  };
  // const dataArry = (data: any) => {
  //   let arr: any = []
  //   data.forEach((item: any) => {
  //     arr.push(item.id)
  //   })
  //   return arr
  // }
  const handleOk = () => {
    setIsModalVisible(false);
    postroleoperate({
      role_id: formArry.getFieldValue("roleid"),
      nick_name: formArry.getFieldValue("nick_name"),
      email: formArry.getFieldValue("email"),
      user_id: userlistid,
      permission_group_ids: formArry.getFieldValue("permission_group_ids")
    }).then((res) => {
      if (res.code === 200) {
        userlistData(1, 10);
        message.success("修改成功");
      } else {
        message.error("This is an error message");
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // 更改用户
  function handleChange(value: string) {
    // console.log(`selected ${value}`);
    // getpglistbyroleid({ role_id: value }).then((res: any) => {
    //   setroleList(res.data);
    // });
    // setroleid(value);
  }
  //创建用户
  const handleClick = () => {
    formLate.validateFields().then((res) => {
      postUsercreate({
        password: res.password,
        username: res.username,
        roleCode:res.roleCode,
        // email: formLate.getFieldValue("email"),
        // nickname: formLate.getFieldValue("nickname"),
        // roleId: formLate.getFieldValue("roleid"),
        // permission_group_ids: formLate.getFieldValue("permission_group_id"),
        is_sub: false
        // redirect_url: formLate.getFieldValue("redirect_url")
      })
        .then((res) => {
          if (res.code === 200) {
            setvisible(false);
            setPageParam({ page: 1, pageSize: 10 });
            message.success("创建成功!");
            userlistData(
              pageParam.page,
              pageParam.pageSize,
              form.getFieldValue("username")
            );
          }
        })
    });
  };
  //关闭弹窗
  const handleClose = () => {
    setvisible(false);
  };
  //角色选择
  // const roleSelect = (e: any) => {
  //   getpglistbyroleid({ role_id: e }).then(((res: any) => {
  //     setpermissionList(res.data)
  //   }))
  // }
  // const permissionSelect = () => {
  //   if (formLate.getFieldValue("roleid") === undefined) {
  //     message.warning("请先选择角色")
  //   }
  // }
  return (
    <div className="centers-router" style={{ height: "100%" }}>
      <div style={{ height: 50 }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="username" label="用户名">
            <Input />
          </Form.Item>
          {/* <Form.Item name="pg_id" label="权限">
            <Select
              defaultValue="请选择"
              style={{ width: 200 }}
            // onChange={handleChange}
            >
              {permissionList?.map((item: any) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item> */}
          {/* <Form.Item style={{ marginLeft: 10 }} label="用户" name="access">
            <Select style={{ width: 200 }}>
              <Select.Option value="1">接入方</Select.Option>
              <Select.Option value="2">运营</Select.Option>
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={search}
            >
              查询
            </Button>
            <Button onClick={reset}>重置</Button>
          </Form.Item>
        </Form>
        {/* <Link to={{ pathname: "/userlists/create" }}>
          {" "} */}
        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            setvisible(true);
            formLate.resetFields();
          }}
        >
          + 创建用户
        </Button>
        {/* </Link> */}
      </div>
      <Table
        columns={columns}
        loading={listload}
        dataSource={userslist}
        pagination={{
          total,
          pageSize: pageParam.pageSize,
          current: pageParam.page,
          showSizeChanger: true,
          showTotal: (total) => `共 ${total}条记录`,
          onChange: (page, size) => setPageParam({ page, pageSize: size }),
        }}
      />
      <Modal
        title="编辑"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
      >
        <Form form={formArry} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item
            name="nick_name"
            label="昵称"
            rules={[{ required: true, message: "请输入昵称" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="邮箱"
            rules={[{ required: true, message: "请输入邮箱" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="roleid"
            label="角色"
            rules={[{ required: true, message: "请选择角色" }]}
          >
            <Select
              defaultValue="请选择"
              // style={{ width: 300 }}
              onChange={handleChange}
            >
              {rolelist.map((item,index) => (
                <Option value={item.id} key={index}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permission_group_ids"
            label="权限组"
            rules={[{ required: true, message: "请选择权限" }]}
          >
            <Select
              defaultValue="请选择"
              // onChange={handleChange}
              mode="multiple" allowClear
            >
              {permissionList?.map((item: any,index) => (
                <Option value={item.id} key={index}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="创建用户"
        visible={visible}
        onOk={handleClick}
        onCancel={handleClose}
        okText="创建"
        cancelText="取消"
      >
        <Form
          form={formLate}
          name="control-hooks"
          style={{ marginTop: 20 }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item
            name="username"
            label="用户名"
            rules={[{ required: true, message: "请输入用户名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="密码"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: (_, value, callback) => {
                  const reg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,12}$/)
                  if (!reg.test(value)) {
                    callback(
                      "请输入密码，密码由大写字母、小写字母和数字组成，长度为6 ~ 12个字符。"
                    );
                  }
                  callback();
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name=""
            label="确认密码"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  console.log(rule,value);
                  
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("两次密码输入不一致");
                },
              }),{ required: true }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="roleCode"
            label="角色"
            rules={[{ required: true, message: "请选择用户角色" }]}
          >
            <Select>
                <Option value="user">普通用户</Option>
                <Option value="admin">管理员</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name="nickname" label="昵称">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="邮箱">
            <Input />
          </Form.Item>
          <Form.Item
            name="roleid"
            label="角色"
            rules={[{ required: true, message: "请选择角色" }]}
          >
            <Select>
              {rolelist.map((item) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permission_group_id"
            label="权限组"
            rules={[{ required: true, message: "请选择权限" }]}
          >
            <Select mode="multiple" allowClear>
              {permissionList?.map((item: any) => (
                <Option label={item.name} value={item.id} key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item> */}
          {/* <Form.Item
            name="redirect_url"
            label="路由"
            rules={[{ required: true, message: "请选择路由" }]}
          >
            <Select
              defaultValue="请选择"
            // onChange={handleChange}
            >
              <Option value={"adminconsole"}>adminconsole</Option>
              <Option value={"useconsole"}>useconsole</Option>
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};
export default Userlist;
