import React, { PureComponent } from 'react';
import { Button, Input, Select, Row, Col } from 'antd';
import { Icon } from "@ant-design/compatible";
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';

const { TextArea } = Input;
const { Option } = Select;

class QueryParamsForm extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    queryParamsData: []
  }

  componentDidMount() {
    const { data } = this.props;

    this.initState(data);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;

    this.initState(data);
  }

  /**
   * 设置数据初始值
   */
  initState(data) {
    let parseData = this.parseJSONString(data);

    this.setState({
      queryParamsData: parseData
    });
  }

  /**
   * 获取唯一id
   */
  getUid = () => {
    return Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
  }

  /**
   * 添加query参数
   */
  onAddQueryParams = () => {
    const { queryParamsData = [] } = this.state;
    const { onChange } = this.props;

    // 不能修改原数据，先浅拷贝一份数据
    const queryParamsCopyData = (queryParamsData || []).concat([]);
    let newQueryParamsItem = {
      id: this.getUid(),
      name: '',
      required: 1,
      example: '',
      mask: '',
      constVal: ''
    };

    queryParamsCopyData.push(newQueryParamsItem);

    onChange && onChange(JSON.stringify(queryParamsCopyData))

    this.setState({
      queryParamsData: queryParamsCopyData
    });
  }

  /**
   * 删除query参数
   */
  onDeleteQueryParams = (index) => {
    const { queryParamsData } = this.state;
    const { onChange } = this.props;

    // 不能修改原数据，先浅拷贝一份数据
    const queryParamsCopyData = queryParamsData.concat([]);
    queryParamsCopyData.splice(index, 1);

    onChange && onChange(JSON.stringify(queryParamsCopyData))
    this.setState({
      queryParamsData: queryParamsCopyData
    });
  }

  /**
   * 表单值的修改change
   * @param index [number] 修改的是条数的索引
   * @param name [string] 修改的字段名
   * @param value [any] 修改之后的值
   */
  onFieldStateChange = (index, name, value) => {
    const { queryParamsData } = this.state;
    const { onChange } = this.props;

    // 不能修改原数据，先浅拷贝一份数据
    const queryParamsCopyData = queryParamsData.concat([]);

    // 修改值
    queryParamsCopyData[index][name] = value;
    onChange && onChange(JSON.stringify(queryParamsCopyData))

    this.setState({
      queryParamsData: queryParamsCopyData
    });
  }

  /**
   * 排序后的回调函数
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { queryParamsData = [] } = this.state;
    const { onChange } = this.props;

    if (oldIndex === newIndex) {
      return;
    }

    // 不能修改原数据，先浅拷贝一份数据
    let queryParamsCopyData = (queryParamsData || []).concat([]);
    queryParamsCopyData = arrayMove(queryParamsCopyData, oldIndex, newIndex);

    onChange && onChange(JSON.stringify(queryParamsCopyData))

    this.setState({
      queryParamsData: queryParamsCopyData
    });
  }

  /**
   * 解析json串
   * @param JSONString [string] 要解析的json字符串
   */
  parseJSONString = (JSONString) => {
    let parseData = [];

    try {
      parseData = JSON.parse(JSONString);
    } catch (e) {
      console.log(e);
    }

    return parseData
  }

  render() {
    const { queryParamsData } = this.state;
    const { btnText } = this.props;

    return <div>
      <div>
        <Button
          style={{ marginBottom: '10px' }}
          type="primary"
          onClick={this.onAddQueryParams}
        >
          {btnText || "添加Query参数"}
        </Button>
      </div>
      {/*
              * 拖拽排序组件
              * SortableElementItem props属性中，不要使用index，他和内部index冲突，所以使用sIndex代替，但是还是要传index
             */}
      <SortableQueryContainer axis="y" onSortEnd={this.onSortEnd} useDragHandle>
        {
          (queryParamsData || []).map((queryParam, index) =>
            <SortableElementItem
              onFieldStateChange={this.onFieldStateChange}
              onDeleteQueryParams={this.onDeleteQueryParams}
              data={queryParam}
              index={index}
              sIndex={index}
              key={queryParam.id}
              collection="queryParams"
            />
          )
        }
      </SortableQueryContainer>
    </div>
  }

}

const SortableElementIcon = sortableHandle(() => {
  return <Col span={1} style={{ textAlign: 'right', cursor: 'move' }}>
    <Icon
      style={{ marginTop: "9px" }}
      type="bars"
    />
  </Col>
});

const SortableElementItem = sortableElement(({ data, sIndex, onFieldStateChange, onDeleteQueryParams }) => {
  const { id, name, required, example, constVal, mask, disableArr = [], defaultLine = false } = data;
  if (!onFieldStateChange) {
    onFieldStateChange = () => {
    }
  }

  if (!onDeleteQueryParams) {
    onDeleteQueryParams = () => {
    }
  }

  return <Row
    key={id}
    className="query-params-hidden"
    gutter={[{ md: 2, lg: 4 }, { md: 10, lg: 12 }]}
    align="middle"
    justify="center"
  >
    <SortableElementIcon />
    <Col span={5}>
      <Input
        disabled={disableArr.includes('name')}
        value={name}
        onChange={(e) => onFieldStateChange(sIndex, 'name', e.target.value)}
        placeholder="参数名称" />
    </Col>
    <Col span={3}>
      <Select
        disabled={disableArr.includes('required')}
        value={required}
        onChange={(value) => onFieldStateChange(sIndex, 'required', value)}
        style={{ width: '100%' }}
        placeholder="是否必需"
      >
        <Option value={1}>必需</Option>
        <Option value={0}>非必需</Option>
      </Select>
    </Col>
    <Col span={4}>
      <Input
        disabled={disableArr.includes('constVal')}
        value={constVal}
        onChange={(e) => onFieldStateChange(sIndex, 'constVal', e.target.value)}
        placeholder="请输入常量" />
    </Col>
    <Col span={4}>
      <TextArea
        disabled={disableArr.includes('example')}
        value={example}
        onChange={(e) => onFieldStateChange(sIndex, 'example', e.target.value)}
        placeholder="参数示例"
        autoSize={{ minRows: 1, maxRows: 4 }}
      />
    </Col>
    <Col span={5}>
      <TextArea

        disabled={disableArr.includes('mask')}
        value={mask}
        onChange={(e) => onFieldStateChange(sIndex, 'mask', e.target.value)}
        placeholder="备注"
        autoSize={{ minRows: 1, maxRows: 4 }}
      />
    </Col>
    <Col span={1} style={{ textAlign: 'left' }}>
      {!defaultLine && <Icon
        style={{ marginTop: "9px" }}
        onClick={() => onDeleteQueryParams(sIndex)}
        type="delete"
      />}
    </Col>
  </Row>
})

const SortableQueryContainer = sortableContainer(({ children }) => {

  return <div>
    {
      children
    }
  </div>;
})

export default QueryParamsForm;