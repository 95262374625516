import React, { useState, useEffect, useCallback } from "react";
import {
  Alert,
  Form,
  Select,
  Input,
  Button,
  Table,
  Modal,
  message,
  Popconfirm,
  Tabs,
} from "antd";
import {
  getinventorylist,
  getstrategylist,
  postinventoryremove,
  postinventorycreate,
  getcurrentstrategy,
  poststrategyswitch,
} from "../../api/bwapi";
import { dataTool } from "echarts";
import moment from "moment";
const Bllist: React.FC = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const [formCl] = Form.useForm();
  const { TextArea } = Input;
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [xgModalopen, setxgModalopen] = useState(false);
  const [userslist, setuserslist] = useState([]);
  const [selelist, setselelist] = useState([]);
  const [defaultValue, setdefaultValue] = useState<any>();
  const [nowType, setnowType] = useState<any>();
  const [clname, setclname] = useState<any>();
  // const [optionvalue, setoptionvalue] = useState<any>();
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "ip地址",
      dataIndex: "IP",
      key: "IP",
      // render: (text: any) => text.name,
    },
    {
      title: "备注说明",
      dataIndex: "remark",
      key: "remark",
      // align: "center" as "center",
    },
    {
      title: "创建时间",
      dataIndex: "createAt",
      render:(text:any)=><>{moment(text*1000).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (record: any) => (
        <Popconfirm
          title="您确定要删除该条记录?"
          onConfirm={() => Remove(record)}
          okText="是"
          cancelText="否"
        >
          <a>删除</a>
        </Popconfirm>
      ),
    },
  ];
  // useEffect(() => {
  //   if (isModalOpen === false) {
  //     formAdd.resetFields();
  //   }
  //   // formAdd.resetFields()
  // }, [isModalOpen]);
  useEffect(() => {
    getCurrent();
    getStategy();
  }, []);
  const getCurrent = () => {
    getcurrentstrategy().then((res: any) => {
      setdefaultValue(res.data.id);
      setnowType(res.data.type);
      setclname(res.data.name);
      formCl.setFieldsValue({ dqcl: res.data.id });
    });
  };
  const getStategy = () => {
    getstrategylist().then((res) => {
      setselelist(res.data);
    });
  };
  useEffect(() => {
    if (nowType) {
      getList();
    }
  }, [pageParam, nowType]);
  const Remove = (id: any) => {
    postinventoryremove({ inventoryId: id }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功!");
        getList();
      }
    });
  };
  const getList = useCallback(() => {
    setlistload(true);
    getinventorylist({
      ...pageParam,
      type: nowType,
      ip: form.getFieldValue("ip"),
    }).then((res: any) => {
      setlistload(false);
      setuserslist(res.data);
      setTotal(res.pagination.total);
    });
  }, [pageParam, nowType]);
  // const handleChange = (value: any, option: any) => {
  //   // setnowType(option.data);
  //   setoptionvalue(option.value);
  // };
  const clChange = () => {
    if (formCl.getFieldValue("dqcl") === 78) {
      setnowType(2);
    } else {
      setnowType(1);
    }
    poststrategyswitch({ strategyId: formCl.getFieldValue("dqcl") }).then(
      (res) => {
        if (res.code === 200) {
          message.success("修改成功！");
          getCurrent();
        }
      }
    );
    setxgModalopen(false);
  };
  const handleOk = () => {
    formAdd.validateFields().then((data) => {
      postinventorycreate({
        ip: data.ip,
        remark: data.remark,
        ipStrategyId: defaultValue,
        type: nowType,
      }).then((res: any) => {
        if (res.code === 200) {
          getList();
          getCurrent();
          message.success("新增成功!");
          formAdd.resetFields();
        }else{
          message.error(res.message)
        }
      }).catch((res)=>{
        message.error(res.message)
      });
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    formAdd.resetFields();
    setIsModalOpen(false);
  };
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <Alert
          message="启动黑名单策略，则默认全部开放，只有黑名单中的ip无法访问；启用白名单策略，则默认全部不可访问，只有白名单中的ip才可以访问。"
          type="info"
          showIcon
        />
      </div>
      <div style={{ marginTop: "22px" }}>
        <div>
          启用策略：{clname}策略
          <a
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setxgModalopen(true);
            }}
          >
            修改
          </a>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          fontSize: "20px",
          // display: "flex",
          // alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          activeKey={nowType + ""}
          onChange={(e) => {
            console.log(e);
            let val: any = selelist.filter((item: any) => item.type == e);
            setdefaultValue(val[0].id);
            setnowType(e);
          }}
        >
          {/* <Tabs.TabPane tab="黑名单" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="白名单" key="2"></Tabs.TabPane> */}
          {selelist.map((item: any) => {
            return (
              <Tabs.TabPane tab={item.name} key={item.type}></Tabs.TabPane>
            );
          })}
        </Tabs>
        {/* <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>{nowType===2?"白名单":"黑名单"}</div> */}
      </div>
      <div style={{ height: 50, marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="ip" label="IP地址">
            <Input placeholder="请输入IP地址" />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          + 新增名单
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={userslist}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
        <Modal
          title="修改策略"
          visible={xgModalopen}
          onOk={clChange}
          onCancel={() => {
            setxgModalopen(false);
          }}
          okText="保存"
          cancelText="取消"
        >
          <Form name="basic" autoComplete="off" form={formCl}>
            <Form.Item label="当前策略" name="dqcl">
              <Select>
                {selelist.map((item: any) => {
                  return (
                    <Option value={item.id} data={item.type} key={item.type}>
                      {item.name}策略
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="新增名单"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="保存"
          cancelText="取消"
        >
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={formAdd}>
            <Form.Item
              name="ip"
              label="ip地址"
              rules={[
                { required: true, message: "请输入ip地址!" },
                {
                  pattern:
                    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
                  message: "请输入正确格式的ip地址",
                },
              ]}
            >
              <Input placeholder="请输入ip地址" />
            </Form.Item>
            <Form.Item name="remark" label="备注说明">
              <TextArea rows={4} placeholder="请输入备注说明" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default Bllist;
