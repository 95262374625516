import React, { useEffect } from "react";
import * as echarts from "echarts";
import Titbar from "./Titbar";

const Gauge: React.FC<any> = (props) => {
  const {jumpData} = props
  useEffect(() => {
    var chartDom = document.getElementById("main");
    if (chartDom != null) {
      var myChart: any = echarts.init(chartDom);
      var option = {
        toolbox: {
          show: false,
          feature: {
            mark: {
              show: true,
            },
            restore: {
              show: true,
            },
            saveAsImage: {
              show: true,
            },
          },
        },
        series: [
          {
            name: "外部进度条",
            type: "gauge",
            radius: 120,
            splitNumber: 10,
            startAngle: 180, //总的360，设置180就是半圆
            endAngle: 0,
            min: 0,
            max: 1,
            center: ['50%', '75%'],
            axisLine: {
              lineStyle: {
                color: [
                  [
                    jumpData.health/jumpData.total,
                    new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: "rgba(145,207,255,0)",
                      },
                      {
                        offset: 0.5,
                        color: "rgba(145,207,255,0.2)",
                      },
                      {
                        offset: 1,
                        color: "rgba(145,207,255,1)",
                      },
                    ]),
                  ],
                  [1, "rgba(28,128,245,.0)"],
                ],
                width: 22,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: "title",
                value: jumpData.health/jumpData.total,
              },
            ],
            
            pointer: {
              show: false,
            },
            animationDuration: 1000,
          },
          {
            name: "内部阴影",
            type: "gauge",
            center: ['50%', '75%'],
            radius: 120,
            min: 0,
            max: 1,
            splitNumber: 10,
            startAngle: 180, //总的360，设置180就是半圆
            endAngle: 0,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    jumpData.health/jumpData.total,
                    new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: "rgba(22, 93, 255, 0.02)",
                      },
                      {
                        offset: 0.5,
                        color: "rgba(22, 93, 255, 0.02)",
                      },
                      {
                        offset: 1,
                        color: "rgba(22, 93, 255, 0.02)",
                      },
                    ]),
                  ],
                  [1, "rgba(22, 93, 255, 0.02)"],
                ],
                width: 60,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
                show: false,
              },
            title: {
              //标题
              show: true,
            },
            
            data: [
              {
                name: `${(jumpData.health/jumpData.total*100).toFixed(2)}%`,
                value: jumpData.health/jumpData.total,
              },
            ],
            pointer: {
                icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                width: 4,
                length: "51%",
                offsetCenter: [0, "-49%"],
                itemStyle: { // 指针样式。{ color , borderColor , borderWidth , borderType , borderDashOffset , borderCap , borderJoin , borderMiterLimit , shadowBlur , shadowColor , shadowOffsetX , shadowOffsetY , opacity }
                    color: '#165DFF'
                }
              },
              animationDuration: 1000,
          },
          {
            name: "最外层",
            type: "gauge",
            center: ['50%', '75%'],
            radius: 120,
            min: 0,
            max: 1,
            splitNumber: 10,
            startAngle: 180, //总的360，设置180就是半圆
            endAngle: 0,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    jumpData.health/jumpData.total,
                    new echarts.graphic.LinearGradient(0,0.7, 0, 0, [
                      {
                        offset: 0,
                        color: "rgba(22,93,255,0.1)",
                      },
                      {
                        offset: 0.5,
                        color: "rgba(22,93,255,0.4)",
                      },
                      {
                        offset: 1,
                        color: "rgba(22,93,255,0.7)",
                      },
                    ]),
                  ],
                  [1, "rgba(145,207,255,0)"],
                ],
                width: 3,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
                show: false,
              },
            title: {
              //标题
              show: false,
            },
            pointer: {
                show: false,
              },
            data: [
              {
                name: `${jumpData.health/jumpData.total*100}%`,
                value: jumpData.health/jumpData.total*100,
              },
            ],
              animationDuration: 1000,
          },
        
        ],
      };
      option && myChart.setOption(option);
    }
  }, [jumpData]);
  const TitleData = [
    {
      id: 1,
      name: "服务总数",
      data: jumpData.total,
      color: "#165DFF",
    },
    {
      id: 2,
      name: "服务异常总数",
      data: jumpData.unHealth,
      color: "#FF5353",
    },
    {
      id: 3,
      name: "服务正常总数",
      data: jumpData.health,
      color: "#4CEFBE",
    },
  ];
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>服务健康监控</div>
      </div>
      <div
        style={{
          height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div style={{ height: "100%", width: "300px" }}>
          <div id="main" style={{ height: "100%", width: "300px" }}></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "4px",
              }}
            ></div>
            <div>健康监测百分比</div>
          </div>
        </div>
        {TitleData.map((item, index) => {
          return <Titbar data={item} key={index} />;
        })}
      </div>
    </div>
  );
};
export default Gauge;
