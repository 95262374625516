// @ts-nocheck
import { useState, useEffect } from "react";
import styles from "../index.module.scss"
import { Avatar, Tooltip } from 'antd';
// import picture from '../../images/picture.png'
import { getScenariolist, Scenario } from "../../../api/scenario";
import { useHistory } from "react-router-dom";
import { some } from "lodash";
// import { PlusOutlined } from '@ant-design/icons'
const Myscence = () => {
  const history = useHistory();
  const [listFlag, stelistFlag] = useState(true)
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 100,
  });
  const [datalist, setDatalist] = useState<Scenario[]>([]);
  const [data, setData] = useState<any[]>([
    { id: 1, title: "张三", src: "https://joeschmoe.io/api/v1/random" },
    { id: 2, title: "李四", src: "https://joeschmoe.io/api/v1/random" },
    { id: 3, title: "王二", src: "https://joeschmoe.io/api/v1/random" },
    { id: 4, title: "大王", src: "https://joeschmoe.io/api/v1/random" },
    { id: 5, title: "小王", src: "https://joeschmoe.io/api/v1/random" },
  ])
  useEffect(() => {
    getScenariolist({
      page: pageParam.page,
      page_size: pageParam.pageSize,
    }).then((res) => {
      // console.log(res);
      setDatalist(res.data || []);
      setPageParam({ page: 1, pageSize: 100 })
    });
  }, [pageParam.page, pageParam.pageSize]);

  return (
    <div className={styles['myscence']} style={{ marginTop: "20px" }}>
      <div className={styles['header']}>
        <div className={styles['title-name']}>所有场景</div>
        <div className={styles['see-more']} onClick={() => stelistFlag(!listFlag)}>查看更多</div>
      </div>
      <div className={styles['scence-box']}>
        {datalist.map((item, i) => {
          if (i > 9 && listFlag) return "";
          return (
            <div className={styles['scences']} onClick={() => history.push(`/scene/${item.id}`)}>
              <div className={styles['scence-name']}>场景名称:{item.name}</div>
              <div className={styles['scence-describe']}>描述{item.description}</div>
              <div className={styles['picture-box']}>
                {/* <img className={styles['picture-img']} src='' alt="" /> */}
                <Avatar.Group
                  maxCount={5}
                  maxStyle={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                  }}
                >
                  {data.map(item =>
                    <Tooltip title={item.title} placement="top" key={item.id}>
                      <Avatar size="small" src={item.src} />
                    </Tooltip>)}
                </Avatar.Group>
                <div className={styles['picture-people']}>等35人</div>
              </div>
              {/* <div className={styles["operation"]}>...
                <div className={styles['mysystemPop']}>
                  <li onClick={() => {

                  }}>编辑</li>
                  <li onClick={() => { }}>删除</li>
                </div>
              </div> */}
            </div>
          )
        })}
        {/* <div className={styles['establish-box']} onClick={() => { history.push("/scene/create"); }}>
          <PlusOutlined style={{ color: "#86909C" }} />
          <div>点击创建场景</div>
        </div> */}
      </div>
    </div>
  )
}
export default Myscence