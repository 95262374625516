//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Input, Select, Row, Col, Button } from "antd";
import { Icon } from "@ant-design/compatible";
import { useSelector, useDispatch } from 'react-redux';
import { setgetparams } from "../../../../../action/httpAction"
const PostHttp: React.FC = () => {
  const { Option } = Select;
  const [max, setmax] = useState([])
  // const list = useSelector((state: any) => state);
  const objData = useSelector((state: any) => state.httpgetSema);
  const dispatch: any = useDispatch(); //全局方法
  const onAddQueryParams = () => {

    let DataType = {
      id: new Date().getTime(),
      name: "",
      selectValue: '',
      sample: "",
      numType: "",
      remark: ""
    }

    let arr = max.length ? max : []
    setmax([...arr, DataType])
  }
  const onDelete = (index) => {
    // console.log(max)
    let arr = max?.map((item) => {
      return item
    })
    arr.splice(index, 1)
    setmax(arr)
  }
  useEffect(() => {
    dispatch(setgetparams(max))
    // console.log(max);
  }, [max])
  useEffect(() => {
    let arr = objData.obj
    console.log(arr);
    if (arr) {
      let a = JSON.parse(arr).Params
      if (a) {
        let data = Object.keys(a).map((val, index) => ({
          id: JSON.parse(arr).Params[val].id,
          name: val,
          numType: JSON.parse(arr).Params[val].defaultValue,
          selectValue: JSON.parse(arr).Params[val].type
        }))
        setmax(data)
      }
    }
  }, [objData.obj])
  return <div style={{ height: "200px", marginTop: "16px" }}>
    <div>
    </div>
    <div>
      {
        max?.map((item, index) => {
          return (
            <Row
              key={item.id}
              className="query-params-hidden"
              gutter={[
                { md: 2, lg: 4 },
                { md: 10, lg: 12 },
              ]}
              align="middle"
              justify="center"
              style={{ marginBottom: "6px" }}
            >
              <Col span={4}>
                <Input value={item.name} style={{
                  background: "none",
                  color: "black",
                  height: "32px",
                  borderColor: "#313d56",
                  fontWeight: 0,
                }} onChange={(e) => {
                  let list = max.map((i) => {
                    if (item.id === i.id) {
                      i.name = e.target.value
                    }
                    return i
                  })
                  setmax(list)
                }} />
                {/* <Input
                    style={{
                      background: "none",
                      color: "black",
                      height: "32px",
                      borderColor: "#313d56",
                      fontWeight: 0,
                    }}
                    // disabled={disabled}
                    // value={name}
                    // onChange={(e) => onFieldStateChange(sIndex, "name", e.target.value)}
                    placeholder="参数名称"
                  /> */}
              </Col>
              <Col span={4}>
                <Select
                  // disabled
                  value={item.selectValue}
                  onChange={(e) => {
                    let list = max.map((i) => {
                      if (item.id === i.id) {
                        i.selectValue = e
                      }
                      return i
                    })
                    setmax(list)
                  }}
                  style={{ width: "100%", color: "black" }}
                  placeholder="是否必需"
                // disabled={disabled}
                >
                  <Option value={1}>必需</Option>
                  <Option value={0}>非必需</Option>
                </Select>
              </Col>
              <Col span={5}>
                <Select
                  value={item.numType}
                  onChange={(e) => {
                    let list = max.map((i) => {
                      if (item.id === i.id) {
                        i.numType = e
                      }
                      return i
                    })
                    setmax(list)
                  }}
                  style={{ width: "100%", color: "black" }}
                  placeholder="varchar"
                // disabled={disabled}
                >
                  <Option value={"Boolean"}>Boolean</Option>
                  <Option value={"String"}>String</Option>
                  <Option value={"Arry"}>Arry</Option>
                  <Option value={"Object"}>Object</Option>
                  <Option value={"JSON"}>JSON</Option>
                  <Option value={"integer"}>integer</Option>
                  {/* <Option value={0}>非必需</Option> */}
                </Select>
              </Col>
              {/* <Col span={6}>
              <TextArea
                disabled={disableArr.includes('example')}
                value={example}
                onChange={(e) => onFieldStateChange(sIndex, 'example', e.target.value)}
                placeholder="value"
                autoSize={{minRows: 1, maxRows: 4}}
              />
            </Col> */}
              {/* <Col span={4}>
                  <Input
                    style={{
                      background: "none",
                      color: "black",
                      height: "28px",
                      borderColor: "#313d56",
                      minHeight: "none",
                    }}
                    // disabled={disableArr.includes("mask")}
                    value={item.remark}
                    onChange={(e) =>{
                        let list =max.map((i)=>{
                            if(item.id===i.id){
                                i.remark =  e.target.value
                            }
                            return i
                        })
                        setmax(list)
                    }}
                    placeholder="备注"
                  />
                </Col> */}
              <Col span={1} style={{ textAlign: "left" }}>
                <Icon
                  style={{ marginTop: "9px" }}
                  onClick={() => onDelete(index)}
                  type="delete"
                />
              </Col>
            </Row>
            //   <Input value={item.value} onChange={(e)=>{
            //     let list =max.map((i)=>{
            //         if(item.id===i.id){
            //             i.value = e.target.value
            //         }
            //         return i
            //     })
            //     setmax(list)
            //   }}/>
          )
        })
      }
    </div>
    <div

      style={{
        width: "90%",
        border: "1px solid #eee",
        margin: " 10px auto",
        padding: "5px 10px ",
        textAlign: "center",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={onAddQueryParams}
    >
      添加Params参数
    </div>
  </div>;
};
export default PostHttp;
