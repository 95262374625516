/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment"
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Table,
  Divider,
  Tabs,
  Modal,
  Empty,
  message
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons"
import {
  getTopiclist,
  unsubTopic,
  getTopicDetail
} from "@/api/topic";
const MyTopicList: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [userslist, setuserslist] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [nowType, setnowType] = useState<any>();
  const [ cancelId, setCancelId ] = useState('')
  const cancelSubscription = () => {
    unsubTopic({topicCode:cancelId}).then((res: any)=>{
      if(res.code === 200) {
        message.success('取消订阅成功')
        getList();
      } else {
        message.warn(res.message)
      }
      setCancelId('')
    }).catch((err)=>{
      message.error('取消订阅失败', err)
    })
    setModalShow(false)
  };
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Topic Code",
      dataIndex: "topicCode",
      key: "topicCode",
      // render: (text: any) => text.name,
    },
    {
      title: "Topic名称",
      dataIndex: "topicName",
      key: "topicName",
      // align: "center" as "center",
    },
    {
      title: "开放订阅",
      dataIndex: "accessLevel",
      key: "accessLevel"
    },
    {
      title: "最近更新时间",
      dataIndex: "updateAt",
      key: "updateAt"
    },
    {
      title: "操作",
      dataIndex: "topicCode",
      // align: "center" as "center",
      render: (topicCode:any, record:any) => (
        <>
          <a onClick={()=>{
            history.push(`/topic-detail/${topicCode}`)
          }}>详情</a>
          <Divider type="vertical" />
          {
            nowType == 1?
            <a onClick={()=>{
              getTopicDetail({ id: topicCode }).then((res: any)=>{
                console.log('res', res)
                history.push({
                  pathname: `/topic/${topicCode}`,
                  state: {
                    code: record.id,
                    initJsonBody: JSON.parse(res.data.bodyStruct)
                  }
                });
              })
            }}>编辑</a>
            :
            <a onClick={()=>{
              setCancelId(topicCode)
              setModalShow(true)
            }}>取消订阅</a>
          }
        </>
      ),
    },
  ];
  const empty = {
    emptyText:<Empty image={'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'}
    imageStyle={{
      marginTop: 100,
      height: 180,
    }}
    description={
      nowType == 1 ?
      <span>
        暂无Topic，请前往注册Topic
      </span>:
      <span>
        暂无订阅，请前往订阅Topic
      </span>
    }
    >
    </Empty>
  }
  const resetParams = () => {
    setPageParam({
      page: 1,
      pageSize: 10
    })
    form.resetFields();
  }
  useEffect(() => {
    setnowType(1);
  }, []);
  useEffect(() => {
    if (nowType) {
      getList();
    }
  }, [pageParam, nowType]);
  const getList = useCallback(() => {
    setlistload(true);
    getTopiclist({
      ...pageParam,
      topicCode: form.getFieldValue("topicCode"),
      topicName: form.getFieldValue("topicName"),
      type: nowType
    }).then((res: any) => {
      setlistload(false);
      res.data = res.data ?  res.data.map((item:any) => {
        item.accessLevel = item.accessLevel === 1 ? 'public(公开授权订阅)' : 'private(不开放订阅权限)'
        item.updateAt = moment(item.updateAt).format('YYYY-MM-DD HH:mm:ss')
        return item
      }) : []
      setuserslist(res.data);
      setTotal(res.pagination?.total);
    });
  }, [pageParam, nowType]);
  // const handleChange = (value: any, option: any) => {
  //   setnowType(option.data);
  // setoptionvalue(option.value);
  // };
  return (
    <div style={{ padding: "20px" }}>
            <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>我的Topic</div>
        <Button
          style={{ marginLeft: "auto" }}
          type="primary"
          onClick={() => {
            history.push(`/topic/${"add"}`);
          }}
        >
          + 注册Topic
        </Button>
      </div>
      <div
        style={{
          marginTop: "20px",
          fontSize: "20px",
          // display: "flex",
          // alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          activeKey={nowType + ""}
          onChange={(e) => {
            
            setnowType(e);
            resetParams()
          }}
        >
          <Tabs.TabPane tab="已注册" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="订阅中" key="2"></Tabs.TabPane>
        </Tabs>
      </div>
      <div style={{ height: 50, marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="topicCode" label="Topic code：">
            <Input placeholder="请输入Topic code" />
          </Form.Item>
          <Form.Item name="topicName" label="Topic名称：">
            <Input placeholder="请输入Topic名称" />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={resetParams}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={userslist}
          locale={empty}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
        <Modal
          visible={modalShow}
          closable={false}
          footer={null}
        >
          <div>
            <div style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              color: "#1D2129"
            }}><InfoCircleOutlined style={{
              marginRight: "10px",
              backgroundColor: "#FF7D00",
              borderRadius: "50%",
              color: "#FFFFFF",
              fontSize: "22px"
            }}/>取消订阅后无法再接收消息!</div>
            <div style={{
              margin: "10px 0 0 31px",
              color: "#86909C",
              fontSize: "12px"
            }}>还要继续操作吗？</div>
            <div style={{
              textAlign: "center",
              marginTop: "20px"
            }}>
              <Button onClick={()=>{
                setModalShow(false)
              }}>取消</Button>
              <Button onClick={cancelSubscription} type="primary" style={{
                marginLeft: "40px"
              }}>确定</Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default MyTopicList;
