import React from "react";
import styles from "../index.module.scss"
import image from '../images/picture.png'
import { Table, Tag, Space, Form, Col, Button, TimePicker, Select } from 'antd';
import { WebAdminContext } from "../../../context/WebAdminContext";
import moment from "moment";
const Journal = () => {
    // 操作日志
    const { userInfo } = React.useContext(WebAdminContext);
    const { Option } = Select;
    const [form] = Form.useForm();
    const columns = [
        {
            title: '操作时间',
            dataIndex: 'name',
            key: 'name',
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: '类型',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: '描述',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '操作人',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '模块',
            key: 'tags',
            dataIndex: 'tags',
            render: (tags: any) => (
                <>
                    {tags.map((tag: any) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'IP地址',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <a>Invite {record.name}</a>
                    <a>Delete</a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer'],
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser'],
        },
        {
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
            tags: ['cool', 'teacher'],
        },
    ];
    return <div className={styles['essential']}>
        <div className={styles['essential-top']}>
            <img src={image} className={styles['image']} alt="" />
            <div className={styles['message']}>
                <div>用户名：<span className={styles["value"]}>{userInfo.LoginName}</span></div>
                <div>账号ID：<span className={styles["value"]}>{userInfo.AccountId}</span></div>
                <div>注册时间：<span className={styles["value"]}>{moment((userInfo.CreatedAt)).format("YYYY-MM-DD HH:mm:ss")}</span></div>
            </div>
        </div>
        <div className={styles['essential-bottom']}>
            <div className={styles['journal-title']}>操作日志</div>
            <Form form={form} layout="inline" style={{ marginTop: 26 }}>
                <Form.Item label="申请单号" name="bill_code">
                    <TimePicker.RangePicker style={{ width: 260 }} />
                </Form.Item>
                <Form.Item label="申请人" name="username">
                    <Select
                        style={{ width: 260 }}
                        placeholder="Select a option and change input text above"
                        allowClear
                    >
                        <Option value="male">male</Option>
                        <Option value="female">female</Option>
                        <Option value="other">other</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="dp_address" name="dp_address">
                    <Select
                        style={{ width: 260 }}
                        placeholder="Select a option and change input text above"
                        allowClear
                    >
                        <Option value="male">male</Option>
                        <Option value="female">female</Option>
                        <Option value="other">other</Option>
                    </Select>
                </Form.Item>
                <Col style={{ marginLeft: "12px" }}>
                    <Button type="primary" >
                        查询
                    </Button>
                </Col>
                <Col style={{ marginLeft: "12px" }}>
                    <Button >重置</Button>
                </Col>
            </Form>
            <Table columns={columns} dataSource={data} style={{ marginTop: 26 }} />

        </div>
    </div>
}
export default Journal