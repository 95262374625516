import { postClient, getClient } from "./requestClient";
import {
  GET_inventory_list,
  GET_strategy_list,
  POST_inventory_remove,
  POST_inventory_create,
  GET_current_strategy,
  POST_strategy_switch
} from "./index";
export const getinventorylist = async (params: any) =>
  await getClient<any>(GET_inventory_list, params);

  export const getstrategylist = async () =>
  await getClient<any>(GET_strategy_list);

  export const postinventoryremove = async (params: any) =>
  await postClient<any>(POST_inventory_remove, params);

  export const postinventorycreate = async (params: any) =>
  await postClient<any>(POST_inventory_create, params);

  export const getcurrentstrategy = async () =>
  await getClient<any>(GET_current_strategy);

  export const poststrategyswitch = async (params: any) =>
  await postClient<any>(POST_strategy_switch, params);