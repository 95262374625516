//@ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Dropdown, Menu } from "antd";
const SceneBottpm: React.FC<any> = (props) => {
  const { changeSearch } = props
  const [propsList, setPropslist] = useState<any>();
  const [category, setCategory] = useState<string>();
  const [cfList, setcfList] = useState<any>([]);
  const [cfsystem, setcfsystem] = useState<any>([]);
  const [cfplantForm, setcfplantForm] = useState<any>([]);
  // const [allList, setAlllist] = useState<[]>([]);
  useEffect(() => {
    if (props.data != undefined && props.data.length > 0) {
      let arr = props.data;
      var arrlist: any = [];
      arr.map((item: any, index: number) => {
        item.dataSource.map((i: any) => {
          arrlist.push(i);
        });
        // setAlllist(arrlist);
        let newArr = arrlist.filter((item: any) => {
          return item.category === "001";
        });
        let newArr_0 = arrlist.filter((item: any) => {
          return item.category === "001";
        });
        let newArr_1 = arrlist.filter((item: any) => {
          return item.category === "001";
        });
        let newArr_2 = arrlist.filter((item: any) => {
          return item.category === "001";
        });
        var a = Object.assign([], newArr_0);
        var b = Object.assign([], newArr_1);
        var c = Object.assign([], newArr_2);
        setCategory("国家");
        setPropslist(newArr);
        // let arrPro = newArr_0;
        filterObj(a);
        filterObjTwice(b);
        // console.log(filterObjaa(c));
        filterObjaa(c)
      });
    } else {
      setPropslist(undefined);
    }
  }, [props.data]);
  // 二级去重
  const filterObjTwice = (objcArray: any) => {
    for (var i = 0; i < objcArray.length; i++) {
      for (var j = i + 1; j < objcArray.length;) {
        if (objcArray[i].dp_address === objcArray[j].dp_address) {
          //通过id属性进行匹配；
          objcArray.splice(j, 1); //去除重复的对象；
        } else {
          j++;
        }
      }
    }
    setcfsystem(objcArray);
    return objcArray;
  };
  // 三级去重
  const filterObj = (objcArray: any) => {
    for (var i = 0; i < objcArray.length; i++) {
      for (var j = i + 1; j < objcArray.length;) {
        if (objcArray[i].system_id === objcArray[j].system_id) {
          //通过id属性进行匹配；
          objcArray.splice(j, 1); //去除重复的对象；
        } else {
          j++;
        }
      }
    }
    setcfList(objcArray);
    return objcArray;
  };
  // 三级去重
  const filterObjaa = (objcArray: any) => {
    for (var i = 0; i < objcArray.length; i++) {
      for (var j = i + 1; j < objcArray.length;) {
        if (objcArray[i].platform === objcArray[j].platform) {
          //通过platform属性进行匹配；
          objcArray.splice(j, 1); //去除重复的对象；
        } else {
          j++;
        }
      }
    }
    setcfplantForm(objcArray);
    // console.log(objcArray);

    return objcArray;
  };
  //hover显示
  const hoverList = (data, color) => {
    // console.log(data, color);
    return (
      <Menu style={{ padding: "6px" }}>
        <div>{data[0].system_id}</div>
        <div className={styles["menuTitle"]}>
          {data.map((item: any) => {
            return (
              <div className={styles["menuClass"]} key={item.id}>
                <i
                  className={
                    item.healthState === 1
                      ? "iconfont icon-a-24gl-square"
                      : "iconfont icon-square"
                  }
                  style={{ color: color }}
                ></i>
                <div style={{ color: "#4E5969", marginLeft: "12px" }}>
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </Menu>
    );
  };
  //渲染色块的方法
  const renderList = (val: any, index: any, item: any) => {
    if (val.color == "system") {

      return <></>
    }
    if (
      // val.dataSource[index].healthState === 2 &&
      props.data[index].dataSource.filter(
        (el: any) => el.system_id == item.system_id && el.category === "001"
      ).length > 0 &&
      val.dataSource
        .filter(
          (el: any) => el.system_id == item.system_id && el.category === "001"
        )
        .every((n) => n.healthState === 2) && val.color != ""
    ) {
      return (
        <>
          <span className={styles["bottomBoxnumber"]}>
            {
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "001"
              ).length
            }
          </span>
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "001"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-square"
              style={{
                color: val.color,
                width: "25px",
                height: "25px",
              }}
            ></i>
          </Dropdown>
          <div className={styles["Bottom_linestyle"]}></div>
        </>
      );
    } else if (
      // val.dataSource[index].healthState === 1 &&
      props.data[index].dataSource.filter(
        (el: any) => el.system_id == item.system_id && el.category === "001"
      ).length > 0 &&
      val.dataSource
        .filter(
          (el: any) => el.system_id == item.system_id && el.category === "001"
        )
        .every((n) => n.healthState === 1) && val.color != ""
    ) {
      return (
        <>
          <span className={styles["bottomBoxnumber_0"]}>
            {
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "001"
              ).length
            }
          </span>
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "001"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-a-24gl-square"
              style={{
                color: val.color,
                width: "25px",
                height: "25px",
              }}
            ></i>
          </Dropdown>
          <div className={styles["Bottom_linestyle"]}></div>
        </>
      );
    } else if (
      // (val.dataSource[index].healthState === 2 || val.dataSource[index].healthState === 1) &&
      props.data[index].dataSource.filter(
        (el: any) => el.system_id == item.system_id && el.category === "001"
      ).length > 0 &&
      val.dataSource
        .filter(
          (el: any) => el.system_id == item.system_id && el.category === "001"
        )
        .every((n) => n.healthState === 1 || 2) && val.color != ""
    ) {
      return (
        <>
          <span className={styles["bottomBoxnumber_0"]}>
            {
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "001"
              ).length
            }
          </span>
          {/* <div
            className={styles["orangerectangle"]}
            style={{
              border: "1px solid",
              borderColor: val.color,
            }}
          ></div> */}
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "001"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-loukongzhengfangxing"
              style={{ color: val.color, width: "25px", height: "25px" }}
            ></i>
          </Dropdown>
          <div className={styles["Bottom_linestyle"]}></div>
        </>
      );
    } else {
      return <>
        {/* <div
        className={styles["orangerectangle"]}
        style={{
          border: "1px solid",
          borderColor: val.color,
        }}
      ></div> */}
        <Dropdown
          overlay={hoverList(
            props.data[index].dataSource.filter(
              (el: any) =>
                el.system_id == item.system_id && el.category === "001"
            ),
            val.color
          )}
          placement="bottomLeft"
          arrow
        >
          <i
            className="iconfont icon-xuxianzhengfangxing"
          // style={{ color: val.color, width: "25px", height: "25px" }}
          ></i>
        </Dropdown>
        <div className={styles["Bottom_linestyle"]}></div>
      </>
    }
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {propsList ? (
        <div style={{ marginTop: "36px" }}>
          <div
            style={{
              height: "85%",
              width: "100%",
              display: "flex",
              padding: "6px",
              alignItems: "flex-end",
            }}
          >
            {cfList.map((item: any) => {
              if(item.system_id){
                return (
                  <div
                    style={{ height: "100%", textAlign: "center" }}
                    className={styles["imgCenter"]}
                    key={item.name}
                  >
                    <>
                      <i
                        className={
                          item.status === 2
                            ? "iconfont icon-liubianxing"
                            : "iconfont icon-hexagon"
                        }
                        style={{
                          color: "#882D8E",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                      <div className={styles["Bottom_linestyle"]}></div>
                    </>
                    {props.data.map((i: any, index: any) => {
                      if (
                        props.data[index].dataSource.filter(
                          (el: any) => el.system_id == item.system_id
                        ).length > 0
                      ) {
                        return (
                          <div>
                            {/* {i.name ? ( */}
                            <div className={styles["card"]} key={index}>
                              {renderList(i, index, item)}
                            </div>
                            {/* ) : ( */}
                            {/* "" */}
                            {/* )} */}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                    <div
                      className={
                        styles[
                        item.constructState == 1
                          ? "bottomTitle_twice_0"
                          : [
                            item.constructState == 3
                              ? "bottomTitle_twice"
                              : "",
                          ]
                        ]
                      }
                      onClick={() => {
                        changeSearch({
                          id: item.systemId,
                          val: true,
                        });
                      }}
                    >
                      {item.system_id}
                    </div>
                  </div>
                );
              }else{
                return <></>
              }
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {cfplantForm.map((item: any) => {
              // return props.data.map((i: any, index: any) => {
              // if (
              //   props.data[index].dataSource.filter(
              //     (el: any) =>
              //       item.platform != "" && el.platform == item.platform
              //   ).length > 0
              // ) {
              // return item.map((arr) => {
              // console.log(arr);
              if (item.platform) {
                return (
                  <div className={styles["pingtaiBottom"]} key={index}>
                    {item.platform}
                  </div>
                );
              } else {
                return <></>
              }
              // });
              // } else {
              //   return <></>;
              // }
              // });
            })}
          </div>

          {cfsystem.length > 0 ? (
            <div className={styles["bottomBian"]}>
              <div className={styles["bottomFirsttitle"]}>{category}</div>
              {cfsystem.map((item: any) => {
                return (
                  <div className={styles["bottomTitle"]} key={item.dp_address}>
                    {item.dp_address}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default SceneBottpm;
