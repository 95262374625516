import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Table, Divider, Popconfirm, message } from "antd";
import { gettestlist, posttestdelete } from "../../api/serviceManagement";
import moment from "moment";
const ServiceTest: React.FC = () => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [datasource, setdatasource] = useState([]);
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "用例名称",
      dataIndex: "testName",
      key: "testName",
      // render: (text: any) => text.name,
    },
    {
      title: "DataAddress",
      dataIndex: "dataAddress",
      key: "dataAddress",
      // align: "center" as "center",
    },
    {
      title: "API名称",
      dataIndex: "apiName",
    },
    {
      title: "后端服务类型",
      dataIndex: "protocolType",
    },
    {
      title: "最后发布时间",
      dataIndex: "updateTime",
      render:(text:any)=><>{moment(text*1000).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <>
          <a
            onClick={() => {
              history.push({
                pathname: `/servicetest/detail`,
                state: { id: text ,
                type:"detail"
                },
              });
            }}
          >
            测试
          </a>
          <Divider type="vertical" />
          <Popconfirm
            title="您确定要删除该条记录?"
            onConfirm={() => Remove(record)}
            okText="是"
            cancelText="否"
          >
            <a>删除</a>
          </Popconfirm>
        </>
      ),
    },
  ];
  const getList = useCallback(() => {
    setlistload(true);
    gettestlist({
      ...pageParam,
      name: form.getFieldValue('accessKey')===undefined?"":form.getFieldValue('accessKey'),
    }).then((res: any) => {
      if (res.code === 200) {
        setlistload(false);
        setdatasource(res.data);
        setTotal(res.pagination.total);
      }
    });
  }, [pageParam]);
  useEffect(() => {
    getList();
  }, [pageParam]);
  const Remove = (id: any) => {
    posttestdelete({
      id:id.id
    }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功!");
        setPageParam({
          page: 1,
          pageSize: 10,
        });
      }
    });
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>服务用例测试</div>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="accessKey" label="用例名称">
            <Input placeholder="请输入用例名称" />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
                onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            history.push({
              pathname: `/servicetest/detail`,
              state: { id: '' ,
                type:"create"
                },
            });
          }}
        >
          在线测试
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={datasource}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};
export default ServiceTest;
