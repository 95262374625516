import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Form,
  Select,
  Input,
  Button,
  Table,
  DatePicker,
  Segmented,
} from "antd";
import { getdailylsit } from "../../api/callrecord";
import moment from "moment";
const CallStatistics: React.FC = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const history = useHistory<any>();
  const [total, setTotal] = useState(0);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [datasource, setdatasource] = useState([]);
  const [starttime, setstarttime] = useState<any>(
    moment().subtract("months").startOf("month").valueOf()
  );
  const [endtime, setendtime] = useState<any>(moment().valueOf());
  const [SegmentedType, setSegmentedType] = useState(0);
  const [isAsc, setisAsc] = useState<any>();
  const [sort, setsort] = useState<any>();
  const columns = [
    {
      title: "调用日期",
      dataIndex: "callDate",
      key: "callDate",
      sorter: true,
      // render: (text: any) => text.name,
    },
    {
      title: "调用量",
      dataIndex: "count",
      key: "count",
      sorter: true,
      // align: "center" as "center",
    },
    {
      title: "错误量",
      dataIndex: "errCount",
      key: "errCount",
      sorter: true,
    },
    {
      title: "最小耗时(ms)",
      dataIndex: "minTime",
      key: "minTime",
      sorter: true,
    },
    {
      title: "平均耗时(ms)",
      dataIndex: "avgTime",
      key: "avgTime",
      sorter: true,
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any,record:any) => (
        <a
          onClick={() => {
            history.push({
              pathname: `/callstatisticsdetail`,
              state: { id: text, accesskey: record.accessKey ,time:record.callDate},
            });
          }}
        >
          查看明细
        </a>
      ),
    },
  ];
  const getList = useCallback(() => {
    setlistload(true);
    getdailylsit({
      ...pageParam,
      accessKey: form.getFieldValue("accessKey"),
      isAsc: isAsc,
      sort: sort,
      start: starttime,
      end: endtime,
    }).then((res: any) => {
      if (res.code === 200) {
        setTotal(res.pagination.total);
        setdatasource(res.data);
        setlistload(false);
      }
    });
  }, [pageParam, SegmentedType, starttime, endtime,isAsc,sort]);
  useEffect(() => {
    getList();
  }, [pageParam, starttime, endtime,isAsc,sort]);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (sorter?.order === "ascend") {
      setisAsc(true);
      setsort(sorter?.column.key);
    } else if (sorter.order === "descend") {
      setisAsc(false);
      setsort(sorter?.column.key);
    } else {
      setisAsc('');
      setsort('');
    }
    console.log(sorter.order, sorter?.column?.key);
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>调用统计</div>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="accessKey" label="AccessKey">
            <Input placeholder="请输入AccessKey" />
          </Form.Item>
          <Form.Item name="" label="">
            <Segmented
              onChange={(e: any) => {
                setSegmentedType(e);
                if (e == 0) {
                  setstarttime(
                    moment().subtract("months").startOf("month").valueOf()
                  );
                  setendtime(moment().valueOf());
                } else if (e == 1) {
                  setstarttime(
                    moment().subtract(1, "months").startOf("month").valueOf()
                  );
                  setendtime(
                    moment().subtract(1, "months").endOf("month").valueOf()
                  );
                }
              }}
              options={[
                {
                  label: <div>本月</div>,
                  value: 0,
                },
                {
                  label: <div>上月</div>,
                  value: 1,
                },
                {
                  label: <div>自定义</div>,
                  value: 2,
                },
              ]}
            />
          </Form.Item>

          {SegmentedType === 2 && (
            <Form.Item name="picker"  label="">
              <RangePicker allowClear={false} onChange={(date,dateString)=>{
                setstarttime(moment(dateString[0]).valueOf())
                setendtime(moment(dateString[1]).valueOf())
              }}/>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setSegmentedType(0)
                setstarttime(moment().subtract("months").startOf("month").valueOf())
                setendtime(moment().valueOf())
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
        {/* <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          + 新增名单
        </Button> */}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={datasource}
          onChange={onChange}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};

export default CallStatistics;
