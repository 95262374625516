import { Select } from "antd";

const { Option } = Select
export const protocolTypeMap = {
  rest: 'RESTful',
  soap: 'Soap',
}
export const signatureOption = ['SK', 'SM2', 'RSA'];

export const DEFAULT_SCHEMA = {
  "type": "object",
  "disabled": {
    "name": true,
    "type": true,
    "del": true,
    "set": true
  },
  "properties": {
    "code": {
      "type": "string",
      "disabled": { "name": true, "type": true, "add": true, "del": true, "set": true, "dimension": true }
    },
    "message": {
      "type": "string",
      "disabled": { "name": true, "type": true, "add": true, "del": true, "set": true, "dimension": true }
    },
    "success": {
      "type": "boolean",
      "disabled": { "name": true, "type": true, "add": true, "del": true, "set": true, "dimension": true }
    },
    "data": {
      "type": "object",
      "properties": {},
      "disabled": { "name": true, "type": false, "add": true, "del": true, "set": true, "dimension": true }
    }
  },
  "required": ["code", "message", "success", "data"]
};
export const DEFAULT_JSON_AREA = {
  "type": "object",
  "disabled": {
    "name": true,
    "type": true,
    "del": true,
    "set": true
  },
  //required: ['areaCode', 'areaCodes']
}

const getUid = () => Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
export const queryArea = () => []
