import { message } from "antd";

class Tools {
  /**
   * getImageFile
   * @param path 图片路径
   * @returns 图片路径
   */
  // static getImageFile(fileName: string): string {
  //     return require(`../assets/images/${fileName}`).default
  // }

  /**
   * 设置页面标题
   * @param title 标题
   */
  static setNavigationTitle(title: string) {
    document.title = title;
  }

  /**
   * async function
   * @param fn function
   */
  static asyncFunc<T = void>(fn: () => T, delay: number = 0) {
    setTimeout(() => {
      fn();
    }, delay);
  }

  /**
   * getStorage
   * @param key
   * @returns
   */
  static getStorage(key: string) {
    return window.localStorage.getItem(key);
  }

  /**
   * setStorage
   * @param key
   * @param val
   */
  static setStorage(key: string, val: unknown) {
    let v = val;
    if (typeof val === "object") {
      v = JSON.stringify(val);
    }
    window.localStorage.setItem(key, v as string);
  }

  /**
   * remove storage
   * @param key
   */
  static removeStorage(key: string) {
    window.localStorage.removeItem(key);
  }

  static errorMessage(error: any) {
    message.error(error.response.data.message || "发生未知错误");
  }

  /**
   * 数据字典转换
   */

  static setTypeString = (state:any, stateArr:any) => {
    //状态码 转后台返回的状态
    const string = stateArr.find(
      (item:any) => item.key?.toString() === state?.toString()
    );
    if (string) {
      return string.value;
    } else {
      return "";
    }
  };

  static getArrayItem = (state:any, stateArr:any) => {
    const string = stateArr.find(
      (item : any) => item.key?.toString() === state?.toString()
    );
    if (string) {
      return string;
    } else {
      return "";
    }
  };

  static humanSize = (bytes:any, precision:any) => {
    const mags = " KMGTPEZY";
    const magnitude = Math.min(
      (Math.log(bytes) / Math.log(1024)) | 0,
      mags.length - 1
    );
    const result = bytes / Math.pow(1024, magnitude);
    const suffix = mags[magnitude].trim() + "B";
    return result.toFixed(precision) + suffix;
  };
  static getUid = () =>
  Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
}

export default Tools;
