// @ts-nocheck
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setN, setM, asyncInitList } from "../../../action/demoAction"
import { Button } from "antd"

export default function Index() {
  const dispatch: any = useDispatch(); //全局方法
  const list = useSelector((state: any) => state.demoStore);//全局数据
  console.log(list);

  useEffect(() => {
    dispatch(asyncInitList({
      page: 1,
      page_size: 10,
    })).then(res => {
      console.log(res);
    })
  }, [])

  return (
    <div>
      <Button onClick={() => { dispatch(setN(+1)) }}>增加</Button>
      <input value={list.n} />
      <Button onClick={() => { dispatch(setN(-1)) }}>减少</Button>
      <br />
      <br />
      <Button onClick={() => { dispatch(setM(+1)) }}>增加</Button>
      <input value={list.m} />
      <Button onClick={() => { dispatch(setM(-1)) }}>减少</Button>
    </div>
  )
}
