import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Steps,
  Breadcrumb,
  Card,
  Badge,
  Row,
  Col,
  Space,
  Button,
  Modal,
  Form,
  Input,
  message,
  Divider,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import TypeComponent from "./component";
import styles from "./index.module.scss";
import {
  getapplyDetail,
  postgrantapproval,
  getcentralInterconnectionlog,
  getcentralInterconnectiongetUrl,
  getdownLoadenclosure
} from "../../api/interconnection";
import { ReactComponent as ErrorImg } from "../../assets/img/erro.svg";
import { ReactComponent as Info } from "../../assets/img/info.svg";
import { ReactComponent as Success } from "../../assets/img/Success.svg";
import moment from "moment";
const ApprovalDetail: React.FC = () => {
  const history = useHistory();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { state } = useLocation<any>();
  const { Step } = Steps;
  const [dataList, setdataList] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logData, setlogData] = useState([]);
  const [onlineUrl, setonlineUrl] = useState("");
  const [downloadUrl,setdownloadUrl] = useState('')
  useEffect(() => {
    console.log(state);
    
    // if (state.type === "审批") {
    getapplyDetail({
      id: state.id,
    }).then((res: any) => {
      if (res.code === 200) {
        
        let a = res.data.fileAddress.split("https://zhongshu-build3.http://oss-cn-hangzhou-yqgcy-d01-a.res.cloud.yqgcy.cn/")
        setdownloadUrl(a[1])
        getcentralInterconnectiongetUrl({
          fileName: a[1],
        }).then((data: any) => {
          if (data.code === 200) {
            setonlineUrl(data.data);
          }
        });
      }
      setdataList(res.data);
    });
    getcentralInterconnectionlog({
      applyId: state.id,
    }).then((res: any) => {
      setlogData(res.data);
    });
    // } else {
    // }
  }, [state]);
  const downloadMethod=()=>{
    getdownLoadenclosure({
      fileName:downloadUrl
    }).then(res=>{

    })
  }
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    postgrantapproval({
      id: state.id,
      applyResult: 2,
      refuseReason: form.getFieldValue("refuseReason"),
    }).then((res: any) => {
      if (res.code === 200) {
        history.go(-1);
        message.success(res.data);
      }
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const approval = () => {
    postgrantapproval({
      id: state.id,
      applyResult: 1,
      refuseReason: "",
    }).then((res: any) => {
      if (res.code === 200) {
        history.go(-1);
        message.success(res.data);
      }
    });
  };
  
// const getCaption= (obj:any)=> {
//   const index = obj.lastIndexOf("?fileName")
//   const res = obj.substring(index, obj.length)
//   return res
// }
  const newCheck = () =>{
    // console.log(state);
    // var w ="26px" 
    // var a = w.lastIndexOf("p") 
    // console.log(getCaption("aaabbbcccdddeeefff"))
    let str = state.name.replace('enclosure','getUrl')
    axios.get(`http://${str}`).then(res=>{
      console.log(str);
      window.open(res.data.data,"_blank");
    })
    // window.open("http://www.cnblogs.com/liumengdie/","_blank");
    // getcentralInterconnectiongetUrl({fileName:state.name}).then((res:any)=>{
    //   if(res.code===200){
    //     console.log(res);
    //     // history.push(res.data)
    //     // window.location.href=res.data
    //   }
    // })
  }
  return (
    <div style={{ background: "#f7f8fa", position: "relative" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.go(-1);
            }}
          >
            互联{state.type}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{state.type}单详情</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "#FFF", marginTop: "17px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "20px",
                background: "#165DFF",
                marginRight: "10px",
              }}
            ></div>
            <div>{state.type}单详情</div>
          </div>
          <TypeComponent type={state.state} />
        </div>
        <Card style={{ marginTop: "20px" }}>
          <Badge color="#165DFF" text="基本信息" />
          <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{ marginLeft: "10px" }}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                申请方实例名称：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.blockchainName}
                </span>
              </span>
            </Col>
            <Col span={6}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                申请方实例编码：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.blockchainCode}
                </span>
              </span>
            </Col>
            {/* <Col span={6}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                申请单号：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  1120220711000001
                </span>
              </span>
            </Col> */}
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{ marginLeft: "10px" }}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                申请时间：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {moment(dataList.updateTime*1000).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              </span>
            </Col>
            <Col span={6}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                申请方联系人：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.applyContacts}
                </span>
              </span>
            </Col>
            <Col span={6}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                联系方式：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.applyPhone}
                </span>
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{ marginLeft: "10px" }}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                附件：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.fileName}
                </span>
              </span>
              <span style={{ marginLeft: "12px" }}>
                {/* <a href={`/api/centralInterconnection/getUrl?fileName=${state.name}`} target="blank">查看</a> */}
                <a onClick={newCheck}>查看</a>
                <Divider type="vertical" />
                {/* {console.log(state.name)} */}
                <a href={`http://${state.name}`} download>下载</a>
              </span>
            </Col>
          </Row>
          <div className={styles["ApprovalDetail-gird-div2"]}></div>
        </Card>
        <Card style={{ marginTop: "20px" }}>
          <Badge color="#165DFF" text={`${state.type}内容`} />
          <Row style={{ marginTop: "20px" }}>
            <Col span={6} style={{ marginLeft: "10px" }}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                实例名称：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.replyBlockchainName}
                </span>
              </span>
            </Col>
            <Col span={6}>
              <span className={styles["ApprovalDetail-gird-child-title"]}>
                实例编码：
                <span className={styles["ApprovalDetail-gird-children-title"]}>
                  {dataList.replyBlockchainCode}
                </span>
              </span>
            </Col>
          </Row>
          <div className={styles["ApprovalDetail-gird-div2"]}></div>
        </Card>
        <Card style={{ marginTop: "20px" }}>
          <Badge color="#165DFF" text={`审批记录`} />
          <Row style={{ marginTop: "20px" }}>
            <Steps
              progressDot
              current={logData?.length - 1}
              direction="vertical"
            >
              {logData.map((item: any) => {
                if (item.state === 0) {
                  return (
                    <>
                      <Step
                        title="发起申请"
                        description={
                          <>
                            <div>
                              <span style={{ color: "#86909C" }}>申请方：</span>
                              <span style={{ color: "#4E5969" }}>
                                {item.applyContacts}
                              </span>
                            </div>
                            {item.refuseReason && (
                              <div style={{ marginTop: "14px" }}>
                                <span style={{ color: "#86909C" }}>
                                  申请理由：
                                </span>
                                <span style={{ color: "#4E5969" }}>
                                  {item.refuseReason}
                                </span>
                              </div>
                            )}
                            <div style={{ marginTop: "14px" }}>
                              <span style={{ color: "#86909C" }}>
                                申请时间：
                              </span>
                              <span style={{ color: "#4E5969" }}>
                                {moment(item.createTime*1000).format('YYYY-MM-DD HH:mm:ss')}
                              </span>
                            </div>
                          </>
                        }
                      />
                    </>
                  );
                } else if (item.state === 1) {
                  return (
                    <>
                      <Step
                        title="实例管理员审批"
                        description={
                          <>
                            <div>
                              <span style={{ color: "#86909C" }}>
                                审批时间：
                              </span>
                              <span style={{ color: "#4E5969" }}>
                                {/* {item.createTime} */}
                                {moment(item.createTime*1000).format('YYYY-MM-DD HH:mm:ss')}
                              </span>
                            </div>
                            {item.refuseReason && (
                              <div
                                style={{ display: "flex", marginTop: "14px" }}
                              >
                                <span style={{ color: "#86909C" }}>
                                  审批状态：
                                </span>
                                <span
                                  style={{
                                    color: "#4E5969",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Success />
                                  <span style={{ marginLeft: "6px" }}>
                                    审批通过
                                  </span>
                                </span>
                              </div>
                            )}
                            <div style={{ marginTop: "14px" }}>
                              <span style={{ color: "#86909C" }}>
                                审批备注：
                              </span>
                              <span style={{ color: "#4E5969" }}>
                                {item.refuseReason}
                              </span>
                            </div>
                          </>
                        }
                      />
                    </>
                  );
                } else {
                  return (
                    <>
                      <Step
                        title="实例管理员审批"
                        description={
                          <>
                            <div>
                              <span style={{ color: "#86909C" }}>
                                审批时间：
                              </span>
                              <span style={{ color: "#4E5969" }}>
                              {moment(item.createTime*1000).format('YYYY-MM-DD HH:mm:ss')}
                              </span>
                            </div>
                            {item.refuseReason && (
                              <div
                                style={{ display: "flex", marginTop: "14px" }}
                              >
                                <span style={{ color: "#86909C" }}>
                                  审批状态：
                                </span>
                                <span
                                  style={{
                                    color: "#4E5969",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ErrorImg />
                                  <span style={{ marginLeft: "6px" }}>
                                    审批不通过
                                  </span>
                                </span>
                              </div>
                            )}
                            <div style={{ marginTop: "14px" }}>
                              <span style={{ color: "#86909C" }}>
                                审批备注：
                              </span>
                              <span style={{ color: "#4E5969" }}>
                                {item.refuseReason}
                              </span>
                            </div>
                          </>
                        }
                      />
                    </>
                  );
                }
              })}
            </Steps>
          </Row>
        </Card>

        {(state.state === 0 || state.state === 2) && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {state.type === "申请" || state.state===1 ||  state.state===2? (
              <></>
            ) : (
              <Space>
                <Button onClick={showModal}>审批不通过</Button>
                <Button type="primary" onClick={approval}>
                  审批通过
                </Button>
              </Space>
            )}
            <Modal
              title="审批不通过原因"
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form form={form}>
                <Form.Item
                  name="refuseReason"
                  label="原因"
                  rules={[{ required: true, message: "请输入审批不通过原因" }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalDetail;
