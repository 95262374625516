import React, { useState } from 'react'
import { Avatar, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { svg1, svg2, svg3, svg4, svg5 } from './svg'
import "./index.scss"
interface dataSource {
  id: number,
  title: string,
  src: React.ReactElement | string
}
export default function Shared() {
  const [data, setData] = useState<dataSource[]>([
    { id: 1, title: "张三", src: svg1 },
    { id: 2, title: "李四", src: svg2 },
    { id: 3, title: "王二", src: svg3 },
    { id: 4, title: "大王", src: svg4 },
    { id: 5, title: "小王", src: svg5 },
  ])

  const addGroup = () => {
    let id = 0;
    if (data.length) {
      let x = data.map(item => item.id).sort((a, b) => b - a)
      id = x[0] + 1
    }
    console.log(id);
    setData([...data, { id, title: `张三${id}`, src: "https://joeschmoe.io/api/v1/random" }])
  }



  return (
    <div className='sharedbar'>
      <div
        style={{
          position: "absolute",
          right: "176px",
          top: "0px"
        }}
      >
        <Avatar.Group
          maxCount={5}
          maxStyle={{
            color: '#f56a00',
            backgroundColor: '#fde3cf',
          }}
        >
          {data.map(item =>
            <Tooltip title={item.title} placement="top" key={item.id}>
              <Avatar src={item.src} />
            </Tooltip>)}
          {/* <Avatar src="https://joeschmoe.io/api/v1/random" />
          <Avatar
            style={{
              backgroundColor: '#f56a00',
            }}
          >
            K
          </Avatar>
          <Tooltip title="Ant User" placement="top">
            <Avatar
              style={{
                backgroundColor: '#87d068',
              }}
              icon={<UserOutlined />}
            />
          </Tooltip>
          <Avatar
            style={{
              backgroundColor: '#1890ff',
            }}
            icon={<AntDesignOutlined />}
          /> */}
        </Avatar.Group>
        <div className='addIconbar' onClick={addGroup}>
          <PlusOutlined className='addIcon' />
        </div>
      </div>
    </div>
  )
}
