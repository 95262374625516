import React from "react";
import { Card, Badge, Input } from "antd";
import styles from "./index.module.scss";
const CardComponent: React.FC<any> = (props: any) => {
  const { TextArea } = Input;
  const { dataList } = props;
  const CardRenderMethods = (data: any) => {
    return data?.map((item: any) => {
      if (item.title === "服务基本信息") {
        return (
          <Card
            style={{ width: "100%" }}
            className={styles["CardComponent-Card"]}
          >
            <Badge
              color="#165DFF"
              text={item.title}
              className={styles["CardComponent-Card-Badge"]}
            />
            <div className={styles["CardComponent-fw-info"]}>
              <div style={{ display: "flex", marginTop: "14px" }}>
                <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[0].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[0].value}
                  </span>
                </div>
                <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[1].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[1].value}
                  </span>
                </div>
                {/* <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[2].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[2].value}
                  </span>
                </div> */}
              </div>
              <div>
                <div style={{ width: "100%", marginTop: "14px" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[3].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[3].value}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        );
      } else if (item.title === "请求信息") {
        return (
          <Card
            style={{ width: "100%" }}
            className={styles["CardComponent-Card"]}
          >
            <Badge
              color="#165DFF"
              text={item.title}
              className={styles["CardComponent-Card-Badge"]}
            />
            <div className={styles["CardComponent-fw-info"]}>
              <div style={{ display: "flex", marginTop: "14px" }}>
                <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[0].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[0].value}
                  </span>
                </div>
                <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[1].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[1].value}
                  </span>
                </div>
                {/* <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[2].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[2].value}
                  </span>
                </div> */}
              </div>
              <div style={{ display: "flex", marginTop: "14px" }}>
                <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[3].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[3].value}
                  </span>
                </div>
                <div style={{ width: "30%" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[4].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[4].value}
                  </span>
                </div>
              </div>
              {/* <div>
                <div style={{ width: "100%", marginTop: "14px" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[5].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[5].value}
                  </span>
                </div>
              </div> */}
            </div>
          </Card>
        );
      } else if (item.title === "请求报文") {
        return (
          <Card
            style={{ width: "100%" }}
            className={styles["CardComponent-Card"]}
          >
            <Badge
              color="#165DFF"
              text={item.title}
              className={styles["CardComponent-Card-Badge"]}
            />
            <div className={styles["CardComponent-fw-info"]}>
              <div style={{ marginTop: "14px" }}>
                <div style={{ width: "100%", marginTop: "14px" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[0].key}
                  </span>
                  <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[0].value}
                  </span>
                </div>
                <div style={{ width: "100%", marginTop: "14px" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[1].key}
                  </span>
                  {/* <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[1].value}
                  </span> */}
                  <TextArea
                    className={styles["CardComponent-Card-textarea"]}
                    value={item.data[1].value}
                    bordered={false}
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </div>
                <div style={{ width: "100%", marginTop: "14px" }}>
                  <span className={styles["CardComponent-Card-Key"]}>
                    {item.data[2].key}
                  </span>
                  {/* <span className={styles["CardComponent-Card-Value"]}>
                    {item.data[1].value}
                  </span> */}
                  <TextArea
                    className={styles["CardComponent-Card-textarea"]}
                    value={item.data[2].value}
                    bordered={false}
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </div>
              </div>
            </div>
          </Card>
        );
      } else {
        return (
          <Card
            style={{ width: "100%" }}
            className={styles["CardComponent-Card"]}
          >
            <Badge
              color="#165DFF"
              text={item.title}
              className={styles["CardComponent-Card-Badge"]}
            />
            <div style={{ width: "100%", marginTop: "14px" }}>
              <span className={styles["CardComponent-Card-Key"]}>
                {item.data[0].key}
              </span>
              <span className={styles["CardComponent-Card-Value"]}>
                {item.data[0].value}
              </span>
            </div>
            <div style={{ width: "100%", marginTop: "14px" }}>
              <span className={styles["CardComponent-Card-Key"]}>
                {item.data[1].key}
              </span>
              <span className={styles["CardComponent-Card-Value"]}>
                {item.data[1].value}
              </span>
            </div>
            <div style={{ width: "100%", marginTop: "14px" }}>
              <span className={styles["CardComponent-Card-Key"]}>
                {item.data[2].key}
              </span>
              {/* {console.log(item.data[2].value)} */}
              <TextArea
                className={styles["CardComponent-Card-textarea"]}
                value={item.data[2].value}
                bordered={false}
                disabled
                autoSize={{ minRows: 0, maxRows: 999999 }}
              />
            </div>
          </Card>
        );
      }
    });
  };
  return (
    <>
      {(dataList !== null || dataList !== undefined) &&
        CardRenderMethods(dataList)}
    </>
  );
};

export default CardComponent;
