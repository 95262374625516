// import { useImperativeHandle, forwardRef } from 'react'
import { message } from 'antd'
import './index.scss'

function Menu({ props }: any) {

  return (
    <div className='menu'>
      <div className="bar">
        <div className="creatScene flex">
          <div className="title">创建场景</div>
          <div className="contenter hander" onClick={() => { props.setCreateSceneFlag(true) }}>
            <div className="top"></div>
          </div>
        </div>
        <div className="line"></div>
        <div className="system flex" >
          <div className="title">系统平台</div>
          <div className="contenter hander" onClick={() => {
            if (props.id !== "create" || props.createSceneFlag) {
              if (props.InputValue) {
                props.setCreateSystemFlag(true)
              } else {
                message.info("请先输入场景名称")
              }
            } else {
              message.info("请先创建场景")
            }
          }}></div>
        </div>
        {/* <div className="line" style={{ top: "160px" }}></div>
        <div className="cockpit flex">
          <div className="title">数字驾驶舱</div>
          <div className="wrap hander">
            <div className="box"></div>
          </div>
        </div>
        <div className="line" style={{ top: "250px" }}></div>
        <div className="central flex">
          <div className="title">中枢系统</div>
          <div className="wrap hander" style={{ borderRadius: "0" }} >
            <div className="box"></div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default Menu
