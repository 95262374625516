import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  Form,
  Select,
  Input,
  Button,
  Table,
  DatePicker,
  Segmented,
  Breadcrumb,
} from "antd";
import {getdailydetails} from "../../../api/callrecord"
const CallStatisticsDetail: React.FC = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { state } = useLocation<any>();
  const [total, setTotal] = useState(0);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [datasource,setdatasource] = useState([])
  const [isAsc, setisAsc] = useState<any>();
  const [sort, setsort] = useState<any>();
  const columns = [
    {
      title: "API名称",
      dataIndex: "apiName",
      key: "apiName",
    },
    {
      title: "DataAddress",
      dataIndex: "dataAddress",
      key: "dataAddress",
    },
    {
      title: "调用量",
      dataIndex: "count",
      key: "count",
      sorter: true,
      // render: (text: any) => text.name,
    },
    {
      title: "错误量",
      dataIndex: "errCount",
      key: "errCount",
      sorter: true,
      // align: "center" as "center",
    },
    {
      title: "最大耗时(ms)",
      dataIndex: "maxTime",
      key: "maxTime",
      sorter: true,
    },
    {
      title: "最小耗时(ms)",
      dataIndex: "minTime",
      key: "minTime",
      sorter: true,
    },
    {
      title: "平均耗时(ms)",
      dataIndex: "avgTime",
      key: "avgTime",
      sorter: true,
    },
  ];
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log(sorter);
    
    if (sorter?.order === "ascend") {
      setisAsc(true);
      setsort(sorter?.column.key);
    } else if (sorter.order === "descend") {
      setisAsc(false);
      setsort(sorter?.column.key);
    } else {
      setisAsc('');
      setsort('');
    }
  };
  const getList =useCallback(()=>{
    setlistload(true)
    getdailydetails({
      ...pageParam,
      accessKey:state.accesskey,
      date:state.time,
      api:form.getFieldValue('api'),
      isAsc: isAsc,
      sort: sort,
    }).then((res:any)=>{
      setlistload(false)
      setdatasource(res.data)
    })
  },[pageParam,isAsc,sort]) 
  useEffect(()=>{
    getList()
  },[pageParam,isAsc,sort])

  return (
    <div style={{ background: "#f7f8fa" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push("/callstatistics");
            }}
          >
            调用统计
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>调用明细</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "#FFF", marginTop: "17px" }}>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>调用明细</div>
        </div>
        <div style={{ height: "45px", marginTop: "20px" }}>
          <div style={{ marginTop: "40px", display: "flex" }}>
            {
              state.accesskey ? <div>
              <span style={{ color: "#86909C" }}>AccessKey：</span>
              <span style={{ color: "#4E5969" }}>{state.accesskey}</span>
            </div>:<div>
              <span style={{ color: "#86909C" }}>AccessKey：</span>
              <span style={{ color: "#4E5969" }}>全部</span>
            </div>
            }
            <div style={{marginLeft:"100px"}}>
              <div>
                <span style={{ color: "#86909C" }}>调用日期：</span>
                <span style={{ color: "#4E5969" }}>{state.time}</span>
              </div>
            </div>
          </div>
          <Form form={form} layout="inline" style={{ marginTop: "25px" }}>
            <Form.Item name="api" label="API名称/DataAddress：">
              <Input
                placeholder="请输入API名称/DataAddress："
                style={{ width: "320px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: "16px" }}
                type="primary"
                onClick={getList}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setPageParam({
                    page: 1,
                    pageSize: 10,
                  });
                }}
              >
                重置
              </Button>
            </Form.Item>
          </Form>
          <div style={{ marginTop: "20px" }}>
            <Table
              columns={columns}
              loading={listload}
              dataSource={datasource}
              onChange={onChange}
              pagination={{
                total,
                pageSize: pageParam.pageSize,
                current: pageParam.page,
                showSizeChanger: true,
                showTotal: (total) => `共 ${total}条记录`,
                onChange: (page, size) =>
                  setPageParam({ page, pageSize: size }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallStatisticsDetail;
