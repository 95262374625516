import React, { useEffect, useState } from "react";
import { Empty, Table } from "antd";
import styles from "./index.module.scss";
import { getplantformlist } from "../../../api/category";
const DetailsList: React.FC<any> = (props) => {
  const [dataList, setdataList] = useState<any>([]);
  const [pageParams, setPageparams] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setListload] = useState(true);
  const [total, setTotal] = useState<number>(0);
  const cols = [
    {
      title: "系统/平台名称",
      dataIndex: "name",
      align: "center" as "center",
    },
    {
      title: "上级类目",
      align: "center" as "center",
    },
    {
      title: "级别",
      align: "center" as "center",
    },
    {
      title: "系统/平台描述",
      dataIndex: "desc",
      align: "center" as "center",
    },
  ];
  //空数据
  const nullType = (
    <div style={{ height: "100%" }}>
      <Empty
        style={{ height: "100%", margin: "16% auto" }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={
          <span style={{ color: "#9AA1A9", fontSize: "16px" }}>
            暂无关联的系统/平台
          </span>
        }
      ></Empty>
    </div>
  );
  useEffect(() => {
    setListload(true);
    getplantformlist({
      categoryIds: props.data,
      page: pageParams.page,
      page_size: pageParams.pageSize,
    }).then((res) => {
      if (res.code === 200) {
        if (res.data.platforms !== null) {
          setdataList(res.data.platforms);
          setListload(false);
          setTotal(res.data.total);
        } else {
          setdataList([]);
          setListload(false);
          setTotal(res.data.total);
        }
      }
    });
  }, [pageParams.page, pageParams.pageSize, props.data]);
  return (
    <div className={styles["detailCss"]}>
      <div className={styles["title"]}>
        <div className={styles["tit_0"]}>{props.data ? props.name : ""}</div>
        <i
          className="iconfont icon-Partition-copy"
          // style={{ color: "#979797" }}
        ></i>
        <span className={styles["tit_1"]}>{dataList.length===0?0:dataList.length}</span>
      </div>
      {dataList.length===0 ? nullType :""}
      {dataList.length > 0 && (
        <Table
          dataSource={dataList}
          columns={cols}
          loading={listload}
          rowKey="id"
          pagination={{
            total,
            showTotal: (total) => `共 ${total}条记录`,
            pageSize: pageParams.pageSize,
            current: pageParams.page,
            onChange: (page, size) => {
              setPageparams({ page, pageSize: size });
            },
          }}
        />
      )}
    </div>
  );
};
export default DetailsList;
