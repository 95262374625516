import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Table, DatePicker, Segmented } from "antd";
import { getrecordlist } from "../../api/callrecord";
import moment from "moment";
const Cnum: React.FC = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [SegmentedType, setSegmentedType] = useState(0);
  const [datasource, setdatasource] = useState([]);
  const [starttime, setstarttime] = useState<any>(
    moment().subtract("months").startOf("month").valueOf()
  );
  const [endtime, setendtime] = useState<any>(moment().valueOf());
  const [isAsc, setisAsc] = useState<any>();
  const [sort, setsort] = useState<any>();
  const columns = [
    {
      title: "请求ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "AccessKey",
      dataIndex: "accessKey",
      key: "accessKey",
    },
    {
      title: "API名称",
      dataIndex: "apiName",
    },
    {
      title: "DataAddress",
      dataIndex: "dataAddress",
    },
    {
      title: "请求时间",
      dataIndex: "start",
      render: (text: any) => <>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</>,
    },

    {
      title: "耗时(ms)",
      key: "elapsedTime",
      dataIndex: "elapsedTime",
      sorter: true,
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any) => (
        <a
          onClick={() => {
            history.push({
              pathname: `/cnumdetail`,
              state: {
                id: text
              },
            });
          }}
        >
          交易详情
        </a>
      ),
    },
  ];
  const getList = useCallback(() => {
    setlistload(true);
    getrecordlist({
      ...pageParam,
      accessKey: form.getFieldValue("accessKey"),
      api: form.getFieldValue("api"),
      start: starttime,
      end: endtime,
      isAsc: isAsc,
      sort: sort,
    }).then((res: any) => {
      if (res.code === 200) {
        setTotal(res.pagination.total);
        setdatasource(res.data);
        setlistload(false);
      }
    });
  }, [pageParam, SegmentedType, starttime, endtime,isAsc,sort]);
  // useEffect(() => {
  //   console.log();

  //   if (SegmentedType == 0) {
  //     setstarttime(moment().subtract("months").startOf("month").valueOf());
  //     setendtime(moment().valueOf());
  //   } else if (SegmentedType == 1) {
  //     setstarttime(moment().subtract(1, "months").startOf("month").valueOf());
  //     setendtime(moment().subtract(1, "months").endOf("month").valueOf());
  //   }
  // }, [SegmentedType]);
  useEffect(() => {
    getList();
  }, [pageParam, starttime, endtime, isAsc]);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (sorter?.order === "ascend") {
      setisAsc(true);
      setsort(sorter?.column.key);
    } else if (sorter.order === "descend") {
      setisAsc(false);
      setsort(sorter?.column.key);
    } else {
      setisAsc('');
      setsort('');
    }
    // console.log(sorter.order, sorter?.column?.key);
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>调用记录</div>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="accessKey" label="AccessKey">
            <Input placeholder="请输入AccessKey" />
          </Form.Item>
          <Form.Item name="api" label="API名称/DataAddress：">
            <Input placeholder="请输入API名称或DataAddress" />
          </Form.Item>
          <Form.Item name="" label="">
            <Segmented
            value={SegmentedType}
              onChange={(e: any) => {
                setSegmentedType(e);
                if (e == 0) {
                  setstarttime(
                    moment().subtract("months").startOf("month").valueOf()
                  );
                  setendtime(moment().valueOf());
                } else if (e == 1) {
                  setstarttime(
                    moment().subtract(1, "months").startOf("month").valueOf()
                  );
                  setendtime(
                    moment().subtract(1, "months").endOf("month").valueOf()
                  );
                }
              }}
              options={[
                {
                  label: <div>本月</div>,
                  value: 0,
                },
                {
                  label: <div>上月</div>,
                  value: 1,
                },
                {
                  label: <div>自定义</div>,
                  value: 2,
                },
              ]}
            />
          </Form.Item>
          {SegmentedType === 2 && (
            <Form.Item name="picker" label="">
              <RangePicker allowClear={false} onChange={(date,dateString)=>{
                setstarttime(moment(dateString[0]).valueOf())
                setendtime(moment(dateString[1]).valueOf())
              }}/>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setSegmentedType(0)
                setstarttime(moment().subtract("months").startOf("month").valueOf())
                setendtime(moment().valueOf())
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
        {/* <Button
          style={{ float: "right" }}
          type="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          + 新增名单
        </Button> */}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={datasource}
          onChange={onChange}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};

export default Cnum;
