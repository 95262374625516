const graphData = {
  cells: [
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '2d82cdc4-3057-4fca-8d9a-2351d064b74f',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: '60436d0a-95d6-4e4f-8747-863bf16b2240',
        port: '0ed621ca-1c29-4c18-85cd-81fcf8911663',
      },
      target: {
        cell: 'f4085492-b598-432f-be76-6da6533241c1',
        port: 'ad7666b4-10f8-45a3-85a6-a9f33a5f429b',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '88a255b9-2a02-4cfc-a88b-c9846db21b66',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: 'f4085492-b598-432f-be76-6da6533241c1',
        port: '116579ee-f00a-4c83-a14f-7923f15006b4',
      },
      target: {
        cell: 'fa27a1e8-ec12-4e16-a38e-396528005f7e',
        port: '5e2723ea-f6f0-4de8-9d36-bb9a96a8d964',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '2e536225-8b39-4878-9f1b-69e363dd09a5',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: 'f4085492-b598-432f-be76-6da6533241c1',
        port: '3911a67d-43b1-4a8a-9216-b7da25341de1',
      },
      target: {
        cell: '99e14e58-4fe2-4d3b-9154-c2bc4f8fc327',
        port: 'aeca5ed5-d0db-47ba-b415-df0df3d19403',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '2b175bf3-333f-424d-9b6d-8ad93b0e2c84',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: 'f4085492-b598-432f-be76-6da6533241c1',
        port: 'd865df64-018a-4f0b-bb54-0ba8b3c9891f',
      },
      target: {
        cell: '23201ba3-0a31-429a-828d-332ff5d92bd3',
        port: 'aeca5ed5-d0db-47ba-b415-df0df3d19403',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '1a4f8cee-8de0-44af-a3de-9a80b408dbf5',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: 'fa27a1e8-ec12-4e16-a38e-396528005f7e',
        port: '2e83a247-fe8d-416c-98da-aff70756e512',
      },
      target: {
        cell: '765b2519-ca1e-4387-8945-0602413fd8e9',
        port: 'ad7666b4-10f8-45a3-85a6-a9f33a5f429b',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '684e442e-c58a-4959-bb05-ceb408104699',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: '99e14e58-4fe2-4d3b-9154-c2bc4f8fc327',
        port: 'e943899d-a7e7-4440-b856-27aca334e291',
      },
      target: {
        cell: '765b2519-ca1e-4387-8945-0602413fd8e9',
        port: '3911a67d-43b1-4a8a-9216-b7da25341de1',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: 'cbf94e89-1f39-4d53-a043-9f723d1d370d',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: '23201ba3-0a31-429a-828d-332ff5d92bd3',
        port: 'e943899d-a7e7-4440-b856-27aca334e291',
      },
      target: {
        cell: '765b2519-ca1e-4387-8945-0602413fd8e9',
        port: 'd865df64-018a-4f0b-bb54-0ba8b3c9891f',
      },
    },
    {
      shape: 'edge',
      attrs: {
        line: {
          stroke: '#5F95FF',
          strokeWidth: 1,
          targetMarker: {
            name: 'classic',
            size: 8,
          },
        },
      },
      id: '35832a5a-e259-4462-9f3d-b704c38a8351',
      router: {
        name: 'manhattan',
      },
      zIndex: 0,
      source: {
        cell: '765b2519-ca1e-4387-8945-0602413fd8e9',
        port: '116579ee-f00a-4c83-a14f-7923f15006b4',
      },
      target: {
        cell: '6ac528e3-cd0e-431e-8653-7502db15ad46',
        port: '8689ec26-b6c3-4273-8043-77caac66660a',
      },
    },
    {
      position: {
        x: 240,
        y: 110,
      },
      size: {
        width: 80,
        height: 42,
      },
      attrs: {
        text: {
          textWrap: {
            text: '起始节点',
          },
        },
        body: {
          rx: 24,
          ry: 24,
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: '8689ec26-b6c3-4273-8043-77caac66660a',
          },
          {
            group: 'right',
            id: '65df26ff-6b9e-41e9-9d51-a5ba8f4ae4fa',
          },
          {
            group: 'bottom',
            id: '0ed621ca-1c29-4c18-85cd-81fcf8911663',
          },
          {
            group: 'left',
            id: '6178d667-46b6-40f5-9e8f-833ee0b040c1',
          },
        ],
      },
      id: '60436d0a-95d6-4e4f-8747-863bf16b2240',
      zIndex: 1,
    },
    {
      angle: 45,
      position: {
        x: 130,
        y: 360,
      },
      size: {
        width: 52,
        height: 52,
      },
      attrs: {
        text: {
          textWrap: {
            text: '判断节点',
          },
          transform: 'rotate(-45deg)',
        },
        'edit-text': {
          style: {
            transform: 'rotate(-45deg)',
          },
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: {
              name: 'top',
              args: {
                dx: -26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: {
              name: 'right',
              args: {
                dy: -26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: {
              name: 'bottom',
              args: {
                dx: 26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: {
              name: 'left',
              args: {
                dy: 26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: 'aeca5ed5-d0db-47ba-b415-df0df3d19403',
          },
          {
            group: 'right',
            id: '4c9e98b4-a474-48b1-87bf-094124c70924',
          },
          {
            group: 'bottom',
            id: 'e943899d-a7e7-4440-b856-27aca334e291',
          },
          {
            group: 'left',
            id: '2299690d-7ed5-4f00-9fcd-aedcb1df5804',
          },
        ],
      },
      id: '99e14e58-4fe2-4d3b-9154-c2bc4f8fc327',
      zIndex: 2,
    },
    {
      position: {
        x: 240,
        y: 234,
      },
      size: {
        width: 80,
        height: 42,
      },
      attrs: {
        text: {
          textWrap: {
            text: '流程节点',
          },
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: 'ad7666b4-10f8-45a3-85a6-a9f33a5f429b',
          },
          {
            group: 'right',
            id: 'd865df64-018a-4f0b-bb54-0ba8b3c9891f',
          },
          {
            group: 'bottom',
            id: '116579ee-f00a-4c83-a14f-7923f15006b4',
          },
          {
            group: 'left',
            id: '3911a67d-43b1-4a8a-9216-b7da25341de1',
          },
        ],
      },
      id: 'f4085492-b598-432f-be76-6da6533241c1',
      zIndex: 3,
    },
    {
      position: {
        x: 245,
        y: 331,
      },
      size: {
        width: 70,
        height: 70,
      },
      attrs: {
        text: {
          textWrap: {
            text: '链接节点',
          },
        },
        body: {
          rx: 35,
          ry: 35,
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: '5e2723ea-f6f0-4de8-9d36-bb9a96a8d964',
          },
          {
            group: 'right',
            id: '0d0c783e-d9aa-494d-af33-c82de65e08c9',
          },
          {
            group: 'bottom',
            id: '2e83a247-fe8d-416c-98da-aff70756e512',
          },
          {
            group: 'left',
            id: '3ede7704-d0b0-4fa8-9ac1-d850b67f6889',
          },
        ],
      },
      id: 'fa27a1e8-ec12-4e16-a38e-396528005f7e',
      zIndex: 4,
    },
    {
      angle: 45,
      position: {
        x: 376,
        y: 340,
      },
      size: {
        width: 52,
        height: 52,
      },
      attrs: {
        text: {
          textWrap: {
            text: '判断节点',
          },
          transform: 'rotate(-45deg)',
        },
        'edit-text': {
          style: {
            transform: 'rotate(-45deg)',
          },
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: {
              name: 'top',
              args: {
                dx: -26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: {
              name: 'right',
              args: {
                dy: -26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: {
              name: 'bottom',
              args: {
                dx: 26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: {
              name: 'left',
              args: {
                dy: 26,
              },
            },
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: 'aeca5ed5-d0db-47ba-b415-df0df3d19403',
          },
          {
            group: 'right',
            id: '4c9e98b4-a474-48b1-87bf-094124c70924',
          },
          {
            group: 'bottom',
            id: 'e943899d-a7e7-4440-b856-27aca334e291',
          },
          {
            group: 'left',
            id: '2299690d-7ed5-4f00-9fcd-aedcb1df5804',
          },
        ],
      },
      id: '23201ba3-0a31-429a-828d-332ff5d92bd3',
      zIndex: 5,
    },
    {
      position: {
        x: 240,
        y: 476,
      },
      size: {
        width: 80,
        height: 42,
      },
      attrs: {
        text: {
          textWrap: {
            text: '流程节点',
          },
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: 'ad7666b4-10f8-45a3-85a6-a9f33a5f429b',
          },
          {
            group: 'right',
            id: 'd865df64-018a-4f0b-bb54-0ba8b3c9891f',
          },
          {
            group: 'bottom',
            id: '116579ee-f00a-4c83-a14f-7923f15006b4',
          },
          {
            group: 'left',
            id: '3911a67d-43b1-4a8a-9216-b7da25341de1',
          },
        ],
      },
      id: '765b2519-ca1e-4387-8945-0602413fd8e9',
      zIndex: 6,
    },
    {
      position: {
        x: 240,
        y: 609,
      },
      size: {
        width: 80,
        height: 42,
      },
      attrs: {
        text: {
          textWrap: {
            text: '结束节点',
          },
          style: {
            display: '',
          },
        },
        body: {
          rx: 24,
          ry: 24,
        },
      },
      shape: 'flow-chart-rect',
      ports: {
        groups: {
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 3,
                magnet: true,
                stroke: '#5F95FF',
                strokeWidth: 1,
                fill: '#fff',
                style: {
                  visibility: 'hidden',
                },
              },
            },
          },
        },
        items: [
          {
            group: 'top',
            id: '8689ec26-b6c3-4273-8043-77caac66660a',
          },
          {
            group: 'right',
            id: '65df26ff-6b9e-41e9-9d51-a5ba8f4ae4fa',
          },
          {
            group: 'bottom',
            id: '0ed621ca-1c29-4c18-85cd-81fcf8911663',
          },
          {
            group: 'left',
            id: '6178d667-46b6-40f5-9e8f-833ee0b040c1',
          },
        ],
      },
      id: '6ac528e3-cd0e-431e-8653-7502db15ad46',
      zIndex: 7,
    },
  ],
};

export default graphData;
