import { postClient, getClient ,putClient} from "./requestClient";
import {
  GET_SRVICE_TYPE,
  GET_SERVICE_LIST,
  GET_SERVICE_OTHERLIST,
  DELETE_SERVICE_ITEM,
  DETAIL_SERVICE_ITEM,
  INFO_CONNECT,
  CHANGE_SERVICE_STATUS,
  ADD_SERVICE_FIRST,
  ADD_SERVICE_SECOND,
  ADD_SERVICE_THIRD,
  ADD_SERVICE_MANAGE,
  GET_CONNECTOR_TYPE,
  GET_CONNECTOR_LIST,
  GET_EDIT_SERVICE_START,
  GET_EDIT_SERVICE_FIRST,
  GET_EDIT_SERVICE_SECOND,
  GET_EDIT_SERVICE_THIRD,
  EDIT_SERVICE_MANAGE,
  GET_CONNECTOR_DETAIL,
  GET_test_list,
  POST_test_delete,
  GET_test_detail,
  POST_test_create,
  POST_test_update,
  GET_serviceManage_detail,
  POST_test_online,
  GET_serviceManageAggregation_query,
  GET_serviceManageAggregation_dtail,
  POST_firstCache,
  POST_secondCache,
  POST_thirdCache,
  POST_Create,
  DETAIL_SERVICE_ITEMV2,
  PSOT_editStart,
  GET_editFirst,
  GET_editSecond,
  GET_editThird,
  PUT_serviceManageAggregation
} from "./index";

export const putedit = async (params: any) =>
  await putClient<any>(PUT_serviceManageAggregation, params);

export const geteditFirst = async (params: any) =>
  await getClient<any>(GET_editFirst+`/${params}`);

  export const geteditSecond = async (params: any) =>
  await getClient<any>(GET_editSecond+`/${params}`);

    export const geteditThird = async (params: any) =>
  await getClient<any>(GET_editThird+`/${params}`);

export const posteditStart = async (params: any) =>
  await postClient<any>(PSOT_editStart, params);

export const postCreate = async (params: any) =>
  await postClient<any>(POST_Create, params);

export const postthirdCache = async (params: any) =>
  await postClient<any>(POST_thirdCache, params);

export const postSecondCache = async (params: any) =>
  await postClient<any>(POST_secondCache, params);

export const postfirstCache = async (params: any) =>
  await postClient<any>(POST_firstCache, params);

export const getserviceManageAggregationdetail = async (params: any) =>
  await getClient<any>(GET_serviceManageAggregation_dtail+`/${params}`);

export const getserviceManageAggregationquery = async (params: any) =>
  await getClient<any>(GET_serviceManageAggregation_query, params);

export const posttestonline = async (params: any) =>
  await postClient<any>(POST_test_online, params);

  export const posttestupdate = async (params: any) =>
  await postClient<any>(POST_test_update, params);

export const getserviceManagedetail = async (params: any) =>
  await getClient<any>(GET_serviceManage_detail, params);

export const posttestcreate = async (params: any) =>
  await postClient<any>(POST_test_create, params);

export const getServiceType = async () =>
  await getClient<any>(GET_SRVICE_TYPE);

export const getServiceList = async (params: any) =>
  await postClient<any>(GET_SERVICE_LIST, params);

export const getOtherServicList = async (params: any) =>
  await postClient<any>(GET_SERVICE_OTHERLIST, params);  
  

export const deleteServiceItem = async (params: any) =>
  await getClient<any>(DELETE_SERVICE_ITEM, params);

export const detailServiceItem = async (params: any) =>
  await getClient<any>(DETAIL_SERVICE_ITEM, params);
  
  export const detailv2ServiceItem = async (params: any) =>
  await getClient<any>(DETAIL_SERVICE_ITEMV2, params);  

export const changeStatus = async (params: any) =>
  await getClient<any>(CHANGE_SERVICE_STATUS, params);  

export const getConnectInfo = async (params: any) =>
  await getClient<any>(INFO_CONNECT, params);   
  

export const addServiceFirst = async (params: any) =>
  await postClient<any>(ADD_SERVICE_FIRST, params);

export const addServiceSecond = async (params: any) =>
  await postClient<any>(ADD_SERVICE_SECOND, params);

export const addServiceThird = async (params: any) =>
  await postClient<any>(ADD_SERVICE_THIRD, params);

export const addServiceManage = async (params: any) =>
  await postClient<any>(ADD_SERVICE_MANAGE, params);

export const getConnectorType = async () =>
  await getClient<any>(GET_CONNECTOR_TYPE);

export const getConnectorList = async (params: any) =>
  await getClient<any>(GET_CONNECTOR_LIST, params);

export const getEditServiceStart = async (params: any) => 
  await postClient<any>(GET_EDIT_SERVICE_START, params);

export const getEditServiceFirst = async (params: any) => 
  await postClient<any>(GET_EDIT_SERVICE_FIRST, params);

export const getEditServiceSecond = async (params: any) => 
  await postClient<any>(GET_EDIT_SERVICE_SECOND, params);

export const getEditServiceThird = async (params: any) => 
  await postClient<any>(GET_EDIT_SERVICE_THIRD, params);

export const editServiceManage = async (params: any) => 
  await postClient<any>(EDIT_SERVICE_MANAGE, params);

  export const getConnectorDetail = async (params: any) =>
await getClient<any>(GET_CONNECTOR_DETAIL, params);

export const gettestlist = async (params: any) =>
  await getClient<any>(GET_test_list, params);  

  export const posttestdelete = async (params: any) =>
  await postClient<any>(POST_test_delete, params);

  export const gettestdetail = async (params: any) =>
  await getClient<any>(GET_test_detail, params);  