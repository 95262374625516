import React, { useEffect, useState } from "react";
import { Divider, Badge, Statistic } from "antd";
import BadgeComponent from "./component/BadgeComponent";
import TopfiveCharts from "./component/TopfiveCharts";
import CallStatisticsCharts from "./component/CallStatisticsCharts";
import styles from "./index.module.scss";
import "./index.scss";
import {
  getreportaggregation,
  getserviceaggregation,
} from "../../api/ServiceHealth";
const ServiceMonitoring: React.FC = () => {
  const [aggregation, setaggregation] = useState<any>({});
  const [fw, setfw] = useState([
    {
      name: "本日新增凭证数",
      value: 12,
      color: "#2D99FF",
    },
    {
      name: "本周新增凭证数",
      value: 12,
      color: "#83CFFF",
    },
    {
      name: "本月新增凭证数",
      value: 12,
      color: "#2CD9C5",
    },
  ]);
  const [fwhz,setfwhz] = useState<any>({})
  const [fwData,setfwData] = useState<any>([
    {
      name: "本日新增服务数",
      value: 12,
      color: "#2D99FF",
    },
    {
      name: "本周新增服务数",
      value: 12,
      color: "#83CFFF",
    },
    {
      name: "本月新增服务数",
      value: 12,
      color: "#2CD9C5",
    },
  ])
  useEffect(() => {
    getreportaggregation().then((res) => {
      if (res.code === 200) {
        setaggregation(res.data);
        setfw([
          {
            name: "本日新增凭证数",
            value: res.data.todayAdd,
            color: "#2D99FF",
          },
          {
            name: "本周新增凭证数",
            value: res.data.weekAdd,
            color: "#83CFFF",
          },
          {
            name: "本月新增凭证数",
            value: res.data.monthAdd,
            color: "#2CD9C5",
          },
        ]);
      }
    });
    getserviceaggregation().then((res)=>{
      if(res.code===200){
        setfwhz(res.data)
        setfwData([
          {
            name: "本日新增服务数",
            value: res.data.todayAdd,
            color: "#2D99FF",
          },
          {
            name: "本周新增服务数",
            value: res.data.weekAdd,
            color: "#83CFFF",
          },
          {
            name: "本月新增服务数",
            value: res.data.monthAdd,
            color: "#2CD9C5",
          },
        ])
      }
    })
  }, []);
  return (
    <div style={{ padding: "20px" }} className="all">
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>服务运行监控</div>
      </div>
      <div
        style={{ marginTop: "20px" }}
        className={styles["ServiceMonitoring-top"]}
      >
        <div
          style={{ width: "30%" }}
          className={styles["ServiceMonitoring-top-card"]}
        >
          <div>
            <div className={styles["acc"]}>
              <Badge color="#165DFF" text="服务汇总" />
            </div>
            <Divider />
            <div
              className={styles["ServiceMonitoring-top-title"]}
              style={{ marginLeft: "16px" }}
            >
              服务总数
            </div>
            <Statistic
              value={fwhz.serviceCount}
              style={{ marginTop: "10px", marginLeft: "16px" }}
            />
          </div>
          <div
            style={{ marginLeft: "16px", display: "flex", marginTop: "10px" ,height:"30px"}}
          >
            <div>
              <span style={{ color: "#86909C" }}>启用</span>
              <span style={{ color: "#3EAF49", marginLeft: "6px" }}>{fwhz.enableCount}</span>
            </div>
            <div style={{ marginLeft: "48px" }}>
              <span style={{ color: "#86909C" }}>停用</span>
              <span style={{ color: "#DC4545", marginLeft: "6px" }}>{fwhz.disableCount}</span>
            </div>
          </div>
          <Divider />
          <div style={{ marginLeft: "16px" }}>
            <div className={styles["ServiceMonitoring-top-title"]}>
              新增服务数
            </div>
            {fwData.map((item:any, index:number) => {
              return (
                <div className={styles["bcc"]}>
                  <BadgeComponent
                    color={item.color}
                    name={item.name}
                    value={item.value}
                    key={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{ width: "25%" }}
          className={styles["ServiceMonitoring-top-card"]}
        >
          <div>
            <div className={styles["acc"]}>
              <Badge color="#165DFF" text="接入方汇总" />
            </div>
            <Divider/>
            <div
              className={styles["ServiceMonitoring-top-title"]}
              style={{ marginLeft: "16px" }}
            >
              总凭证数
            </div>
            <Statistic
              value={aggregation.credentialCount}
              style={{ marginTop: "10px", marginLeft: "16px" }}
            />
            <div style={{height:"30px",marginTop:"10px"}}></div>
            <Divider />
          </div>
          <div style={{ marginLeft: "16px" }}>
            <div className={styles["ServiceMonitoring-top-title"]}>
              新增凭证数
            </div>
            {fw.map((item, index) => {
              return (
                <div className={styles["bcc"]}>
                  <BadgeComponent
                    color={item.color}
                    name={item.name}
                    value={item.value}
                    key={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{ width: "40%" }}
          className={styles["ServiceMonitoring-top-card"]}
        >
          <div className={styles["acc"]}>
            <Badge color="#165DFF" text="今日凭证调用TOP5" />
          </div>
          <Divider />
          <TopfiveCharts />
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          width: "100%",
          height: "372px",
          background: "#FAFCFF",
          padding: "20px",
        }}
      >
        <CallStatisticsCharts />
      </div>
    </div>
  );
};

export default ServiceMonitoring;
