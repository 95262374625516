import usePublishAgent, { initState, StateType } from "./utils-data/useReducer";
import { Spin, Steps, Tabs } from "antd";
import { publishServeSteps, detailBreadcrumbValue } from "./utils-data/commonData";
import qs from 'qs';
// @ts-ignore
// import {useDetailLayout, useDetail} from '@cspire/zs-detail-layout';
// @ts-ignore
import './index.scss';
import NameAgent from "./NameAgent";
import BackendConfig from "./BackendConfig";
import FrontendConfig from "./FrontendConfig";
import PublishingAgent from "./PublishingAgent";
import PageCard from '../../../component/PageCard';
import { get } from 'lodash'
import * as React from "react";
import { Dispatch, ReducerWithoutAction, SetStateAction, useEffect, useState } from "react";
import { RouteComponentProps } from 'react-router-dom';
// import ApiDetailPage from "../api-detail-page";

const { Step } = Steps

interface ContextProps {
  state: StateType;
  getDetail?: () => void,
  dispatch?: ReducerWithoutAction<any>;
  history?: RouteComponentProps["history"];
  setApiCode?: Dispatch<SetStateAction<any>>;
  setAppApiId?: Dispatch<SetStateAction<any>>;
  startGetEditInfo?: (T: any) => void;
}

export const PublishContext = React.createContext<ContextProps>({ state: initState })

interface Props {
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"]
}

const PublishServe: React.FC<Props> = (props) => {
  const urlInfo = qs.parse(window.location.search.replace('?', ''))
  console.log(urlInfo);
  
  let {
    type,
    referer,
    hideBlockChain,
    serverName,
    status,
    detailType,
    appApiId,
    apiCode
  } = urlInfo
  const {
    state,
    dispatch,
    getDetail,
  } = usePublishAgent(urlInfo, type,referer)
  const { currentStep } = state;
  useEffect(() => {
    // referer && useDetail.update(detailBreadcrumbValue[referer])
  }, [])
  //带type=detail的页面进详情页
  return <PageCard>
    <PublishContext.Provider
      value={{ state, getDetail, dispatch, history: get(props, 'history') }}>
      <>
        {type !== 'detail' && <div style={{ padding: '0 100px' }}>
          <Steps className="protocol-editor-steps-layout" current={publishServeSteps[currentStep][1] as number} labelPlacement="vertical">
            {Object.values(publishServeSteps).map(([label, step]) =>
              <Step
                title={''} key={step}
                description={label}
              />)
            }
          </Steps>
        </div>}

        <div style={{ paddingBottom: '20px' }}>
          {type !== 'detail' ?
            <>
              <NameAgent appApiId={appApiId} referer={referer}/>
              <BackendConfig appApiId={appApiId} referer={referer}/>
              <FrontendConfig appApiId={appApiId} />
              <PublishingAgent appApiId={appApiId} />
            </>
            : null}
        </div>
      </>
    </PublishContext.Provider>
  </PageCard>
}
export default PublishServe;
