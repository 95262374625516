import actionType from "../action"

const data = {
    n: 1,
    m: 0,
    systemData: {}
}

export const demoStore = (state = data, action) => {
    // console.log(state);
    // console.log(action);
    switch (action.type) {
        case actionType.CALC:
            return { ...state, n: state.n + action.playload }
        case actionType.CALC1:
            return { ...state, m: state.m + action.playload }
        case actionType.GETSYSTEMDATA:
            return { ...state, systemData: action.playload }
        default: return state
    }
}