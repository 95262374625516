
import { isEmpty } from 'lodash'
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
interface StepsType<T> {
  [T: string]: [string, number]
}
export const publishServeSteps = {
  nameAgent: ['基本信息', 0],
  backendConfig: ['后端服务配置', 1],
  frontendConfig: ['前端服务配置', 2],
  publishingService: ['发布', 3]
}

export const detailBreadcrumbValue = {
  apiDetailPage: [{ name: 'API详情', path: '/biz/api-list/publish-serve' }, { name: '业务API详情' }],
  apiListEdit: [{ name: '自建API', path: '/biz/api-list/index' }, { name: '编辑业务类API' }],
  apiListNew: [{ name: '自建API', path: '/biz/api-list/index' }, { name: '新建业务类API' }],
  apiList: [{ name: '自建API', path: '/biz/api-list/index' }, { name: '业务类API详情' }],
  interconnect: [{ name: '互联API', path: '/biz/api-manage/interconnect' }, { name: '业务类API详情' }],
  allAPI: [{ name: '全部API', path: '/biz/api-manage/hub-api-list' }, { name: '业务类API详情' }],
  account: [{ name: '账号管理', path: '/console/account-auth/account-manage' }, { name: '业务类API详情' }],
  approve: [{ name: '我的审批', path: '/console/approval-process/my-approve' }, { name: '业务类API详情' }],
  apply: [{ name: '我的申请', path: '/console/approval-process/auth-approval-api' }, { name: '业务类API详情' }],
  resource: [{ name: '授权资源', path: '/console/safety-manage/publickey-manage/auth-resource' }, { name: '业务类API详情' }]
}

export const authModes = ['public', 'private']

export const labels = ['一', '二', '三']

export const currentLimiting = (idx: any) => {
  const keys = ['One', 'Two', 'Three']
  return {
    [`res${keys[idx]}Min`]: { label: `${labels[idx]}级限流：1分钟内平均响应时间介于` },
    [`res${keys[idx]}Max`]: { label: 'ms到' },
    [`qps${keys[idx]}`]: { label: 'ms时，QPS降低到', unit: '%' }
  }
}
export const currentFusingLimiting = (idx: any) => {
  const keys = ['One', 'Two', 'Three']
  return {
    [`fuse${keys[idx]}Min`]: { label: `${labels[idx]}级熔断：在` },
    [`fuse${keys[idx]}Error`]: { label: '分钟内，累计错误次数达到' },
    [`fuse${keys[idx]}Second`]: { label: '，熔断', unit: '秒' },
  }
}
export const TipCom = (type: string) => {
  const tips:any = {
    maxQp: '按总调用量限制：所有消费者的可调用量总和，若达到最大调用量，消费者将不可调用。',
    limiting: <div>
      <p>若未填写或一个等级的限流触发条件未填写完整，则限流不触发；</p>
      <p>{`触发顺序：一级限流->二级限流->三级限流”`}</p>
    </div>,
    'limiting-fusing': <div>
      <p>若未填写或一个等级的熔断控制未填写完整，则熔断不触发；</p>
      <p>{`触发顺序：一级熔断->二级熔断->三级熔断”`}</p>
    </div>
  }
  return <>{tips[type] && <Tooltip title={tips[type]}>
    <QuestionCircleOutlined style={{ marginLeft: "7px", color: "#165DFF" }}
    /></Tooltip>}
  </>
}