// @ts-ignore
import APIParamsRequestForm from "@/component/APIParamsRequestForm";
import moment from 'moment'
import { useHistory, useParams } from "react-router-dom";
import React, {useEffect, useState, PropsWithChildren} from "react";
import { Timeline , Form, Table, Button, Tabs, Row, Col, message } from "antd";
import {
  getMsgDetail,
  getMsgRecord
} from "@/api/topic";
import { CheckCircleOutlined } from "@ant-design/icons";
type Props = {};
const DetailRender: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const data: any = Object.prototype.toString.call(children) === '[object Array]' ? children : [children]
  return <>
    {
      data.map((it: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
        <Col md={8} sm={24} key={index}>
          {it}
        </Col>
      ))
    }
  </>
}
// @ts-ignore
const MessageInfo: React.FC = () => {
  interface routerHeader {
    id: any;
  }
  const { id } = useParams<routerHeader>();
  const [nowType, setnowType] = useState<any>();
  const [messageInfo, setMessageInfo] = useState<any>({})
  const [messageRecord, setMessageRecord] = useState<any>({})
  const [listload, setlistload] = useState(true);
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [consumerList, setConsumerList] = useState([]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "消费状态",
      dataIndex: "state",
      key: "state",
      // render: (text: any) => text.name,
    },
    {
      title: "消费时间",
      dataIndex: "consumeDate",
      key: "consumeDate",
      // align: "center" as "center",
    },
    {
      title: "投递时间",
      dataIndex: "receivedDate",
    }
  ];
  useEffect(() => {
    setnowType(1)
    if(id) {
      getMsgDetail({id: id}).then((res:any) => {
        if (res.code === 200) {
          setMessageInfo(res.data)
        } else {
          message.warn(res.message)
        }
      })
      getMsgRecord({id: id}).then((res:any) => {
        res.data.consumeInfo = res.data.consumeInfo.map((item:any) => {
          item.state = item.state ? '消费成功' : '消费失败'
          item.receivedDate = moment(item.receivedDate).format('YYYY-MM-DD HH:mm:ss')
          item.consumeDate = moment(item.consumeDate).format('YYYY-MM-DD HH:mm:ss')
          return item
        })
        setConsumerList(res.data.consumeInfo)
        setMessageRecord(res.data)
        setTotal(res.data.consumeInfo.length)
        setlistload(false)
        if (res.code === 200) {
  
        } else {
          message.warn(res.message)
        }
      })
    }
  }, [id])

  return (
    <div style={{ padding: "20px"}}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>查看消息</div>
      </div>
      <Tabs
        activeKey={nowType + ""}
        onChange={(e) => {
          setnowType(e);
        }}
        style={{marginTop: "20px"}}
      >
        <Tabs.TabPane tab="消息正文" key="1"></Tabs.TabPane>
        <Tabs.TabPane tab="消息轨迹" key="2"></Tabs.TabPane>
      </Tabs>
      {
        nowType == 1?
        <div          
          style={{
            width: "100%",
          }}
        >
          <div>基本信息</div>
          <div style={{
              marginTop: "10px",
              padding: "20px",
              border: "1px solid #F1F1F1",
              borderRadius: "4px"
            }}
          >
            <div style={{              
              display:"flex",
              alignItems:"center",
              marginBottom: "14px"}}>
              <div style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "10px",
                borderRadius: "50%"}}></div>
                <div>订阅信息</div>
            </div>
            <Row>
              <DetailRender>
                <Form.Item {...formLayout} label="Message code">
                  {messageInfo.msgCode}
                </Form.Item>
                <Form.Item {...formLayout} label="Topic code">
                  {messageInfo.topicCode}
                </Form.Item>
                <Form.Item {...formLayout} label="发布方">
                  {messageInfo.publisher}
                </Form.Item>
                <Form.Item {...formLayout} label="创建时间">
                  {moment(messageInfo.receiveAt).format('YYYY-MM-DD HH:mm:ss')}
                </Form.Item>
                <Form.Item {...formLayout} label="内容类型">
                  {'Application/Json'}
                </Form.Item>
              </DetailRender>
            </Row>
          </div>
          <div style={{marginTop: "20px"}}>消息体</div>
          <div style={{
              marginTop: "10px",
              padding: "20px",
              border: "1px solid #F1F1F1",
              borderRadius: "4px"
            }}
          >
            <div style={{              
              display:"flex",
              alignItems:"center",
              marginBottom: "14px"}}>
              <div style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "10px",
                borderRadius: "50%"}}></div>
                <div>原文</div>
            </div>
            <div style={{padding: "20px", background: "#F8F8F8"}}>
              <pre>{JSON.stringify(messageInfo.body, null, 2)}</pre>
            </div>
          </div>
        </div>
        :
        <div          
          style={{
            width: "100%",
          }}
        >
          <div>消息轨迹</div>
          <div style={{
              marginTop: "10px",
              padding: "20px",
              border: "1px solid #F1F1F1",
              borderRadius: "4px"
            }}
          >
            <div style={{              
              display:"flex",
              alignItems:"center",
              marginBottom: "14px"}}>
              <div style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "10px",
                borderRadius: "50%"}}></div>
                <div>消息轨迹</div>
            </div>
            <Timeline style={{marginLeft: "15px"}}>
              <Timeline.Item color="#165DFF">
                <div>消息发布</div>
                <div>
                  <div>发布时间：{moment(messageInfo.receiveAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>发布状态：<CheckCircleOutlined style={{
                    fontSize: "14px",
                    marginRight: "5px",
                    color: "#165DFF",
                    background: "#E8F3FF",
                    border: "none",
                    borderRadius: "50%"
                  }}/>成功</div>
                </div>
              </Timeline.Item>
              <Timeline.Item color="#165DFF">
                <div>消息储存</div>
                <div>
                  <div>储存时间：{moment(messageInfo.saveAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>失效时间：{moment(messageRecord.lossDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>储存状态：<CheckCircleOutlined style={{
                    fontSize: "14px",
                    marginRight: "5px",
                    color: "#165DFF",
                    background: "#E8F3FF",
                    border: "none",
                    borderRadius: "50%"
                  }}/>成功</div>
                </div>
              </Timeline.Item>
            </Timeline>
          </div>
          <div style={{marginTop: "20px"}}>消费方信息</div>
          <div style={{
              marginTop: "10px",
              padding: "20px",
              border: "1px solid #F1F1F1",
              borderRadius: "4px"
            }}
          >
            <div style={{              
              display:"flex",
              alignItems:"center",
              marginBottom: "14px"}}>
              <div style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "10px",
                borderRadius: "50%"}}></div>
                <div>消费方信息</div>
            </div>
            <Table
              columns={columns}
              loading={listload}
              dataSource={consumerList}
              pagination={{
                total,
                pageSize: pageParam.pageSize,
                current: pageParam.page,
                showSizeChanger: true,
                showTotal: (total) => `共 ${total}条信息`,
                onChange: (page, size) => setPageParam({ page, pageSize: size }),
              }}
            />
          </div>
        </div>
      }
      <div style={{
        textAlign: "center"
      }}>
        <Button style={{
          marginTop: "30px"
        }}
        onClick={()=>{
          history.push("/topic-select")
        }}>返回</Button>
      </div>
    </div>
  )
}
export default MessageInfo;
