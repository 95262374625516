// @ts-nocheck
import styles from "../index.module.scss"
import React, { useState } from "react"
import { Tabs, Button } from 'antd';
import MyTable from '../../ResourcePort/component/MyTable'
// import { useHistory } from "react-router-dom"
import { WebAdminContext } from "../../../context/WebAdminContext";
const { TabPane } = Tabs;

const Port = () => {
  // const history = useHistory()
  const { handleClick } = React.useContext(WebAdminContext);
  const [tabKeys, settabKeys] = useState<any>("0");
  function callback(key: any) {
    // console.log(key);
    settabKeys(key)
  }

  return (
    <div className={styles['port']}>
      <Button className={styles['seeMore']} type="link" onClick={() => {
        handleClick({ key: "/resourceport", tag: "1" });
      }}>查看更多</Button>
      <Tabs defaultActiveKey="0" onChange={callback}>
        <TabPane tab="所有接口" key="0" />
        <TabPane tab="我的接口" key="1" />
      </Tabs>
      <MyTable tag="some" tabKeys={tabKeys} />
    </div>
  )
}
export default Port