// @ts-nocheck
import "../index.scss";
import { useState, useEffect } from "react";
import { Table, Button, Form, Input, message } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import moment from "moment";
import { getinterfacelist, postinterfaceremove } from "../../../api/interface";
import { useHistory } from "react-router-dom";
const MyTable = (props: any) => {
  const history = useHistory();
  const [total, setTotal] = useState<number>(0);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [form] = Form.useForm();
  const [interfaceList, setinterfaceList] = useState([]);
  const columns = [
    {
      title: "接口名称",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "系统名称",
      dataIndex: "system_name",
      align: "center" as "center",
    },
    {
      title: "创建人",
      dataIndex: "create_user_info",
      render: (text: any) => <>{text.nick_name}</>,
      align: "center" as "center",
    },
    {
      title: "创建时间",
      dataIndex: "createAt",
      render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      align: "center" as "center",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: number) =>
        text === 1 ? <span>已上线</span> : <span>已下线</span>,
      align: "center" as "center",
    },
    {
      title: "版本数量",
      dataIndex: "versions",
      render: (text: number, record: any) => (
        <a onClick={() => history.push(`/interfaceversion/${record.id}`)}>
          {text}
        </a>
      ),
      align: "center" as "center",
    },
    {
      title: "操作",
      render: (text: any, record: any) => (
        <>
          <Button type="link" onClick={() => removeInterface(record.id)}>
            删除
          </Button>
          <Button type="link" onClick={() => {
            history.push(`/resource/detail/${record.id}/${"detail"}`)
          }}>
            编辑
          </Button>
        </>
      ),
      align: "center" as "center",
    },
  ];

  const InterfaceList = useCallback(
    (page, pageSize, name?) => {
      setlistload(true);
      getinterfacelist({
        page: page,
        page_size: pageSize,
        name: name,
        self: props.tabKeys,
      }).then((res: any) => {
        if (res.code === 200) {
          let list = [];
          res.data.item?.forEach((item, index) => {
            if (index > 4 && props.tag !== "all") return "";
            list.push(item);
          });
          setinterfaceList(list);
          setTotal(res.data.total);
          setlistload(false);
        }
      });
    },
    [props.tabKeys]
  );
  const restSub = () => {
    form.resetFields();
    setPageParam({
      page: 1,
      pageSize: 10,
    });
    InterfaceList(
      pageParam.page,
      pageParam.pageSize,
      form.getFieldValue("name")
    );
  };
  const onSearch = () => {
    setPageParam({
      page: 1,
      pageSize: 10,
    });
    InterfaceList(
      pageParam.page,
      pageParam.pageSize,
      form.getFieldValue("name")
    );
  };
  const removeInterface = async (id: any) => {
    try {
      const { code } = await postinterfaceremove({ id: id.toString() });
      if (code === 200) {
        setPageParam({
          page: 1,
          pageSize: 10,
        });
        await InterfaceList(pageParam.page, pageParam.pageSize);
      }
    } catch (e) {
      // console.log(e);
      message.error(e.message);
    }
  };
  useEffect(() => {
    InterfaceList(pageParam.page, pageParam.pageSize);
    // console.log(props.tabKeys);
  }, [pageParam.page, pageParam.pageSize, props.tabKeys]);

  return (
    <div className="portlist">
      <Form
        form={form}
        layout="inline"
        onFinish={(v) => {
          // console.log(v);
        }}
        style={{ marginBottom: "20px" }}
      >
        <Form.Item label="接口名称" name="name">
          <Input className="inpt" placeholder="请输入接口名称" />
        </Form.Item>

        {/* <Form.Item label="Dp Address" name="dpAddress">
          <Input className="inpt" placeholder="请输入集合名称" />
        </Form.Item>

        <Form.Item label="创建者" name="createName">
          <Input className="inpt" />
        </Form.Item> */}

        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            icon={<SearchOutlined />}
            onClick={onSearch}
          >
            查询
          </Button>
        </Form.Item>

        <Form.Item>
          <Button htmlType="reset" icon={<ReloadOutlined />} onClick={restSub}>
            重置
          </Button>
        </Form.Item>

        {/* <Button type="primary" icon={<PlusOutlined />}>新建接口</Button> */}
      </Form>
      <Table
        columns={columns}
        dataSource={interfaceList}
        loading={listload}
        pagination={
          props.tag !== "all"
            ? false
            : {
              total,
              pageSize: pageParam.pageSize,
              current: pageParam.page,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20, 50, 100],
              // hideOnSinglePage: true,
              showTotal: (total) => `共 ${total}条记录`,
              onChange: (page, size) =>
                setPageParam({ page, pageSize: size }),
            }
        }
      />
    </div>
  );
};
export default MyTable;
