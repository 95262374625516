import {
  getClient,
  postClient,
  putClient,
  deleteClient,
} from "./requestClient";
import {
  GET_gray_scale,
  POST_gray_scale,
  POST_grayScale_strategy,
  PUT_grayScale_release,
  PUT_grayScale_state,
  DELETE_grayScale_id,
} from "./index";

//灰度详情
export const getgrayscale = async (params: any) =>
  await getClient(GET_gray_scale + `/${params}`);

//创建灰度
export const postgrayscale = async (params: any) =>
  await postClient(POST_gray_scale, params);

//创建灰度策略
export const postgrayScalestrategy = async (params: any) =>
  await postClient(POST_grayScale_strategy, params);

//发布灰度服务配置(全量发布)
export const putgrayScalerelease = async (params: any) =>
  await putClient(PUT_grayScale_release, params);

//变更灰度状态(灰度发布)
export const putgrayScalestate = async (params: any) =>
  await putClient(PUT_grayScale_state, params);

//删除灰度
export const deletegrayScale = async (params: any) =>
  await deleteClient(DELETE_grayScale_id + `/${params}`);

//删除灰度策略
export const deletegrayscalestrategy = async (params: any) =>
  await deleteClient(POST_grayScale_strategy + `/${params}`);
