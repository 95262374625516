//@ts-nocheck
import {
  Button,
  message,
  Steps,
  Form,
  Input,
  Badge,
  Divider,
  Radio,
  Modal,
  Table,
  Row,
  Select,
  Switch,
  Tooltip,
  Drawer,
  Space,
  Col,
  Result,
} from "antd";
import React, { useState, useEffect, AllHTMLAttributes, useRef } from "react";
import { authModes } from "../../../pages/Service/PublishServe/utils-data/commonData";
import ParseRequestJsonTable from "../../../pages/Service/Detail/components/parse-request-json-table";
import { PublishContext } from "@/pages/Service/PublishServe";
import { useHistory, useLocation } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import Img from "../../../assets/img/down.png";
import {
  getserviceManageAggregationquery,
  postfirstCache,
  postSecondCache,
  postthirdCache,
  postCreate,
  detailv2ServiceItem,
  posteditStart,
  geteditFirst,
  geteditSecond,
  geteditThird,
  putedit,
  getserviceManageAggregationdetail,
} from "../../../api/serviceManagement";
import style from "../Detail/index.module.css";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import DetailCard from "../../../component/DetailCard";
import AceEditor from "@/component/SchemaEditor/JSONSchemaEditor/components/AceEditor/AceEditor.js";
import RestArgInput from "../../../pages/Service/PublishServe/FrontendConfig/component/restArgInput";
const steps = [
  {
    title: "基本信息",
    content: "First-content",
  },
  {
    title: "前端服务配置",
    content: "Second-content",
  },
  {
    title: "子服务配置",
    content: "cc-content",
  },
  {
    title: "发布",
    content: "Last-content",
  },
];
const DetailRender: React.FC<any> = ({ children }) => {
  return (
    <>
      {children.map(
        (
          it:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <Col md={8} sm={24} key={index}>
            {it}
          </Col>
        )
      )}
    </>
  );
};
const AddServe: React.FC = () => {
  let timer: string | number | NodeJS.Timeout | null | undefined = null;
  // let mockJson: string | null = null;
  const mockJson = useRef("");
  let isFormatTrue = true;
  const location = useLocation();
  const { state, dispatch } = React.useContext(PublishContext);
  const { confirm } = Modal;
  const { currentStep, frontendConfig, extraButton } = state;
  const [metaJson, setMetaJson] = useState<any>(
    JSON.stringify(frontendConfig.metaJson)
  );
  console.log(frontendConfig);
  const { Step } = Steps;
  const history = useHistory();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [formSecond] = Form.useForm();
  const [formDrawer] = Form.useForm();
  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [mockJsonVal, setmockJsonVal] = useState("");
  const [jhData, setjhData] = useState([]);
  const [uuid, setuuid] = useState("");
  const [listLoad, setlistLoad] = useState(true);
  const [applyList, setapplyList] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [methodValue, setmethodValue] = useState<any>("GET");
  const [selectedRowKeys_, setSelectedRowKeys] = useState([]);
  const [saveIndex, setsaveIndex] = useState(null);
  const [dataSource, setdataSource] = useState<any>([]);
  const [total, settotal] = useState();
  // const [childData, setchildData] = useState([]);
  const [newData, setnewData] = useState({});
  const [NewData, setNewData] = useState([]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 5,
  });
  const { Option } = Select;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 8 },
  };
  useEffect(() => {
    if (location.state.type === "edit") {
      posteditStart({ id: location.state.id }).then(async (res) => {
        if (res.code === 200) {
          setuuid(res.data.uuid);
          const { data } = await geteditFirst(res.data.uuid);
          const data1 = await geteditSecond(res.data.uuid);
          const data2 = await geteditThird(res.data.uuid);
          
          form.setFields([
            {
              name: "apiname",
              value: data.apiName,
            },
            {
              name: "version",
              value: data.apiVersion,
            },
            {
              name: "description",
              value: data.apiDesc,
            },
          ]);
          formSecond.setFields([
            {
              name: "accessProtocol",
              value: data1.data.accessProtocol,
            },
            {
              name: "requestMethod",
              value: data1.data.requestMethod,
            },
            {
              name: "requestPath",
              value: data1.data.requestPath,
            },
            {
              name: "isMock",
              value: data1.data.isMock,
            },
          ]);
          setSwitchChecked(data1.data.isMock);
          setMetaJson(data1.data.metaJson);
          setValue(data.serviceLimiting.authMode);
          let arr = [];
          let brr = [];
          mockJson.current = data1.data.mockJson;
          data2.data.detailItems.map((item, index) => {
            brr.push(item.dpAddress);
            arr[index] = {
              apiName: item.apiName,
              childDpAddress: item.dpAddress,
              reqGroovy: item.reqGroovy,
              respGroovy: item.respGroovy,
            };
          });
          setSelectedRowKeys(brr);
          setNewData(arr);
        }
      });
    }
  }, []);
  useEffect(() => {
    setlistLoad(true);
    getserviceManageAggregationquery({
      ...pageParam,
    }).then((res: any) => {
      if (res.code === 200) {
        setlistLoad(false);
        setapplyList(res.data);
        settotal(res.pagination.total);
      }
    });
  }, [pageParam, isModalOpen === true]);
  const columns = [
    // {
    //   title: "序号",
    //   render: (text: any, record: any, index: number) => `${index + 1}`,
    // },
    {
      title: "API名称",
      dataIndex: "apiName",
      key: "apiName",
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress",
      key: "dpAddress",
    },

    {
      title: "后端服务类型",
      dataIndex: "connectorType",
      key: "approve_status",
    },
  ];

  const handleInputEditor = (e: { text: any; jsonData: any; format: any }) => {
    console.log(e);
    mockJson.current = e.text;
    isFormatTrue = e.format === true || e.text === "" ? true : false;
    if (!e.text === undefined || e.format !== true) return;
    window["mockJson"] = e.text;
    // mockJson = e.text;
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys_,
    onSelect: (record: any, selected: any, all: any) => {
      console.log(record, selected, all);

      let arr = [];
      if (selected) {
        // 如果选中,加入selectedRowKeys
        if (selectedRowKeys_?.length) {
          arr = Array.from(new Set([...selectedRowKeys_, record.dpAddress]));
        } else {
          arr = [record.dpAddress];
        }
        let onceData = {}
        onceData.apiName = record.apiName
        onceData.childDpAddress = record.dpAddress
        onceData.reqGroovy = ""
        onceData.respGroovy = ""
        setNewData([onceData,...NewData]);
      } else {
        // 如果未选中,删除这一项数据
        arr = selectedRowKeys_.filter((item) => item !== record.dpAddress);
        let brr = NewData.filter(item=>item.childDpAddress!==record.dpAddress)
        setNewData(brr);
      }
      setSelectedRowKeys(arr);
    },
    // onSelectAll: (selected:any, selectedRows:any, changeRows:any) => {
    //   console.log(selectedRows,selected,changeRows);

    //   let arr = selectedRows.map((item:any) => item.dpAddress);
    //   setSelectedRowKeys(arr);
    // },
  };
  // useEffect(() => {
  //   console.log(selectedRowKeys_);
  // }, [selectedRowKeys_]);
  const next = () => {
    if (current === 0) {
      form.validateFields().then((data) => {
        postfirstCache({
          apiName: data.apiname,
          apiVersion: data.version,
          apiDesc: data.description,
          serviceLimiting: {
            authMode: value,
          },
        }).then((res) => {
          if (res.code === 200) {
            setuuid(res.data.uuid);
            setCurrent(current + 1);
          }
        });
      });
    } else if (current === 1) {
      formSecond.validateFields().then((data) => {
        postSecondCache({
          uuid: uuid,
          metaJson: metaJson,
          mockJson: mockJson.current,
          isMock: switchChecked,
          accessProtocol: data.accessProtocol,
          requestMethod: data.requestMethod,
          requestPath: data.requestPath,
        }).then((res) => {
          if (res.code === 200) {
            if (location.state.type === "create") {
              geteditSecond(uuid).then((res) => {
                setjhData(res.data);
              });
            }
            setCurrent(current + 1);
          }
        });
      });
    } else if (current === 2) {
      if (NewData.length <= 0) {
        message.error("请添加子服务配置");
      } else {
        // if (location.state.type === "create") {
        postthirdCache({
          items: NewData,
          uuid: uuid,
        }).then((res) => {
          if (res.code === 200) {
            if (location.state.type === "create") {
              postCreate({
                uuid: uuid,
              }).then((res) => {
                setCurrent(current + 1);
                message.success("创建成功!");
              });
            } else {
              putedit({
                id: location.state.id,
                uuid: uuid,
              }).then((res) => {
                setCurrent(current + 1);
                message.success("修改成功!");
              });
            }

            // message.success("发布成功!")
          }
        });
        // } else {

        // }
      }
    } else {
    }
  };
  const RemoveDp = (data, index) => {
    confirm({
      title: `确认删除子服务${data.apiName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        let arr = [...NewData];
        let brr = [...selectedRowKeys_];

        let a = arr.filter(
          (item) => item.childDpAddress !== data.childDpAddress
        );
        let b = brr.filter((item) => item !== data.childDpAddress);
        setNewData(a);
        setSelectedRowKeys(b);
      },
      onCancel() {},
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showDrawer = (data: any, index: any) => {
    detailv2ServiceItem({ dpAddress: data.childDpAddress }).then((res) => {
      setdataSource(res.data.serviceManage);
    });
    if (location.state.type !== "create") {
      getserviceManageAggregationdetail(location.state.address).then((res) => {
        setjhData(res.data);
      });
    }

    setnewData(data);
    setsaveIndex(index);
    setOpen(true);
    formDrawer.setFields([
      {
        name: "qqsp",
        value: NewData[index].reqGroovy,
      },
      {
        name: "xysp",
        value: NewData[index].respGroovy,
      },
    ]);
  };

  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open === false) {
      formDrawer.resetFields();
    }
  }, [open]);
  const onSave = () => {
    let arr = [...NewData];
    arr[saveIndex].reqGroovy = formDrawer.getFieldValue("qqsp");
    arr[saveIndex].respGroovy = formDrawer.getFieldValue("xysp");
    setNewData(arr);
    setOpen(false);
  };
  const onInputChange = (e: any) => {
    let value = e.currentTarget.value || "";
    let reg = /{([a-zA-Z0-9*%+-_.]+)}/g;
    let arr = [];
    let matched = null;
    while ((matched = reg.exec(value)) !== null) {
      arr.push(matched[1]);
    }
    let _frontendConfig = JSON.parse(JSON.stringify(frontendConfig));
    let json = JSON.parse(_frontendConfig.metaJson) || {};
    let new_arr: any[] = [];
    arr.map((item) => {
      new_arr.push({
        id: Number(Math.random().toString().substr(3, 3) + Date.now()).toString(
          36
        ),
        paramKey: item,
        paramMask: "",
        paramName: "",
        paramRequired: "必须",
        paramType: "string",
      });
    });
    json["APIRequestStructure"] = {
      ...(json["APIRequestStructure"] || {}),
      path: new_arr,
    };
    // json['APIRequestStructure']['path'] = new_arr
    _frontendConfig.metaJson = JSON.stringify(json);
    timer && clearTimeout(timer);
    timer = setTimeout(() => setMetaJson(_frontendConfig.metaJson), 800);
    // dispatch && dispatch({
    //   type: 'EDIT_PAGE_INFO', payload: {
    //     ...state,
    //     frontendConfig: _frontendConfig
    //   }
    // })
  };
  useEffect(() => {
    return () => {
      timer = null;
      mockJson.current = null;
    };
  }, []);
  const stepRender = (data: any) => {
    switch (data) {
      case 0:
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Badge color="#165DFF" text="基本信息" />
            <Form {...layout} form={form}>
              <Form.Item
                label="聚合API名称"
                name="apiname"
                rules={[{ required: true, message: "请输入聚合API名称" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="版本号"
                name="version"
                rules={[{ required: true, message: "请输入版本号" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="API说明"
                name="description"
                rules={[{ required: true, message: "请输入API说明" }]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Divider dashed={true}></Divider>
            <Badge color="#165DFF" text="鉴权模式" />
            <div style={{ width: "100%", textAlign: "center" }}>
              <Radio.Group
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
              >
                <Radio value={1}>Public</Radio>
                <Radio value={2}>Private</Radio>
              </Radio.Group>
            </div>
          </div>
        );
      case 1:
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Form {...layout} form={formSecond}>
              <div style={{ marginTop: 30 }}>
                <DetailCard title={"API接入配置"}>
                  <div className="frontend-config-layout">
                    <div className="frontend-config-box">
                      <Form.Item
                        label={"接入协议"}
                        name="accessProtocol"
                        rules={[{ required: true, message: "请选择接入协议" }]}
                      >
                        <Select>
                          <Option value="HTTP">http</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="frontend-config-box">
                      <Form.Item
                        label={"请求方式"}
                        name="requestMethod"
                        rules={[{ required: true, message: "请选择请求方式" }]}
                      >
                        <Select
                          onChange={(e) => {
                            setmethodValue(e);
                          }}
                        >
                          <Select.Option value="POST">post</Select.Option>
                          <Select.Option value="GET">get</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="frontend-config-box tscl">
                      <Form.Item
                        label={"请求路径"}
                        name="requestPath"
                        rules={[{ required: true, message: "请输入请求路径" }]}
                      >
                        <Input
                          onChange={onInputChange}
                          addonAfter={
                            <div>
                              {
                                "请求Path可以包含请求参数，用{ }标识，比如/user/query/{areaCode}/{userId}，支持 * % + - _ . 等特殊字符"
                              }
                            </div>
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </DetailCard>
                <RestArgInput
                  typeer={"jh"}
                  initialValue={metaJson}
                  metaJson={metaJson}
                  method={methodValue}
                  protocolType={frontendConfig.protocolIn}
                  appApiId={location.state.id}
                  onChange={(val: any) => setMetaJson(val)}
                />
                <DetailCard title={"返回设置"}>
                  <div className="callback-layout">
                    <Form.Item
                      label={
                        <div>
                          是否开启Mock
                          <Tooltip
                            title={
                              "启用模拟返回，将直接将Mock返回数据作为出参进行响应，会在请求后端服务"
                            }
                          >
                            <QuestionCircleOutlined
                              style={{ marginLeft: "7px", color: "#165DFF" }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="isMock"
                      initialValue={true}
                      // rules={[{ required: true, message: '请选择开启Mock' }]}
                    >
                      <Switch
                        checked={switchChecked}
                        onChange={(value) => setSwitchChecked(value)}
                      />
                    </Form.Item>
                    <Form.Item label={"Mock返回数据"} name="mockJson">
                      <div>
                        <AceEditor
                          data={mockJson.current || ""}
                          mode="json"
                          onChange={handleInputEditor}
                        />
                      </div>
                    </Form.Item>
                  </div>
                </DetailCard>
              </div>
            </Form>
          </div>
        );
      case 2:
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <div>
              <Badge color="#165DFF" text="基本信息" />
              <div
                style={{
                  marginTop: "12px",
                  width: "100%",
                  background: "#CDDBFB",
                  textAlign: "center",
                  color: "#606F90",
                  height: "60px",
                  lineHeight: "60px",
                  fontSize: "16px",
                  fontWeight: "500",
                  border: "1px solid #CDDBFB",
                }}
              >
                聚合服务test1
              </div>
              <div style={{ textAlign: "center", verticalAlign: "middle" }}>
                <img src={Img} alt="" />
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  border: "1px dashed #CDDBFB",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#606F90",
                  }}
                >
                  子服务
                </div>
                <div style={{ width: "100%" }}>
                  {console.log(NewData)}
                  {NewData.map((item, index) => {
                    return (
                      <div
                        style={{
                          background: "#F1F4F9",
                          display: "flex",
                          borderRadius: "4px",
                          justifyContent: "space-between",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        <div>
                          <span>{item.apiName}</span>
                          {item.reqGroovy !== "" && (
                            <span
                              style={{
                                display: "inline-block",
                                color: "white",
                                background: "#227AFF",
                                padding: "4px",
                                marginLeft: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              请求适配
                            </span>
                          )}
                          {item.respGroovy !== "" && (
                            <span
                              style={{
                                display: "inline-block",
                                color: "white",
                                background: "#227AFF",
                                padding: "4px",
                                marginLeft: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              响应适配
                            </span>
                          )}
                        </div>
                        <div>
                          <a
                            onClick={() => {
                              showDrawer(item, index);
                            }}
                          >
                            配置
                          </a>
                          <a
                            style={{ color: "#666666", marginLeft: "10px" }}
                            onClick={() => {
                              RemoveDp(item, index);
                            }}
                          >
                            删除
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Drawer
                  title="子服务配置"
                  placement="right"
                  onClose={onClose}
                  visible={open}
                  width={1050}
                  extra={
                    <Space>
                      <Button onClick={onClose}>取消</Button>
                      <Button type="primary" onClick={onSave}>
                        保存
                      </Button>
                    </Space>
                  }
                >
                  <div
                    style={{ display: "flex", width: "100%", height: "100%" }}
                  >
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                        borderRight: "1px solid #F1F1F1",
                        padding: "20px",
                      }}
                    >
                      <div
                        className={style["ltTittle"]}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>聚合服务配置</div>
                        <div style={{ fontSize: "13px", color: "#4E5969" }}>
                          请求方式：{jhData.requestMethod}
                        </div>
                      </div>
                      <div className={style["borderWith"]}>
                        <div style={{ marginTop: "14px" }}>
                          <Badge color={"#165DFF"} text={"入参设置"} />
                          <ParseRequestJsonTable
                            method={jhData?.requestMethod || jhData?.method}
                            type={"request"}
                            jsonData={(() => {
                              let param: any = {};
                              try {
                                param = JSON.parse(jhData?.metaJson || "{}");
                              } catch (error) {}
                              return param?.APIRequestStructure || {};
                            })()}
                            protocolIn={"rest"}
                          />
                        </div>
                        <div style={{ marginTop: "14px" }}>
                          <Badge color={"#165DFF"} text={"出参设置"} />
                          <ParseRequestJsonTable
                            method={jhData?.requestMethod || jhData?.method}
                            type={"response"}
                            jsonData={(() => {
                              let param: any = {};
                              try {
                                param = JSON.parse(jhData?.metaJson || "{}");
                              } catch (error) {}

                              return param?.APIResponseStructure || {};
                            })()}
                            protocolIn={jhData.accessProtocol}
                          />
                        </div>
                      </div>
                      <div
                        className={style["ltTittle"]}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "14px",
                        }}
                      >
                        <div>子服务出入参</div>
                        <div style={{ fontSize: "13px", color: "#4E5969" }}>
                          请求方式：{dataSource.requestMethod}
                        </div>
                      </div>
                      <div className={style["borderWith"]}>
                        <div style={{ marginTop: "14px" }}>
                          <Badge color={"#165DFF"} text={"入参设置"} />
                          <ParseRequestJsonTable
                            method={
                              dataSource?.requestMethod || dataSource?.method
                            }
                            type={"request"}
                            jsonData={(() => {
                              let param: any = {};
                              try {
                                param = JSON.parse(
                                  dataSource?.metaJson || "{}"
                                );
                              } catch (error) {}
                              return param?.APIRequestStructure || {};
                            })()}
                            protocolIn={"rest"}
                          />
                        </div>
                        <div style={{ marginTop: "14px" }}>
                          <Badge color={"#165DFF"} text={"出参设置"} />
                          <ParseRequestJsonTable
                            method={
                              dataSource?.requestMethod || dataSource?.method
                            }
                            type={"response"}
                            jsonData={(() => {
                              let param: any = {};
                              try {
                                param = JSON.parse(
                                  dataSource?.metaJson || "{}"
                                );
                              } catch (error) {}

                              return param?.APIResponseStructure || {};
                            })()}
                            protocolIn={dataSource.accessProtocol}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                        padding: "20px",
                      }}
                    >
                      <div style={{ fontSize: "18px", fontWeight: "400" }}>
                        子服务配置
                      </div>
                      <div style={{ marginTop: "10px", padding: "10px" }}>
                        <Form form={formDrawer}>
                          <Form.Item label="API名称">
                            {newData.apiName}
                          </Form.Item>
                          <Form.Item label="DataAddress">
                            {newData.childDpAddress}
                          </Form.Item>
                          <Form.Item label="自定义请求协议适配" name="qqsp">
                            <TextArea rows={4} placeholder="请输入" />
                          </Form.Item>
                          <Form.Item label="自定义响应协议适配" name="xysp">
                            <TextArea rows={4} placeholder="请输入" />
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Drawer>
                <Button
                  style={{ marginTop: "10px", width: "100%" }}
                  onClick={showModal}
                >
                  + 关联子服务
                </Button>
                <Modal
                  title="子服务选择"
                  visible={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  width={966}
                >
                  <Table
                    loading={listLoad}
                    columns={columns}
                    style={{ marginTop: "10px" }}
                    rowKey={(r) => r.dpAddress}
                    dataSource={applyList}
                    rowSelection={rowSelection}
                    pagination={{
                      total: total,
                      pageSize: pageParam.pageSize,
                      current: pageParam.page,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      onChange: (page, size) =>
                        setPageParam({ page, pageSize: size }),
                    }}
                  />
                </Modal>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Result
              status="success"
              title="发布成功"
              subTitle="您的服务已成功发布，可前往列表查看"
              extra={[
                <Button
                  type="primary"
                  onClick={() => {
                    history.push("/aggregationserver");
                  }}
                >
                  立即前往
                </Button>,
              ]}
            />
          </div>
        );
    }
  };
  return (
    <div style={{ padding: "20px 250px" }}>
      <Steps current={current}>
        {steps.map((item: any) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{stepRender(current)}</div>
      <div className="steps-action">
        <div style={{ width: "100%", textAlign: "center" }}>
          {current < steps.length - 1 && (
            <Button
              style={{ marginRight: "20px" }}
              onClick={() => {
                history.go(-1);
              }}
            >
              取消
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              下一步
            </Button>
          )}
          {/* {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AddServe;
