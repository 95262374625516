//@ts-nocheck
import { useEffect, useState } from "react";
import { Checkbox, Table, Button, message, Modal, Form, Select } from "antd";
import { postaddpermission, postbindUserPermissionGroup } from "../../../api/permissionGroup";
import { getRolelist } from "../../../api/userlist"
export default function RoleList(props) {
  const [dataSource1, setdataSource] = useState()
  const [columns1, setcolumns] = useState()
  const [ccType, setccType] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const columnWidthArray = ["10%", "10%", "30%", "25%", "25%"]
  const [selectLsit, setselectLsit] = useState([])
  const [bindGroup,setbindGroup] = useState([])
  const { Option } = Select;
  //编码Data, 转成表格格式
  const encodeData = (data, i = 0, addData = {}) => {
    let ret = [];
    // eslint-disable-next-line array-callback-return
    data?.map((item) => {
      let next = Object.assign({ [i]: item.permission_id }, addData);
      if (item.childPermissions) {
        ret.push(...encodeData(item.childPermissions, i + 1, next));
      } else {
        ret.push(next);
      }
    });
    return ret;
  };

  //获取最深的深度以确定列数
  const getMaxDepth = (data) => {
    let max = 1;
    // eslint-disable-next-line array-callback-return
    data?.map((item) => {
      if (item.childPermissions) {
        let childDepth = getMaxDepth(item.childPermissions);
        if (max < 1 + childDepth) max = 1 + childDepth;
      }
    });
    return max;
  };

  const generateData = (list) => {
    //转换格式后生成的表格数据
    const dataSource = encodeData(list);
    //最大深度, 用于确认表格列数
    const max = getMaxDepth(list);
    const columns = [];
    for (let i = 0; i < max; i++) {
      columns.push({
        key: i,
        dataIndex: i,
        title: i,
        width: columnWidthArray[i],
        render: (t, r, rowIndex) => {
          const obj = {
            children: t ? getCheckBox(t, r, list) : "",
            props: {},
          };
          //列合并
          if (r[i] === undefined) {
            obj.props.colSpan = 0;
          } else if (r[i + 1] === undefined && i < max - 1) {
            obj.props.colSpan = max - i;
          }
          //行合并
          if (
            dataSource[rowIndex - 1] &&
            dataSource[rowIndex - 1][i] === dataSource[rowIndex][i]
          ) {
            obj.props.rowSpan = 0;
          } else {
            let rowSpan = 1;
            for (
              let j = 1;
              dataSource[rowIndex + j] &&
              dataSource[rowIndex + j][i] === dataSource[rowIndex][i];
              j++
            ) {
              rowSpan++;
            }
            obj.props.rowSpan = rowSpan;
          }
          return obj;
        },
      });
    }
    setdataSource(dataSource)
    setcolumns(columns)
  };

  //利用id获取整个对象
  const mapData = (obj, id, list) => {
    list.map(item => {
      if (item.permission_id === id) {
        obj = item
      } else if (item.childPermissions) {
        obj = mapData(obj, id, item.childPermissions)
      }
    })
    return obj
  }

  //半选
  const halfchecked = (list) => {
    list.map(item => {
      if (item.childPermissions) {
        let flag = item.childPermissions.some(item => item.IsObtain || item.halfchecked)
        item.halfchecked = flag
        halfchecked(item.childPermissions)
      }
    })
  }

  //反选
  const reversecheck = (list) => {
    list.map(item => {
      if (item.childPermissions) {
        let flag = item.childPermissions.every(item => item.IsObtain)
        item.IsObtain = flag
        reversecheck(item.childPermissions)
      }
    })
  }

  //全选
  const checkAllbtn = (checked, data) => {
    data.map(item => {
      item.IsObtain = checked
      if (item.childPermissions) {
        checkAllbtn(checked, item.childPermissions)
      }
    })
  }

  //点击选择框
  const checkedItem = (data, checked, id) => {
    return data.map(item => {
      if (item.permission_id === id) {
        item.IsObtain = checked
        //全选
        if (item.childPermissions) {
          checkAllbtn(checked, item.childPermissions)
        }
        if (item.permission_pid !== "null") {
          //反选/半选
          reversecheck(props.dataList)
          halfchecked(props.dataList)
        }
      }
      //递归
      if (item.childPermissions) {
        checkedItem(item.childPermissions, checked, id)
        //反选/半选
        reversecheck(props.dataList)
        halfchecked(props.dataList)
      }
      //反选/半选
      reversecheck(props.dataList)
      halfchecked(props.dataList)
      return item
    })
  }

  //渲染选择框
  const getCheckBox = (t, r, data) => {
    let obj = {}
    //页面加载的时候调用一次
    halfchecked(props.dataList)
    reversecheck(props.dataList)
    return (
      <Checkbox
        checked={mapData(obj, t, props.dataList).IsObtain}
        indeterminate={mapData(obj, t, props.dataList).IsObtain ? false : mapData(obj, t, props.dataList).halfchecked}
        onChange={(e) => {
          setccType(!ccType)
          checkedItem(data, e.target.checked, mapData(obj, t, props.dataList).permission_id)
          console.log(props.dataList);
          props.setdataList(props.dataList)
        }}
      >
        {mapData(obj, t, props.dataList).permission_name}
      </Checkbox>
    );
  };

  useEffect(() => {
    generateData(props.dataList)
  }, [props.dataList, ccType]);

  //获取选择的数据id
  const getData = (list, arr) => {
    list.map(item => {
      if (item.IsObtain || item.halfchecked) {
        arr.push(item.permission_id)
      }
      if (item.childPermissions) {
        getData(item.childPermissions, arr)
      }
    })
  }

  //点击保存
  const addPermission = async () => {
    const arr = []
    getData(props.dataList, arr)
    const res = await postaddpermission({
      id: props.id,
      permission_id_list: arr,
    })
    console.log(res);
    if (res.data.success) {
      message.success("操作成功")
    }
  }
  return (
    <div>
      <div
        style={{
          position: "sticky",
          textAlign: "right",
          height: "40px",
          lineHeight: "40px",
        }}
      >
        <Button type="primary" onClick={addPermission}>
          保存
        </Button>
      </div>
      <div>
        <Table
          bordered
          pagination={false}
          scroll={{ y: true }}
          showHeader={false}
          dataSource={dataSource1}
          columns={columns1}
        />
      </div>
    </div>
  )
}
