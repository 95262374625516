import React, { useState, useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import {
  Input,
  Modal,
  Form,
  message,
  Tree,
  Menu,
  Dropdown,
  Cascader,
} from "antd";
import {
  postCategory,
  getCategorylist,
  postCategorymodify,
  getCategorydelete,
} from "../../../api/category";
const MenuList: React.FC<any> = (props) => {
  const { TreeNode } = Tree;
  const [form] = Form.useForm();
  const [formLate] = Form.useForm();
  const [visible, setvisible] = useState(false);
  const [dialogVisible, setdialogVisible] = useState(false);
  const [titleName, settitleName] = useState("");
  const [pageParams, setpageParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const [bjId, setbjId] = useState("");
  const [menuLsit, setmenuLsit] = useState([]);
  //创建类目
  const creatMenu = () => {
    setvisible(true);
    settitleName("创建类目");
    form.resetFields();
    form.setFieldsValue({
      parentId: [],
    });
  };
  //编辑类目
  const compile = (id: any, name: string) => {
    setbjId(id);
    setvisible(true);
    settitleName("编辑类目");
    form.setFieldsValue({
      name: name,
      parentId: getFathersId(id, menuLsit) ? getFathersId(id, menuLsit) : [],
    });
  };

  //操作渲染
  const menu = (id: any, name: string) => (
    <Menu>
      <Menu.Item onClick={() => compile(id, name)}>
        <a href="">
          <i className="iconfont icon-bianji"></i>
          <span style={{ marginLeft: "6px" }}>编辑类目</span>
        </a>
      </Menu.Item>
      <Menu.Item onClick={() => Remove(id, name)}>
        <a href="">
          <i className="iconfont icon-shanchu1"></i>
          <span style={{ marginLeft: "6px" }}>删除类目</span>
        </a>
      </Menu.Item>
      <Menu.Item onClick={() => createChlid(id, name)}>
        <a href="">
          <i className="iconfont icon-shanchu1"></i>
          <span style={{ marginLeft: "6px" }}>创建子类目</span>
        </a>
      </Menu.Item>
    </Menu>
  );
  const createChlid = (id: any, name: any) => {
    setdialogVisible(true);
    setbjId(id);
  };
  const Remove = (id: any, name: string) => {
    getCategorydelete({ id: id })
      .then((res) => {
        if (res.code === 200) {
          message.success("删除成功");
          categoryList(1, 100);
        }
      })
      .catch(() => {
        Modal.warning({
          title: "提示",
          content: "该类目仍有系统/平台关联，请确认系统/平台下线后再行删除",
          okText: "确认",
        });
      });
  };
  // 创建or编辑节点
  const handleOk = () => {
    form.validateFields().then(async (res) => {
      if (titleName === "创建类目") {
        const { code } = await postCategory({
          name: form.getFieldValue("name"),
          parentId:
            form.getFieldValue("parentId")[
            form.getFieldValue("parentId").length - 1
            ],
        });
        if (code === 200) {
          setvisible(false);
          message.success("创建成功");
          categoryList(1, 100);
        }
      } else {
        const { code } = await postCategorymodify({
          name: form.getFieldValue("name"),
          parentId:
            form.getFieldValue("parentId").length > 0
              ? form.getFieldValue("parentId")[
              form.getFieldValue("parentId").length - 1
              ]
              : "",
          id: bjId,
        });
        if (code === 200) {
          setvisible(false);
          categoryList(1, 100);
        }
      }
    });
  };
  const handleCancel = () => {
    setvisible(false);
  };
  //递归菜单渲染方法
  const menuMethod = (data: any) => {
    return data?.map((item: any) => {
      if (item.subCategories == null) {
        return (
          <TreeNode
            key={item.id}
            data={item.name}
            style={{ width: "240px", marginTop: "15px" }}
            title={
              <div style={{ display: "flex", position: "relative" }}>
                <i className="iconfont icon-Partition-copy"></i>
                <div style={{ marginLeft: "14px" }}>{item.name}</div>
                <Dropdown
                  overlay={() => menu(item.id, item.name)}
                  trigger={["click"]}
                >
                  <i
                    onClick={(e) => e.preventDefault()}
                    className="iconfont icon-diandiandian"
                    style={{ position: "absolute", right: "0" }}
                  ></i>
                </Dropdown>
              </div>
            }
          >
            {/* {React.createElement(Icon[item.icon])} */}
            {/* <span>{item.name}4444</span> */}
          </TreeNode>
        );
      } else {
        return (
          <TreeNode
            style={{ width: "240px", marginTop: "15px" }}
            key={item.id}
            data={item.name}
            title={
              <div style={{ display: "flex" }}>
                <i className="iconfont icon-Partition-copy"></i>
                <div style={{ marginLeft: "14px" }}>
                  {item.name}({item.subCategories.length})
                </div>
                <Dropdown
                  overlay={() => menu(item.id, item.name)}
                  trigger={["click"]}
                >
                  <i
                    className="iconfont icon-diandiandian"
                    style={{ position: "absolute", right: "4px" }}
                  ></i>
                </Dropdown>
              </div>
            }
          // onClick={(e) => {
          // handleClick(e);
          // }}
          //   selectedKeys={[current]}
          >
            {menuMethod(item.subCategories)}
          </TreeNode>
        );
      }
    });
  };
  //树形菜单
  const categoryList = useCallback(async (page: any, pageSize: any) => {
    const { code, data } = await getCategorylist({
      page: page,
      page_size: pageSize,
    });
    if (code === 200) {
      setmenuLsit(data);
    }
  }, []);
  //拖动节点触发的事件
  const onDragEnter = (info: any) => { };
  //松开鼠标触发的事件
  const onDrop = async (info: any) => {
    const { code } = await postCategorymodify({
      id: info.dragNode.key,
      name: info.dragNode.data,
      parentId: info.node.key,
    });
    if (code === 200) {
      categoryList(1, 100);
    }
    console.log(info);
  };
  const onChange = (value: any, selectedOptions: any) => {
    console.log(value, selectedOptions);
  };
  //删除child为null的子节点
  const childDelete = (data?: any) => {
    let data1 = data ? data : [];
    data1?.map((item: any) => {
      if (item.subCategories !== null) {
        childDelete(item.subCategories);
      } else {
        delete item.subCategories;
      }
    });
    let b: any = { id: "", name: "无", parentId: "" };
    // setCascaderlist([b,...data])
    return [b, ...data1];
  };

  //选择tree节点
  const onSelect = (info: any, selectedKeys: any) => {
    // console.log(info, selectedKeys);
    props.getdata(info.toString(), selectedKeys.node.data);
    // let parentNode = getParentTitle(info[0], menuLsit)
    // console.log(info[0]);
  };
  const getFathersId = (id: any, data: any, props = "id") => {
    var arr: any[] = [];
    const rev = (data: any, IDS: any) => {
      for (var i = 0, length = data.length; i < length; i++) {
        const node = data[i];
        if (node[props] === IDS) {
          arr.unshift(node[props]);
          return true;
        } else {
          if (node.subCategories && node.subCategories.length) {
            if (rev(node.subCategories, IDS)) {
              arr.unshift(node[props]);
              return true;
            }
          }
        }
      }
      return false;
    };
    rev(data, id);
    arr.pop();
    return arr;
  };
  const handleCancel_ = () => {
    setdialogVisible(false);
  };
  const handleOk_ = async () => {
    const { code } = await postCategory({
      name: formLate.getFieldValue("name"),
      parentId: bjId
    })
    if (code === 200) {
      setdialogVisible(false);
      message.success("添加成功")
      categoryList(1, 100);
    }

  };
  useEffect(() => {
    setpageParams({
      page: 1,
      pageSize: 10,
    });
    categoryList(pageParams.page, pageParams.pageSize);
    // console.log(childDelete(menuLsit));
  }, [categoryList, pageParams.page, pageParams.pageSize]);
  return (
    <div className={styles["menuCss"]}>
      <div className={styles["title"]} style={{ fontSize: "16px" }}>
        <i
          className="iconfont icon-Profile"
          style={{ width: "25px", height: "25px", marginLeft: "6px" }}
        ></i>
        <span style={{ marginLeft: "8px", fontWeight: "700" }}>类目管理</span>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Input
          placeholder="请输入类目名称"
          className={styles["iptIcon"]}
        ></Input>

        <Tree
          draggable
          blockNode
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          onSelect={onSelect}
          style={{ marginTop: "15px", display: "flex" }}
          defaultExpandAll={true}
        >
          {/* <div style={{display:'flex'}}> */}
          {menuMethod(menuLsit)}
          {/* </div> */}
        </Tree>
        <div className={styles["create"]} onClick={creatMenu}>
          +&nbsp;创建类目
        </div>
        {/* <Categorycascader data={menuLsit}/> */}
        <Modal
          title={titleName}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="确定"
          cancelText="取消"
        >
          <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
            <Form.Item
              label="类目名称"
              name="name"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="上级类目" name="parentId">
              <Cascader
                // defaultValue={["无"]}
                changeOnSelect
                options={childDelete(menuLsit)}
                onChange={onChange}
                placeholder=""
                fieldNames={{
                  label: "name",
                  value: "id",
                  children: "subCategories",
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={"创建子类目"}
          visible={dialogVisible}
          onOk={handleOk_}
          onCancel={handleCancel_}
          okText="确定"
          cancelText="取消"
        >
          <Form
            form={formLate}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item name="name" label="类目名称">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default MenuList;
