import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Space,
  Badge,
  Form,
  Input,
  Upload,
  message,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  getmanagegetInstance,
  postgrantauthorization,
  postcentralInterconnectionupload, 
} from "../../../api/interconnection";
import Tools from "../../../util/tools";
const ApplyRender: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [instance, setinstance] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [fileAddress, setfileAddress] = useState("");
  const [fileName,setfileName] = useState("")
  
  const props: any = {
    onChange: (info: any) => {
      console.log(info);
      
      setLoading(true);
      if (info.file.status === "done") {
        setfileName(info.file.name)
        setfileAddress(info.file.response.data);
        form.setFieldsValue({
          file:info.file.response.data
        })
        // setLoading(false);
      }
    },
    onRemove:()=>{
      // setLoading(true);
      setfileAddress('')
      form.setFieldsValue({
        file:""
      })
    },
    beforeUpload:(file:any)=>{
      // console.log(file);
      
    }
  };
  // useEffect(()=>{
  //   console.log(fileAddress);
    
  // },[fileAddress])
  useEffect(() => {
    getmanagegetInstance().then((res) => {
      setinstance(res.data);
    });
  }, []);
  const applyMethod = () => {
    form.validateFields().then((data) => {
      postgrantauthorization({
        blockchainCode: instance.instanceCode,
        // blockchain_name: instance.instanceName,
        applyPhone: form.getFieldValue("phone"),
        applyContacts: form.getFieldValue("name"),
        applyReason: form.getFieldValue("reason"),
        replyBlockchainCode: state.code,
        replyBlockchainName: state.name,
        direction: 0,
        fileAddress:window.location.host+fileAddress,
        fileName:fileName
      }).then((res: any) => {
        if (res.code === 200) {
          message.success(res.data);
          history.go(-1);
        }else{
          message.error(res.message)
        }
      });
    });
  };

  return (
    <div style={{ background: "#f7f8fa" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push("/instancemanagement");
            }}
          >
            互联申请
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>实例申请</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "#FFF", marginTop: "17px" }}>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>实例申请</div>
        </div>
        <div>
          <div style={{ padding: "40px 200px" }}>
            <Badge color="#165DFF" text="基本信息" />
            <div style={{ padding: "20px 200px" }}>
              <div style={{ textAlign: "center" }}>
                <span>
                  <span style={{ color: "#86909C" }}>申请方实例名称：</span>
                  <span style={{ color: "#4E5969" }}>
                    {instance.instanceName}
                  </span>
                </span>
                <span style={{ marginLeft: "40px" }}>
                  <span style={{ color: "#86909C" }}>申请方实例编码：</span>
                  <span style={{ color: "#4E5969" }}>
                    {instance.instanceCode}
                  </span>
                </span>
              </div>
              <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                style={{ marginTop: "20px" }}
              >
                <Form.Item
                  name="name"
                  label="申请方联系人"
                  rules={[
                    { required: true, message: "请输入申请授权的联系人姓名" },
                  ]}
                >
                  <Input placeholder="请输入申请授权的联系人姓名" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="联系方式"
                  rules={[
                    { required: true, message: "请输入联系方式", max: 11 },
                    {
                      pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                      message: "请输入正确的手机号",
                    },
                  ]}
                >
                  <Input placeholder="请输入申请授权的联系人方式，11位手机号或区号-XXXXXXXX1" />
                </Form.Item>
                <div>
                  <Form.Item
                    name="file"
                    label="上传附件"
                    rules={[
                      { required: true, message: "请上传文件" },
                    ]}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Upload
                        {...props}
                        maxCount={1}
                        accept={".pdf"}
                        action={"/api/centralInterconnection/fileUpload"}
                        headers={{
                          Authorization: `Bearer ${Tools.getStorage(
                            "__token__"
                          )}`,
                        }}
                      >
                        <Button icon={<UploadOutlined />}>
                          上传文件
                        </Button>
                      </Upload>
                      <a
                        href="/api/centralInterconnection/downLoad"
                        style={{ marginLeft: "6px" }}
                      >
                        下载模板
                      </a>
                    </div>
                    <div style={{ color: "#86909C", marginTop: "10px" }}>
                      注: 请上传授权证明文件，附件仅支持pdf
                    </div>
                  </Form.Item>
                </div>

                <Form.Item name="reason" label="申请理由">
                  <TextArea rows={4} placeholder="请输入申请理由" />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        {/* <Divider/> */}
        <div>
          <div style={{ padding: "40px 200px" }}>
            <Badge color="#165DFF" text="申请内容" />
            <div style={{ padding: "20px 200px", textAlign: "center" }}>
              <span>
                <span style={{ color: "#86909C" }}>实例名称：</span>
                <span style={{ color: "#4E5969" }}>{state?.name}</span>
              </span>
              <span style={{ marginLeft: "40px" }}>
                <span style={{ color: "#86909C" }}>实例编码：</span>
                <span style={{ color: "#4E5969" }}>{state?.code}</span>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <Space>
            <Button
              onClick={() => {
                history.push(`/instancemanagement`);
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={applyMethod}>
              确认
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};
export default ApplyRender;
