// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { Card, Avatar, Button, Modal, Tooltip, Empty } from "antd"
import { ExclamationCircleOutlined } from '@ant-design/icons'
import CreateSystem from "./createSystem";
import systemLogo from '../../../assets/img/systemLogo.png'
import platformLogo from '../../../assets/img/platformLogo.png'
import EditSystem from '../EditSystem'
import { WebAdminContext } from "../../../context/WebAdminContext";
import { getSystemPlatformlist } from '../../../api/scenario'
import './index.scss'
const { confirm } = Modal;

export default function Index(props: any) {
  const { handleClick } = React.useContext(WebAdminContext);
  const systemRef = useRef<any>()
  const editRef = useRef<any>()
  const [dataSouse, stedataSouse] = useState([])
  // const data: any = [
  //   {
  //     id: "1",
  //     title: "居住证办理系统",
  //     type: "1",
  //     category: "市/房产局/政务共产局",
  //     port: "5"
  //   },
  //   {
  //     id: "2",
  //     title: "居住证办理系统",
  //     type: "2",
  //     category: "市/房产局",
  //     port: "5"
  //   },
  // ]

  //所有系统/平台列表
  const systemPlatformList = async () => {
    //我的
    const res = await getSystemPlatformlist({
      page: 1,
      page_size: 10,
    })
    console.log(res);
    if (res.code === 200) {
      stedataSouse(res.data.stat_system_platforms)
    }
  }

  useEffect(() => {
    systemPlatformList()
  }, [])

  function deleteConfirm() {
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '该系统下仍有接口处在上线状态，是否直接删除系统下的所有的接口？',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
      okText: "确定",
      cancelText: "取消"
    });
  }

  return (
    <div className="mysystembar">
      <div className="title">我的系统/平台</div>
      <Button type='link' className='lookmore' onClick={() => {
        handleClick({ key: "/systemlist", tag: "2" });
      }}>查看更多</Button>
      {dataSouse?.length ? <div className="cardbar">
        {dataSouse?.map((item: any, index: number) => {
          if (index > 9) return "";
          let Title = item.system_info.category_nmae
          let logo = systemLogo
          let portNum = item.system_info.interfaces_count

          if (item.source_type === 1) {
            Title = item.platform_info.platform_category_list.map((item: any) => item.category_name).join("，")
            logo = platformLogo
            portNum = item.platform_info.interfaces_count
          }
          return (
            <Card
              className="cardItem cardItemhover"
              key={item.id}
            >
              <Card.Meta
                avatar={<Avatar src={logo} />}
                title={item.name}
              />
              <div className="textbar">
                <div className="item">
                  <span>所属类目：</span>
                  <Tooltip title={Title}>
                    <span className="val">{Title}</span>
                  </Tooltip>
                </div>
                <div className="item">
                  <span>关联接口：</span>
                  <span className="val">{portNum}</span>
                </div>
              </div>
              {/* <div className='operation'>...
                <div className='mysystemPop'>
                  <li onClick={() => {
                    editRef.current.seteditVisible(true)
                  }}>编辑</li>
                  <li onClick={deleteConfirm}>删除</li>
                </div>
              </div> */}
            </Card>)
        })}
        {/* <Card className="cardItem" onClick={() => {
          systemRef.current.setSystemVisible(true)
        }}
        >
          <div className="create">
            <PlusOutlined style={{ color: "#86909C" }} />
            <div >点击创建系统/平台</div>
          </div>
        </Card> */}
        <CreateSystem ref={systemRef} />
        <EditSystem ref={editRef} />
      </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </div>
  )
}
