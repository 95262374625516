import React, { useEffect } from "react";
import { Form, Input, Button, Space, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { postApply } from "../../api/apply";
const Applyresource: React.FC = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 6 },
  };
  const history = useHistory();
  const sendInfo = () => {
    form.validateFields().then((data) => {
      
      postApply({
        contact: data.contact,
        dp_address: data.dp_address,
        phone: data.phone,
        remark: data.remark,
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          history.push("/apply");
          message.success("提交成功");
        }
      });
    });
  };
  useEffect(() => {
    form.setFieldsValue({ dp_address: id });
  },[form, id]);
  return (
    <div className='centers-router'>
      <Form {...layout} form={form}>
        <Form.Item
          label="联系人"
          name="contact"
          rules={[{ required: true, message: "请输入联系人" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="dpAddress"
          name="dp_address"
          rules={[{ required: true, message: "dp_address不能为空" }]}
        >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          label="联系方式"
          name="phone"
          rules={[{ required: true, message: "请输入联系方式" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="申请说明"
          name="remark"
          rules={[{ required: true, message: "请输入申请说明" }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11, span: 12 }}>
          <Space>
            <Button type="primary" onClick={sendInfo}>
              确定
            </Button>
            <Button
              onClick={() => {
                history.go(-1);
              }}
            >
              返回
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Applyresource;
