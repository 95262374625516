import {
    GET_PLUGINS_INFO,
    POST_PLUGINS_install,
    GET_PLUGINS_INSTALLINFO,
    GET_PLUGINS_INSTALLLIST,
    GET_PLUGINS_LIST,
    POST_PLUGINS_UNINSTALL,
    POST_PLUGINS_UPDATA
} from "./index";
import { getClient, postClient } from "./requestClient";

export interface pluginslists {
    description: string,
    id: string,
    image: string,
    name: string,
    owner: string,
    release_date: string,
    version: string
    status: number
}
export interface Ipluginslist {
    pluginList: pluginslists[]

}

export interface pluginsinstalllist {
    create_time: string,
    description: string,
    health: number,
    id: number,
    image: string,
    modify_time: number,
    name: string,
    replicas: number,
    state: number,
    user_id: number,
    version: string
    pod: Ipod[]
}
export interface Ipod {
    name:string,
    status:string
}

export interface Ipluginsinstalllist {
    install_plugin_list: pluginsinstalllist[]
}
//插件市场插件详情
export const getpluginsinfo = async (params: any) =>
    await getClient<any>(GET_PLUGINS_INFO, params);

//插件安装
export const postpluginsinstall = async (params: any) =>
    await postClient(POST_PLUGINS_install, params);

//已安装插件详情
export const getpluginsinstallinfo = async (params: any) =>
    await getClient<any>(GET_PLUGINS_INSTALLINFO, params);


// 已安装插件列表
export const getpluginsinstalllist = async (params: any) =>
    await getClient<Ipluginsinstalllist>(GET_PLUGINS_INSTALLLIST, params);

// 插件市场插件列表
export const getpluginslist = async (params: any) =>
    await getClient<Ipluginslist>(GET_PLUGINS_LIST, params);

//卸载插件
export const postpluginsuninstall = async (params: any) =>
    await postClient(POST_PLUGINS_UNINSTALL, params);

//更改插件pod数量
export const postpluginsupdata = async (params: any) =>
    await postClient(POST_PLUGINS_UPDATA, params);
