import fetch from "./fetch";
const request = fetch.request;
export function apiAjax(url, params) {
  // 接入组列表接口
  return request({
    url,
    method: "POST",
    data: {
      reqBody: params || {},
    },
  });
}
export function GetapiAjax(url, params) {
  // console.log(params);
  // 接入组列表接口
  return request(url, {
    method: "GET",
    headers: params,
  });
}

export function PutApiAjax(url, params, headers) {
  return request(url, {
    method: "PUT",
    body: params,
    headers: headers,
  });
}
