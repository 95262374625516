import React, { useState } from "react";
import "./index.scss";
import { Menu, Dropdown, Modal, Form, Input, message, Select } from "antd";
import {
  getpermissiondelete,
  getpermissionmodify,
  postunbindUserPermissionGroup
} from "../../../api/permissionGroup";
import { getRolelist } from "../../../api/userlist"
const Rolebase: React.FC<any> = (props) => {
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isModalVisible_, setisModalVisible_] = useState(false);
  const [bindGroup, setbindGroup] = useState([])
  const [selectLsit, setselectLsit] = useState([])
  const [form] = Form.useForm();
  const { Option } = Select;
  const handleClick = () => {
    //调用父组件的方法 将逻辑处理交给父组件
    props.handleClick(props.index);
  };
  const deleteMenu = (id: string) => {
    getpermissiondelete({ id: id }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功");
        props.deleteMenu();
      }
    });
  };
  const handleOk = () => {
    getpermissionmodify({
      id: props.id,
      name: form.getFieldValue("name"),
    }).then((res) => {
      console.log(res);
      if (res.code === 200) {
        props.deleteMenu();
        message.success("修改成功");
        setisModalVisible(false);
      }
    });
  };
  const restName = () => {
    setisModalVisible(true);
  };
  const handleCancel = () => {
    setisModalVisible(false);
  };
  const jiebang = (id: any) => {
    setisModalVisible_(true);
    getRolelist({}).then((res: any) => {
      console.log(res);
      if (res.code === 200) {
        setselectLsit(res.data)
      }
    })
  }
  const handleChange = (value: any) => {
    setbindGroup(value)
  }
  const handleOks = () => {
    postunbindUserPermissionGroup({
      id: props.id,
      user_ids: bindGroup
    }).then((res) => {
      if (res.code === 200) {
        setisModalVisible_(false)
        message.success("解绑成功")
      }
    })
  }
  const handleCancels = () => {
    setisModalVisible_(false)
  }
  const menu = () => (
    <Menu>
      <Menu.Item onClick={restName}>
        <a>重命名</a>
      </Menu.Item>
      <Menu.Item onClick={() => deleteMenu(props.id)}>
        <a>删除</a>
      </Menu.Item>
      <Menu.Item onClick={() => jiebang(props.id)}>
        <a>解绑</a>
      </Menu.Item>
    </Menu>
  );
  return (
    <div
      onClick={handleClick}
      className={props.currentClass(props.index)}
      style={{ position: "relative" }}
    >
      <Modal
        title="编辑"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      // bodyStyle={{ height: "300px" }}
      >
        <Form form={form}>
          <Form.Item name="name" label="名称">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="角色解绑" visible={isModalVisible_}
        onOk={handleOks}
        onCancel={handleCancels}
        okText={"确定"}
        cancelText={"取消"}
      >
        <Form initialValues={{ role: props.roleId }}>
          <Form.Item
            label="角色"
            name="role"
            rules={[{ required: true, message: '请绑定角色' }]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="请选择"
              onChange={handleChange}
            >
              {selectLsit.map((item: any) => {
                return (<Option key={item.id} value={item.id}>{item.name}</Option >)
              }
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <i className="iconfont icon-yly_guanliyuan"></i>
      {props.val}
      {props.currentClass(props.index) === "current" ? (
        <Dropdown
          overlay={() => menu()}
          placement="bottomLeft"
          trigger={["click"]}
          arrow
        >
          <i
            className="iconfont icon-diandiandian"
            style={{
              position: "absolute",
              right: "10px",
              width: "20px",
              height: "50px",
              color: "#979797",
            }}
            onClick={(e) => e.preventDefault()}
          ></i>
        </Dropdown>
      ) : (
        ""
      )}
    </div>
  );
};
export default Rolebase;
