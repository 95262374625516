import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./index.scss";
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  message,
  Table,
  Checkbox,
  InputNumber,
  Tooltip,
} from "antd";
import {
  getprotocollist,
  postconnectorcreate,
  postconnectordetail,
  postconnectorupdate,
} from "../../../api/connector";
import { QuestionCircleOutlined } from "@ant-design/icons";
// import PlayloadComponent from "../components/PlayloadComponent";
const ConnectorDetail: React.FC = () => {
  interface routerHeader {
    id: any;
  }
  // const { id } = useParams<routerHeader>();
  const history = useHistory();
  const {state} = useLocation<any>()
  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [selectlist, setselectlist] = useState([]);
  const [count, setCount] = useState(2);
  const [renderMd, setrenderMd] = useState(1);
  const [ableType,setableType] = useState(false)
  // const dataSource = useRef<any>([
  //   // {
  //   //   id:1,
  //   //   name: "配置项名称",
  //   //   key: "配置项key",
  //   //   type: "text" /**string (文本:text,单选框:radio,多选框:checkbox,选择框:select)*/,
  //   //   optionName: "配置项optionName",
  //   //   optionValue: "配置项value",
  //   //   maxLength: "长度",
  //   //   placeHolder: "文案",
  //   //   checkValue: false,
  //   // },
  // ]);
  const [dataSource, setdataSource] = useState<any>([]);
  const columns: any = [
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>配置项名称
        </div>
      ),
      dataIndex: "name",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`name${index}`}
              rules={[{ required: true, message: "请输入配置项名称" }]}
            >
              <Input
              disabled={ableType}
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].name = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      // fixed: "left",
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>配置项key
        </div>
      ),
      dataIndex: "key",
      key: "key",
      editable: true,
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`key${index}`}
              rules={[{ required: true, message: "请填写配置项" }]}
            >
              <Input
                style={{ width: "100%" }}
                value={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].key = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
      // fixed: "left",
    },
    {
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>配置项类型
        </div>
      ),
      dataIndex: "type",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`type${index}`}
              rules={[{ required: true, message: "请选择配置项类型" }]}
            >
              <Select
                style={{ width: "100%" }}
                value={[text + ""]}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].type = e;
                  setdataSource(arr);
                }}
              >
                <Option value="1">文本框</Option>
                <Option value="2">多行文本</Option>
                <Option value="3">单选框</Option>
                <Option value="4">多选框</Option>
                <Option value="5">选择框</Option>
              </Select>
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
      // fixed: "left",
    },
    {
      title: "是否必填",
      dataIndex: "checkValue",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item label="" name={`checkValue${index}`}>
              <Checkbox
                checked={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].checkValue = e.target.checked;
                  setdataSource(arr);
                }}
              ></Checkbox>
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <span style={{ color: "red" }}>*</span>配置项
          </div>
          <Tooltip title="如果是radio、checkbox、select，则此项需要填写；如果单选框radio有两个选择：是 和 否，则使用英文逗号隔开填入此处">
            <span
              style={{ marginLeft: "3px", color: "#165DFF", cursor: "pointer" }}
            >
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "optionName",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`optionName${index}`}
              rules={
                (record.type == 1 || record.type == 2) && record.type !== ""
                  ? []
                  : [
                      {
                        validator: (regExpObj, value) => {
                          var val = value.split(",");
                          if (
                            val.length > 0 &&
                            val.every((item: any) => item !== "")
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("请输入正确的配置项格式")
                            );
                          }
                        },
                        required: true,
                        message: "配置项不能为空",
                      },
                    ]
              }
            >
              <Input
                // placeholder="请输入配置项"
                style={{ width: "100%" }}
                value={text}
                disabled={
                  (record.type == 1 || record.type == 2) && record.type !== ""
                    ? true
                    : false
                }
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].optionName = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <span style={{ color: "red" }}>*</span>配置项值
          </div>
          <Tooltip title="如果是radio、checkbox、select，则此项需要填写；如果单选框radio有两个选择：是 和 否，值对应是 1 和 0，则使用英文逗号隔开填入此处">
            <span
              style={{ marginLeft: "3px", color: "#165DFF", cursor: "pointer" }}
            >
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "optionValue",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              label=""
              name={`optionValue${index}`}
              dependencies={[`optionName${index}`]}
              rules={
                (record.type == 1 || record.type == 2) && record.type !== ""
                  ? []
                  : [
                      {
                        validator: (regExpObj, value) => {
                          var val = value.split(",");
                          var arr: any = [];
                          val.map((item: any) => {
                            if (item !== "") {
                              arr.push(item);
                            }
                          });
                          var pzVal = record.optionName.split(",");
                          var pzVal_ = [];
                          pzVal.map((item: any) => {
                            if (item !== "") {
                              pzVal_.push(item);
                            }
                          });
                          if (
                            pzVal_.length > 0 &&
                            pzVal_.length === arr.length
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("请检查配置项值格式是否正确")
                            );
                          }
                        },
                        // required:true,
                        // message:"配置项值不能为空"
                      },
                    ]
              }
            >
              <Input
                // placeholder="请输入配置项值"
                style={{ width: "100%" }}
                value={text}
                disabled={
                  (record.type == 1 || record.type == 2) && record.type !== ""
                    ? true
                    : false
                }
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].optionValue = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    {
      title: "最大长度",
      dataIndex: "maxLength",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item label="" name={`maxLength${index}`}>
              <InputNumber
                min={1}
                // max={128}
                style={{ width: "100%" }}
                value={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].maxLength = e;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    {
      title: "提醒文案",
      dataIndex: "placeHolder",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item label="" name={`placeHolder${index}`}>
              <Input
                // placeholder="请输入提醒文案"
                style={{ width: "100%" }}
                value={text}
                disabled={ableType}
                onChange={(e) => {
                  setrenderMd(renderMd + 1);
                  let arr = [...dataSource];
                  arr[index].placeHolder = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
      width: 200,
      align: "center" as "center",
    },
    // {
    //   title: "操作",
    //   dataIndex: "number",
    //   render: (text: any, record: any, index: number) => {
    //     return <a onClick={() => handleDelete(text, index)}>删除</a>;
    //   },
    //   width: 100,
    //   align: "center" as "center",
    //   fixed: "right",
    // },
  ];
  useEffect(() => {
    getprotocollist().then((res) => {
      setselectlist(res.data);
    });
    if (state.type === "add") {
      setableType(false)
    } else {
      setableType(true)
      postconnectordetail({
        id: state.id * 1,
      }).then((res: any) => {
        if (res.code === 200) {
          let respone = res.data;
          let arr =
            respone.payloadDefinition == null ? [] : respone.payloadDefinition;
          setdataSource(arr);
          respone.payloadDefinition.map((item: any, index: any) => {
            form.setFields([
              {
                name: [`name${index}`],
                value: item.name,
              },
              {
                name: [`key${index}`],
                value: item.key,
              },
              {
                name: [`type${index}`],
                value: item.type + "",
              },
              {
                name: [`checkValue${index}`],
                value: item.checkValue,
              },
              {
                name: [`optionName${index}`],
                value: item.optionName,
              },
              {
                name: [`maxLength${index}`],
                value: item.maxLength,
              },
              {
                name: [`placeHolder${index}`],
                value: item.placeHolder,
              },
              {
                name: [`optionValue${index}`],
                value: item.optionValue,
              },
            ]);
          });
          setCount(1);
          form.setFieldsValue({
            protocol: respone.protocol,
            name: respone.name,
            description: respone.description,
            // payloadDefinition: respone.payloadDefinition,
            payloadDefinitionDescription: respone.payloadDefinitionDescription,
          });
        }
      });
    }
  }, [state]);
  const submit = () => {
    form.validateFields().then((data) => {
      if (dataSource.length > 0) {
        let arr = dataSource.every((item: any) => item.key !== "");
        if (arr) {
          if (state.type === "add") {
            postconnectorcreate({
              protocol: data.protocol,
              name: data.name,
              description: data.description,
              payloadDefinition: dataSource,
              payloadDefinitionDescription: data.payloadDefinitionDescription,
            })
              .then((res) => {
                if (res.code === 200) {
                  message.success("保存成功!");
                  history.push("/connector");
                }
              })
              .catch(() => {
                message.error("保存失败");
              });
          } else {
            postconnectorupdate({
              code: state.code,
              protocol: data.protocol,
              name: data.name,
              description: data.description,
              payloadDefinition: dataSource,
              payloadDefinitionDescription: data.payloadDefinitionDescription,
            })
              .then((res) => {
                if (res.code === 200) {
                  message.success("更新成功!");
                  history.push("/connector");
                }
              })
              .catch(() => {
                message.error("更新失败");
              });
          }
        } else {
          message.warning("配置项Key值不能为空!");
        }
      } else {
        if (state.type === "add") {
          postconnectorcreate({
            protocol: data.protocol,
            name: data.name,
            description: data.description,
            payloadDefinition: dataSource,
            payloadDefinitionDescription: data.payloadDefinitionDescription,
          })
            .then((res) => {
              if (res.code === 200) {
                message.success("保存成功!");
                history.push("/connector");
              }
            })
            .catch(() => {
              message.error("保存失败");
            });
        } else {
          postconnectorupdate({
            code: state.code,
            protocol: data.protocol,
            name: data.name,
            description: data.description,
            payloadDefinition: dataSource,
            payloadDefinitionDescription: data.payloadDefinitionDescription,
          })
            .then((res) => {
              if (res.code === 200) {
                message.success("更新成功!");
                history.push("/connector");
              }
            })
            .catch(() => {
              message.error("更新失败");
            });
        }
      }
    });
  };
  const handleAdd = () => {
    const newData = {
      number: count,
      name: "",
      key: "",
      type: "1" /**string (文本:1,多行文本2,单选框:3,多选框:4,选择框:5)*/,
      optionName: "",
      optionValue: "",
      maxLength: "",
      placeHolder: "",
      checkValue: false,
    };
    let arr = [...dataSource];
    let brr = [...arr, newData];
    brr.map((item: any, index: any) => {
      form.setFields([
        {
          name: [`name${index}`],
          value: item.name,
        },
        {
          name: [`key${index}`],
          value: item.key,
        },
        {
          name: [`type${index}`],
          value: item.type + "",
        },
        {
          name: [`checkValue${index}`],
          value: item.checkValue,
        },
        {
          name: [`optionName${index}`],
          value: item.optionName,
        },
        {
          name: [`maxLength${index}`],
          value: item.maxLength,
        },
        {
          name: [`placeHolder${index}`],
          value: item.placeHolder,
        },
        {
          name: [`optionValue${index}`],
          value: item.optionValue,
        },
      ]);
    });
    setdataSource(brr);
    setCount(count + 1);
  };
  const handleDelete = (text: any, index: any) => {
    if (dataSource.length >= 1) {
      let arr = dataSource.filter((item: any) => item.number !== text);
      arr.map((item: any, index: any) => {
        form.setFields([
          {
            name: [`name${index}`],
            value: item.name,
          },
          {
            name: [`key${index}`],
            value: item.key,
          },
          {
            name: [`type${index}`],
            value: item.type + "",
          },
          {
            name: [`checkValue${index}`],
            value: item.checkValue,
          },
          {
            name: [`optionName${index}`],
            value: item.optionName,
          },
          {
            name: [`maxLength${index}`],
            value: item.maxLength,
          },
          {
            name: [`placeHolder${index}`],
            value: item.placeHolder,
          },
          {
            name: [`optionValue${index}`],
            value: item.optionValue,
          },
        ]);
      });
      setdataSource(arr);
      setCount(count + 1);
    }
  };
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>Connector表单项配置</div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          //   height: "300px",
          marginTop: "40px",
          //   textAlign: "center",
          padding: "0px 10%",
          paddingRight: "10%",
        }}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "150px" }}
          // labelAlign="right"
          form={form}
          colon={false}
        >
          <Form.Item
            label="Connector插件类型"
            name="protocol"
            rules={[{ required: true, message: "请选择插件类型" }]}
          >
            {/* <Select disabled={ableType}>
              {selectlist.map((item: any) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select> */}
            <Input disabled={ableType}/>
          </Form.Item>
          <Form.Item
            label="插件名称"
            name="name"
            rules={[{ required: true, message: "请输入插件名称" }]}
          >
            <Input disabled={ableType}/>
          </Form.Item>
          <Form.Item
            label="插件介绍"
            name="description"
            rules={[{ required: true, message: "请输入插件描述" }]}
          >
            <TextArea rows={4} disabled={ableType}/>
          </Form.Item>
          <Form.Item
            label="插件连接信息配置项"
            name="payloadDefinition"
            // rules={[{ required: true }]}
          >
            <Table
              rowKey={state.id}
              // rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              scroll={{ x: 1500 }}
            />
              {/* <Button
                onClick={handleAdd}
                // type="primary"
                style={{ marginTop: 16, width: "100%" }}
              >
                + 添加配置项
              </Button> */}
            {/* <TextArea rows={6} /> */}
            {/* <PlayloadComponent/> */}
          </Form.Item>
          <Form.Item
            label="插件Payload定义说明"
            name="payloadDefinitionDescription"
          >
            <TextArea rows={6} disabled={ableType}/>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }} label="  ">
            <Space>
              <Button
                onClick={() => {
                  history.push("/connector");
                }}
              >
                返回
              </Button>
              {/* <Button type="primary" onClick={submit}>
                保存
              </Button> */}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default ConnectorDetail;
