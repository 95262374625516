// @ts-nocheck
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Input, Form, Select, Modal, Radio, TreeSelect, message, Cascader } from "antd";
import { getCreateCategry, postCreateSystem, getMyPlatformlist, postCreatePlatform, getsystemprotocol } from "../../../../api/scenario"
const { Option } = Select;
const { TreeNode } = TreeSelect;
const { SHOW_CHILD } = Cascader;

function Index(props: any, ref: any) {
  const addressChildren: any = [];
  const [form] = Form.useForm();
  const [category, setCategory] = useState<any>([])
  const [platform, setplatform] = useState<any>([])
  const [systemVisible, setSystemVisible] = useState<boolean>(false)
  const [selectlabel, setlabel] = useState<string>("系统");
  const [relevance, setrelevance] = useState<string>("类目");
  const [agreementlist, setagreementlist] = useState([])
  const [pad, setpad] = useState("http")
  useImperativeHandle(ref, () => ({
    setSystemVisible
  }))

  const onFinish = async (v: any) => {
    // console.log(v);
    if (selectlabel === "系统") {
      const res = await postCreateSystem({
        address: v.address,
        // categoryId: v.categoryId ? v.categoryId : "",
        categoryId: v.categoryId ? v.categoryId[v.categoryId.length - 1] : "",
        platformId: v.platformId ? v.platformId : "",
        name: v.name,
        protocol: v.protocol,
        desc: v.desc,
      })
      // console.log(res);
      if (res.code === 200) {
        message.success("创建成功")
        if (props.tag === "1") {
          // console.log(1);
          props.allref.systemList()
          // props.allref.systemPlatformList()
        }
      }
    } else {
      let data = []
      v.categoryId.forEach(item => {
        data.push(item[item.length - 1])
      })
      const res = await postCreatePlatform({
        categoryIds: data,
        desc: v.desc,
        name: v.name,
      })
      // console.log(res);
      if (res.code === 200) {
        message.success("创建成功")
        if (props.tag === "1") {
          // console.log(2);
          props.allref.platformList()
          // props.allref.systemPlatformList()
        }
      }
    }
    form.resetFields();
    setSystemVisible(false)
  }

  //获取平台列表
  const platformList = async () => {
    const res = await getMyPlatformlist({
      page: 1,
      page_size: 20,
      name: "",
    })
    // console.log(res);
    setplatform(res.data?.platforms)
  }

  //获取类别列表
  const getCategryList = () => {
    getCreateCategry({
      page: 1,
      page_size: 20
    }).then((res) => {
      // console.log(res);
      if (res.code === 200) {
        setCategory(mapCategry(res.data))
        // console.log(mapCategry(res.data));
      }
    })
  }
  //递归映射
  function mapCategry(data: any) {
    return data?.map((item: any) => {
      item.title = item.name;
      item.label = item.name;
      item.value = item.id;
      if (item.subCategories) {
        item.children = mapCategry(item.subCategories)
      }
      return item
    })
  }

  useEffect(() => {
    setlabel("系统")
    if (systemVisible) {
      platformList()
      getCategryList()
      getsystemprotocol({}).then((res) => {
        setagreementlist(res.data)
      })
    }
  }, [systemVisible])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  //选择树渲染
  // const renderTreeNodes = data =>
  //   data?.map(item => {
  //     // console.log('item', item);
  //     if (item.children) {
  //       item.disabled = true;
  //       return (
  //         <TreeNode key={item.id} title={item.title} value={item.value} disabled={item.disabled} className="createTree">
  //           {renderTreeNodes(item.children)}
  //         </TreeNode>
  //       );
  //     }
  //     return <TreeNode {...item} key={item.id} title={item.title} value={item.value} />;
  //   });

  let url = "(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
  let protocolReg = "(https|http)(:\/\/)" //协议 
  let domainNameReg = `([a-zA-Z0-9]([a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,11}` //域名
  let ipReg = `(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])` //ip
  let portReg = ":\\d{1,5}" //端口
  //域名，协议+域名，ip，协议+ip，域名+端口，协议+域名+端口，ip+端口，协议+ip+端口，url
  let reg = new RegExp(`^(${domainNameReg}|${protocolReg}${domainNameReg}|${ipReg}|${protocolReg}${ipReg}|${domainNameReg}${portReg}|${protocolReg}${domainNameReg}${portReg}|${ipReg}${portReg}|${protocolReg}${ipReg}${portReg}|${url})$`)

  // console.log(reg);
  // let strRegex = "^((https|http|ftp|rtsp|mms)?://)"
  //   + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
  //   + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
  //   + "|" // 允许IP和DOMAIN（域名）
  //   + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
  //   + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
  //   + "[a-z]{2,6})" // first level domain- .com or .museum
  //   + "(:[0-9]{1,5})?" // 端口- :80
  //   + "((/?)|" // 如果没有文件名，则不需要斜杠
  //   + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$"; //参数
  // let reg = new RegExp(strRegex)

  return (
    <div>
      <Modal
        title="新建系统/平台"
        onOk={() => { form.submit() }}
        onCancel={() => { form.resetFields(); setSystemVisible(false) }}
        width={700}
        okText="确定"
        cancelText="取消"
        className="createSystem"
        visible={systemVisible}
      >
        <Form
          form={form}
          onFinish={onFinish}
          {...layout}
          className="systemForm"
          onKeyDown={(e) => {
            if (e.code === "Enter") form.submit()
          }}
          initialValues={{ pad: "123121321" }}
        >
          <Form.Item label="类别">
            <Radio.Group
              onChange={(e) => { setlabel(e.target.value); form.resetFields() }}
              value={selectlabel}
            // defaultValue={selectlabel}
            >
              <Radio value={"系统"}>系统</Radio>
              <Radio value={"平台"}>平台</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="名称" name="name"
            rules={[{ required: true, message: '请输入名称!' }]}
          >
            <Input placeholder="请输入名称" />
          </Form.Item >

          <Form.Item label="描述" name="desc">
            <Input.TextArea showCount maxLength={200} placeholder='请输入内容，最多不超过200字' />
          </Form.Item >


          {selectlabel === "系统" && <>
            <Form.Item label="访问地址" name="address"
              rules={[
                {
                  required: true,
                  message: '请输入正确的地址!',
                  // pattern: new RegExp(url),
                  pattern: new RegExp(reg),
                  //ip，ip+端口，域名，域名加端口
                },
              ]}
            >
              {/* <Input /> */}
              <Select mode="tags" placeholder="请输入访问地址">
                {addressChildren}
              </Select>
            </Form.Item >
            <Form.Item label="协议" name="protocol"
              rules={[{ required: true, message: '请选择协议' }]}
            >
              <Select placeholder="请选择协议">
                {agreementlist.map((item,index) => {
                  return (
                    <Option value={item.kind} key={index}>{item.desc}</Option>
                  )
                })}
              </Select>
            </Form.Item>
            {/* <Form.Item label="协议地址" name="pad"
              rules={[{ required: true, message: '请选择协议' }]}
            >
              <Input className='protocoladdress' addonBefore={
                <Select value={pad} onChange={(v) => { setpad(v) }}>
                  {agreementlist.map((item) => {
                    return <Option value={item.kind}>{item.desc}</Option>
                  })}
                </Select>} />
            </Form.Item> */}
          </>}
          {/* 关联平台还是类目二选一 */}
          {selectlabel === "系统" ?
            <>
              <Form.Item label="关联方式">
                <Radio.Group
                  onChange={(e) => { setrelevance(e.target.value) }}
                  value={relevance}
                >
                  <Radio value={"类目"}>类目</Radio>
                  <Radio value={"平台"}>平台</Radio>
                </Radio.Group>
              </Form.Item>
              {relevance === "类目" ? <Form.Item label="所属类目" name="categoryId"
                rules={[{ required: true, message: '请选择类目!' }]}
              >
                {/* <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  multiple={selectlabel === "平台" ? true : false}
                  treeDefaultExpandAll={false}
                >
                  {renderTreeNodes(category)}
                </TreeSelect> */}
                <Cascader expandTrigger="hover" options={category} placeholder="请选择类目" />
              </Form.Item> :
                <Form.Item label="所属平台" name="platformId"
                  rules={[{ required: true, message: '请选择平台!' }]}
                >
                  <Select placeholder="请选择平台">
                    {platform?.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                </Form.Item>}
            </> :
            <Form.Item label="所属类目" name="categoryId"
              rules={[{ required: true, message: '请选择类目!' }]}
            >
              <Cascader
                multiple
                options={category}
                expandTrigger="hover"
                maxTagCount="responsive"
                placeholder="请选择类目"
                showCheckedStrategy={SHOW_CHILD}
              />
            </Form.Item>
          }
        </Form>
      </Modal>
    </div >
  )
}
export default forwardRef(Index)
