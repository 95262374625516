import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { debounce } from 'lodash'
import { Form, Input, Select, Button, Space, message , Modal, Tabs, InputNumber  } from "antd";
import { CheckOutlined } from "@ant-design/icons"
import { postTopicCreate, getTopicDetail, putTopicEdit } from "@/api/topic"
import HttpParamsForm from "@/component/HttpParamsForm";
import { handleIndicatorImportJson } from '../utils'
import { DEFAULT_SCHEMA, queryArea } from "../commonData";
const CreateTopic: React.FC = () => {
  interface routerHeader {
    id: any
  }
  const { id } = useParams<routerHeader>();
  const isEdit = id !== 'add'
  const history = useHistory();
  const { TextArea } = Input;
  const { code } = useLocation<any>().state || ''
  const { initJsonBody } = useLocation<any>().state || {}
  const [nowType, setnowType] = useState<any>();
  const [ successAdd, setSuccessAdd ] = useState(false)
  const [times, setTimes ] = useState<any>({
    expireTime: 60,
    delayTime: 1
  })
  const [form] = Form.useForm();
  const onAPIParamsChange = (data: string) => {
    let parseData:any = {};
    try {
      parseData = JSON.parse(data);
    } catch (e) {
      console.log('json内容错误', e);
    }
    originData.current.APIResponseStructure = parseData
  }
  useEffect(() => {
    setnowType(1)
    if(isEdit) {
      getDetail()
    } 
  }, [id])
  const originData:any = React.useRef(isEdit ? initJsonBody : {
    APIRequestStructure: {post: {type: 'form', form: queryArea()}, query: queryArea(), headers: [], form: []},
    APIResponseStructure: DEFAULT_SCHEMA
  });
  const submit = () => {
    form.validateFields().then((data) => {
      if(isEdit) {
        putTopicEdit({
          id: code,
          topicName: data.topicName,
          accessLevel: Number(data.accessLevel),
          expireTime: Number(data.expireTime),
          delayTime: Number(data.delayTime),
          topicDescription: data.topicDescription,
          bodyStruct: JSON.stringify(originData.current)
        }).then((res) => {
          if (res.code === 200) {
            message.success('编辑成功')
            history.push("/mytopic");
          }
        })
        .catch(() => {
          message.error("更新失败");
        });
      } else {
        postTopicCreate({
          topicName: data.topicName,
          accessLevel: Number(data.accessLevel),
          expireTime: Number(data.expireTime),
          delayTime: Number(data.delayTime),
          topicDescription: data.topicDescription,
          bodyStruct: JSON.stringify(originData.current)
        }).then((res) => {
          if (res.code === 200) {
            setSuccessAdd(true)
            // history.push("/mytopic");
          }
        })
        .catch(() => {
          message.error("发布失败");
        });
      }
    }).catch((err:any) => {
      console.log('注册失败', err);
      
    });
  };
  const getDetail= () => {
    getTopicDetail({id}).then((res:any)=>{
      form.setFieldsValue({
        topicName: res.data.topicName,
        accessLevel: res.data.accessLevel,
        expireTime: res.data.expireTime,
        delayTime: res.data.delayTime,
        topicDescription: res.data.topicDescription
      })
      setTimes({
        expireTime: res.data.expireTime,
        delayTime: res.data.delayTime
      })
    }).catch((err:any) => {
      console.log('获取详情错误', err);
    })
  };
  return (
    <div style={{ padding: "20px", width: "100%" }}>
      <div>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          {
            id === 'add'?
            <div>Topic注册</div>
            :
            <div>Topic编辑</div>
          }
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "40px",
        }}
      >
        <div style={{ width: "80%", margin: "0 auto" }}>
          <div style={{
              display:"flex",
              alignItems:"center"
            }}
          >
            <div style={{
              width: "6px",
              height: "6px",
              background: "#165DFF",
              marginRight: "10px",
              borderRadius: "50%"}}></div>
            <div>基本信息</div>
          </div>
          <div style={{width: "40%", margin: "0px auto"}}>
            <Form
              name="wrap"
              // labelCol={{ flex: "200px" }}
              // labelAlign="right"
              layout="vertical"
              form={form}
              colon={false}
            >
              <Form.Item
                label="Topic名称"
                name="topicName"
                rules={[{ required: true, message: "请输入Topic名称" }]}
              >
                <Input placeholder="请输入Topic名称"/>
              </Form.Item>
              <Form.Item
                label="开放订阅"
                name="accessLevel"
                rules={[{ required: true, message: "请选择开放订阅" }]}
              >
                <Select 
                  placeholder="请选择开放订阅"
                  options={[
                    {
                      value: 1,
                      label: 'public(公开授权订阅)',
                    },
                    {
                      value: 2,
                      label: 'private(不开放订阅权限)',
                    }
                  ]}>
                  {/* {selectlist.map((item: any) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    );
                  })} */}
                </Select>
              </Form.Item>
              <Form.Item
                label="消息保存时长"
                name="expireTime"
                rules={[{ required: true, message: "请输入消息保存时长" }]}
              >
                <div style={{display: "flex", alignItems: "center"}}>
                  <InputNumber placeholder="取值范围：1～604,800" min={60} value={times.expireTime} onChange={(val)=>{
                    setTimes({
                      expireTime: val,
                      delayTime: times.delayTime
                    })
                  }} style={{minWidth: "190px", marginRight: "5px"}} parser={(text:any)=>/^\d+$/.test(text)? text:60}/>秒
                </div>
              </Form.Item>
              <Form.Item
                label="延时时长"
                name="delayTime"
                rules={[{ required: true, message: "请输入延时时长" }]}
              >
                <div style={{display: "flex", alignItems: "center"}}>
                  <InputNumber placeholder="取值范围：1～86,400" min={1} value={times.delayTime} onChange={(val)=>{
                    setTimes({
                      expireTime: times.expireTime,
                      delayTime: val
                    })}} style={{minWidth: "190px", marginRight: "5px"}} parser={(text:any)=>/^\d+$/.test(text)? text:1}/>秒
                </div>
              </Form.Item>
              <Form.Item
                label="Topic说明"
                name="topicDescription"
              >
                <TextArea rows={6} />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div style={{ width: "80%", margin: "0 auto" }}>
          <div style={{
              display:"flex",
              alignItems:"center"
            }}
          >
            <div style={{
              width: "6px",
              height: "6px",
              background: "#165DFF",
              marginRight: "10px",
              borderRadius: "50%"}}></div>
            <div>消息内容</div>
          </div>
          <div style={{width: "100%", margin: "0px auto"}}>
            <Tabs
              activeKey={nowType + ""}
              onChange={(e) => {
                setnowType(e);
              }}
            >
              <Tabs.TabPane tab="模板" key="1"></Tabs.TabPane>
              <Tabs.TabPane tab="预览" key="2"></Tabs.TabPane>
            </Tabs>
            { 
              nowType == 1?
              <HttpParamsForm
                showConstantVal={false}
                isCloseUpdate={true}
                handleImportTransformJson={(json: any, type: any) => handleIndicatorImportJson(json, type, DEFAULT_SCHEMA)}
                data={JSON.stringify(originData.current.APIResponseStructure)}
                onChange={debounce(onAPIParamsChange, 500)}
              />
              : 
              <div style={{padding: "20px", background: "#F8F8F8"}}>
                <pre>{JSON.stringify(originData.current.APIResponseStructure, null, 2)}</pre>
              </div>
            }
          </div>
        </div>
      </div>
      <div style={{textAlign: "center", marginTop: "20px"}}>
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={submit}>
            确认
          </Button>
        </Space>
      </div>
      <Modal
          visible={successAdd}
          closable={false}
          footer={null}
        >
          <div>
            <div style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              color: "#1D2129"
            }}> 
              <div style={{
                textAlign: "center",
                marginRight: "10px",
                backgroundColor: "#E5E6EB",
                borderRadius: "50%",
                width: "22px",
                height: "22px",
              }}>
                <CheckOutlined style={{
                  color: "#165DFF",
                  fontSize: "14px",
                }}/>
              </div>注册成功</div>
            <div style={{
              margin: "10px 0 20px 31px",
              color: "#86909C",
              fontSize: "12px"
            }}>可前往我的Topic/已注册查看已注册的Topic</div>
          </div>
          <div style={{
            textAlign: "center"
          }}>
            <Button type="primary" onClick={()=>{
              history.push("/mytopic")
              setSuccessAdd(false)
            }}>确定</Button>
          </div>
        </Modal>
    </div>
  );
};
export default CreateTopic;
