import { postClient, getClient } from "./requestClient";
import {
  GET_credential_details,
  POST_credential_create,
  GET_download_decode,
  GET_download_examine,
  POST_upload_decode,
  POST_upload_examine,
  POST_credential_list,
  POST_retarded_expiry
} from "./index";

export const getcredentialdetails = async () =>
  await getClient<any>(GET_credential_details);

export const postcredentialcreate = async (params: any) =>
  await postClient<any>(POST_credential_create, params);

export const getdownloaddecode = async () =>
  await getClient<any>(GET_download_decode);

  export const getdownloaddeexamine = async () =>
  await getClient<any>(GET_download_examine);

  export const postuploaddecode = async (params: any) =>
  await postClient<any>(POST_upload_decode, params);

  export const postuploadexamine = async (params: any) =>
  await postClient<any>(POST_upload_examine, params);

  export const postcredentiallist = async (params: any) =>
  await postClient<any>(POST_credential_list, params);

  export const postretardedexpiry = async (params: any) =>
  await postClient<any>(POST_retarded_expiry, params);
