// import React from "react";
import styles from "./index.module.scss";
// import Myscence from "./Myscence";
import Port from "./Port";
// import Entrance from "./Entrance";
// import Commission from "./Commission";
// import Document from "./Document";
// import { Skeleton, Empty } from "antd";
import MySystem from "../systemList/MySystem"
// import { WebAdminContext } from "../../context/WebAdminContext";

const Console = () => {
  // const { rolelist } = React.useContext(WebAdminContext);
  return (
    <div>
      <div className={styles["Console"]}>
        <div className={styles["Console-left"]}>
          <MySystem />
          <Port />
        </div>
        {/* <div className={styles["Console-right"]}>
                <Entrance />
                <Commission />
                <Document />
              </div> */}
      </div>
    </div>
  );
};
export default Console;
