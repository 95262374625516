
import * as React from "react";
import { AllHTMLAttributes, useEffect, useState } from "react";
import { Button, Switch, Form, Input, message, Select, Spin, Checkbox } from "antd";
import { FormItemProps } from "antd/es/form";
import '../index.scss';
import { PublishContext } from "@/pages/Service/PublishServe";
import DetailCard from "../../../../component/DetailCard";
import {
  getConnectorList,
  addServiceSecond,
  getConnectInfo
} from "../../../../api/serviceManagement";
import FormTool, { FormDataProps } from "./component/form-tool";

type BackendConfigProps = { appApiId: any } & AllHTMLAttributes<HTMLElement> & FormItemProps;
const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};
const BackendConfig: React.FC<any> = (props) => {
  const { appApiId ,referer} = props;
  const [form] = Form.useForm();
  const { state, dispatch, history } = React.useContext(PublishContext);
  const { backendConfig, currentStep, extraButton, loading, uuid, connectType } = state;
  const [connectList, setConnectList] = useState([]);
  const [dataList, setDataList] = useState<FormDataProps[]>([])
  const [disable, setdisable] = useState<boolean>(false);
//上一步
  const onPreviousStep = () => {
    dispatch && dispatch({ type: 'NEXT_STEP', payload: 'nameAgent' })
  }
  useEffect(() => {
    if (referer === "apiGray") {
      setdisable(true);
    } else {
      setdisable(false);
    }
  }, [referer]);
  useEffect(() => {
    const { connectorTypeId, connectorId, dataSystem, route, useTls } = backendConfig;
    let connecterName = connectType.filter((item:any)=>item.id === connectorTypeId)
    handleChange(connecterName[0]?.protocol);
    form.setFieldsValue({
      connectorTypeId,
      connectorId,
      dataSystem: Object.prototype.toString.call(dataSystem) === '[object Array]' ? dataSystem.join(',') : dataSystem,
      useTls: useTls ? ['1'] : [],
      ...JSON.parse(route || '{ }')
    })
    if (!connectorId) return
    getConnectInfo({ id: connectorId }).then(res => {
      setDataList(res.data || [])
      console.log(res.data);
      
    })
  }, [backendConfig])

//下一步
  const onNextStep = () => {
    form.validateFields().then((values) => {
      dispatch && dispatch({ type: 'BEGIN_QUERY' });
      let obj:any = {}
      dataList.map(item => {
        if (values[item?.key]) obj[item?.key] = values[item?.key]
      })
      values['route'] = JSON.stringify(obj)
      values['useTls'] = !!values['useTls'].length
      values['dataSystem'] = [values['dataSystem']]
      console.log(values);
      let arr = values
      arr.connectorTypeId = arr.connectorId
      console.log(arr);
      
      addServiceSecond({ uuid, ...arr }).then((res) => {
        if (res.code == 200) {
          dispatch && dispatch({ type: 'TRANSFER_CONNECTOR_ID', payload: form.getFieldValue('connectorId') });
          dispatch && dispatch({ type: 'NEXT_STEP', payload: 'frontendConfig' })
        }
      })
    })
  }
//选择后端接入Connector类型查询后端接入Connector
  const handleChange = (value: any) => {
    getConnectorList({
      page: 1,
      pageSize: 9999,
      protocol: value
    }).then((res) => {
      if (res.code == 200) {
        setConnectList(res.data);
        const connId = form.getFieldValue('connectorId');
        const result = (res.data || []).findIndex((item: any) => {
          return item.id == connId;
        })
        if (result == -1) {
          form.setFieldsValue({
            connectorId: ''
          })
        }
      }
    })
  }

  // const ipValidate = (value: string, callback: { (error?: string | undefined): void; (arg0: string | undefined): void; }) => {
  //   const reg = /(^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?):([0-9]|[1-9]\d{1, 3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$)|(^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$)/;

  //   if (!value) {
  //     callback('请输入正确的信息（包含: IP地址+端口号,  长度最大不超过50）');
  //     return;
  //   }
  //   if (!reg.test(value) || value.length > 50) {
  //     callback('请输入正确的信息（包含: IP地址+端口号,  长度最大不超过50）');
  //     return;
  //   }
  //   let arr = [];
  //   let valueCopy = '';
  //   if (value.indexOf(':') !== -1) {
  //     arr = value.split(':');
  //     valueCopy = arr[0];
  //     if (valueCopy === '127.0.0.1' || valueCopy === '224.0.0.1' || valueCopy === '0.0.0.0' || valueCopy === '255.255.255.255') {
  //       callback('禁止输入IP地址为127.0.0.1,224.0.0.1,0.0.0.0,255.255.255.255');
  //       return;
  //     }
  //   }
  //   if (value === '127.0.0.1' || value === '224.0.0.1' || value === '0.0.0.0' || value === '255.255.255.255') {
  //     callback('禁止输入IP地址为127.0.0.1,224.0.0.1,0.0.0.0,255.255.255.255');
  //     return;
  //   }
  //   callback();
  // }

  // const dataList = [
  //   {
  //     name: "配置项名称", //string
  //     key: "key", //string
  //     type: "text", //string (文本:text,单选框:radio,多选框:checkbox,选择框:select)
  //     optionName: "配置项optionName",  //string
  //     optionValue: "key", //string
  //     maxLength: 100,//number
  //     placeHolder: "请输入", //string
  //     checkValue: true //boolean
  //   }
  // ]

  return <div style={{ display: currentStep === 'backendConfig' ? 'block' : 'none' }}>
    <Spin spinning={loading}>
      <Form {...layout} style={{ marginTop: 30 }} form={form}>
        <DetailCard title={'后端服务配置'}>
          <div className="backend-config-layout">
            <div className="backend-config-box">
              <Form.Item
                label={'后端接入Connector类型'}
                name="connectorTypeId"
                rules={[{ required: true, message: '请选择后端接入Connector类型' }]}
              >
                <Select placeholder={'请选择'} disabled={disable} onChange={(e,option:any)=>{
                  // console.log(e,option);
                  
                  handleChange(option.children)
                }}>
                  {connectType.map((item: any) => {
                    return (
                      <Option value={item.id}>
                        {item.protocol}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={'后端接入Connector'}
                name="connectorId"
                rules={[{ required: true, message: '请选择后端接入Connector' }]}
              >
                <Select placeholder={'请选择'} disabled={disable} onChange={(value) => {
                  getConnectInfo({ id: value }).then(res => {
                    setDataList(res.data || [])
                    console.log(res.data);
                    res.data.map((item:any)=>{
                      if(item.regularValue!==''){
                        form.setFields([
                          {
                            name:item.key,
                            value:item.regularValue
                          }
                        ])
                      }
                    })
                  })
                }}>
                  {connectList.map((item: any) => {
                    return (
                      <Option value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="backend-config-box">
              <Form.Item
                label={'后端服务IP地址端口'}
                name="dataSystem"
                rules={[{ required: true }]}
              >
                <Input placeholder={'请选择'} disabled={disable}/>
              </Form.Item>
            </div>
            <div className="backend-config-box">
              <Form.Item
                label={'是否启用TLS'}
                name="useTls"
                
              // rules={[{ required: true }]}
              >
                <Checkbox.Group options={[{ "label": '', value: '1' }]} disabled={disable}/>
              </Form.Item>
            </div>
          </div>
        </DetailCard>
        <DetailCard title={'后端连接配置'}>
          <div className="backend-config-layout">
            <div className="backend-config-box">
              {dataList.map((item:any) => (
                <Form.Item
                  label={item.name}
                  name={item.key}
                  rules={[{ required: item.checkValue, message: item.placeHolder || `请输入${item.name}` }]}
                >
                  <FormTool
                    type={item.type + ''}
                    placeholder={item.placeHolder}
                    optionNameList={(item.optionName || '').split(',')}
                    optionValueList={(item.optionValue || '').split(',')}
                    regularValue={item?.regularValue?item.regularValue:''}
                  />
                </Form.Item>
              ))}
            </div>
            {/* <div className="backend-config-box">
              <Form.Item
                label={'数据库名称'}
                name="dataSystem2"
                rules={[{ required: true }]}
              >
                <Input placeholder={'请输入'} />
              </Form.Item>
            </div> */}
          </div>
        </DetailCard>
      </Form>
      <div className="publish-agent-action">
        {extraButton}
        <Button onClick={onPreviousStep} style={{ width: 100, marginRight: 16 }}>上一步</Button>
        <Button style={{ width: 100 }} type={"primary"} onClick={onNextStep}>下一步</Button>
      </div>
    </Spin>
  </div>
}
export default BackendConfig;
