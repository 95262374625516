import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Breadcrumb , Button} from "antd";
import CardComponent from "../component/CardComponent";
import { getrecorddetail } from "../../../api/callrecord";
import moment from "moment";
const CnumDetail: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [datasource, setdatasource] = useState<any>({});
  const test = {
    code: 200,
    data: {
      user: {
        gmtModified: 1521882278000,
        loginId: "XXX集团股份有限公司",
        tenantId: "24hgewuyr-vcx2-4219-a807-sd4356fgdsf",
        id: 7822,
        isAdmin: false,
        gmtCreate: 1521882279000,
        userId: 34782,
        status: 2,
      },
    },
    success: true,
    message: "success",
  };
  const dataList = [
    {
      title: "服务基本信息",
      data: [
        {
          key: "API名称：",
          value: datasource.apiName,
        },
        {
          key: "DataAddress：",
          value: datasource.dataAddress,
        },
        {
          key: "版本号：",
          value: "1.0",
        },
        {
          key: "API说明描述：",
          value: datasource.apiDescription,
        },
      ],
    },
    {
      title: "请求信息",
      data: [
        {
          key: "交易ID：",
          value: "234542345543453",
        },
        {
          key: "AccessKey：",
          value: datasource.accessKey,
        },
        {
          key: "版本号：",
          value: "1.0",
        },
        {
          key: "请求时间：",
          value: moment(datasource.start).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          key: "DataAddress：",
          value: datasource.dataAddress,
        },
        {
          key: "API说明描述：",
          value:
            "这段描述很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长",
        },
      ],
    },
    {
      title: "请求报文",
      data: [
        {
          key: "请求URL：",
          value: datasource.url,
        },
        {
          key: "请求头：",
          value: (datasource.requestHeader!==undefined && datasource.requestHeader!=="")?JSON.stringify(JSON.parse(datasource.requestHeader), null, 2):"",
        },
        // {
        //   key: "API说明描述：",
        //   value: "from-site:www.apaas.commodule:self-test",
        // },
        {
          key: "请求Body：",
          value: (datasource.requestBody!==undefined && datasource.requestBody!=="")?JSON.stringify(JSON.parse(datasource.requestBody), null, 2):"",
        },
      ],
    },
    {
      title: "响应报文",
      data: [
        {
          key: "响应码：",
          value: "200",
        },
        {
          key: "响应头：",
          value: (datasource.responseHeader!==undefined && datasource.responseHeader!=="")?JSON.stringify(JSON.parse(datasource.responseHeader), null, 2):"",
        },
        {
          key: "响应Body：",
          value:(datasource.responseBody!==undefined && datasource.responseBody!=="")?JSON.stringify(JSON.parse(datasource.responseBody), null, 2):"",
          
        },
      ],
    },
  ];
  useEffect(() => {
    getrecorddetail({ id: state.id }).then((res) => {
      if (res.code === 200) {
        setdatasource(res.data);
        console.log(JSON.parse(res.data.responseHeader));
        
      }
    });
  }, [state]);
  return (
    <div style={{ background: "#f7f8fa" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push("/cnum");
            }}
          >
            调用记录
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>交易详情</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "#FFF", marginTop: "17px" }}>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>交易详情</div>
        </div>
        <div>
          <CardComponent dataList={dataList} />
        </div>
        <div style={{marginTop:"20px",display:"flex",justifyContent:"center"}}>
          <Button onClick={()=>{
            history.go(-1)
          }}>返回</Button>
        </div>
      </div>
    </div>
  );
};

export default CnumDetail;
