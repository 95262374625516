import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, message, Table } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { getversionlist, postiversionremove } from "../../../api/interface";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss"
const InterfaceVersion: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [total, setTotal] = useState<number>(0);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [versionArr, setversionArr] = useState([])
  const [listload, setlistload] = useState(true);
  const [form] = Form.useForm();
  const history = useHistory();
  const columns = [
    {
      title: "接口名称",
      dataIndex: "interface_name",
      align: "center" as "center",
    },
    {
      title: "Dp_address",
      dataIndex: "dp_address",
      align: "center" as "center",
    },
    {
      title: "协议类型",
      dataIndex: "protocol",
      align: "center" as "center",
    },
    {
      title: "版本号",
      dataIndex: "version",
      align: "center" as "center",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: any) => {
        if (text === 1) {
          return "未发布"
        } else if (text === 2) {
          return "已发布上线"
        } else {
          return "已停用"
        }
      },
      align: "center" as "center",
    },
    {
      title: "连接状态",
      dataIndex: "connected_status",
      render: (text: any) => text === 1 ? (
        <div className={styles["approve-reject"]}> 未连接 </div>
      ) : (
        <div className={styles["approve-pass"]}> 已连接 </div>
      ),
    },
    {
      title: "操作",
      render: (text: any, recod: any) => {
        return (
          <>
            <Button type="link" onClick={() => onRemove(recod.id)}>删除</Button>
            <Button type="link" onClick={() => { history.push(`/resource/detail/${id}/${recod.id}`) }}>编辑</Button>
          </>
        )
      },
      align: "center" as "center",
    },
  ];
  const onRemove = (ida: any) => {
    postiversionremove({
      id: ida
    }).then((res) => {
      if (res.code === 200) {
        setPageParam({
          page: 1,
          pageSize: 10
        })
        versionList(pageParam.page, pageParam.pageSize, id)
        message.success("删除成功")
      }
    })
  }
  const versionList = useCallback((page: any, pagesize: any, interfaceid: any, dpadress?: any, protocol?: any) => {
    setlistload(true)
    getversionlist({
      page: page,
      page_size: pagesize,
      interface_id: interfaceid,
      dp_address: dpadress,
      protocol: protocol
    }).then((res) => {
      setversionArr(res.data.item)
      setTotal(res.data.total)
      setlistload(false)
    })
  }, [])
  const restSub = () => {
    form.resetFields()
    setPageParam({
      page: 1,
      pageSize: 10
    })
    versionList(pageParam.page, pageParam.pageSize, id, form.getFieldValue("dpAddress"), form.getFieldValue("xyType"))
  }
  const onSearch = () => {
    setPageParam({
      page: 1,
      pageSize: 10
    })
    versionList(pageParam.page, pageParam.pageSize, id, form.getFieldValue("dpAddress"), form.getFieldValue("xyType"))
  }
  useEffect(() => {
    versionList(pageParam.page, pageParam.pageSize, id)
    console.log(id);

  }, [id, pageParam.page, pageParam.pageSize, versionList])
  return (
    <div style={{ padding: "16px" }}>
      <Form
        form={form}
        layout="inline"
        onFinish={(v) => {
          console.log(v);
        }}
        style={{ marginBottom: "20px", width: "100%", position: "relative" }}
      >
        <Form.Item label="协议类型" name="xyType">
          <Input className="inpt" placeholder="请输入协议类型" />
        </Form.Item>
        <Form.Item label="Dp Address" name="dpAddress">
          <Input className="inpt" placeholder="请输入集合名称" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" icon={<SearchOutlined />} onClick={onSearch}>
            查询
          </Button>
        </Form.Item>
        <Form.Item>
          <Button htmlType="reset" icon={<ReloadOutlined />} onClick={restSub}>
            重置
          </Button>
        </Form.Item>
        <Button type="primary" style={{ position: "absolute", right: "16px" }} onClick={() => {
          history.push(`/resource/detail/${id}/${"create"}`)
        }}>+ 创建接口版本</Button>
      </Form>
      <Table
        columns={columns}
        dataSource={versionArr}
        loading={listload}
        pagination={{
          total,
          pageSize: pageParam.pageSize,
          current: pageParam.page,
          showSizeChanger: true,
          showTotal: (total) => `共 ${total}条记录`,
          onChange: (page, size) => setPageParam({ page, pageSize: size }),
        }}
      />
    </div>
  );
};
export default InterfaceVersion;
