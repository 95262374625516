//@ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Dropdown, Menu } from "antd";
const SceneLeft: React.FC<any> = (props) => {
  const [propsList, setPropslist] = useState<any>();
  const [category, setCategory] = useState<string>();
  const [cfList, setcfList] = useState<any>([]);
  const [cfsystem, setcfsystem] = useState<any>([]);
  const [cfplantForm, setcfplantForm] = useState<any>([]);
  // const [allList, setAlllist] = useState<[]>([]);
  const { changeSearch } = props;
  useEffect(() => {
    // console.log(props.data);

    if (props.data != undefined && props.data.length > 0) {
      let arr = props.data;
      var arrlist: any = [];
      arr?.map((item: any, index: number) => {
        item.dataSource.map((i: any) => {
          arrlist.push(i);
        });
        // setAlllist(arrlist);
        let newArr = arrlist.filter((item: any) => {
          return item.category === "002";
        });
        let newArr_0 = arrlist.filter((item: any) => {
          return item.category === "002";
        });
        let newArr_1 = arrlist.filter((item: any) => {
          return item.category === "002";
        });
        let newArr_2 = arrlist.filter((item: any) => {
          return item.category === "002";
        });
        var a = Object.assign([], newArr_0);
        var b = Object.assign([], newArr_1);
        var c = Object.assign([], newArr_2);
        setCategory("省级");
        setPropslist(newArr);
        filterObjaa(c);
        // let arrPro = newArr_0;
        // console.log(a);

        filterObj(a);
        filterObjTwice(b);
      });
    } else {
      setPropslist(undefined);
    }
  }, [props.data]);
  // 二级去重
  const filterObjTwice = (objcArray: any) => {
    for (var i = 0; i < objcArray.length; i++) {
      for (var j = i + 1; j < objcArray.length;) {
        if (objcArray[i].dp_address === objcArray[j].dp_address) {
          //通过id属性进行匹配；
          objcArray.splice(j, 1); //去除重复的对象；
        } else {
          j++;
        }
      }
    }
    setcfsystem(objcArray);
    return objcArray;
  };
  // 三级去重
  const filterObj = (objcArray: any) => {
    for (var i = 0; i < objcArray.length; i++) {
      for (var j = i + 1; j < objcArray.length;) {
        if (objcArray[i].system_id === objcArray[j].system_id) {
          //通过id属性进行匹配；
          objcArray.splice(j, 1); //去除重复的对象；
        } else {
          j++;
        }
      }
    }
    // console.log(objcArray);
    setcfList(objcArray);
    return objcArray;
  };
  // 三级去重
  const filterObjaa = (objcArray: any) => {
    for (var i = 0; i < objcArray.length; i++) {
      for (var j = i + 1; j < objcArray.length;) {
        if (objcArray[i].platform === objcArray[j].platform) {
          //通过platform属性进行匹配；
          objcArray.splice(j, 1); //去除重复的对象；
        } else {
          j++;
        }
      }
    }
    setcfplantForm(objcArray);
    // console.log(objcArray);

    return objcArray;
  };
  const renderList = (val: any, index: any, item: any) => {
    // console.log(123);
    if (val.color == "system") {
      return <></>;
    }

    // console.log(val.dataSource);
    if (
      props.data[index].dataSource.filter(
        (el: any) => el.system_id == item.system_id && el.category === "002"
      ).length > 0 &&
      val.dataSource
        .filter(
          (el: any) => el.system_id == item.system_id && el.category === "002"
        )
        .every((n: any) => n.healthState === 2) &&
      val.color != ""
    ) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={styles["linestyle"]}></div>
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-square"
              style={{
                color: val.color,
                // width: "25px",
                // height: "25px",
              }}
            ></i>
          </Dropdown>
          <span className={styles["littleBoxnumber"]}>
            {
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ).length
            }
          </span>
        </div>
      );
    } else if (
      props.data[index].dataSource.filter(
        (el: any) => el.system_id == item.system_id && el.category === "002"
      ).length > 0 &&
      val.dataSource
        .filter(
          (el: any) => el.system_id == item.system_id && el.category === "002"
        )
        .every((n: any) => n.healthState === 1) &&
      val.color != ""
    ) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={styles["linestyle"]}></div>
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-a-24gl-square"
              style={{
                color: val.color,
                // width: "25px",
                // height: "25px",
              }}
            ></i>
          </Dropdown>
          <span className={styles["littleBoxnumber_0"]}>
            {
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ).length
            }
          </span>
        </div>
      );
    } else if (
      props.data[index].dataSource.filter(
        (el: any) => el.system_id == item.system_id && el.category === "002"
      ).length > 0 &&
      val.dataSource
        .filter(
          (el: any) => el.system_id == item.system_id && el.category === "002"
        )
        .every((n: any) => n.healthState === 1 || 2) &&
      val.color != ""
    ) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={styles["linestyle"]}></div>
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-loukongzhengfangxing"
              style={{ color: val.color }}
            ></i>
          </Dropdown>
          <span className={styles["littleBoxnumber_0"]}>
            {
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ).length
            }
          </span>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={styles["linestyle"]}></div>
          <Dropdown
            overlay={hoverList(
              props.data[index].dataSource.filter(
                (el: any) =>
                  el.system_id == item.system_id && el.category === "002"
              ),
              val.color
            )}
            placement="bottomLeft"
            arrow
          >
            <i
              className="iconfont icon-xuxianzhengfangxing"
            // style={{ color: val.color }}
            ></i>
          </Dropdown>
        </div>
      );
    }
  };
  //hover显示
  const hoverList = (data: any, color: any) => {
    return (
      <Menu style={{ padding: "6px" }}>
        <div>{data[0].system_id}</div>
        <div className={styles["menuTitle"]}>
          {data.map((item: any) => {
            return (
              <div className={styles["menuClass"]} key={item.id}>
                <i
                  className={
                    item.healthState === 1
                      ? "iconfont icon-a-24gl-square"
                      : "iconfont icon-square"
                  }
                  style={{ color: color }}
                ></i>
                <div style={{ color: "#4E5969", marginLeft: "12px" }}>
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </Menu>
    );
  };
  return (
    <div style={{ width: "100%", height: "100%", display: "flex" }}>
      {propsList ? (
        <div style={{ display: "flex" }}>
          {cfsystem.length > 0 ? (
            <div className={styles["all"]}>
              <div className={styles["leftFirsttitle"]}>{category}</div>
              {cfsystem.map((item: any) => {
                return (
                  <div className={styles["leftTitle"]} key={item.dp_address}>
                    {item.dp_address}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <div style={{ height: "100%", marginLeft: "6px", marginTop: "12px" }}>
            {cfplantForm.map((item: any,index) => {
              // return props.data.map((i: any, index: any) => {
              //   if (
              //     props.data[index].dataSource.filter(
              //       (el: any) =>
              //         item.platform != "" && el.platform == item.platform
              //     ).length > 0
              //   ) {
              // return props.data[index].dataSource
              //   .filter(
              //     (el: any) =>
              //       item.platform != "" && el.platform == item.platform
              //   )
              //   .map((arr:any) => {
              // console.log(arr);
              if (item.platform) {
                return (
                  <div
                    className={styles["pingtaileftRight"]}
                    style={{ marginTop: "6px" }}
                    key={index}
                  >
                    {item.platform}
                  </div>
                );
              } else {
                return <></>;
              }

              // });
              // } else {
              //   return <></>;
              // }
              // });
            })}
          </div>
          <div
            style={{
              height: "100%",
              //   background: "cyan",
              flex: "1",
              padding: "12px",
            }}
          >
            {cfList.map((item: any) => {
              if (item.system_id) {
                return (
                  <div className={styles["leftmodules"]} key={item.name}>
                    <div
                      className={
                        styles[
                        item.constructState == 1
                          ? "modulesTitle_0"
                          : [item.constructState == 3 ? "modulesTitle" : ""]
                        ]
                      }
                      onClick={() => {
                        changeSearch({
                          id: item.systemId,
                          val: true,
                        });
                      }}
                    >
                      {item.system_id}
                    </div>
                    {
                      // allList.filter((el: any) => (el.system_id == item.system_id))
                      props.data.map((i: any, index: any) => {
                        console.log(
                          props.data[index].dataSource.filter(
                            (el: any) => el.system_id == item.system_id
                          )
                        );

                        if (
                          props.data[index].dataSource.filter(
                            (el: any) => el.system_id == item.system_id
                          ).length > 0
                        ) {
                          return (
                            <div>
                              <div className={styles["littleBox"]}>
                                {renderList(i, index, item)}
                              </div>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      })
                    }
                    <>
                      <div className={styles["linestyle"]}></div>
                      <i
                        className={
                          item.status === 2
                            ? "iconfont icon-liubianxing"
                            : "iconfont icon-hexagon"
                        }
                        style={{
                          color: "#882D8E",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                    </>
                  </div>
                );
              } else {
                return <></>
              }
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default SceneLeft;
