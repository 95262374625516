import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "../index.module.scss";
import image from "../images/picture.png";
import {
  Table,
  Space,
  Modal,
  Form,
  Input,
  Button,
  Switch,
  Avatar,
  message,
  Popconfirm,
  Select,
} from "antd";
import { WebAdminContext } from "../../../context/WebAdminContext";
import moment from "moment";
import "./index.scss";
import {
  getUserlist,
  postUserdisable,
  postUserenable,
  postUsercreate,
  getSubuserdelete,
  // getSubuserdetail
} from "../../../api/userlist";
// import { getpglistbyroleid } from "../../../api/permissionGroup";
const Subaccount = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  // const [permissionList, setpermissionList] = useState([]);
  // const [roleList,setroleList] = useState<any>([])
  const [listload, setlistload] = useState(true);
  const [total, setTotal] = useState<any>(0);
  const [userListParams, setuserListParams] = useState([]);
  const { userInfo } = React.useContext(WebAdminContext);
  // console.log(userInfo);

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };
  function onChange(e: any) {
    if (e.status === 1) {
      postUserdisable({ id: e.id }).then((res: any) => {
        if (res.code === 200) {
          userListMethod(1, 10);
          message.success("修改成功");
        }
      });
    } else {
      postUserenable({ id: e.id }).then((res: any) => {
        if (res.code === 200) {
          userListMethod(1, 10);
          message.success("修改成功 ");
        }
      });
    }
  }
  const cancel = (e: any) => { };
  const confirm = async (e: any) => {
    const { code } = await getSubuserdelete({ id: e, creator_id: userInfo.AccountId });
    if (code === 200) {
      userListMethod(1, 10);
      message.success("操作成功");
    }
  };
  const columns = [
    {
      title: "用户名",
      dataIndex: "nickname",
      key: "nickname",
      render: (text: any, record: any) => {
        return (
          <div className="nameBar">
            <Avatar className="img" src="http://192.168.12.156:32002/api/oss/file/avatar.png" />
            <span className="text">{text}</span>
            {/* {userInfo.AccountId === record.id ?
              <div className="icon" style={{ backgroundColor: "#FD9A16" }}>主</div> :
              <div className="icon" style={{ backgroundColor: "#D8D8D8", }}>子</div>
            } */}
          </div>
        );
      },
      align: "center" as "center",
    },
    {
      title: "角色",
      dataIndex: "user_role",
      key: "user_role",
      render: (text: any) => (text.name),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      align: "center" as "center",
    },
    {
      title: "最后登录时间",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      align: "center" as "center",
    },
    {
      title: "最后登录IP",
      dataIndex: "last_login_ip",
      key: "last_login_ip",
      align: "center" as "center",
    },
    // {
    //   title: "创建人",
    //   dataIndex: "create_user_info",
    //   render: (text: any) => <>{text.nick_name}</>,
    //   align: "center" as "center",
    // },
    {
      title: (
        <div>
          权限
          {/* <Tooltip placement='right' title=""> */}
          <InfoCircleOutlined style={{ marginLeft: "5px" }} />
          {/* </Tooltip> */}
        </div>
      ),
      dataIndex: "role",
      key: "role",
      align: "center" as "center",
    },
    {
      title: "启用/禁用",
      key: "status",
      dataIndex: "status",
      render: (text: any, record: any) => {
        // console.log(text, record);
        return (
          <Switch
            defaultChecked={record.status === 1 ? true : false}
            onChange={() => onChange(record)}
          />
        );
      },
      align: "center" as "center",
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          {/* <Button type="link">编辑</Button> */}
          <Popconfirm
            title="您确定要删除本条记录吗?"
            onConfirm={() => confirm(record.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      ),
      align: "center" as "center",
    },
  ];
  //角色选择
  const roleSelect = (e: any) => {
    // getpglistbyroleid({ role_id: e }).then((res: any) => {
    //   setpermissionList(res.data);
    // });
  };
  // const permissionSelect = () => {
  //   if (form.getFieldValue("roleid") === undefined) {
  //     message.warning("请先选择角色")
  //   }
  // }
  const createUse = () => {
    form.validateFields().then((res) => {
      postUsercreate({
        password: res.password,
        username: res.username,
        email: form.getFieldValue("email"),
        nickname: form.getFieldValue("nickname"),
        roleId: form.getFieldValue("roleid"),
        is_sub: true
        // redirect_url:form.getFieldValue("redirect_url")
        // permission_group_id: form.getFieldValue("permission_group_id"),
      }).then((respone) => {
        if (respone.code === 200) {
          message.success("创建成功");
          setIsModalVisible(false);
          userListMethod(1, 10);
        }
      });
    });
  };
  const userListMethod = useCallback(async (page: any, pageSize: any) => {
    setlistload(true);
    const { code, data, paginate } = await getUserlist({
      page: page,
      page_size: pageSize,
    });
    if (code === 200) {
      setlistload(false);
      setuserListParams(data.user_infos);
      setTotal(paginate?.total);
    }
  }, []);
  useEffect(() => {
    userListMethod(pageParam.page, pageParam.pageSize);
  }, [pageParam.page, pageParam.pageSize, userListMethod]);
  return (
    <div className={styles["essential"]}>
      <div className={styles["essential-top"]}>
        {/* <img src={image} className={styles['image']} alt="" /> */}
        <Avatar className={styles["image"]} src={image} />
        <div className={styles["message"]}>
          <div>
            用户名：<span className="value">{userInfo.NickName}</span>
          </div>
          <div>
            账号ID：<span className="value">{userInfo.AccountId}</span>
          </div>
          <div>
            注册时间：<span className="value">
              {moment(userInfo.CreatedAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        </div>
      </div>
      <div className={styles["essential-bottom"]}>
        <div className={styles["journal-title"]} style={{ fontWeight: "700" }}>
          子账户
        </div>
        <div>
          <div className={styles["establish-son"]} onClick={showModal}>
            {" "}
            + 创建子账号
          </div>
        </div>
        <Table
          columns={columns}
          loading={listload}
          dataSource={userListParams}
          style={{ marginTop: 26 }}
          pagination={{
            total,
            showTotal: (total) => `共 ${total}条记录`,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            // showQuickJumper: false,
            // hideOnSinglePage: true,
            showSizeChanger: true,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />

        <Modal
          title="创建子帐户"
          visible={isModalVisible}
          onOk={createUse}
          onCancel={handleCancel}
          okText="保存"
          cancelText="取消"
          width={800}
        >
          <Form
            form={form}
            name="control-hooks"
            // onFinish={onFinish}
            style={{ marginTop: 20 }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
          >
            <Form.Item
              name="username"
              label="用户名"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="密码"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: (_, value, callback) => {
                    const reg = new RegExp(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/);
                    if (!reg.test(value)) {
                      callback(
                        "请输入密码，密码由大写字母、小写字母和数字组成，长度为6 ~ 12个字符。"
                      );
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input.Password style={{ background: "white" }} />
            </Form.Item>
            <Form.Item
              name=""
              label="确认密码"
              dependencies={["password"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("两次密码输入不一致");
                  },
                }),
              ]}
            >
              <Input.Password style={{ background: "white" }} />
            </Form.Item>
            <Form.Item name="nickname" label="昵称">
              <Input />
            </Form.Item>
            <Form.Item name="email" label="邮箱">
              <Input />
            </Form.Item>
            <Form.Item
              name="roleid"
              label="角色"
              rules={[{ required: true, message: "请选择角色" }]}
            >
              <Select onChange={roleSelect}>
                <Option value={"2"}>普通用户</Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
            name="redirect_url"
            label="路由"
            rules={[{ required: true, message: "请选择路由" }]}
          >
            <Select
              defaultValue="请选择"
              // onChange={handleChange}
            >
              <Select
                defaultValue="请选择"
              // onChange={handleChange}
              >
                <Option value={"adminconsole"}>adminconsole</Option>
                <Option value={"useconsole"}>useconsole</Option>
            </Select>
          </Form.Item> */}
            {/* <Form.Item
              name="permission_group_id"
              label="权限组"
              rules={[{ required: true, message: "请选择权限" }]}
            >
              <Select onBlur={permissionSelect}>
                {permissionList?.map((item: any) => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item> */}
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default Subaccount;
