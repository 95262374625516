/**----------------------------------------------------------------------
 * API,http请求参数编辑组件
 * @param method [string] 请求方式
 * @param data [string] json字符串，所有用于展示的数据
 * @param onChange [function] 数据变化回调方法
 * @param dataHeaders [string] headers数据
 * @param onHeadersChange [function] headers数据变化回调
 * @param onPathChange [function] path数据变化回调
 * @param callback [function] 触发页面更新回调
 *
 * data 数据结构
 * {
 *  post: {
 *   json: {},
 *   form: []
 *  },
 *  query: [],
 *  headers: [],
 * }
 *-----------------------------------------------------------------------*/

import React, { Fragment, PureComponent } from 'react';
import HttpParamsForm from '../HttpParamsForm';
import QueryParamsForm from '../QueryParamsForm';
import HeadersParamsForm from '../HeadersParamsForm';
import PathParamsForm from '../PathParamsForm';
import PropTypes from 'prop-types';
import { Radio, Input } from 'antd';

const { TextArea } = Input;
const MethodTypes1 = {
  post: [
    {
      name: 'Body'
    },
    {
      name: 'Query'
    },
    {
      name: 'Headers'
    }
  ],
  get: [
    {
      name: 'Query'
    },
    {
      name: 'Headers'
    }
  ]
}
const MethodTypes2 = {
  post: [
    { name: 'Path' },
    {
      name: 'Body'
    },
    {
      name: 'Query'
    },
    {
      name: 'Headers'
    }
  ],
  get: [
    { name: 'Path' },
    {
      name: 'Query'
    },
    {
      name: 'Headers'
    }
  ]
}
class APIParamsRequestForm extends PureComponent {
  state = {
    // 选中请求方式的参数形式
    methodType: '',
    // 选中POST请求方式的参数形式
    paramType: 'json',
    // 请求方式对应的参数形式
    MethodTypes: JSON.parse(this.props.metaJson).APIRequestStructure?.path?.length ? MethodTypes2 : MethodTypes1
  }

  componentDidMount() {
    const { method, data = {} } = this.props;
    this.changeRadioType(method, data);
  }

  componentWillReceiveProps(nextProps) {
    const newMethod = nextProps.method;
    const oldMethod = this.props.method;
    // if (newMethod !== oldMethod || nextProps.data !== this.props.data) {
    if (newMethod !== oldMethod) {
      this.changeRadioType(newMethod, nextProps.data || {});
    }
    if (JSON.parse(this.props.metaJson)?.APIRequestStructure?.path && JSON.stringify(JSON.parse(nextProps.metaJson).APIRequestStructure.path) !== JSON.stringify(JSON.parse(this.props.metaJson).APIRequestStructure.path)) {
      if (JSON.parse(nextProps.metaJson).APIRequestStructure.path.length) {
        this.setState({
          MethodTypes: MethodTypes2,
          methodType: MethodTypes2[newMethod][0].name
        })
      } else {
        this.setState({
          MethodTypes: MethodTypes1,
          methodType: MethodTypes1[newMethod][0].name
        })
      }
    }
  }

  /**
   * 首次进来或更新页面是重置radio选中第一个
   * @param method [string] 请求方式
   */
  changeRadioType = (method, data) => {
    const { MethodTypes } = this.state
    const radioData = MethodTypes[method] || [];
    let paramType = (data && data[method] && data[method].type) || 'form';
    this.setState({
      paramType,
      methodType: (radioData[0] && radioData[0].name) || MethodTypes.get[0].name
    });
  }

  // 获取对应post，get对应数据
  getDataByMethod = (method, methodType, paramType, data) => {
    method = (method && method.toLowerCase()) || "";
    if (methodType?.toLowerCase() === 'query') {
      return (data && data.query) || [];
    } else if (method === 'post') {
      if (method && data && data[method]) {
        let methodData = data[method];
        if (methodData && methodData[paramType]) {
          return methodData[paramType];
        }
      }
    }

    return "";
  }

  /**
   * 根据请求方式渲染不同的页面结构
   */
  switchMethodDOM = () => {
    const { option = {}, data = {}, onChange, method } = this.props;
    const { methodType, paramType } = this.state;
    let allData = {};
    allData = data || {};

    // 获取显示组件的数据
    let viewData = this.getDataByMethod(method, methodType, paramType, allData);

    switch (method) {
      case 'get':
        viewData = JSON.stringify(viewData || []);
        return <QueryParamsForm
          data={viewData}
          onChange={onChange}
        />;
      case 'post':
        // post请求下有 Body和Query
        if (methodType === 'Query') {
          viewData = JSON.stringify(viewData || []);
          return <QueryParamsForm
            data={viewData}
            onChange={(data) => onChange(data, 'post_query')}
          />;
        } else {
          // post下面请求的Body的参数形式
          // viewData = JSON.stringify(viewData);

          return this.switchContentByParamType(paramType, viewData);
        }
      default:
        viewData = JSON.stringify(viewData || []);

        return <QueryParamsForm
          data={viewData}
          onChange={onChange}
        />;
    }
  }

  /**
   * 根据POST请求方式下参数形式不同，渲染不同的格式
   * @param paramType [string] post请求参数类型
   * @param viewData [object | array] 参数的展示数据
   */
  switchContentByParamType = (paramType, viewData) => {
    const { option = {}, onChange, showDimensions } = this.props;
    let viewDataStr = JSON.stringify(viewData);
    switch (paramType) {
      case 'form':
        return <QueryParamsForm
          btnText="添加form参数"
          data={viewDataStr}
          onChange={(data) => onChange(data, null, paramType)}
        />;
      case 'json':
        return <HttpParamsForm
          showConstantVal={true}
          paramType={paramType}
          isCloseUpdate={this.props.isCloseUpdate}
          handleImportTransformJson={this.props.handleImportTransformJson}
          option={option}
          showDimensions={showDimensions}
          data={viewDataStr}
          onChange={(data) => onChange(data, null, paramType)}
        />;
      case 'file':
        return <TextArea
          value={viewData}
          onChange={(e) => onChange(e.target.value, null, paramType)}
          autoSize={{ minRows: 6, maxRows: 8 }}>
        </TextArea>;
      case 'raw':
        return <TextArea
          value={viewData}
          onChange={(e) => onChange(e.target.value, null, paramType)}
          autoSize={{ minRows: 6, maxRows: 8 }}>
        </TextArea>;
      default:
        return '';
    }
  }

  /**
   * post下面请求参数的方式变化change
   */
  onParamTypeChange = (e) => {
    let value = e.target.value;
    const paramTypes = ['form', 'file', 'raw'];

    // 不是json格式的时候去更新页面
    if (paramTypes.indexOf(value) > -1) {
      this.props.callback && this.props.callback();
    }

    this.setState({
      paramType: value
    });

    const onParamTypeChange = this.props.onParamTypeChange;
    onParamTypeChange && onParamTypeChange(value);
  }

  /**
   * 参数格式change回调方法
   */
  radioMethodChange = (e) => {
    let value = e.target.value;

    // 如果是Query，需要去更新传入的数据，这样props中的数据就是最新的
    if (value === 'Query') {
      this.props.callback && this.props.callback();
    }

    this.setState({
      methodType: value
    });
  }

  /**
   * 根据用户选择的请求方式（GET，POST）去渲染是哪种参数形式
   * 切换[Body,Query,Headers],[Query,Headers]
   */
  renderRadioMethod = () => {
    const { method } = this.props;
    let { methodType, MethodTypes } = this.state;
    const radioData = MethodTypes[method] || [];

    return <div style={{ textAlign: 'center' }}>
      <Radio.Group
        value={methodType}
        onChange={this.radioMethodChange}
        buttonStyle="solid"
        size="large"
      >
        {
          radioData.map(radioItem => {
            return <Radio.Button key={radioItem.name} value={radioItem.name}>{radioItem.name}</Radio.Button>
          })
        }
      </Radio.Group>
    </div>
  }

  /**
   * 根据是选择的`Body,Query`还是`Headers`来渲染不同的结构
   */
  renderMethodOrHeaders = () => {
    const { methodType, paramType } = this.state;
    const { onHeadersChange, onPathChange, dataHeaders = [], dataPath = [], method } = this.props;
    let isHeaders = methodType === 'Headers';
    let isPath = methodType === 'Path';

    return <div style={{ marginTop: "20px", padding: "15px", backgroundColor: "rgba(236,238,241,.67)" }}>
      <div style={isPath ? {} : { display: "none" }}>
        <PathParamsForm
          data={dataPath || []}
          onChange={onPathChange}
        />
      </div>
      <div style={isHeaders ? {} : { display: "none" }}>
        <HeadersParamsForm
          data={dataHeaders || []}
          onChange={onHeadersChange}
        />
      </div>
      <div style={(isHeaders || isPath) ? { display: "none" } : {}}>
        {
          (method === 'post' && methodType === 'Body') ?
            <Radio.Group
              style={{ marginBottom: '15px' }}
              value={paramType}
              onChange={this.onParamTypeChange}
            >
              <Radio value="form" key={'form'}>form</Radio>
              <Radio value="json" key={'json'}>json</Radio>
              {/* <Radio value="file" disabled={true} key={'file'}>file</Radio>
              <Radio value="raw" key={'raw'} disabled={true}>raw</Radio> */}
            </Radio.Group> : null
        }
        {
          this.switchMethodDOM()
        }
      </div>
    </div>
  }

  render() {
    return <div ref={this.myRef}>
      {
        this.renderRadioMethod()
      }

      {
        this.renderMethodOrHeaders()
      }
    </div>
  }

}

APIParamsRequestForm.propTypes = {
  method: PropTypes.string,
  data: PropTypes.object,
  onChange: PropTypes.func
};

export default APIParamsRequestForm;
