import { postClient,getClient } from "./requestClient";
import { POST_INTERFACE_CREATE , POST_VERSION_MODIFY,GET_DATABASE_LIST,POST_VERSION_REMOVE,POST_INTERFACE_MODIFY,POST_INTERFACE_REMOVE,GET_VERSION_LIST,GET_INTERFACE_LIST,POST_INTERFACE_HTTPCREATE,GET_SYSTEM_IDLIST} from "./index";

//接口创建
export const postinterface = async (params: any) =>
    await postClient<any>(POST_INTERFACE_CREATE, params);

//创建接口HTTP版本
export const postinterfacehttpCreate = async (params: any) =>
    await postClient<any>(POST_INTERFACE_HTTPCREATE, params);

//系统id&name列表
export const getsystemidlist = async (params: any) =>
    await getClient<any>(GET_SYSTEM_IDLIST, params);

//接口列表
export const getinterfacelist = async (params: any) =>
    await getClient<any>(GET_INTERFACE_LIST, params);

//接口板本列表
export const getversionlist = async (params: any) =>
    await getClient<any>(GET_VERSION_LIST, params);

//删除接口
export const postinterfaceremove = async (params: any) =>
    await postClient<any>(POST_INTERFACE_REMOVE, params);

//获取接口详情
export const getinterfacedetail = async (params: any) =>
    await getClient<any>(`/api/interface/${params.id}/detail`);

//修改接口
export const postinterfacemodify = async (params: any) =>
    await postClient<any>(POST_INTERFACE_MODIFY, params);

//删除接口版本
export const postiversionremove = async (params: any) =>
    await postClient<any>(POST_VERSION_REMOVE, params);

//获取接口版本详情
export const getversiondetail = async (params: any) =>
    await getClient<any>(`/api/interface/version/${params.id}/detail`);

//修改接口版本
export const postversionmodify = async (params: any) =>
    await postClient<any>(POST_VERSION_MODIFY, params);

//接口database查询
export const getdatabaselist = async (params: any) =>
    await getClient<any>(GET_DATABASE_LIST, params);