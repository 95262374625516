import { useRef, useEffect, useState } from 'react'
import AllSystem from './AllSystem'
import './index.scss'
import { Tabs, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons"
import CreateSystem from "./MySystem/createSystem";
import Tools from "@util/tools";
const { TabPane } = Tabs;

export default function SystemList() {
  const allRef = useRef<any>()
  const systemRef = useRef<any>()
  const [allList, setallList] = useState()
  const [tagkey, settagkey] = useState("1")
  function callback(key: any) {
    settagkey(key)
    Tools.setStorage("__tag__", key)
  }

  useEffect(() => {
    let tag = Tools.getStorage("__tag__")
    if (tag) {
      settagkey(tag)
    }
    setallList(allRef.current)
  }, [tagkey])

  return (
    <div className='systembar'>
      <Button
        className="addSystem"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          systemRef.current.setSystemVisible(true)
        }}
      >创建系统/平台</Button>
      <CreateSystem allref={allList} tag="1" ref={systemRef} />
      <Tabs activeKey={tagkey} onChange={callback}>
        <TabPane tab="所有系统/平台" key="1" />
        <TabPane tab="我的系统/平台" key="2" />
      </Tabs>
      <AllSystem ref={allRef} tag={tagkey} />
    </div>
  )
}
