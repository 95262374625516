import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  Form,
  Input,
  Button,
  Select,
  Divider,
  Popconfirm,
  Table,
  Badge,
} from "antd";
import {
  getapprovalList,
  getcentralInterconnection,
} from "../../api/interconnection";
import moment from "moment";
const Approval: React.FC = () => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [TabsKey, setTabsKey] = useState<string>("1");
  const [listload, setlistload] = useState(false);
  const [datasource, setdatasource] = useState([]);
  const [dataCount, setdataCount] = useState<any>({});
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      // colSpan:0
    },
    {
      title: "来源中枢实例名称",
      dataIndex: "blockchainName",
      key: "blockchainName",
      // render: (text: any) => text.name,
    },
    {
      title: "来源中枢实例编码",
      dataIndex: "blockchainCode",
      key: "blockchainCode",
      // align: "center" as "center",
    },
    {
      title: "申请方",
      dataIndex: "blockchainCode",
      key: "blockchainCode",
      // align: "center" as "center",
    },
    {
      title: "联系人",
      dataIndex: "applyContacts",
    },
    {
      title: "联系方式",
      dataIndex: "applyPhone",
      // render: (text: any) => (
      //   <>
      //     {text === 0 && <Badge color="#FFAA58" text="待审批" />}
      //     {text === 1 && <Badge color="#4CEFBE" text="通过" />}
      //     {text === 2 && <Badge color="#FF5353" text="不通过" />}
      //   </>
      // ),
    },
    {
      title: "申请时间",
      dataIndex: "updateTime",
      render:(text:any)=><>{moment(text*1000).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <>
          {record.type === 2 && (
            <>
              <Button type="link">重新提交</Button>
              <Divider type="vertical" />
            </>
          )}
          {record.type === 0 && (
            <>
              <Button
                type="link"
                onClick={() => {
                  history.push(`/applyrender`);
                }}
              >
                查看资源
              </Button>
              <Divider type="vertical" />
            </>
          )}

          <Button
            type="link"
            onClick={() => {
              history.push({
                pathname: "/approvaldetail",
                state: {
                  type: "审批",
                  pathname: "/interconnectionapplication",
                  id: text,
                  state: record.state,
                  name:record.fileAddress
                },
              });
            }}
          >
            审批详情
          </Button>
        </>
      ),
    },
  ];
  const columns_ = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      // colSpan:0
    },
    {
      title: "来源中枢实例名称",
      dataIndex: "blockchainName",
      key: "blockchainName",
      // render: (text: any) => text.name,
    },
    {
      title: "来源中枢实例编码",
      dataIndex: "blockchainCode",
      key: "blockchainCode",
      // align: "center" as "center",
    },
    {
      title: "申请方",
      dataIndex: "blockchainCode",
      key: "blockchainCode",
      // align: "center" as "center",
    },
    {
      title: "联系人",
      dataIndex: "applyContacts",
    },
    {
      title: "联系方式",
      dataIndex: "applyPhone",
      // render: (text: any) => (
      //   <>
      //     {text === 0 && <Badge color="#FFAA58" text="待审批" />}
      //     {text === 1 && <Badge color="#4CEFBE" text="通过" />}
      //     {text === 2 && <Badge color="#FF5353" text="不通过" />}
      //   </>
      // ),
    },
    {
      title: "申请时间",
      dataIndex: "updateTime",
      render:(text:any)=><>{moment(text*1000).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <>
          {record.type === 2 && (
            <>
              <Button type="link">重新提交</Button>
              <Divider type="vertical" />
            </>
          )}
          {record.type === 0 && (
            <>
              <Button
                type="link"
                onClick={() => {
                  history.push(`/applyrender`);
                }}
              >
                查看资源
              </Button>
              <Divider type="vertical" />
            </>
          )}
          <Button
            type="link"
            onClick={() => {
              history.push({
                pathname: "/approvaldetail",
                state: {
                  type: "审批",
                  pathname: "/interconnectionapplication",
                  id: text,
                  state: record.state,
                  name:record.fileAddress
                },
              });
            }}
          >
            审批详情
          </Button>

        </>
      ),
    },
  ];
  const dataType = (type: any) => {
    if (type === "1") {
      return form.getFieldValue("state") === undefined
        ? 3
        : form.getFieldValue("state");
    } else if (type === "2") {
      return 0;
    } else if (type === "3") {
      return 1;
    } else if (type === "4") {
      return 2;
    }
  };
  const dataList = useCallback((type: any) => {
    setlistload(true);
    getapprovalList({
      ...pageParam,
      state: dataType(type),
      direction: 1,
      name:
        form.getFieldValue("name") === undefined
          ? ""
          : form.getFieldValue("name"),
      code:
        form.getFieldValue("code") === undefined
          ? ""
          : form.getFieldValue("code"),
    }).then((res: any) => {
      if (res.code === 200) {
        setdatasource(res.data);
        setlistload(false);
      }
    });
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      state: 3,
    });
    dataList(TabsKey);
    getcentralInterconnection({
      direction: 1,
    }).then((res: any) => {
      setdataCount(res.data);
    });
  }, [pageParam, TabsKey]);
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>互联审批</div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Tabs
          activeKey={TabsKey}
          size="large"
          onChange={(e) => {
            setTabsKey(e);
          }}
        >
          <Tabs.TabPane
            tab={`全部 (${
              dataCount.count0!==undefined? dataCount.count0 + dataCount.count1 + dataCount.count2:0
            })`}
            key="1"
          ></Tabs.TabPane>
          <Tabs.TabPane
            tab={`待审批 (${dataCount.count0===undefined?0:dataCount.count0})`}
            key="2"
            style={{ fontSize: "20px" }}
          ></Tabs.TabPane>
          <Tabs.TabPane
            tab={`通过 (${dataCount.count1===undefined?0:dataCount.count1})`}
            key="3"
            style={{ fontSize: "20px" }}
          ></Tabs.TabPane>
          <Tabs.TabPane
            tab={`不通过 (${dataCount.count2===undefined?0:dataCount.count2})`}
            key="4"
            style={{ fontSize: "20px" }}
          ></Tabs.TabPane>
        </Tabs>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="name" label="申请方实例名称">
            <Input placeholder="请输入实例名称" />
          </Form.Item>
          <Form.Item name="code" label="申请方实例编码">
            <Input placeholder="请输入实例编码" />
          </Form.Item>
          {TabsKey === "1" && (
            <Form.Item name="state" label="审批状态">
              <Select
                style={{ width: "127px" }}
                defaultValue={3}
                options={[
                  {
                    value: 3,
                    label: "全部",
                  },
                  {
                    value: 0,
                    label: "待审批",
                  },
                  {
                    value: 1,
                    label: "通过",
                  },
                  {
                    value: 2,
                    label: "不通过",
                  },
                ]}
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={() => {
                dataList(TabsKey);
              }}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={TabsKey === "1" ? columns : columns_}
          loading={listload}
          dataSource={datasource}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};
export default Approval;
