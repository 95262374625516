import React, { useState, useCallback, useEffect } from "react";
// import { PartitionOutlined, HomeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Button, Table, Divider, message, Breadcrumb,Popconfirm } from "antd";
import { getconnectorlist, postconnectorromove } from "../../api/connector";
import moment from "moment";
const Connector: React.FC = () => {
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [listdata, setlistdata] = useState([]);
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Connector协议类型",
      dataIndex: "protocol",
      key: "protocol",
    },
    {
      title: "插件编码",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "插件名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "在线实例数",
      dataIndex: "instanceNum",
      key: "instanceNum",
      // render:(text:any,record:any)=><a onClick={()=>{
      //   history.push(`/OnlineConnector/${record.protocol}`)
      // }}>{text}</a>
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "更新时间",
      dataIndex: "updateDate",
      key: "updateDate",
      render:(text:any)=><>{moment(text*1000).format("YYYY-MM-DD HH:mm:ss")}</>
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any,record:any) => (
        <>
          <a onClick={()=>{
            history.push({
              pathname:'/connectordetail',
              state:{
                type:"detail",
                id:text,
                code:record.code
              }
            })
          }}>查看详情</a>
          {/* <Divider type="vertical" /> */}
          {/* <Popconfirm
            title="您确定要删除该条记录?"
            onConfirm={() => Remove(record.code)}
            okText="是"
            cancelText="否"
          >
            <a>删除</a>
          </Popconfirm> */}
          <Divider type="vertical" />
          <a onClick={()=>{
            history.push({
              pathname:`OnlineConnector`,
              state:{
                protocol:record.protocol,
                code:record.code
              }
            })
          }}>检测记录</a>
        </>
      ),
    },
  ];
  const Remove = (text: any) => {
    postconnectorromove({code:text}).then((res)=>{
      if(res.code===200){
        getList()
        message.success("删除成功!")
      }
    })
  };
  const getList = useCallback(() => {
    setlistload(true);
    getconnectorlist({
      ...pageParam,
      protocol:""
    }).then((res:any) => {
      if (res.code === 200) {
        setlistdata(res.data);
        setTotal(res.pagination.total)
        setlistload(false);
      }
    });
  }, [pageParam]);
  useEffect(() => {
    getList();
  }, [pageParam]);
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>Connector插件</div>
        </div>
      </div>
      {/* <div style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          onClick={() => {
            history.push({
              pathname:'/connectordetail',
              state:{
                type:"add",
                id:'',
                code:''
              }
            })
          }}
        >
          + 新建应用插件
        </Button>
      </div> */}
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={listdata}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};
export default Connector;
