// @ts-nocheck
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Select,
  Row,
  Col,
  Button,
  message,
  Radio,
  TreeSelect
} from "antd";
import {
  getCreateCategry,
  postCreateSystem,
  getHealthcheck,
  postSystemmodify,
} from "../../../api/scenario";
import "./index.scss";
// import { CloseCircleTwoTone } from '@ant-design/icons';
const { Option } = Select;

function CreateSystem({ props }: any, ref: any) {
  const addressChildren: any = [];
  const [form] = Form.useForm();
  const [btnLoad, setBtnload] = useState<boolean>(false);
  const [category, setCategory] = useState<any>([]);
  const [healthCheck, sethealthCheck] = useState<any>({
    constructState: 1,
    healthState: 1,
    id: "",
  });
  const [hasFeedstatus, sethasFeedback] = useState<any>(false);
  const [healthId, sethealthId] = useState(null);
  const [selectlabel, setlabel] = useState<string>("系统");
  const [healthtype, sethealthtype] = useState<any>("success");
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  useImperativeHandle(ref, () => ({
    // formVal: form.getFieldValue,
  }));

  const createData = (v: any, data: any) => {
    let obj = {
      // id: "",
      name: "",
      color: "system",
      dataSource: [
        {
          category: v.category,
          dp_address: v.departments,
          system_id: v.name,
          // id: "",
          key: "",
          name: "",
          status: data.healthState, //是否连接中枢
          // flagId: data.id,
          systemId: data.id,
          healthState: healthCheck.healthState, //接口健康状态
          constructState: healthCheck.constructState, //系统平台状态
          platform: v.platform,
        },
      ],
    };
    console.log(obj);
    props.onCreateSystemOk(obj);
    form.resetFields();
  };
  const onFinish = async (v: any) => {
    if (selectlabel === "系统") {
      console.log(1);
      const res = await postCreateSystem({
        address: v.address,
        categoryId: v.categoryId,
        name: v.name,
        protocol: v.protocol,
        desc: v.desc,
      })
      console.log(res);
      if (res.code === 200) {
        message.success("创建成功")
        createData(v, data)
        // props.allRef.systemList()
      }
    } else {
      console.log(2);
      const res = await postCreatePlatform({
        categoryIds: v.categoryId,
        desc: v.desc,
        name: v.name,
      })
      console.log(res);
      if (res.code === 200) {
        message.success("创建成功")
        createData(v, data)
        // props.allRef.platformList()
      }
    }
    form.resetFields();
    setSystemVisible(false)

    // if (healthId) {
    //   const { code, data } = await postSystemmodify({
    //     code: v.code,
    //     category: v.category,
    //     domain: v.departments,
    //     healthUrl: v.healthUrl,
    //     name: v.name,
    //     protocol: v.agreement,
    //     url: v.url,
    //     id: healthId,
    //     platform: v.platform ? v.platform : null,
    //   });
    //   if (code === 200) {
    //     message.success("创建成功");
    //     createData(v, data)
    //     // console.log(data);
    //     sethasFeedback(false);
    //   }
    // } else {
    //   const { code, data } = await postCreateSystem({
    //     code: v.code,
    //     category: v.category,
    //     domain: v.departments,
    //     healthUrl: v.healthUrl,
    //     name: v.name,
    //     protocol: v.agreement,
    //     url: v.url,
    //     platform: v.platform ? v.platform : null,
    //   });
    //   if (code === 200) {
    //     message.success("创建成功");
    //     // console.log(data);
    //     createData(v, data);
    //     sethasFeedback(false);
    //   }
    // }
    // sethealthId(null)
    // sethealthCheck({
    //   constructState: 1,
    //   healthState: 1,
    //   id: ""
    // })
  };
  //健康检测
  const checkClick = () => {
    setBtnload(true);
    form
      .validateFields()
      .then(async (res) => {
        getHealthcheck({
          url: form.getFieldValue("healthUrl"),
        })
          .then(({ code, data }) => {
            if (code === 200) {
              // console.log(data);
              setBtnload(false);
              sethealthtype("success");
              sethasFeedback(true);
              sethealthId(data.id);
              sethealthCheck(data);
            } else {
              sethasFeedback(true);
              sethealthtype("error");
            }
          })
          .catch((err) => {
            message.warn("请输入正常可访问的网址");
            setBtnload(false);
          });
      })
      .catch(() => {
        setBtnload(false);
      });
    // setTimeout(() => {
    // }, 3000);
  };

  //获取类别列表
  const getCategryList = () => {
    getCreateCategry({
      page: 1,
      page_size: 20
    }).then((res) => {
      // console.log(res);
      if (res.code === 200) {
        setCategory(mapCategry(res.data))
        // console.log(mapCategry(res.data));
      }
    })
  }
  //递归映射
  function mapCategry(data: any) {
    return data.map((item: any) => {
      item.title = item.name;
      item.value = item.id;
      if (item.subCategories) {
        item.children = mapCategry(item.subCategories)
      }
      return item
    })
  }

  const onChange = (e: any) => {
    // console.log(e);
    setlabel(e.target.value);
  };
  useEffect(() => {
    // console.log(healthCheck);
    getCategryList();
  }, [props.createSystemFlag]);

  return (
    <div>
      <Modal
        title="创建系统/平台"
        visible={props.createSystemFlag}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          // setChecked(false)
          sethealthId(null)
          form.resetFields();
          sethealthCheck({
            constructState: 1,
            healthState: 1,
            id: ""
          })
          props.setCreateSystemFlag(false);
          sethasFeedback(false);
        }}
        width={700}
        okText="新建"
        cancelText="取消"
        className="sceneModal"
      >
        <Form
          form={form}
          onFinish={onFinish}
          {...layout}
          initialValues={{
            category: "001",
            departments: "自然资源局",
            agreement: "http",
          }}
          className="createForm"
        >
          <Form.Item label="类别">
            <Radio.Group
              onChange={onChange}
              value={selectlabel}
              defaultValue={selectlabel}
            >
              <Radio value={"系统"}>系统</Radio>
              <Radio value={"平台"}>平台</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="名称"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="描述" name="desc">
            <Input.TextArea showCount maxLength={200} placeholder='请输入内容，最多不超过200字' />
          </Form.Item >

          <Form.Item label="所属类目" name="categoryId"
            rules={[{ required: true, message: 'Please input your category!' }]}
          >
            <TreeSelect
              // value={111}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={category}
              // placeholder="Please select"
              multiple={selectlabel === "平台" ? true : false}
              treeDefaultExpandAll={false}
              onChange={() => { }}
            />
          </Form.Item>
          {selectlabel === "系统" && <>
            <Form.Item label="访问地址" name="address"
              rules={[
                {
                  required: true,
                  message: 'Please input your address!',
                  pattern: /http[s]{0,1}:\/\/([\w.]+\/?)\S*/,
                }
              ]}
            >
              <Select mode="tags">
                {addressChildren}
              </Select>
            </Form.Item >
            <Form.Item label="协议" name="protocol"
              rules={[{ required: true, message: '请输入正确的地址!' }]}
            >
              <Select>
                <Option value="http">http</Option>
                <Option value="webSocket">webSocket</Option>
              </Select>
            </Form.Item>
          </>}
        </Form>
      </Modal>
    </div>
  );
}
export default forwardRef(CreateSystem);
