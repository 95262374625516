import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Space,
  Badge,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  Table,
  message,
} from "antd";
import {
  gettestdetail,
  posttestcreate,
  getServiceList,
  posttestonline,
  posttestupdate,
  detailServiceItem,
} from "../../../api/serviceManagement";
import { getcredentialdetails } from "../../../api/credential";
import { isTemplateMiddle } from "typescript";
const ServiceTestDetail: React.FC = () => {
  const optionsPost = [
    { label: "Body", value: "Body" },
    { label: "Query", value: "Query" },
    { label: "Headers", value: "Headers" },
    { label: "Path", value: "Path" },
  ];
  const optionsGet = [
    { label: "Query", value: "Query" },
    { label: "Headers", value: "Headers" },
    { label: "Path", value: "Path" },
  ];
  const history = useHistory();
  const [form] = Form.useForm();
  const [seleVal, setseleVal] = useState("");
  const [formBody] = Form.useForm();
  const [formQuery] = Form.useForm();
  const [formHeader] = Form.useForm();
  const [formPath] = Form.useForm();
  const { TextArea } = Input;
  const { state } = useLocation<any>();
  const [value, setValue] = useState(1);
  const [radValue, setradValue] = useState(optionsPost[0].value);
  const [dpOptions, setdpOptions] = useState([]);
  const [count, setCount] = useState(2);
  const [dataSource, setdataSource] = useState<any>([]);
  const [querySource, setquerySource] = useState<any>([]);
  const [headerSource, setheaderSource] = useState<any>([]);
  const [pathSource, setpathSource] = useState<any>([]);
  const [testTag, settestTag] = useState<any>(false);
  const [jsonValue, setjsonValue] = useState("");
  const [testJsonVal, settestJsonVal] = useState("");
  const [dataAddressVal, setdataAddressVal] = useState("");
  const [testBodyRadio,settestBodyRadio] = useState<any>("")
  const columns: any = [
    {
      title: <div>Key</div>,
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formBody}>
            <Form.Item label="" name={`key${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...dataSource];
                  arr[index].key = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: <div>Value</div>,
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formBody}>
            <Form.Item label="" name={`value${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...dataSource];
                  arr[index].value = e.target.value;
                  setdataSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any, index: number) => {
        return <a onClick={() => handleDelete(text, index)}>删除</a>;
      },
      width: 100,
      align: "center" as "center",
      fixed: "right",
    },
  ];
  const columns_1: any = [
    {
      title: <div>Key</div>,
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formQuery}>
            <Form.Item label="" name={`key${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...querySource];
                  arr[index].key = e.target.value;
                  setquerySource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: <div>Value</div>,
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formQuery}>
            <Form.Item label="" name={`value${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...querySource];
                  arr[index].value = e.target.value;
                  setquerySource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any, index: number) => {
        return <a onClick={() => handleDelete(text, index)}>删除</a>;
      },
      width: 100,
      align: "center" as "center",
      fixed: "right",
    },
  ];
  const columns_2: any = [
    {
      title: <div>Key</div>,
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formHeader}>
            <Form.Item label="" name={`key${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...headerSource];
                  arr[index].key = e.target.value;
                  setheaderSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: <div>Value</div>,
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formHeader}>
            <Form.Item label="" name={`value${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...headerSource];
                  arr[index].value = e.target.value;
                  setheaderSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any, index: number) => {
        return <a onClick={() => handleDelete(text, index)}>删除</a>;
      },
      width: 100,
      align: "center" as "center",
      fixed: "right",
    },
  ];
  const columns_3: any = [
    {
      title: <div>Key</div>,
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formPath}>
            <Form.Item label="" name={`key${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...pathSource];
                  arr[index].key = e.target.value;
                  setpathSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: <div>Value</div>,
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any, index: number) => {
        return (
          <Form form={formPath}>
            <Form.Item label="" name={`value${index}`}>
              <Input
                style={{ width: "100%" }}
                value={text}
                onChange={(e) => {
                  let arr = [...pathSource];
                  arr[index].value = e.target.value;
                  setpathSource(arr);
                }}
              />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any, index: number) => {
        return <a onClick={() => handleDelete(text, index)}>删除</a>;
      },
      width: 100,
      align: "center" as "center",
      fixed: "right",
    },
  ];
  useEffect(() => {
    if (seleVal === "POST") {
      setradValue(optionsPost[0].value);
    } else if (seleVal === "GET") {
      setradValue(optionsGet[0].value);
    } else {
      setradValue("Body");
    }
  }, [seleVal]);
  useEffect(() => {
    getServiceList({
      page: 1,
      pageSize: 9999,
    }).then((res) => {
      var arr: any = [];
      res.data.map((item: any, index: any) => {
        arr.push({
          label: item.dpAddress,
          value: item.dpAddress,
          protocolType: item.connectorType,
          apiName: item.apiName,
          id: item.id,
        });
      });
      setdpOptions(arr);
    });
  }, []);
  useEffect(() => {
    if (dataAddressVal !== "") {
      let arr: any = dpOptions.filter(
        (item: any) => item.value == form.getFieldValue("dataAddress")
      );
      detailServiceItem({
        id: arr[0].id,
      }).then((res) => {
        form.setFieldsValue({
          method: res.data.serviceManage.requestMethod,
          path: res.data.serviceManage.requestPath,
        });
        setseleVal(res.data.serviceManage.requestMethod);
        // console.log(JSON.parse(res.data.serviceManage.metaJson));
        let metaData = JSON.parse(res.data.serviceManage.metaJson);
        if (metaData.APIRequestStructure.post) {
          settestBodyRadio(metaData?.APIRequestStructure?.post?.type)
          setValue(metaData?.APIRequestStructure?.post?.type==="form"?1:2);
          // setjsonValue(JSON.stringify(metaData?.APIRequestStructure?.post?.json, null, 2))
          if (metaData.APIRequestStructure.post.type === "form") {
            let arr: any = [];
            metaData.APIRequestStructure.post?.form.map(
              (item: any, index: number) => {
                formBody.setFields([
                  {
                    name: [`key${index}`],
                    value: item.name,
                  },
                  {
                    name: [`value${index}`],
                    value: item.example,
                  },
                ]);
                arr.push({
                  id: item.id,
                  key: item.name,
                  value: item.example,
                });
              }
            );
            setdataSource(arr);
          } else {
            setdataSource([]);
            setjsonValue(
              JSON.stringify(metaData?.APIRequestStructure?.post?.json, null, 2)
            );
          }
        } else {
          setValue(1);
        }
        if (metaData.APIRequestStructure.query) {
          let arr: any = [];
          metaData.APIRequestStructure.query?.map(
            (item: any, index: number) => {
              formQuery.setFields([
                {
                  name: [`key${index}`],
                  value: item.name,
                },
                {
                  name: [`value${index}`],
                  value: item.example,
                },
              ]);
              arr.push({
                id: item.id,
                key: item.name,
                value: item.example,
              });
            }
          );
          setquerySource(arr);
        } else {
          setquerySource([]);
        }
        if (metaData.APIRequestStructure.pathValue) {
          let arr: any = [];
          metaData.APIRequestStructure.pathValue?.map(
            (item: any, index: number) => {
              formPath.setFields([
                {
                  name: [`key${index}`],
                  value: item.name,
                },
                {
                  name: [`value${index}`],
                  value: item.example,
                },
              ]);
              arr.push({
                id: item.id,
                key: item.name,
                value: item.example,
              });
            }
          );
          setpathSource(arr);
        } else {
          setpathSource([]);
        }
        if (metaData.APIRequestStructure.headers) {
          let arr: any = [];
          metaData.APIRequestStructure.headers?.map(
            (item: any, index: number) => {
              formHeader.setFields([
                {
                  name: [`key${index}`],
                  value: item.name,
                },
                {
                  name: [`value${index}`],
                  value: item.example,
                },
              ]);
              arr.push({
                id: item.id,
                key: item.name,
                value: item.example,
              });
            }
          );
          setheaderSource(arr);
        } else {
          setheaderSource([]);
        }
        if (metaData.APIRequestStructure.path) {
          let arr: any = [];
          metaData.APIRequestStructure.path?.map((item: any, index: number) => {
            formPath.setFields([
              {
                name: [`key${index}`],
                value: item.paramKey,
              },
              {
                name: [`value${index}`],
                value: item.paramName,
              },
            ]);
            arr.push({
              id: item.id,
              key: item.paramKey,
              value: item.paramName,
            });
          });
          setpathSource(arr);
        } else {
          setpathSource([]);
        }
      });
    }
  }, [dataAddressVal]);
  // useEffect(() => {
  //   console.log(dataSource, querySource, headerSource);
  // }, [dataSource, querySource, headerSource]);
  useEffect(() => {
    if (state.type === "create") {
      getcredentialdetails().then((res): any => {
        if (res.code === 200) {
          form.setFieldsValue({
            appKey: res.data.accessKey,
            appSecret: res.data.secretKey,
          });
        }
      });
    } else {
      gettestdetail({
        id: state.id,
      }).then((res) => {
        if (res.code === 200) {
          const resopne = res.data;
          setseleVal(resopne.method)
          form.setFieldsValue({
            testName: resopne.testName,
            dataAddress: resopne.dataAddress,
            appKey: resopne.appKey,
            appSecret: resopne.appSecret,
            path: resopne.path,
            method: resopne.method,
          });
          JSON.parse(resopne.query).map((item: any, index: number) => {
            formQuery.setFields([
              {
                name: [`key${index}`],
                value: item.key,
              },
              {
                name: [`value${index}`],
                value: item.value,
              },
            ]);
          });
          JSON.parse(resopne.header).map((item: any, index: number) => {
            formHeader.setFields([
              {
                name: [`key${index}`],
                value: item.key,
              },
              {
                name: [`value${index}`],
                value: item.value,
              },
            ]);
          });
          JSON.parse(resopne.pathValue).map((item: any, index: number) => {
            formPath.setFields([
              {
                name: [`key${index}`],
                value: item.key,
              },
              {
                name: [`value${index}`],
                value: item.value,
              },
            ]);
          });
          setquerySource(JSON.parse(resopne.query));
          setheaderSource(JSON.parse(resopne.header));
          setValue(resopne.bodyFormat*1);
          setpathSource(JSON.parse(resopne.pathValue))
          if (resopne.bodyFormat * 1 === 1) {
            JSON.parse(resopne.body).map((item: any, index: number) => {
              formBody.setFields([
                {
                  name: [`key${index}`],
                  value: item.key,
                },
                {
                  name: [`value${index}`],
                  value: item.value,
                },
              ]);
            });
            setdataSource(JSON.parse(resopne.body));
          } else {
            setjsonValue(resopne.body);
          }
          // if (resopne.testJson !== "") {
          //   let respone = JSON.parse(resopne.testJson);
            
            
          //   settestTag(true);
          //   form.setFieldsValue({
          //     qqbody: JSON.stringify(JSON.parse(respone.qqbody), null, 2),
          //     xyheader: JSON.stringify(respone.xyheader, null, 2),
          //     qqheader: respone.qqheader,
          //     xybody: respone.xybody,
          //     status: respone.code,
          //     qqurl:
          //       "http://192.168.14.247:43000/api/call/" +
          //       form.getFieldValue("dataAddress") +
          //       form.getFieldValue("path"),
          //   });
          // }
        }
      });
    }
  }, [state]);
  const saveMethods = () => {
    if (state.type === "create") {
      let arr: any = dpOptions.filter(
        (item: any) => item.value == form.getFieldValue("dataAddress")
      );
      form.validateFields().then((res) => {
        posttestcreate({
          testName: form.getFieldValue("testName"),
          dataAddress: form.getFieldValue("dataAddress"),
          appKey: form.getFieldValue("appKey"),
          appSecret: form.getFieldValue("appSecret"),
          path: form.getFieldValue("path"),
          header: JSON.stringify(headerSource),
          query: JSON.stringify(querySource),
          pathValue:JSON.stringify(pathSource),
          body: value == 1 ? JSON.stringify(dataSource) : jsonValue,
          method: form.getFieldValue("method"),
          testJson: testJsonVal,
          apiName: arr[0].apiName,
          protocolType: arr[0].protocolType,
          bodyFormat: value+"",
        }).then((res) => {
          if (res.code === 200) {
            history.push("/servicetest");
            message.success(res.data);
          }
        });
      });
    } else {
      let arr: any = dpOptions.filter(
        (item: any) => item.value == form.getFieldValue("dataAddress")
      );
      form.validateFields().then((res) => {
        posttestupdate({
          testName: form.getFieldValue("testName"),
          dataAddress: form.getFieldValue("dataAddress"),
          appKey: form.getFieldValue("appKey"),
          appSecret: form.getFieldValue("appSecret"),
          path: form.getFieldValue("path"),
          header: JSON.stringify(headerSource),
          query: JSON.stringify(querySource),
          body: value == 1 ? JSON.stringify(dataSource) : jsonValue,
          method: form.getFieldValue("method"),
          testJson: testJsonVal,
          apiName: arr[0].apiName,
          protocolType: arr[0].protocolType,
          bodyFormat: value+"",
          id: state.id,
        }).then((res) => {
          if (res.code === 200) {
            history.push("/servicetest");
            message.success(res.data);
          }
        });
      });
    }
  };
  const handleAdd = () => {
    const newData = {
      id: count,
      key: "",
      value: "",
    };
    if (radValue === "Body") {
      let arr = [...dataSource];
      let brr = [...arr, newData];
      brr.map((item: any, index: any) => {
        formBody.setFields([
          {
            name: [`key${index}`],
            value: item.key,
          },
          {
            name: [`value${index}`],
            value: item.value + "",
          },
        ]);
      });
      setdataSource(brr);
      setCount(count + 1);
    } else if (radValue === "Query") {
      let arr = [...querySource];
      let brr = [...arr, newData];
      brr.map((item: any, index: any) => {
        formQuery.setFields([
          {
            name: [`key${index}`],
            value: item.key,
          },
          {
            name: [`value${index}`],
            value: item.value + "",
          },
        ]);
      });
      setquerySource(brr);
      setCount(count + 1);
    } else if (radValue === "Headers") {
      let arr = [...headerSource];
      let brr = [...arr, newData];
      brr.map((item: any, index: any) => {
        formHeader.setFields([
          {
            name: [`key${index}`],
            value: item.key,
          },
          {
            name: [`value${index}`],
            value: item.value + "",
          },
        ]);
      });
      setheaderSource(brr);
      setCount(count + 1);
    } else {
      let arr = [...pathSource];
      let brr = [...arr, newData];
      brr.map((item: any, index: any) => {
        formHeader.setFields([
          {
            name: [`key${index}`],
            value: item.key,
          },
          {
            name: [`value${index}`],
            value: item.value + "",
          },
        ]);
      });
      setpathSource(brr);
      setCount(count + 1);
    }
  };
  const handleDelete = (text: any, index: any) => {
    if (radValue === "Body") {
      if (dataSource.length >= 1) {
        let arr = dataSource.filter((item: any) => item.id !== text);
        arr.map((item: any, index: any) => {
          formBody.setFields([
            {
              name: [`key${index}`],
              value: item.key,
            },
            {
              name: [`value${index}`],
              value: item.value,
            },
          ]);
        });
        setdataSource(arr);
        setCount(count + 1);
      }
    } else if (radValue === "Query") {
      if (querySource.length >= 1) {
        let arr = querySource.filter((item: any) => item.id !== text);
        arr.map((item: any, index: any) => {
          formQuery.setFields([
            {
              name: [`key${index}`],
              value: item.key,
            },
            {
              name: [`value${index}`],
              value: item.value,
            },
          ]);
        });
        setquerySource(arr);
        setCount(count + 1);
      }
    }else if(radValue === "Path"){
      if (pathSource.length >= 1) {
        let arr = pathSource.filter((item: any) => item.id !== text);
        arr.map((item: any, index: any) => {
          formPath.setFields([
            {
              name: [`key${index}`],
              value: item.key,
            },
            {
              name: [`value${index}`],
              value: item.value,
            },
          ]);
        });
        setpathSource(arr);
        setCount(count + 1);
      }
    } else {
      if (headerSource.length >= 1) {
        let arr = headerSource.filter((item: any) => item.id !== text);
        arr.map((item: any, index: any) => {
          formHeader.setFields([
            {
              name: [`key${index}`],
              value: item.key,
            },
            {
              name: [`value${index}`],
              value: item.value,
            },
          ]);
        });
        setheaderSource(arr);
        setCount(count + 1);
      }
    }
  };
  //转json方法
  const toJsonMethod=(data:any)=>{
    let json:any ={}
    if(data.length>0){
      
      data.map((item:any,index:any)=>{
        json[item.key] = item.value
      })
      return json
    }else{
      return undefined
    }
  }
  const testMethod = () => {
    let a = form.getFieldValue("path");
    // let b = a.split("/");
    let n:any = ""//组装url
    console.log(pathSource);
    let pathUrl:any = []
    console.log(pathSource.length);
    
    if(pathSource.length>0){
    pathSource.map((item:any,index:any)=>{
      pathUrl.push({
        id:item.id,
        key:'{'+item.key+'}',
        value:item.value
      })
    })
    console.log(pathUrl);
    // b.map((item:any)=>{
      pathUrl.map((i:any)=>{
        n = a.replaceAll(i.key,i.value)
      })
    }else{
      n = form.getFieldValue("path")
    }

      // console.log(n);
      
    // })
    // let pathUrl = b[0];
    querySource?.map((item: any, index: any) => {
      if (pathSource.length > 1) {
        // pathUrl += `?${item.key}=${item.value}`;
        if (index === 0) {
          n += `?${item.key}=${item.value}&`;
        } else if (index === pathSource.length - 1) {
          n += `${item.key}=${item.value}`;
        } else {
        }
      } else {
        n += `?${item.key}=${item.value}`;
      }
    });
    settestTag(true);
    // console.log(dataSource,jsonValue,value,n);
    
    posttestonline({
      dataAddress: form.getFieldValue("dataAddress"),
      path:n,
      query: JSON.stringify(toJsonMethod(querySource)),
      header: JSON.stringify(toJsonMethod(headerSource)),
      body:seleVal==="POST"?value===1?JSON.stringify(toJsonMethod(dataSource)):JSON.stringify(jsonValue):undefined ,
      method: form.getFieldValue("method"),
      appKey:form.getFieldValue('appKey'),
      appSecret:form.getFieldValue('appSecret')
    })
      .then((res: any) => {
        if (value===2) {
          const respone = JSON.parse(res.data.body);
          form.setFieldsValue({
            status: res.data.status,
            xybody: JSON.stringify(JSON.parse(res.data.body), null, 2),
            qqheader: JSON.stringify(toJsonMethod(headerSource),null,2),
            qqbody: JSON.stringify(JSON.parse(jsonValue), null, 2),
            xyheader: JSON.stringify(res.data.header, null, 2),
            qqurl:"http://192.168.14.247:43000/api/call/" +
                  form.getFieldValue("dataAddress") +
                  n,
          });
          const jsonData = {
            status: res.data.status,
            xybody: JSON.stringify(JSON.parse(res.data.body), null, 2),
            qqheader: JSON.stringify(headerSource, null, 2),
            qqbody: JSON.stringify(JSON.parse(res.xhrDetail.data), null, 2),
            xyheader: JSON.stringify(res.data.header, null, 2),
            qqurl:form.getFieldValue("dataAddress") +
                  n,
          };
          settestJsonVal(JSON.stringify(jsonData));
        } else {
          form.setFieldsValue({
            status: res.data.status,
            qqbody: seleVal==="POST"?JSON.stringify(toJsonMethod(dataSource), null, 2):JSON.stringify(toJsonMethod(querySource), null, 2),
            qqheader: JSON.stringify(toJsonMethod(headerSource),null,2),
            xybody: res.data.body,
            qqurl:"http://192.168.14.247:43000/api/call/" +
              form.getFieldValue("dataAddress") +
              n,
              xyheader:JSON.stringify(res.data.header,null,2)
          });
          const jsonData = {
            status: res.data.status,
            qqbody: JSON.stringify(JSON.parse(res.xhrDetail.data), null, 2),
            qqheader: JSON.stringify(headerSource, null, 2),
            xybody: res.data.body,
            qqurl:
              "/api/call/" +
              form.getFieldValue("dataAddress") +
              form.getFieldValue("path"),
          };
          settestJsonVal(JSON.stringify(jsonData));
        }
      })
      .catch((error: any) => {
        message.error('调用错误')
      });
  };
  useEffect(() => {
    if (radValue === "Query" || radValue === "Headers" || radValue === "Path") {
      setValue(1);
    }
  }, [radValue]);
  return (
    <div style={{ background: "#f7f8fa" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push("/servicetest");
            }}
          >
            服务测试
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{state.type==="detail"?"测试详情":"在线测试"}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "#FFF", marginTop: "17px" }}>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>在线测试</div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            marginTop: "40px",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "50%", padding: "0px 100px" }}>
            <Badge color="#165DFF" text="基本信息" />
            <Form form={form} style={{ marginTop: "14px", marginLeft: "20px" }}>
              <Form.Item
                name="testName"
                label="用例名称"
                rules={[{ required: true, message: "请输入用例名称" }]}
              >
                <Input placeholder="请输入用例名称" />
              </Form.Item>
            </Form>
            <Divider />
            <Badge color="#165DFF" text="身份信息" />
            <Form form={form} style={{ marginTop: "14px", marginLeft: "20px" }}>
              <Form.Item
                name="appKey"
                label="AccessKey"
                rules={[{ required: true, message: "请输入AccessKey" }]}
              >
                <Input placeholder="请输入AccessKey" />
              </Form.Item>
              <Form.Item
                name="appSecret"
                label="SecretKey"
                rules={[{ required: true, message: "请输入SecretKey" }]}
              >
                <Input placeholder="请输入SecretKey" />
              </Form.Item>
            </Form>
            <Divider />
            <Badge color="#165DFF" text="选择测试服务" />
            <Form form={form} style={{ marginTop: "14px", marginLeft: "20px" }}>
              <Form.Item
                name="dataAddress"
                label="DataAddress"
                rules={[{ required: true, message: "请输选择DataAddress" }]}
              >
                <Select
                showSearch
                  style={{ width: "100%" }}
                  options={dpOptions}
                  filterOption={(input, option:any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    setdataAddressVal(e);
                    settestTag(false);
                  }}
                />
              </Form.Item>
            </Form>
            <Divider />
            <Badge color="#165DFF" text="填写请求入参" />
            <Form form={form} style={{ marginTop: "14px", marginLeft: "20px" }}>
              <Form.Item
                name="method"
                label="请求方法"
                rules={[{ required: true, message: "请输入SecretKey" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setseleVal(e);
                  }}
                  options={[
                    {
                      label: "POST",
                      value: "POST",
                    },
                    {
                      label: "GET",
                      value: "GET",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="path"
                label="请求路径"
                rules={[{ required: true, message: "请输入请求路径" }]}
              >
                <Input placeholder="请输入请求路径" />
              </Form.Item>
            </Form>
            <Divider />
            <div style={{ textAlign: "center" }}>
              <div>
                <Radio.Group
                  options={
                    seleVal==="GET"
                      ? optionsGet
                      : optionsPost
                  }
                  value={radValue}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => {
                    // console.log(e.target.value);
                    // setCount(count+1)
                    setradValue(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Radio.Group
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                >
                  <Radio value={1}>Form</Radio>
                  {radValue === "Body" && <Radio value={2}>Json</Radio>}
                </Radio.Group>
              </div>
              {value === 1 && (
                <>
                  <Table
                    style={{ marginTop: "10px" }}
                    // rowClassName={() => "editable-row"}
                    rowKey={(data) => data.id}
                    bordered
                    dataSource={
                      radValue === "Body"
                        ? dataSource
                        : radValue === "Query"
                        ? querySource
                        : radValue === "Headers"
                        ? headerSource
                        : pathSource
                    }
                    columns={
                      radValue === "Body"
                        ? columns
                        : radValue === "Query"
                        ? columns_1
                        : radValue === "Headers"
                        ? columns_2
                        : columns_3
                    }
                    pagination={false}
                  />
                  <Button
                    onClick={handleAdd}
                    style={{ marginTop: 16, width: "100%" }}
                  >
                    + 添加参数
                  </Button>
                </>
              )}
              {value === 2 && (
                <TextArea
                  rows={8}
                  style={{ marginTop: "10px" }}
                  value={jsonValue}
                  onChange={(e) => {
                    setjsonValue(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <div style={{ width: "50%", padding: "0px 100px" }}>
            <Badge color="#165DFF" text="请求报文" />
            {testTag ? (
              <Form
                form={form}
                style={{ marginTop: "14px", marginLeft: "20px" }}
                name="wrap"
                labelCol={{ flex: "100px" }}
              >
                <Form.Item name="qqurl" label="请求URL">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </Form.Item>
                <Form.Item name="qqheader" label="请求头">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </Form.Item>
                <Form.Item name="qqbody" label="请求Body">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </Form.Item>
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  background: "#F2F3F5",
                  textAlign: "center",
                  marginLeft: "20px",
                  marginTop: "12px",
                  color: "#86909C",
                  padding: "5px 0px",
                }}
              >
                您未发送服务请求
              </div>
            )}
            <Divider />
            <Badge color="#165DFF" text="响应报文" />
            {testTag ? (
              <Form
                form={form}
                style={{ marginTop: "14px", marginLeft: "20px" }}
                name="wrap"
                labelCol={{ flex: "100px" }}
              >
                <Form.Item name="status" label="响应码">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </Form.Item>
                <Form.Item name="xyheader" label="响应头">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </Form.Item>
                <Form.Item name="xybody" label="响应Body">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 0, maxRows: 999999 }}
                  />
                </Form.Item>
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  background: "#F2F3F5",
                  textAlign: "center",
                  marginLeft: "20px",
                  marginTop: "12px",
                  color: "#86909C",
                  padding: "5px 0px",
                }}
              >
                您未发送服务请求
              </div>
            )}
            <Divider />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <Space>
            <Button type="primary" onClick={saveMethods}>
              保存用例
            </Button>
            <Button type="primary" onClick={testMethod}>
              发起测试
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};
export default ServiceTestDetail;
