/**----------------------------------------------------------------------
 * http请求参数，返回参数，设置组件
 * 仿yapi风格
 * @props
 *    @param option [object] - json-schema-editor-visual组件初始化参数
 *    @param data [obejct] - json数据，用来渲染对应结构的数据
 *    @param showDimensions [boolean] - 是否显示维度
 *    @param defaultSchema [object] - 默认显示的数据
 *    @param onSchemaChange [function] - 数据变化的回调函数，用来获取变化后的数据
 *-----------------------------------------------------------------------*/

 import React, {Component} from 'react';
 import PropTypes from 'prop-types';
 
 import schemaEditor from '../SchemaEditor/JSONSchemaEditor/index.js';
 
 // 空函数
 const empty = () => {
 };
 
 // 无数据时的默认数据
 const defaultData = JSON.stringify({"type": "object", "properties": {}});
 
 class HttpParamsForm extends Component {
   constructor(props) {
     super(props);
   }
 
   shouldComponentUpdate(nextProps) {
     // 如果数据不变化，就不用去更新组件
     if (this.props.isCloseUpdate===nextProps.isCloseUpdate) {
       return false
     }
     if (this.props.data === nextProps.data) {
       return false
     }
     return true;
   }
 
   render() {
     let {option = {}, openValue, onChangeOpenValue, handleImportTransformJson, data = "", showDimensions = false, showConstantVal, defaultSchema, onChange = empty} = this.props;
     const defaultOption = {
       lang: "zh_CN"
     };
     let schemaOption = {...defaultOption, ...option, openValue};
     const SchemaEditorDOM = schemaEditor(schemaOption);
     // 如果没有数据或者数据是`""`，去拿默认数据
 
     if (!data || data === JSON.stringify("")) {
       // `defaultSchema`是外部用户传的默认数据，`defaultData`是内部定义的默认数据
       data = (defaultSchema && JSON.stringify(defaultSchema)) || defaultData;
     }
 
     return <SchemaEditorDOM
       data={data}
       showDimensions={showDimensions}
       showConstantVal={showConstantVal}
       showEditor={false}
       onChangeOpenValue={onChangeOpenValue}
       handleImportTransformJson={handleImportTransformJson}
       onChange={onChange}
     />
   }
 
 }
 
 HttpParamsForm.propTypes = {
   data: PropTypes.string,
   onChange: PropTypes.func
 };
 
 export default HttpParamsForm;
 