import actionType from "../action"

export const httppostSema = (state = { obj: '', Body: [], Query: [], Headers: [], Path: [] }, action) => {
    // console.log(action);
    switch (action.type) {
        case actionType.POSTOBJ:
            return { ...state, obj: action.playload }
        case actionType.POSTBODY:
            return { ...state, Body: action.playload }
        case actionType.POSTQUERY:
            return { ...state, Query: action.playload }
        case actionType.POSTHEADERS:
            return { ...state, Headers: action.playload }
        case actionType.POSTPATH:
            return { ...state, Path: action.playload }
        default: return state
    }
}

export const httpgetSema = (state = { obj: '', Params: [], Headers: [], Path: [] }, action) => {
    switch (action.type) {
        case actionType.GETOBJ:
            return { ...state, obj: action.playload }
        case actionType.GETPARAMS:
            return { ...state, Params: action.playload }
        case actionType.GETHEADERS:
            return { ...state, Headers: action.playload }
        case actionType.GETPATH:
            return { ...state, Path: action.playload }
        default: return state
    }
}