
import { httppostSema, httpgetSema } from "./httpSema"
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk" //中间件配置
import { demoStore } from "./demoReducer";
const all = combineReducers({
    demoStore,
    httppostSema,
    httpgetSema
})
export default createStore(all, applyMiddleware(thunk));
