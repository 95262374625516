import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Space,
  Badge,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  Table,
} from "antd";
import { getalllistByCode } from "../../../api/interconnection";
import { getServiceType } from "../../../api/serviceManagement";
const InstanceTableList: React.FC = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  // const { id } = useParams<any>();
  const {state} = useLocation<any>()
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [typeList, setTypeList] = useState([]);
  const [listload, setlistload] = useState(false);
  const [datasource, setdatasource] = useState([]);
  const columns = [
    {
      title: "序号",
      dataIndex: "connectorId",
      key: "connectorId",
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress",
      key: "dpAddress",
      // render: (text: any) => text.name,
    },
    {
      title: "API名称",
      dataIndex: "apiName",
      key: "apiName",
      // align: "center" as "center",
    },
    {
      title: "版本号",
      dataIndex: "apiVersion",
      key: "apiVersion",
    },
    {
      title: "鉴权模式",
      dataIndex: "createAt",
      render:(text:any,record:any)=><>
      {record.serviceLimiting?.authMode===1 && "public"}
      {record.serviceLimiting?.authMode===2 && "private"}
      </>
    },
    {
      title: "API状态",
      dataIndex: "status",
      render: (text: any) => (
        <>
          {text === 0 && <Badge color="#ccc" text="下线" />}
          {text === 1 && <Badge color="#4CEFBE" text="上线" />}
        </>
      ),
    },
    {
      title: "后端服务类型",
      dataIndex: "connectorType",
    },
    {
      title: "最后发布时间",
      dataIndex: "updateTime",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <>
          <Button
            type="link"
            onClick={() => {
              history.push({
                pathname: `/instancetableListdetail`,
                state: {
                  code: state.code,
                  dpAddress: record.dpAddress,
                },
              });
            }}
          >
            详情
          </Button>
        </>
      ),
    },
  ];
  const getList: any = useCallback(() => {
    setlistload(true);
    getalllistByCode({
      ...pageParam,
      code: state.code,
      dpAddress: form.getFieldValue("dpAddress"),
      status: form.getFieldValue("status") * 1,
      connectorType: form.getFieldValue("connectorType"),
    }).then((res: any) => {
      if (res.code === 200) {
        setlistload(false);
        setdatasource(res.data);
        setTotal(res.pagination.total);
      }
    });
  }, [state.code, pageParam]);
  useEffect(() => {
    getList();
  }, [pageParam]);
  useEffect(() => {
    getServiceType().then((res) => {
      setTypeList(res?.data);
    });
  }, []);
  return (
    <div style={{ background: "#f7f8fa" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push("/instancemanagement");
            }}
          >
            互联实例管理
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>互联资源</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "#FFF", marginTop: "17px" }}>
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "20px",
              background: "#165DFF",
              marginRight: "10px",
            }}
          ></div>
          <div>互联资源</div>
        </div>
      </div>
      <div style={{ padding: "20px", background: "#FFF" }}>
        <div style={{ height: "45px" }}>
          <Form form={form} layout="inline">
            <Form.Item name="dpAddress" label="DataAddress">
              <Input placeholder="请输入DataAddress" />
            </Form.Item>
            <Form.Item name="connectorType" label="后端服务类型">
              <Select placeholder="请选择" style={{ width: "127px" }}>
                {typeList.map((item: any) => {
                  return <Option value={item.name}>{item.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name="status" label="API状态">
              <Select
                placeholder="请选择"
                style={{ width: "127px" }}
                options={[
                  {
                    value: "0",
                    label: "下线",
                  },
                  {
                    value: "1",
                    label: "上线",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: "16px" }}
                type="primary"
                onClick={getList}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setPageParam({
                    page: 1,
                    pageSize: 10,
                  });
                }}
              >
                重置
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Table
            columns={columns}
            loading={listload}
            dataSource={datasource}
            pagination={{
              total,
              pageSize: pageParam.pageSize,
              current: pageParam.page,
              showSizeChanger: true,
              showTotal: (total) => `共 ${total}条记录`,
              onChange: (page, size) => setPageParam({ page, pageSize: size }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default InstanceTableList;
