import React, { useState, useEffect } from 'react';
import styles from "../index.module.scss"
import image from '../images/picture.png'
import { Tabs } from 'antd';
import { Form, Input, Modal } from "antd";
import { WebAdminContext } from "../../../context/WebAdminContext";
import moment from "moment";
import { message, } from 'antd';
import { postusermodifypwd } from '../../../api/personal'
// import { useHistory } from "react-router-dom";
//基本信息
const Essential = () => {
  // const history = useHistory();
  const { userInfo } = React.useContext(WebAdminContext);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [formpassword] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalamend, setIsModalamend] = useState(false);
  const layout = {
    labelCol: { span: 1 },
    wrapperCol: { span: 6 },
  };
  // const tailLayout = {
  //     wrapperCol: { offset: 1, span: 16 },
  // };
  useEffect(() => {
    form.setFieldsValue({
      nickname: userInfo.NickName
    });
  }, [userInfo])
  // 创建子账号
  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = (values: any) => {
  };
  // 修改
  const showModalamend = () => {
    setIsModalamend(true);
  };

  const handleOkamend = () => {
    formpassword.validateFields().then((data) => {

      if (data.newPassword === data.affirmPassword) {
        postusermodifypwd({ prevPwd: data.presentPassword, password: data.newPassword }).then(res => {
          if (res.code === 200) {
            setIsModalamend(false);
            message.success('修改成功');
            formpassword.resetFields();
          } else {
            message.error(res.message);
            setIsModalamend(true);
          }
        })
      } else {
        setIsModalamend(true);
        message.warning('两次输入新密码不一致');
      }
    })
  };

  const handleCancelamend = () => {
    setIsModalamend(false);
  };
  return <div className={styles['essential']}>
    <div className={styles['essential-top']}>
      <img src={image} className={styles['image']} alt="" />
      <div className={styles['message']}>
        <div>用户名：<span className={styles["value"]}>{userInfo.LoginName}</span></div>
        <div>账号ID：<span className={styles["value"]}>{userInfo.AccountId}</span></div>
        <div>注册时间：<span className={styles["value"]}>{moment((userInfo.CreatedAt)).format("YYYY-MM-DD HH:mm:ss")}</span></div>
      </div>
      {/* {rolelist.name === '管理员' ? '' : <div className={styles['establish']} onClick={showModal}> +  创建子账号</div>} */}
      <Modal title="创建子帐户" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} name="control-hooks" onFinish={onFinish} style={{ marginTop: 20 }}>
          <Form.Item name="username" label="username" rules={[{ required: true }]}

          >
            <Input />
          </Form.Item>
          <Form.Item name="password" label="password" rules={[
            {
              required: true,
              message: '',
            },
            {
              validator: (_, value, callback) => {
                const reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/)
                if (!reg.test(value)) {
                  callback('请输入密码，密码由大写字母、小写字母和数字组成，长度为6 ~ 12个字符。')
                }
                callback()
              },
            },
          ]}>
            <Input />
          </Form.Item>
          <Form.Item name="nickname" label="nickname" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
    <div className={styles['essential-bottom']}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="基础信息" key="1">
          <Form {...layout} form={form}>
            <Form.Item label="邮箱" name="mailbox" rules={[{ required: true, message: "Please choose openoption!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="昵称" name="nickname" rules={[{ required: true, message: "Please choose openoption!" }]}>
              <Input />
            </Form.Item>
          </Form>
          {/* <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                        <Button htmlType="button" style={{ marginLeft: 10 }}>
                            重置
                        </Button>
                    </Form.Item> */}
        </TabPane>
        <TabPane tab="安全设置" key="2">
          <div className={styles['Personal']}>
            <div className={styles['Personal-title']}> 登录密码 </div>
            <div className={styles['Personal-box']}>
              <div className={styles['Personal-text']}> 已设置。密码至少6位字符，支持数字、字母和除空格外的特殊字符，且必须同时包含数字和大小写字母。</div>
              <div className={styles['Personal-action']} onClick={showModalamend} >修改</div>
            </div>
            <Modal title="修改密码" visible={isModalamend} onOk={handleOkamend} onCancel={handleCancelamend}>
              <Form
                form={formpassword}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <Form.Item
                  label="当前密码"
                  name="presentPassword"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="新密码"
                  name="newPassword"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="确认新密码"
                  name="affirmPassword"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </TabPane>
      </Tabs>
    </div>
  </div>
}
export default Essential