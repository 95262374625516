import * as React from "react";
import {Button, Form, Spin, Result} from "antd";
import { Link } from "react-router-dom";
import '../index.scss';
import {PublishContext} from "@/pages/Service/PublishServe";
import DetailCard from "../../../../component/DetailCard";

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};
const PublishingAgent = (props: { appApiId: any; }) => {
  const {appApiId} = props;
  const {state, dispatch, history} = React.useContext(PublishContext);
  const {backendConfig, currentStep,extraButton, loading, uuid, diapatchServiceStatus} = state;

  const onPreviousStep = () => {
    dispatch && dispatch({type: 'NEXT_STEP', payload: 'frontendConfig'});
  }
  
  return <div style={{display: currentStep === 'publishingService' ? 'block' : 'none'}}>
    <Spin spinning={loading}>
      <Form {...layout} style={{marginTop: 30}}>
        <DetailCard title={'结果提示'}>
          <div className="publish-service-layout">
            { diapatchServiceStatus ? 
            <Result
              status="success"
              title="发布成功"
              subTitle="您的服务已成功发布，可前往列表查看"
              extra={[
                <Button type="primary">
                  <Link to={{
                      pathname: '/servicemanagement'
                    }}>立即前往</Link>
                </Button>
              ]}
            /> :
            <Result
              status="error"
              title="发布失败"
              subTitle="请核对并修改以下信息后重新提交"
              extra={[
                <Button type="primary" onClick={onPreviousStep}>上一步</Button>
              ]}
            />
            }
          </div>
        </DetailCard>
      </Form>
    </Spin>
  </div>
}
export default PublishingAgent;
