import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  Form,
  Input,
  Button,
  Table,
  Divider,
  Popconfirm,
  Modal,
  Radio,
  message,
  Badge,
  Select,
} from "antd";
import {
  getnodelist,
  postcentralInterconnectiondelete,
  postnodecreate,
} from "../../api/interconnection";
import Tools from "../../util/tools";
const InstanceManagement: React.FC = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [formAdd] = Form.useForm();
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [TabsKey, setTabsKey] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datasource, setdatasource] = useState([]);
  const [listload, setlistload] = useState(false);
  const [userInfo, setuserInfo] = useState<any>(Tools.getStorage("userInfo"));
  // const userslist = [
  //   {
  //     id: 1,
  //     ip: 1,
  //     remark: 1,
  //   },
  // ];
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "中枢实例名称",
      dataIndex: "name",
      key: "name",
      // render: (text: any) => text.name,
    },
    {
      title: "中枢实例编码",
      dataIndex: "code",
      key: "code",
      // align: "center" as "center",
    },
    {
      title: "IP/域名：端口",
      dataIndex: "address",
    },
    {
      title: "连接状态",
      dataIndex: "connectState",
      render: (text: any) => (
        <>
          {text === 1 && <Badge color="#4CEFBE" text="连接正常" />}
          {text === 0 && <Badge color="#FF5353" text="断开" />}
        </>
      ),
    },
    {
      title: "DataAddress数量",
      dataIndex: "dataCount",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <>
          {JSON.parse(userInfo).roleCode === "user" ? (
            <></>
          ) : (
            <Button
            disabled={record.applyState===3?true:false}
              type="link"
              onClick={() => {
                history.push({
                  pathname: `/applyrender`,
                  state: {
                    name: record.name,
                    code: record.code,
                  },
                });
              }}
            >
              互联申请
            </Button>
          )}

          {record.addType === 0 && JSON.parse(userInfo).roleCode !== "user" && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="您确定要删除该条记录?"
                onConfirm={() => Remove(record)}
                okText="是"
                cancelText="否"
              >
                <Button type="link">卸载实例</Button>
              </Popconfirm>
            </>
          )}

          {record.applyState === 3 && (
            <>
              {JSON.parse(userInfo).roleCode === "user" ? (
                <></>
              ) : (
                <Divider type="vertical" />
              )}
              <Button
                type="link"
                onClick={() => {
                  history.push({
                    pathname: `/instancemanagementDetail`,
                    state: {
                      code: record.code,
                    },
                  });
                }}
              >
                查看资源
              </Button>
            </>
          )}
        </>
      ),
    },
  ];
  useEffect(() => {
    if (JSON.parse(userInfo).roleCode === "user") {
      setTabsKey("3");
    } else {
      setTabsKey("0");
    }
  }, [userInfo]);
  const Remove = (data: any) => {
    postcentralInterconnectiondelete({
      id: data.id,
    }).then((res) => {
      if (res.code === 200) {
        dataList(TabsKey);
        message.success("卸载成功!");
      }else{
        message.error(res.message)
      }
    });
  };
  const dataType = (type: any) => {
    if (type === "0") {
      return 0;
    } else if (type === "1") {
      return 1;
    } else if (type === "2") {
      return 2;
    } else {
      return 3;
    }
  };
  const dataList = useCallback(
    (type: any) => {
      setlistload(true);
      getnodelist({
        ...pageParam,
        code:
          formAdd.getFieldValue("code") === undefined
            ? ""
            : formAdd.getFieldValue("code"),
        name:
          formAdd.getFieldValue("name") === undefined
            ? ""
            : formAdd.getFieldValue("name"),
        applyState: dataType(type),
      }).then((res: any) => {
        if (res.code === 200) {
          setlistload(false);
          setdatasource(res.data);
          setTotal(res.pagination.total);
        }
      });
    },
    [formAdd, pageParam]
  );
  useEffect(() => {
    if (TabsKey !== "") {
      dataList(TabsKey);
      form.setFieldsValue({
        tls: 1,
      });
    }
  }, [pageParam, TabsKey]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.validateFields().then((res) => {
      postnodecreate({
        name: form.getFieldValue("name"),
        address: form.getFieldValue("address"),
        description: form.getFieldValue("description"),
        code: form.getFieldValue("code"),
        httpAddress:form.getFieldValue('httpAddress')
      }).then((res) => {
        if (res.code === 200) {
          setPageParam({
            page: 1,
            pageSize: 10,
          });
          setIsModalOpen(false);
        }else{
          message.error(`${res.message}`)
        }
      });
      // console.log(res);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>
          {JSON.parse(userInfo).roleCode !== "user"
            ? "互联实例管理"
            : "互联资源查看"}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Tabs
          defaultActiveKey="1"
          size="large"
          activeKey={TabsKey}
          onChange={(e) => {
            setTabsKey(e);
          }}
        >
          {JSON.parse(userInfo).roleCode !== "user" ? (
            <>
              <Tabs.TabPane tab="全部" key="0"></Tabs.TabPane>
              <Tabs.TabPane
                tab="未授权"
                key="1"
                style={{ fontSize: "20px" }}
              ></Tabs.TabPane>
              <Tabs.TabPane
                tab="授权不通过"
                key="2"
                style={{ fontSize: "20px" }}
              ></Tabs.TabPane>
            </>
          ) : (
            <></>
          )}
          <Tabs.TabPane
            tab="已授权"
            key="3"
            style={{ fontSize: "20px" }}
          ></Tabs.TabPane>
        </Tabs>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <Form form={formAdd} layout="inline" style={{ float: "left" }}>
          <Form.Item name="name" label="实例名称">
            <Input placeholder="请输入实例名称" />
          </Form.Item>
          <Form.Item name="code" label="实例编码">
            <Input placeholder="请输入实例编码" />
          </Form.Item>
          {/* <Form.Item name="state" label="审批状态">
              <Select
                style={{ width: "127px" }}
                defaultValue={0}
                options={[
                  {
                    value: 0,
                    label: "全部",
                  },
                  {
                    value: 1,
                    label: "未授权",
                  },
                  {
                    value: 2,
                    label: "授权不通过",
                  },
                  {
                    value: 3,
                    label: "已授权",
                  },
                ]}
              />
            </Form.Item> */}
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={()=>{
                dataList(TabsKey)
              }}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                formAdd.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
        {JSON.parse(userInfo).roleCode === "user" ? (
          <></>
        ) : (
          <Button style={{ float: "right" }} type="primary" onClick={showModal}>
            手动添加中枢实例
          </Button>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={datasource}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
      <Modal
        title="手动添加中枢实例"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          <Form.Item
            name="name"
            label="中枢实例名称"
            rules={[{ required: true, message: "请输入中枢实例名称" }]}
          >
            <Input placeholder="请输入实例名称" />
          </Form.Item>
          <Form.Item
            name="code"
            label="中枢实例编码"
            rules={[{ required: true, message: "请输入中枢实例编码" }]}
          >
            <Input placeholder="请输入实例编码" />
          </Form.Item>
          <Form.Item
            name="tls"
            label="TLS"
            rules={[{ required: true, message: "请选择TLS" }]}
          >
            <Radio.Group
              //   onChange={onChange}
              defaultValue={1}
            >
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="address"
            label="API-IP/域名端口号"
            dependencies={["tls"]}
            rules={[
              { required: true, message: "IP/域名端口号不能为空" },
              { whitespace: true, message: "IP/域名端口号不能为空" },
              ({ getFieldValue }) => ({
                validator(rule, value, callback) {
                  
                  if (value === undefined || value.length === 0) {
                    callback();
                  } else {
                    let RegExpIp = /https?:\/\/([\w.-]+)/;
                    let RegExpHttp_ = /^https:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
                    if (getFieldValue("tls") == 2) {
                      if (RegExpIp.test(value)) {
                        callback();
                      } else {
                        callback("请输入正确的IP域名端口");
                      }
                    } else {
                      if (RegExpHttp_.test(value)) {
                        callback();
                      } else {
                        callback("请输入正确的https地址");
                      }
                    }
                  }
                },
              }),
            ]}
          >
            <Input placeholder="请输入IP地址/域名端口号" />
          </Form.Item>
          <Form.Item
            name="httpAddress"
            label="HTTP-IP/域名端口号"
            dependencies={["tls"]}
            rules={[
              { required: true, message: "IP/域名端口号不能为空" },
              { whitespace: true, message: "IP/域名端口号不能为空" },
              ({ getFieldValue }) => ({
                validator(rule, value, callback) {
                  
                  if (value === undefined || value.length === 0) {
                    callback();
                  } else {
                    let RegExpIp = /https?:\/\/([\w.-]+)/;
                    let RegExpHttp_ = /^https:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
                    if (getFieldValue("tls") == 2) {
                      if (RegExpIp.test(value)) {
                        callback();
                      } else {
                        callback("请输入正确的IP域名端口");
                      }
                    } else {
                      if (RegExpHttp_.test(value)) {
                        callback();
                      } else {
                        callback("请输入正确的https地址");
                      }
                    }
                  }
                },
              }),
            ]}
          >
            <Input placeholder="请输入IP地址/域名端口号" />
          </Form.Item>
          <Form.Item
            name="description"
            label="描述"
            rules={[{ required: true, message: "请输入描述" }]}
          >
            <TextArea rows={4} placeholder="请输入描述" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default InstanceManagement;
