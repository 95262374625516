import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  getServiceList,
  deleteServiceItem,
  changeStatus,
} from "../../api/serviceManagement";
import {
  Form,
  Input,
  Button,
  Table,
  Divider,
  Popconfirm,
  message,
  Badge,
  Modal,
} from "antd";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const Aggregation: React.FC = () => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [total, setTotal] = useState(0);
  const history = useHistory<any>();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(true);
  const [datasource, setdatasource] = useState([]);
  const columns: any = [
    {
      title: "序号",
      dataIndex: "dataIndex",
      width: 80,
      render: (text: any, record: any, index: any) =>
        (pageParam.page - 1) * pageParam.pageSize + (index + 1),
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress",

      width: 250,
      render: (text: any, record: any) => (
        <>
          <span>{text}</span>
          {record.isGenGrayScale && (
            <span
              style={{
                background: "#85AAFF",
                padding: "0px 4px",
                borderRadius: "4px",
                color: "white",
                textAlign: "center",
                marginLeft: "2px",
              }}
            >
              灰度
            </span>
          )}
        </>
      ),
    },
    {
      title: "聚合API名称",
      dataIndex: "apiName",
    },
    {
      title: "版本号",
      dataIndex: "apiVersion",
    },
    {
      title: "鉴权模式",
      dataIndex: "authMode",
      render: (text: any) => (
        <>
          {text === 1 && "public"}
          {text === 2 && "private"}
        </>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: string) => {
        return text == "1" ? (
          <Badge color="#4CEFBE" text="已上架" />
        ) : (
          <Badge color="#FF5353" text="已下架" />
        );
      },
    },
    {
      title: "含子服务数",
      dataIndex: "subs",
    },
    {
      title: "最后发布时间",
      dataIndex: "updateTime",
    },
    {
      title: "操作",
      dataIndex: "id",
      fixed: "right",
      width: 400,
      // align: "center" as "center",
      render: (id: number, record: any) => (
        <>
          <Link
            to={{
              pathname: "/AggregationDetail",
              // search: qs.stringify({
              //   id: record.id,
              // }),
              state: {
                address: record.dpAddress,
              },
            }}
          >
            <Button type="link">详情</Button>
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: "/aggregationadd",
              state: { id: record.id, type: "edit",address: record.dpAddress, },
              // search: qs.stringify({
              //   appApiId: record.id,
              //   referer: "apiListEdit",
              // }),
            }}
          >
            <Button type="link">编辑</Button>
          </Link>
          <Divider type="vertical" />
          {record.status == 0 ? (
            <Button type="link" onClick={() => showConfirmup(record.id, 1)}>
              上架
            </Button>
          ) : (
            <Button type="link" onClick={() => showConfirmdown(record.id, 0)}>
              下架
            </Button>
          )}
          <Divider type="vertical" />
          <Popconfirm
            title="您确定要删除本条记录吗?"
            onConfirm={() => onRemove(id)}
            placement="topLeft"
            okText="是"
            cancelText="否"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const onChangeStatus = (id: number, status: number) => {
    changeStatus({ id, status }).then((res) => {
      if (res.code === 200) {
        message.success(`${status == 1 ? "上架" : "下架"}成功!`);
        getList();
      }
    });
  };
  const showConfirmup = (id: any, type: any) => {
    confirm({
      title: "确认上架吗？",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        onChangeStatus(id, type);
      },
      onCancel() {},
    });
  };
  const showConfirmdown = (id: any, type: any) => {
    confirm({
      title: "下架后DataAddress将不可调用，还要继续操作吗？",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        onChangeStatus(id, type);
      },
      onCancel() {},
    });
  };
  const onRemove = (id: any) => {
    deleteServiceItem({ id }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功!");
        getList();
      }
    });
  };
  const getList = (values = {}) => {
    setlistload(true);
    getServiceList({
      dpAddress:form.getFieldValue('dataAddress'),
      apiName:form.getFieldValue('apiName'),
      ...pageParam,
      type: 1,
    }).then((res: any) => {
      if (res.code == 200) {
        setlistload(false);
        setdatasource(res?.data);
        setTotal(res.pagination.total);
      }
    });
  };
  useEffect(() => {
    getList();
  }, [pageParam.page, pageParam.pageSize]);
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>聚合服务管理</div>
      </div>
      <div style={{ height: "45px", marginTop: "20px" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              history.push({
                pathname: `/aggregationadd`,
                state: { id: "", type: "create" },
              });
            }}
          >
            + 添加聚合服务
          </Button>
        </div>
        <Form form={form} layout="inline">
          <Form.Item name="dataAddress" label="DataAddress">
            <Input />
          </Form.Item>
          <Form.Item name="apiName" label="聚合服务API">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={getList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
                getList()
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: "40px" }}>
        <Table
          columns={columns}
          loading={listload}
          dataSource={datasource}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};

export default Aggregation;
