import React, { useState, useEffect } from "react";
import { Empty, Card, Badge, Row, Breadcrumb, Col } from "antd";
import style from "./index.module.scss";
import GrayCreate from "./component/GrayCreate";
import { getgrayscale, postgrayscale } from "../../../api/grayscale";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import qs from "qs";
const GrayLevel: React.FC = () => {
  const [isHavegray, setisHavegray] = useState(false);
  const [basicInfo, setbasicInfo] = useState<any>({});
  const [userInfo, setuserInfo] = useState<any>({});
  const { state } = useLocation<any>();
  const history = useHistory();
  useEffect(() => {
    setuserInfo(state);
    if (state.type) {
      getgrayscale(state.id).then((res: any) => {
        setbasicInfo(res.data);
        setisHavegray(res.data.content === undefined ? false : true);
      });
    } else {
      postgrayscale({ dpAddress: state.dpAddress }).then((res: any) => {
        if (res.code === 200) {
          getgrayscale(res.data.id).then((res: any) => {
            setbasicInfo(res.data);
            setisHavegray(res.data.content === undefined ? false : true);
          });
        }
      });
    }
  }, [state]);
  const goback = (data: boolean) => {
      // setisHavegray(state.type);
      getgrayscale(state.id).then((res: any) => {
        setbasicInfo(res.data);
        setisHavegray(res.data.content === undefined ? false : true);
      });
    setisHavegray(data);
  };
  const refresh = (data: boolean) => {
    if (data === true) {
        getgrayscale(state.id).then((res: any) => {
          setbasicInfo(res.data);
          setisHavegray(res.data.content === undefined ? false : true);
        });

    }
  };
  return (
    <div style={{ background: "#f7f8fa" }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <a
            onClick={() => {
              history.push(`/servicemanagement`);
            }}
          >
            服务管理
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>灰度版本</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          padding: "20px",
          background: "#FFF",
          marginTop: "17px",
          borderRadius: "4px",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "20px",
                background: "#165DFF",
                marginRight: "10px",
              }}
            ></div>
            <div>灰度版本</div>
          </div>
        </div>
        <div style={{ marginTop: "40px" }}>
          <div style={{ marginLeft: "12px", fontSize: "18px" }}>
            服务集成信息
          </div>
          <Card style={{ width: "100%", marginTop: "10px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Badge color="#165DFF" text="基本信息" />
              <Link
                to={{
                  pathname: "/service/publish-serve",
                  search: qs.stringify({
                    appApiId: basicInfo.grayScaleDpAddress,
                    referer: "apiGray",
                  }),
                }}
              >
                修改服务配置
              </Link>
            </div>
            <Row style={{ marginTop: "10px" }}>
              <Col md={7} sm={24} style={{ marginLeft: "12px" }}>
                <span className={style["GrayName"]}>DataAddress：</span>
                <span className={style["GrayValue"]}>
                  {basicInfo.dpAddress}
                </span>
              </Col>
              <Col md={7} sm={24}>
                <span className={style["GrayName"]}>服务名称：</span>
                <span className={style["GrayValue"]}>{basicInfo.apiName}</span>
              </Col>
              <Col md={7} sm={24}>
                <span className={style["GrayName"]}>版本号：</span>
                <span className={style["GrayValue"]}>
                  {basicInfo.apiVersion}
                </span>
              </Col>
            </Row>
          </Card>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div style={{ marginLeft: "12px", fontSize: "18px" }}>灰度发布</div>
          <Card style={{ width: "100%", marginTop: "10px" }}>
            {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Badge color="#165DFF" text="灰度发布" />
            <a>修改灰度策略</a>
          </div>
          <Row style={{ marginTop: "10px" }}>
            <Col md={7} sm={24} style={{ marginLeft: "12px" }}>
              <span className={style["GrayName"]}>DataAddress：</span>
              <span className={style["GrayValue"]}>ZS1.162A4C935CC21000</span>
            </Col>
            <Col md={7} sm={24}>
              <span className={style["GrayName"]}>服务名称：</span>
              <span className={style["GrayValue"]}>个人信用分查询</span>
            </Col>
            <Col md={7} sm={24}>
              <span className={style["GrayName"]}>版本号：</span>
              <span className={style["GrayValue"]}>1.0</span>
            </Col>
          </Row> */}
            {isHavegray === false ? (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 180,
                }}
                description={
                  <span>
                    暂无灰度策略，请先开始{" "}
                    <a
                      onClick={() => {
                        setisHavegray(true);
                      }}
                    >
                      配置灰度策略
                    </a>
                  </span>
                }
              ></Empty>
            ) : (
              <GrayCreate data={basicInfo} goback={goback} refresh={refresh} />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GrayLevel;
