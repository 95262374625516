import { getClient, postClient } from "./requestClient";
import {
  GET_PERMISSIONGROUP_LIST,
  POST_REMOVE_PERMISSION,
  POST_ADD_PERMISSION,
  GET_PREMISSION_TREE,
  GET_PGLIST_ROLEID,
  GET_PERMISSIONGROUP_CREATE,
  GET_PERMISSIONGROUP_DELETE,
  GET_PERMISSIONGROUP_MODIFY,
  POST_PERMISSION_BINDUSER,
  POST_PERMISSION_UNBINDUSER
} from "./index";
//获取所有权限组
export const getpermissionGroup = async () =>
  await getClient<any>(GET_PERMISSIONGROUP_LIST);

//创建权限组
export const getpermissionCreate = async (params: any) =>
  await postClient<any>(GET_PERMISSIONGROUP_CREATE, params);

//删除权限组
export const getpermissiondelete = async (params: any) =>
  await getClient<any>(GET_PERMISSIONGROUP_DELETE, params);

//更新权限组
export const getpermissionmodify = async (params: any) =>
  await postClient<any>(GET_PERMISSIONGROUP_MODIFY, params);

//获取所有权限
export const getpermissiontree = async (params: any) =>
  await getClient<any>(GET_PREMISSION_TREE, params);

//通过roleld查询权限组集合
export const getpglistbyroleid = async (params: any) =>
  await getClient<any>(GET_PGLIST_ROLEID, params);

//添加权限入权限组
export const postaddpermission = async (params: any) =>
  await postClient<any>(POST_ADD_PERMISSION, params);

//移除权限出权限组
export const postremovepermission = async (params: any) =>
  await postClient<any>(POST_REMOVE_PERMISSION, params);

//绑定用户和权限组
export const postbindUserPermissionGroup = async (params: any) =>
  await postClient<any>(POST_PERMISSION_BINDUSER, params);

//解绑用户和权限组
export const postunbindUserPermissionGroup = async (params: any) =>
  await postClient<any>(POST_PERMISSION_UNBINDUSER, params);