import {
  GET_RESOURCE_DETAIL,
  GET_RESOURCE_LIST,
  GET_RESOURCE_LIST_MY,
  POST_RESOURCE_CREATE,
  POST_RESOURCE_DISABLE,
  POST_RESOURCE_ENABLE,
  POST_RESOURCE_MODIFY,
} from ".";
import { getClient, postClient } from "./requestClient";

export interface IResourceReq {
  description: string;
  http_route: {
    body: string;
    content_type: string;
    method: string;
    query_string: string;
    url: string;
  };
  name: string;
  security: {
    use_tls: boolean;
  };
  status: number;
  transfer: {
    endpoints: string[];
    protocol: string;
  };
}
export interface IResource extends IResourceReq {
  create_user_id: string;
  create_user_info: {
    id: string;
    nick_name: string;
    user_name: string;
  };
  created_at: 0;
  dp_address: string;
  id: string;
  updated_at: number;
  user_apply_status: number;
}

export interface IResourceModifyReq extends IResourceReq {
  id: string;
  dp_address: string;
}

export interface IResourceParam {
  create_user_id?: string;
  dp_address?: string;
  name?: string;
  page: number;
  page_size: number;
  status?: number;
}

export const getResourceList = async (params: IResourceParam) =>
  await getClient<IResource[]>(GET_RESOURCE_LIST, params);

export const getResourceMy = async (params: any) =>
  await getClient<IResource[]>(GET_RESOURCE_LIST_MY, params);

export const getResourceDetail = async (params: { id: string }) =>
  await getClient<IResource>(GET_RESOURCE_DETAIL, params);

export const postResourceCreate = async (params: any) =>
  await postClient(POST_RESOURCE_CREATE, params);

export const postResourceModify = async (params: IResourceModifyReq) =>
  await postClient(POST_RESOURCE_MODIFY, params);

export const postResourceDisable = async (params: { id: string }) =>
  await postClient(POST_RESOURCE_DISABLE, params);

export const postResourceEnable = async (params: { id: string }) =>
  await postClient(POST_RESOURCE_ENABLE, params);
