/**----------------------------------------------------------------------
 *
 * 指数维度,编辑组件
 * props.disabled //是否禁用编辑，默认false
 * props.param {} //对应维度的参数，在维度选择时，onChange中回调传出该值
 * props.dimensions [] //纬度值（code-name-type）数组
 * props.onChange(value,param)
 *              value:选择的维度值,
 *              param:any 对应的参数（由父组件传入）
 *-----------------------------------------------------------------------*/

// @ts-nocheck
import React, { Component } from "react";
import { TreeSelect } from "antd";
import SelectTree from "../../../SelectTree";
import { apiAjax } from "../../../services/apiAjax";

const types = {
  indicatorCycleRange: "time",
  indicatorCycles: "time",
  indicatorCycle: "time",
  areaCode: "area",
  areaCodes: "area",
  etlTime: "time",
};

class IndexDimensionForm extends Component {
  state = {
    dimenTreeData: [], //维度包括标准维度和自定义维度
    value: [],
  };

  lastDimens = []; //上一次选择的维度

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.dimensions) {
      this.checkSelectedData(this.props.dimensions);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.checkSelectedData(nextProps.dimensions);
  }

  // 处理选中数据
  checkSelectedData = (dimensions, isDidMount) => {
    let data = [];
    const { dimensMap } = this.state;

    (dimensions || []).forEach((item) => {
      let itemArr = (item && item.split(";")) || [];

      if (itemArr.length === 3 && dimensMap) {
        let codeData = dimensMap && dimensMap[itemArr[0]];

        item = item + ";" + (codeData && codeData.specType);
      }

      if (itemArr.length > 0) {
        data.push({
          label: itemArr[1],
          value: item,
        });
      }
    });

    if (isDidMount) {
      this.lastDimens = data;
    }

    this.setState({
      value: data,
    });
  };

  /**
   * 加载维度数据
   * @param type 1:标准维度，2：自定义维度
   * /agent/dimension/dimensionValueSelector
   */
  loadDimenDatas() {
    let params = {};

    this.props.name &&
      types[this.props.name] &&
      (params.specType = types[this.props.name]);

    apiAjax("/agent/dimension/stdAndCusDimensionTree", params).then((res) => {
      let data = (res && res.data) || {};
      // cusDimensionTree：自定义维度树 stdDimensionTree：标准维度树
      let { cusDimensionTree = [], stdDimensionTree = [] } = data || {};

      let datas = [];
      // 用于选择父节点
      let dimensMap = {};
      let dimensMapIDs = {};
      let type = 1;
      (stdDimensionTree || []).forEach((item) => {
        let obj = {
          id: item.id,
          parentId: item.pid == null ? -type : item.pid,
          title: item.name,
          specType: item.specType,
          value: `${item.code};${item.name};${item.type};${item.specType}`,
          disabled: item.bizType === 1 || !item.bizType, //bizType 1:文件夹 ，2:维度
        };
        dimensMap[item.code] = obj;
        dimensMapIDs[item.id] = obj;
        datas.push(obj);
      });
      datas.push({
        id: -type,
        parentId: 0,
        title: type === 1 ? "标准维度" : "自定义维度",
        value: -type,
        disabled: true,
        checkable: false,
      });

      if (!this.props.isOnlyStd) {
        type = 2;
        (cusDimensionTree || []).forEach((item) => {
          let obj = {
            id: item.id,
            parentId: item.pid == null ? -type : item.pid,
            title: item.name,
            specType: item.specType,
            value: `${item.code};${item.name};${item.type};${item.specType}`,
            disabled: item.bizType === 1 || !item.bizType, //bizType 1:文件夹 ，2:维度
          };
          dimensMap[item.code] = obj;
          dimensMapIDs[item.id] = obj;
          datas.push(obj);
        });
        datas.push({
          id: -type,
          parentId: 0,
          title: type === 1 ? "标准维度" : "自定义维度",
          value: -type,
          disabled: true,
          checkable: false,
        });
      }

      this.setState(
        {
          dimenTreeData: datas,
          dimensMap: dimensMap,
          dimensMapIDs: dimensMapIDs,
        },
        () => {
          this.checkSelectedData(this.props.dimensions, true);
        }
      );

      // if(callback)callback(datas);
    });
  }

  mathCurrValue = (selValues) => {
    //alert(selValues);
    let _value = [];
    if (selValues.length === 0) {
      return _value;
    }

    selValues.forEach((v) => {
      if (!this.state.value.includes(v)) {
        _value.push(v);
      }
    });

    //alert(_value);

    return _value;
  };

  // 原版选中当前这个会选中他所有的父级
  // onChange=(value)=>{
  //   if(this.props.disabled) return;
  //   let lastDimens = this.lastDimens;

  //   let dimensions = [];

  //   // 如果当前选中维度个数大于上次的，则是新增选中，否则是取消选中
  //   if(value.length > lastDimens.length) {
  //     let diffDimens = this.calDiffDimension(value, lastDimens, true);

  //     if(diffDimens) {
  //       dimensions = this.getParentDimenByCurr(diffDimens, true);
  //     }
  //   } else {
  //     let diffDimens = this.calDiffDimension(value, lastDimens, false);

  //     if(diffDimens) {
  //       dimensions = this.getParentDimenByCurr(diffDimens, false);
  //     }
  //   }

  //   // 本次变为上次
  //   this.lastDimens = dimensions;

  //   let dimensionsData = [];
  //   dimensions.forEach(item => {
  //     if(item && item.value) {
  //       dimensionsData.push(item.value);
  //     }
  //   })

  //   this.setState({
  //     value: dimensions
  //   });

  //   if(this.props.onChange){
  //     this.props.onChange(dimensionsData,this.props.param);
  //   }
  // }

  onChange = (value) => {
    if (this.props.disabled) return;
    let lastDimens = this.lastDimens;

    let dimensions = value;

    // 如果当前选中维度个数大于上次的，则是新增选中，否则是取消选中
    if (value.length <= 1) {
    } else if (value.length > lastDimens.length) {
      let diffDimens = this.calDiffDimension(value, lastDimens, true);

      if (diffDimens) {
        // 找出当前和之前的数据的父级是否一致，一致说明是同一级
        let currItem = diffDimens.value;
        let beforeItem =
          diffDimens.index === 0 ? value[1].value : value[0].value;
        let currDimensions = this.getParentDimenByCurr(currItem, true);
        let beforeDimensions = this.getParentDimenByCurr(beforeItem, true);

        // 如果是同一级，直接选中
        if (
          currDimensions[0] &&
          beforeDimensions[0] &&
          currDimensions[0].value === beforeDimensions[0].value
        ) {
          dimensions = value;
        } else {
          // 如果不是同一级，清除之前的只选中当前
          dimensions = [
            {
              label: diffDimens.label,
              value: diffDimens.value,
            },
          ];
        }
      }
    }

    // 本次变为上次
    this.lastDimens = dimensions;

    let dimensionsData = [];
    dimensions.forEach((item) => {
      if (item && item.value) {
        dimensionsData.push(item.value);
      }
    });

    this.setState({
      value: dimensions,
    });

    if (this.props.onChange) {
      this.props.onChange(dimensionsData, this.props.param);
    }
  };

  /**
   * 根据当前维度去查找父维度
   * @param currDimens [string] 当前维度
   * @param isNeedCurr [boolean] 返回的数据中是否需要当前条
   * @return [array] 返回需要选中的父节点
   */
  getParentDimenByCurr = (currDimens, isNeedCurr) => {
    let { dimensMap, dimensMapIDs } = this.state;
    let parentDimens = [];

    if (!currDimens) {
      return parentDimens;
    }
    let currDimensCode = currDimens.split(";")[0] || "";
    let currDimensData = (dimensMap && dimensMap[currDimensCode]) || "";

    if (!currDimensCode || !currDimensData) return parentDimens;

    if (isNeedCurr) {
      parentDimens.push({
        label: currDimensData.title,
        value: currDimens,
      });
    }

    while (true) {
      let { parentId } = currDimensData || {};

      if (parentId === -1 || parentId === -2 || !parentId) {
        break;
      } else {
        // 找到父节点
        let parentDimensData = dimensMapIDs[parentId];
        let { value, disabled } = parentDimensData || {};

        let dimensCode = value.split(";")[0] || "";
        let dimensData = parentDimensData || "";

        if (!dimensCode || !dimensData || disabled) break;

        parentDimens.push({
          label: dimensData.title,
          value: value,
        });
        currDimensData = dimensData;
      }
    }

    parentDimens.reverse();

    return parentDimens;
  };

  /**
   * 计算出本次和上次选择维度的不一样的维度并返回
   * @param currDimensions [array] 当前选中维度
   * @param lastDimensions [array] 上次选中维度
   * @param isSelected [boolean] 是否是选中维度
   */
  calDiffDimension = (currDimensions, lastDimensions, isSelected) => {
    let currDimens = [],
      lastDimens = [];
    if (isSelected) {
      currDimens = currDimensions;
      lastDimens = lastDimensions;
    } else {
      currDimens = lastDimensions;
      lastDimens = currDimensions;
    }

    for (let i = 0; i < currDimens.length; i++) {
      let item = currDimens[i];

      if (!this.inArray(item.value, lastDimens)) {
        return { value: item.value, label: item.label, index: i };
      }
    }

    return null;
  };

  inArray = (value, arr) => {
    for (let j = 0; j < arr.length; j++) {
      if (arr[j].value === value) {
        return true;
      }
    }

    return false;
  };

  render() {
    // const { option = {}, data = "", onChange } = this.props;

    return (
      <div>
        <SelectTree
          style={{ width: "100%" }}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="请选择维度"
          allowClear={!this.props.disabled}
          treeCheckable
          treeCheckStrictly
          showSearch
          treeDefaultExpandAll
          treeData={this.state.dimenTreeData}
          parentId={0}
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          value={this.state.value}
          onChange={(value) => this.onChange(value)}
        ></SelectTree>
      </div>
    );
  }

  componentDidMount() {
    // this.loadDimenTreeData();
    this.loadDimenDatas();
    // this.setState({
    //   dimenTreeData:[
    //     {
    //       id: '1',
    //       parentId:0,
    //       title: '标准维度',
    //       value:"1",
    //       disabled:this.props.disabled,
    //       checkable:false
    //     },{
    //       id: '1-1',
    //       parentId:'1',
    //       title: '时间',
    //       value:"1-1",
    //       disabled:this.props.disabled
    //     },{
    //       id: '2',
    //       parentId:0,
    //       title: '自定义维度',
    //       value:"2",
    //       disabled:this.props.disabled,
    //       checkable:false
    //     },{
    //       id: '2-1',
    //       parentId:'2',
    //       title: '时间',
    //       value:"2-1",
    //       disabled:this.props.disabled
    //     },{
    //       id: '2-1-1',
    //       parentId:'2-1',
    //       title: '时',
    //       value:"2-1-1",
    //       disabled:this.props.disabled
    //     }
    //   ]
    // });
  }
}

export default IndexDimensionForm;
