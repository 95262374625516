import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Tabs from "./component/tab";
import RoleList from "./component/roleList";
// import Allpermission from "./component/roleData";
import { Input, Button, message } from "antd";
import { getpermissionGroup, getpermissionCreate, getpermissiontree } from "../../api/permissionGroup";
const Permissions: React.FC = () => {
  const [typeState, settypeState] = useState(false);
  const [InpValue, setInpValue] = useState("");
  // const list = ["导航一", "导航二", "导航三"];
  const [list, setList] = useState([]);
  const [idList, setidList] = useState<any>("")
  const [dataList, setdataList] = useState<any>([])
  // const roleId = ["6231cf34b32cd22908e31b89", "1321321"]
  // const content = [
  //   { item: "管理员" },
  //   { item: "普通成员" },
  //   { item: "超级管理员" },
  // ];
  const [current, setcurrent] = useState<any>(0);
  const currentClass = (index: any) => {
    return current === index ? "current" : "";
  };
  // const contentClass = (index: any) => {
  //   return current === index ? "active" : "";
  // };
  const handleClick = (index: number, id: any) => {
    // console.log(index);
    setidList(id)
    setcurrent(index);
    arrId(id)
  };

  const halfchecked = (list: any) => {
    return list?.map((item: any) => {
      item.halfchecked = false
      if (item.childPermissions) {
        halfchecked(item.childPermissions)
      }
      return item
    })
  }

  const arrId = useCallback((id: any) => {
    getpermissiontree({ pg_id: id }).then((res => {
      let list = halfchecked(res.data)
      setdataList(list)
    }))
  }, [])
  // const deleteMenu=(res:any)=>{
  //   console.log(res);

  // }
  const addList = () => {
    settypeState(true);
  };
  const submit = async () => {
    const { code } = await getpermissionCreate({ name: InpValue })
    if (code === 200) {
      message.success("创建成功")
      permissionList()
    }
    // list.push(InpValue);
    // setList([...list,InpValue])
    settypeState(false);
  };
  //左侧列表数据
  const permissionList = useCallback(() => {
    getpermissionGroup().then((res) => {
      // console.log(res);
      if (res.data?.length > 0) {
        setList(res.data);
        setidList(res.data[0]?.id)
      }
    });
  }, []);
  useEffect(() => {
    setInpValue("");
    permissionList()
    if (idList) {
      arrId(idList)
    }
  }, [arrId, idList !== "", permissionList, typeState]);
  return (
    <div className={styles["parent"]} id="outer">
      <div className={styles["box_1"]}>
        <div id="tab">
          {/* <div style={{ cursor: "default" }}>权限组</div> */}
          {list.map((val: any, index: any) => {
            return (
              <Tabs
                roleId={val.role_id}
                val={val.name}
                key={index}
                index={index}
                id={val.id}
                handleClick={() => handleClick(index, val.id)}
                currentClass={() => currentClass(index)}
                deleteMenu={permissionList}
              />
            );
          })}
          {typeState === false && (
            <div style={{ color: "#165DFF" }} onClick={addList}>
              <i className="iconfont icon-tianjia"></i>
              添加权限组
            </div>
          )}
          {typeState === true && (
            <div style={{ height: "auto", width: "100%" }}>
              <Input
                value={InpValue}
                onChange={(e) => setInpValue(e.target.value)}
              ></Input>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  border: "none",
                  justifyContent: "space-between",
                }}
              >
                <Button type="primary" onClick={submit}>
                  确定
                </Button>
                <Button onClick={() => settypeState(false)}>取消</Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <RoleList dataList={dataList} setdataList={setdataList} id={idList} />
      {/* <Allpermission dataList={dataList} setdataList={setdataList} id={idList} /> */}
    </div>
  );
};
export default Permissions;
