import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import { Badge, Row, Col, Form, Table, Button , Input} from "antd";
import qs from "qs";
import { authModes } from "../../../pages/Service/PublishServe/utils-data/commonData";
import ParseRequestJsonTable from "../../../pages/Service/Detail/components/parse-request-json-table";
import { useLocation , useHistory} from "react-router-dom";
import { getserviceManageAggregationdetail } from "../../../api/serviceManagement";

const DetailRender: React.FC<any> = ({ children }) => {
  return (
    <>
      {children.map(
        (
          it:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <Col md={8} sm={24} key={index}>
            {it}
          </Col>
        )
      )}
    </>
  );
};
const Aggregation: React.FC = () => {
  const { state } = useLocation<any>();
  const history = useHistory()
  const [dataSource, setdataSource] = useState<any>([]);
    const { TextArea } = Input;
  const columns = [
    {
      title: "序号",
      render: (text: any, record: any, index: any) => <>{index + 1}</>,
    },
    {
      title: "API名称",
      dataIndex: "apiName",
      key: "apiName",
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress",
      key: "dpAddress",
      render:(text:any,record:any)=><a onClick={()=>{
        history.push({
          pathname:"/service/detail",
          search: qs.stringify({
            id: record.id,
          }),
        })
      }}>{text}</a>
    },
    {
      title: "后端服务类型",
      dataIndex: "connectorType",
      key: "connectorType",
    },
    {
      title: "自定义请求协议适配",
      dataIndex: "reqGroovy",
      key: "reqGroovy",
      render:(text:any,record:any)=><>{text===""?"否":"是"}</>
    },
    {
      title: "自定义响应协议适配",
      dataIndex: "respGroovy",
      key: "respGroovy",
      render:(text:any,record:any)=><>{text===""?"否":"是"}</>
    },
  ];
  useEffect(() => {
    getserviceManageAggregationdetail(state.address).then((res) => {
      setdataSource(res.data);
    });
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>服务详情</div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className={style["ltTittle"]}>基本信息</div>
        <div className={style["borderWith"]}>
          <Badge color={"#165DFF"} text={"基本信息"} />
          <div style={{ marginTop: "14px" }}>
            <Row>
              <Col span={8}>API名称：{dataSource.apiName}</Col>
              <Col span={8}>版本：{dataSource.apiVersion}</Col>
              <Col span={8}>
                获取方式：{dataSource?.serviceLimiting?.authMode===1?'Public':'Private'}
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Row>
              <Col>API说明描述：{dataSource.apiDesc}</Col>
            </Row>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className={style["ltTittle"]}>前端服务配置</div>
        <div className={style["borderWith"]}>
          <Badge color={"#165DFF"} text={"API接入配置"} />
          <div style={{ marginTop: "14px" }}>
            <Row>
              <Col span={8}>接入协议：{dataSource.accessProtocol}</Col>
              <Col span={8}>请求方式：{dataSource.requestMethod}</Col>
              <Col span={8}>请求方路径：{dataSource.requestPath}</Col>
            </Row>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Badge color={"#165DFF"} text={"入参设置"} />
            <ParseRequestJsonTable
              method={dataSource?.requestMethod || dataSource?.method}
              type={"request"}
              jsonData={(() => {
                let param: any = {};
                try {
                  param = JSON.parse(dataSource?.metaJson || "{}");
                } catch (error) {}
                return param?.APIRequestStructure || {};
              })()}
              protocolIn={"rest"}
            />
          </div>
          <div style={{ marginTop: "14px" }}>
            <Badge color={"#165DFF"} text={"出参设置"} />
            <ParseRequestJsonTable
              method={dataSource?.requestMethod || dataSource?.method}
              type={"response"}
              jsonData={(() => {
                let param: any = {};
                try {
                  param = JSON.parse(dataSource?.metaJson || "{}");
                } catch (error) {}

                return param?.APIResponseStructure || {};
              })()}
              protocolIn={dataSource.accessProtocol}
            />
          </div>
          <div style={{ marginTop: "14px" }}>
            <Badge color={"#165DFF"} text={"返回设置"} />
            <Row>
              <DetailRender>
                <Form.Item label="是否开启Mock">
                  {dataSource.isMock ? "是" : "否"}
                </Form.Item>
                <Form.Item label="Mock返回数据">
                  <TextArea rows={4} disabled value={dataSource.mockJson}/>
                </Form.Item>
              </DetailRender>
            </Row>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className={style["ltTittle"]}>子服务配置</div>
        <div className={style["borderWith"]}>
          <Table dataSource={dataSource.items} columns={columns} />
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center", marginTop: "14px" }}>
        {" "}
        <Button onClick={()=>{
          history.go(-1)
        }}>返回</Button>{" "}
      </div>
    </div>
  );
};

export default Aggregation;
