import React, { useEffect } from "react";
import * as echarts from "echarts";
import {getreportcredentia} from "../../../api/ServiceHealth"
const TopfiveCharts: React.FC = () => {
  useEffect(() => {
    getreportcredentia().then((res)=>{
      let xarr = res.data.x
      let yarr = res.data.y
        if(res.code===200){
          res.data.x.map((item:any,index:any)=>{
            if(item==="null" || item===null || item===""){
              xarr.splice(index,1)
              yarr.splice(index,1)
            }
          })
          var chartDom = document.getElementById("main");
          if (chartDom !== null) {
            var myChart = echarts.init(chartDom);
            var option;
            option = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
              },
              legend: {},
              grid: {
                left: "4%",
                right: "20%",
                bottom: "20%",
                top:"0%",
                containLabel: true,
              },
              xAxis: {
                type: "value",
                boundaryGap: [0, 0.01],
                axisLine: {
                  show: false, //隐藏y轴
                },
                axisTick: {
                  show: false, //刻度线
                },
              },
              yAxis: {
                type: "category",
                data: xarr,
                axisLine: {
                  show: false, //隐藏y轴
                },
                axisTick: {
                  show: false, //刻度线
                },
              },
              series: [
                {
                  type: "bar",
                  data: yarr,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                        {
                          // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                          // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                          offset: 0,
                          color: "rgba(22, 93, 255, 0.5)",
                        },
                        {
                          offset: 1,
                          color: "rgba(22, 93, 255, 0)",
                        },
                      ]),
                    },
                  },
                },
              ],
            };
            option && myChart.setOption(option);
          }
        }
    })
  }, []);
  return <div id="main" style={{width:"100%",height:"100%"}}></div>;
};

export default TopfiveCharts;
