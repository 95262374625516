import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import Gauge from "./component/Gauge";
import { Table, Button, Form, Input, Select, Badge } from "antd";
import { getserviceManage } from "../../api/ServiceHealth";
import { getServiceList } from "../../api/serviceManagement";
import moment from "moment";
const ServiceHealth: React.FC = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [listload, setlistload] = useState(false);
  const [total, setTotal] = useState(0);
  const [tabData, settabData] = useState([]);
  const [jumpData, setjumpData] = useState(null);
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress",
      key: "dpAddress",
    },
    {
      title: "API名称",
      dataIndex: "apiName",
      key: "apiName",
    },
    {
      title: "后端服务类型",
      dataIndex: "connectorType",
      key: "connectorType",
      //   render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      // align: "center" as "center",
    },
    {
      title: "版本号",
      dataIndex: "apiVersion",
      key: "apiVersion",
      //   render: (text: any) => (text?moment(text).format("YYYY-MM-DD HH:mm:ss"):""),
    },
    {
      title: "最后检测时间",
      dataIndex: "lastCheckTime",
      render: (text: any) => <>{text?moment(text).format("YYYY-MM-DD HH:mm:ss"):""}</>,
      // align: "center" as "center",
    },
    {
      title: "状态",
      dataIndex: "healthState",
      render: (text: any) => {
        return (
          <div className="nameBar">
            {text === 0 && <Badge color="#FF5353" text="异常" />}
            {text === 1 && <Badge color="#4CEFBE" text="正常" />}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      // align: "center" as "center",
      render: (id: string, record: any, index: number) => (
        <Button
          type="link"
          onClick={() => {
            history.push({
              pathname:"/servicehealthdetail",
              state:{
                apiName:record.apiName,
                dpAddress:record.dpAddress
              }
            }
              // `/servicehealthdetail/${record.dpAddress}/${record.apiName}`
            );
          }}
        >
          检测历史
        </Button>
      ),
    },
  ];
  const heartJump = useCallback(() => {
    getserviceManage().then((res) => {
      setjumpData(res.data);
    });
  }, []);
  const dataList = useCallback(() => {
    setlistload(true);
    getServiceList({
      dpAddress:
        form.getFieldValue("dpAddress") === ""
          ? undefined
          : form.getFieldValue("dpAddress"),
      // healthState: form.getFieldValue("connectorType"),
      ...pageParam,
    }).then((res: any) => {
      setlistload(false);
      settabData(res.data);
      setTotal(res.pagination.total);
    });
  }, [form, pageParam]);
  useEffect(() => {
    dataList();
    heartJump();
    setInterval(() => {
      dataList();
      heartJump();
    }, 1000 * 60 * 5);
  }, [dataList, heartJump]);
  return (
    <div className={styles["service-health"]}>
      <div className={styles["service-health-charts"]}>
        <Gauge jumpData={jumpData !== null ? jumpData : ""} />
      </div>
      <div className={styles["service-health-list"]}>
        <Form
          form={form}
          layout="inline"
          style={{ float: "left", marginBottom: "20px" }}
        >
          <Form.Item name="dpAddress" label="DataAddress">
            <Input placeholder="请输入DataAddress" />
          </Form.Item>
          {/* <Form.Item name="connectorType" label="健康状态">
            <Select style={{ width: 150 }} placeholder="请选择">
              <Option value={0}>异常</Option>
              <Option value={1}>正常</Option>
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={dataList}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setPageParam({
                  page: 1,
                  pageSize: 10,
                });
                dataList();
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          loading={listload}
          dataSource={tabData}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};
export default ServiceHealth;
