// @ts-nocheck
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Input, Form, Select, Modal, TreeSelect, message, Radio } from "antd";
import { getCreateCategry, getMyPlatformlist, postSystemmodify, postPlatformmodify } from "../../../api/scenario"
const { Option } = Select
const { TreeNode } = TreeSelect;

function Index({ props }: any, ref: any) {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  const addressChildren: any = [];
  const [editVisible, seteditVisible] = useState<boolean>(false)
  const [selectlabel, setlabel] = useState<string>("系统");
  const [initForm, setinitForm] = useState<any>({});
  const [category, setCategory] = useState<any>([])
  const [platform, setplatform] = useState<any>([])
  const [relevance, setrelevance] = useState<string>("类目");

  useImperativeHandle(ref, () => ({
    seteditVisible,
    setinitForm,
    setFieldsValue: form.setFieldsValue
  }))

  const onFinish = async (v: any) => {
    console.log(v);
    if (selectlabel === "系统") {
      console.log(1);
      const res = await postSystemmodify({
        id: initForm.id,
        address: v.address,
        categoryId: v.categoryId ? v.categoryId : "",
        platformId: v.platformId ? v.platformId : "",
        name: v.name,
        protocol: v.protocol,
        desc: v.desc,
      })
      console.log(res);
      if (res.code === 200) {
        message.success("更新成功")
        if (props.tag === "all") {
          props.systemList()
          // props.systemPlatformList()
        }
      }
    } else {
      console.log(2);
      const res = await postPlatformmodify({
        id: initForm.id,
        categoryIds: v.categoryId,
        desc: v.desc,
        name: v.name,
      })
      console.log(res);
      if (res.code === 200) {
        message.success("更新成功")
        if (props.tag === "all") {
          props.platformList()
          // props.systemPlatformList()
        }
      }
    }
    form.resetFields();
    seteditVisible(false)
  }

  //获取平台列表
  const platformList = async () => {
    const res = await getMyPlatformlist({
      page: 1,
      page_size: 20,
    })
    console.log(res);
    setplatform(res.data?.platforms)
  }

  //获取类别列表
  const getCategryList = () => {
    getCreateCategry({
      page: 1,
      page_size: 20
    }).then((res) => {
      if (res.code === 200) {
        setCategory(mapCategry(res.data))
      }
    })
  }
  //递归映射
  function mapCategry(data: any) {
    return data?.map((item: any) => {
      item.title = item.name;
      item.value = item.id;
      if (item.subCategories) {
        item.children = mapCategry(item.subCategories)
      }
      return item
    })
  }

  //选择树渲染
  const renderTreeNodes = data =>
    data?.map(item => {
      // console.log('item', item);
      if (item.children) {
        item.disabled = true;
        return (
          <TreeNode key={item.id} title={item.title} value={item.value} disabled={item.disabled} className="createTree">
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} key={item.id} title={item.title} value={item.value} />;
    });

  useEffect(() => {
    if (editVisible) {
      if (initForm.protocol) {
        platformList()
        setlabel("系统")
      } else {
        // setlabel("系统")
        setlabel("平台")
      }
    }
  }, [editVisible])

  useEffect(() => {
    getCategryList()
  }, [])

  let url = "(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
  let protocolReg = "(https|http)(:\/\/)" //协议 
  let domainNameReg = `([a-zA-Z0-9]([a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,11}` //域名
  let ipReg = `(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-5][0-5])` //ip
  let portReg = ":\\d{1,5}" //端口
  //域名，协议+域名，ip，协议+ip，域名+端口，协议+域名+端口，ip+端口，协议+ip+端口，url
  let reg = new RegExp(`^(${domainNameReg}|${protocolReg}${domainNameReg}|${ipReg}|${protocolReg}${ipReg}|${domainNameReg}${portReg}|${protocolReg}${domainNameReg}${portReg}|${ipReg}${portReg}|${protocolReg}${ipReg}${portReg}|${url})$`)
  // let reg = new RegExp(`^${protocolReg1}${ipReg}$`)
  // console.log(reg);

  return (
    <div>
      <Modal
        title={`编辑${selectlabel}`}
        onOk={() => { form.submit() }}
        onCancel={() => { form.resetFields(); seteditVisible(false) }}
        width={700}
        okText="确定"
        cancelText="取消"
        className="sceneModal"
        visible={editVisible}
      >
        <Form
          form={form}
          onFinish={onFinish}
          {...layout}
          initialValues={initForm}
          className="systemForm"
        >
          <Form.Item label="平台ID" name="id"
            rules={[{ required: true, message: 'id不能为空' }]}
          >
            <Input disabled />
          </Form.Item >
          <Form.Item label="名称" name="name"
            rules={[{ required: true, message: '请输入名称!' }]}
          >
            <Input />
          </Form.Item >

          <Form.Item label="描述" name="desc">
            <Input.TextArea showCount maxLength={200} placeholder='请输入内容，最多不超过200字' />
          </Form.Item >

          {/* 关联平台还是类目二选一 */}
          {selectlabel === "系统" && <Form.Item label="关联方式">
            <Radio.Group
              onChange={(e) => { setrelevance(e.target.value) }}
              value={relevance}
            >
              <Radio value={"类目"}>类目</Radio>
              <Radio value={"平台"}>平台</Radio>
            </Radio.Group>
          </Form.Item>}

          {relevance === "类目" ? <Form.Item label="所属类目" name="categoryId"
            rules={[{ required: true, message: '请选择类目!' }]}
          >
            <TreeSelect
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              multiple={selectlabel === "平台" ? true : false}
              treeDefaultExpandAll={false}
            >
              {renderTreeNodes(category)}
            </TreeSelect>
          </Form.Item> :
            <Form.Item label="所属平台" name="platformId"
              rules={[{ required: true, message: '请选择平台!' }]}
            >
              <Select>
                {platform.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
              </Select>
            </Form.Item>}

          {selectlabel === "系统" && <>
            <Form.Item label="访问地址" name="address"
              rules={[
                {
                  required: true,
                  message: '请输入正确的地址!',
                  // pattern: /http[s]{0,1}:\/\/([\w.]+\/?)\S*/,
                  pattern: new RegExp(reg),
                }
              ]}
            >
              <Select mode="tags">
                {addressChildren}
              </Select>
            </Form.Item >
            <Form.Item label="协议" name="protocol"
              rules={[{ required: true, message: '请选择协议' }]}
            >
              <Select>
                <Option value="http">http</Option>
                <Option value="webSocket">webSocket</Option>
              </Select>
            </Form.Item>
          </>}
        </Form>
      </Modal>
    </div>
  )
}
export default forwardRef(Index)