
import * as React from "react";
import { Table, Tabs } from "antd";
// @ts-ignore
// import styles from "@/pages/biz/ProtocolEditor/publish-serve/index.less";
// import { SoapColumns } from "../../step-2/component/SoapColumn";
import { ColumnProps, TableProps } from "antd/es/table";
const publishInfoColumns: (K?: string) => ColumnProps<any>[] = (type: any) => {
  return [
    { key: "name", dataIndex: 'name', title: '参数名称', width: 250 },
    {
      key: 'nullable', width: 150, dataIndex: 'nullable', title: '是否必须', render: (t: any, row: { required: any; }) => {
        return (t || Boolean(row.required)) ? '是' : '否'
      }, type: ['json', 'query', 'form', 'headers']
    },
    { key: 'type', dataIndex: 'type', title: '类型', width: 150, type: ['json'] },
    // {key: 'value', dataIndex: 'value', title: '参数值', width: 250, type: ['headers', 'header']},
    { key: 'title', dataIndex: 'title', title: '参数名称', width: 250, type: ['json'] },
    {
      key: 'example',
      dataIndex: 'example',
      title: '参数示例',
      width: 250,
      type: ['form', 'query', 'headers', 'header']
    },
    {
      key: 'description',
      title: '备注',
    }
  ].filter(i => !i.type || i.type.includes(type))
}

export interface ParseRequestJsonTableType {
  jsonData: any;//传入的request和response json数据，根据parseJson函数处理返回
  protocolIn: 'soap' | 'rest';//判断api是soap还是rest，以区分表头
  type?: 'response' | 'request';//是否有tabs的判断依据
  method?: 'get' | 'post';//tabsList数据内容
}

export const parseBodyJson: any = (data: any) => {
  const { required = [], properties } = data?.items || data || {};
  return properties ? Object.entries(properties).map(([name, values]) => {
    // @ts-ignore
    const { type, title, description } = values
    return Object.assign({
      name,
      required: required?.includes(name),
      type, title, description,
    }, (type === 'object' || type === 'array') ? { children: parseBodyJson(values) } : {})
  }) : []
}

const ParseRequestJsonTable: React.FC<ParseRequestJsonTableType> = (props) => {
  const { jsonData, protocolIn, type, method } = props

  const tabsList = (method?.toLowerCase() === 'get' ? [] : ['Body']).concat(['Query', 'Headers'])
  const tableProps: (T: string) => TableProps<any> = (jsonType) => ({
    // columns: protocolIn === 'soap' ? SoapColumns() : publishInfoColumns(jsonType),
    columns: publishInfoColumns(jsonType),
    dataSource: protocolIn === 'soap' ? jsonData : type === 'response' ? parseBodyJson(jsonData) : [],
    // className: styles['response-query'],
    rowKey: (row: { name: any; }) => row.name,
    bordered: false,
    defaultExpandAllRows: true,
    pagination: false
  })
  const isRestRequest = protocolIn === 'rest' && type === 'request'
  const getRequestTable = (methodType: string) => {
    if (jsonData && methodType?.toLowerCase() !== 'body') {
      return <Table {...tableProps(methodType?.toLowerCase())} dataSource={jsonData[methodType?.toLowerCase()]} />
    } else if (typeof jsonData?.post?.type === "string") {
      const isJson = jsonData?.post?.type === 'json'
      const dataSource = isJson ? parseBodyJson(jsonData?.post?.json) : jsonData?.post?.form;
      return <React.Fragment>
        {/* {jsonData?.post?.type && <h3 style={{ paddingLeft: 16, paddingBottom: 8 }}>{jsonData?.post?.type}</h3>} */}
        <Table {...tableProps(isJson ? 'json' : 'form')} dataSource={dataSource} />
      </React.Fragment>
    }
    return <Table {...tableProps('form')} dataSource={[]} />
  }
  return <React.Fragment>{jsonData && (isRestRequest ? <div>
    <Tabs defaultActiveKey={tabsList[0]}>
      {tabsList.map(methodType => <Tabs.TabPane tab={methodType} key={methodType}>{getRequestTable(methodType)}</Tabs.TabPane>)}
    </Tabs>
  </div> : <Table {...tableProps('json')} />)
  }</React.Fragment>
}
export default ParseRequestJsonTable
