//@ts-nocheck
import { useState, useCallback, useEffect, useContext } from "react";
import styles from "./index.module.scss";
import { Button, Form, Input, message, Select, Radio, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import SchemaEditor from "src/component/SchemaEditor";
import ItnterfaceMethod from "./component/index";
import { metaJson as sampleJson } from "./data";
// import {
//   getResourceDetail,
//   postResourceCreate,
//   postResourceModify,
// } from "src/api/resource";
// import { getSystemlist } from "../../../api/scenario";
import { useHistory, useParams } from "react-router-dom";
// import { WebAdminContext } from "src/context/WebAdminContext";
// import { RESOURCE_LIST } from "src/constants/path";
import { setpostObjData, setgetObjData } from "../../../action/httpAction";
import { Steps } from "antd";
import {
  postinterface,
  postinterfacehttpCreate,
  getsystemidlist,
  getinterfacedetail,
  postinterfacemodify,
  getversiondetail,
  postversionmodify,
  getdatabaselist,
} from "../../../api/interface";
const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 6 },
};
const { Option } = Select;
const Page = () => {
  // const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const postMethod = useSelector((state: any) => state.httppostSema); //Post
  const getMethod = useSelector((state: any) => state.httpgetSema); //Post
  const { Step } = Steps;
  const [formone] = Form.useForm();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectList, setSelectlist] = useState<[]>([]);
  const [protocol, setProtocol] = useState("");
  const [metaJson, setMetaJson] = useState<any>(JSON.stringify(sampleJson));
  const [method, setMethod] = useState("get");
  const { id, type } = useParams<any>();
  const [dp_address, setDPAddress] = useState("");
  const [state, setState] = useState(0);
  // const [create_id, setCreateId] = useState("");
  const [disable, setDisable] = useState(false);
  const [finished, setFinished] = useState(false);
  const [current, setCurrent] = useState(0);
  const [databaselist, setdatabaselist] = useState([]);
  const [databaseindex, setdatabaseindex] = useState([]);
  const [selectvalue, setValue] = useState<any>("http");
  const [methodValue, setmethodValue] = useState<any>("post");
  const [fromonevalue, setfromonevalue] = useState({
    name: "",
    description: "",
    system_id: "",
    protocol: "",
  });
  const [saveId, setsaveId] = useState<any>(null);
  const steps = [
    {
      title: "填写基本信息",
      content: "First-content",
      description: "创建接口信息",
    },
    {
      title: "协议关联字段",
      content: "Second-content",
      description: "输入详细的渠道内容",
    },
    {
      title: "完成创建",
      content: "Last-content",
      description: "创建成功",
    },
  ];
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const elementary = () => {
    formone.validateFields().then((data) => {
      console.log(data);
      setfromonevalue({
        name: data.name,
        description: data.description,
        system_id: data.system_id,
        protocol: data.protocol,
      });
    });
  };
  useEffect(() => {
    if (id === "create" && type === "create") {
      setCurrent(0);
    } else {
      setCurrent(1);
      setsaveId(id);
    }
  }, [id, type]);
  useEffect(() => {
    if (type === "detail" && id !== "create") {
      setCurrent(0);
      setsaveId(id);
      getinterfacedetail({
        id: id,
      }).then((res) => {
        const respone = res.data.item;
        formone.setFieldsValue({
          name: respone.name,
          kind: respone.kind,
          system_id: respone.system_id,
          desc: respone.desc,
        });
      });
    } else if (type !== "detail" && type !== "create" && id !== "create") {
      setCurrent(1);
      setsaveId(id);
      getversiondetail({ id: type }).then((res) => {
        const respone = res.data.item;
        if (res.code === 200) {
          if (respone.http_schema.method === "post") {
            dispatch(setpostObjData(respone.http_schema.send_schema));
          } else {
            dispatch(setgetObjData(respone.http_schema.send_schema));
          }
          form.setFieldsValue({
            version: respone.interface_name,
            kind: respone.kind,
            system_id: respone.system_id,
            desc: respone.desc,
            httpversion: respone.http_schema.version,
            url: respone.http_schema.url,
            send_codec: respone.http_schema.send_codec,
            receive_codec: respone.http_schema.receive_codec,
            postmethod: respone.http_schema.method,
            databaseKind: respone.database_schema.databaseKind,
            databaseName: respone.database_schema.databaseName,
            databaseoptions: respone.database_schema.options,
            baseusername: respone.database_schema.username,
            basepassword: respone.database_schema.password,
            databaseversion: respone.database_schema.version,
          });
          formone.setFieldsValue({
            protocol: respone.protocol,
          });
          setValue(respone.protocol);
        }
      });
    } else {
      dispatch(setpostObjData(""));
      dispatch(setgetObjData(""));
    }
  }, [type]);
  useEffect(() => {
    getdatabaselist({}).then((res) => {
      setdatabaselist(res.data);
    });
  }, []);
  useEffect(() => {
    if (id) {
      // getData();
    } else {
      setFinished(true);
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      setProtocol("http");
    }
  }, [id]);
  const chooseMethod = (e) => {
    console.log(e);

    setmethodValue(e);
  };
  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  const baseChange = (e, event) => {
    setdatabaseindex(event.index);
  };
  const renderContent = (data: any) => {
    switch (data) {
      case "http":
        return (
          <>
            <Form
              form={form}
              {...layout}
              initialValues={{ postmethod: "post" }}
            >
              <Form.Item label="HTTP版本号" name="httpversion">
                <Select disabled={disable}>
                  <Select.Option value="HTTP/1.1">HTTP/1.1</Select.Option>
                  <Select.Option value="HTTP/2.0">HTTP/2.0</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="接口版本号" name="version">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="接口地址" name="url">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="请求编码" name="send_codec">
                <Select disabled={disable}>
                  <Select.Option value="application/json">
                    application/json
                  </Select.Option>
                  <Select.Option value="application/x-www-form-urlencoded">
                    application/x-www-form-urlencoded
                  </Select.Option>
                  <Select.Option value="application/xml">
                    application/xml
                  </Select.Option>
                  <Select.Option value="text/xml">text/xml</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="响应编码" name="receive_codec">
                <Select disabled={disable}>
                  <Select.Option value="application/json">
                    application/json
                  </Select.Option>
                  <Select.Option value="application/x-www-form-urlencoded">
                    application/x-www-form-urlencoded
                  </Select.Option>
                  <Select.Option value="application/xml">
                    application/xml
                  </Select.Option>
                  <Select.Option value="text/xml">text/xml</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="请求方式" name="postmethod">
                <Select
                  disabled={disable}
                  onChange={chooseMethod}
                // defaultValue={"post"}
                >
                  <Select.Option value="post">post</Select.Option>
                  <Select.Option value="get">get</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <ItnterfaceMethod methodValue={form.getFieldValue("postmethod")} />
            {/* <Form form={form}>
              <Form.Item label="" name="schema">
                {finished && (
                  <SchemaEditor
                    initialValue={metaJson}
                    method={method}
                    disabled={disable}
                    onChange={(val) => setMetaJson(val)}
                    changeMethod={(v) => setMethod(v)}
                  />
                )}
              </Form.Item>
            </Form> */}
          </>
        );
      case "database":
        return (
          <>
            <Form form={form} {...layout}>
              <Form.Item label="数据库类型" name="databaseKind">
                <Select onChange={baseChange}>
                  {databaselist.map((item, index) => {
                    return (
                      <Option value={item.kind} index={index} key={item.kind}>
                        {item.description}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="数据库名称" name="databaseName">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="接口版本号" name="version">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="Options" name="databaseoptions">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="用户名" name="baseusername">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="密码" name="basepassword">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="数据库类型" name="databaseversion">
                <Select>
                  {databaselist[databaseindex]?.versions.map((item, index) => {
                    return (
                      <Option value={item.version} key={item.version}>
                        {item.description}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </>
        );
      case "message":
        return (
          <>
            <Form form={form} {...layout}>
              <Form.Item label="版本号" name="version">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="消息中间件类型" name="middleware">
                <Select disabled={disable}>
                  <Select.Option value="true">kafka</Select.Option>
                  <Select.Option value="false">RabbitMQ</Select.Option>
                  <Select.Option value="false">RocketMQ</Select.Option>
                  <Select.Option value="false">Plusar</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="数据库版本" name="theme">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="数据库主题" name="theme">
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item label="消息格式" name="middleware">
                <Select disabled={disable}>
                  <Select.Option value="true">kafka</Select.Option>
                  <Select.Option value="false">RabbitMQ</Select.Option>
                  <Select.Option value="false">RocketMQ</Select.Option>
                  <Select.Option value="false">Plusar</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        );
      default:
        return <></>;
    }
  };

  const stepRender = (data: any, value: any) => {
    switch (data) {
      case 0:
        return (
          <Form {...layout} form={formone}>
            <Form.Item
              label="接口名称"
              name="name"
              rules={[{ required: true, message: "请填写接口名称" }]}
            >
              <Input disabled={disable} />
            </Form.Item>
            <Form.Item
              label="接口类型"
              name="kind"
              rules={[{ required: true, message: "请选择类别" }]}
            >
              <Select
                style={{ textAlign: "left" }}
              //   onChange={handleChange}
              >
                <Option value={1}>健康检测</Option>
                <Option value={2}>认证</Option>
                <Option value={3}>数据</Option>
                <Option value={4}>业务</Option>
                <Option vvalue={99}>其他</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="所属系统"
              name="system_id"
              rules={[{ required: true, message: "请选择类别" }]}
            >
              <Select
                style={{ textAlign: "left" }}
              //   onChange={handleChange}
              >
                {selectList?.map((item) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="接口描述"
              name="desc"
              rules={[{ required: true, message: "请填写接口描述" }]}
            >
              <TextArea
                rows={4}
                placeholder="请输入接口介绍，最多不超过200字。"
                maxLength={200}
              />
            </Form.Item>
            {/* <Form.Item label="系统平台" name="system_id">
              <Select>
                {selectList.map((item: any) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {categoryMethod(item.category)}/{item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
          </Form>
        );
      case 1:
        return (
          <>
            <Form {...layout} form={formone}>
              <Form.Item
                label="类别"
                name="protocol"
                rules={[
                  { required: true, message: "Please choose openoption!" },
                ]}
              >
                <Radio.Group onChange={onChange} value={selectvalue}>
                  <Radio value={"http"}>HTTP</Radio>
                  <Radio value={"database"}>DATABASE</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
            {renderContent(value)}
          </>
        );
      case 2:
        return <>创建成功</>;
      default:
        return "";
    }
  };
  //保存
  const saveInfo = async () => {
    try {
      if (type === "detail" || saveId) {
        const { code, data } = await postinterfacemodify({
          name: formone.getFieldValue("name"),
          kind: formone.getFieldValue("kind"),
          system_id: formone.getFieldValue("system_id"),
          desc: formone.getFieldValue("desc"),
          id: saveId,
        });
        if (code === 200) {
          setsaveId(data.id);
          message.success("修改成功！");
        }
      } else {
        const { code, data } = await postinterface({
          name: formone.getFieldValue("name"),
          kind: formone.getFieldValue("kind"),
          system_id: formone.getFieldValue("system_id"),
          desc: formone.getFieldValue("desc"),
        });
        if (code === 200) {
          setsaveId(data.id);
          message.success("保存成功！");
        }
      }
    } catch (erro) { }
  };
  //创建接口
  const createSubmit = () => {
    if (type !== "create") {
      let psBody = {};
      let psQuery = {};
      let psHeaders = {};
      let psPath = {};
      let gtParams = {};
      let gtHeaders = {};
      let getPath = {};
      form.validateFields().then((data) => {
        postMethod.Body?.map((item, index) => {
          psBody[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        postMethod.Query?.map((item, index) => {
          psQuery[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        postMethod.Headers?.map((item, index) => {
          psHeaders[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        postMethod.Path?.map((item, index) => {
          psPath[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        getMethod.Params?.map((item, index) => {
          gtParams[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        getMethod.Headers?.map((item, index) => {
          gtHeaders[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        getMethod.Path?.map((item, index) => {
          getPath[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        let a = {};
        a["Body"] = {
          ...psBody,
        };
        a["queryParam"] = {
          ...psQuery,
        };
        a["header"] = {
          ...psHeaders,
        };
        a["pathVariable"] = {
          ...psPath,
        };
        let b = {};
        b["Params"] = {
          ...gtParams,
        };
        b["header"] = {
          ...gtHeaders,
        };
        b["pathVariable"] = {
          ...getPath,
        };
        postversionmodify({
          id: type,
          protocol: formone.getFieldValue("protocol"),
          version: form.getFieldValue("version"), //版本号
          http_schema: {
            url: form.getFieldValue("url"), //接口地址
            receive_codec: form.getFieldValue("receive_codec"), //响应编码
            receive_schema: "1",
            send_codec: form.getFieldValue("send_codec"), //请求编码
            send_schema:
              form.getFieldValue("postmethod") == "get"
                ? JSON.stringify(b)
                : JSON.stringify(a),
            method: form.getFieldValue("postmethod"),
            version: form.getFieldValue("httpversion"),
          },
          database_schema: {
            databaseKind: form.getFieldValue("databaseKind"),
            databaseName: form.getFieldValue("databaseName"),
            options: form.getFieldValue("databaseoptions"),
            username: form.getFieldValue("baseusername"),
            password: form.getFieldValue("basepassword"),
            version: form.getFieldValue("databaseversion"),
          },
        }).then((res) => { });
      });
    } else {
      let psBody = {};
      let psQuery = {};
      let psHeaders = {};
      let psPath = {};
      let gtParams = {};
      let gtHeaders = {};
      let getPath = {};
      form.validateFields().then((data) => {
        postMethod.Body?.map((item, index) => {
          psBody[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        postMethod.Query?.map((item, index) => {
          psQuery[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        postMethod.Headers?.map((item, index) => {
          psHeaders[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        postMethod.Path?.map((item, index) => {
          psPath[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        getMethod.Params?.map((item, index) => {
          gtParams[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        getMethod.Headers?.map((item, index) => {
          gtHeaders[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });
        getMethod.Path?.map((item, index) => {
          getPath[item.name] = {
            // optional: item.required,
            defaultValue: item.numType,
            type: item.selectValue,
            id: item.id,
          };
        });

        let a = {};

        a["Body"] = {
          ...psBody,
        };
        a["queryParam"] = {
          ...psQuery,
        };
        a["header"] = {
          ...psHeaders,
        };
        a["pathVariable"] = {
          ...psPath,
        };
        let b = {};
        b["Params"] = {
          ...gtParams,
        };
        b["header"] = {
          ...gtHeaders,
        };
        b["pathVariable"] = {
          ...getPath,
        };
        postinterfacehttpCreate({
          interface_id: saveId,
          protocol: formone.getFieldValue("protocol"),
          version: form.getFieldValue("version"), //版本号
          http_schema: {
            url: form.getFieldValue("url"), //接口地址
            receive_codec: form.getFieldValue("receive_codec"), //响应编码
            receive_schema: "1",
            send_codec: form.getFieldValue("send_codec"), //请求编码
            send_schema:
              form.getFieldValue("postmethod") == "get"
                ? JSON.stringify(b)
                : JSON.stringify(a),
            method: form.getFieldValue("postmethod"),
            version: form.getFieldValue("httpversion"),
          },
          database_schema: {
            databaseKind: form.getFieldValue("databaseKind"),
            databaseName: form.getFieldValue("databaseName"),
            options: form.getFieldValue("databaseoptions"),
            username: form.getFieldValue("baseusername"),
            password: form.getFieldValue("basepassword"),
            version: form.getFieldValue("databaseversion"),
          },
        }).then((res) => {
          console.log(res);
        });
      });
    }
  };
  const nextStep = () => {
    formone.validateFields().then((res) => {
      next();
      saveInfo();
    });
  };
  useEffect(() => {
    formone.setFieldsValue({
      protocol: "http",
    });
    getsystemidlist({
      page: 1,
      page_size: 1000,
    }).then((res) => {
      setSelectlist(res.data.systemIdNames);
    });
  }, []);
  return (
    <div className={styles["wrapper"]}>
      <div style={{ height: 10 }}></div>
      <div style={{ marginTop: "32px" }}>
        {id != "create" || type != "create" ? (
          <></>
        ) : (
          <Steps current={current}>
            {steps.map((item) => (
              <Step
                key={item.title}
                title={item.title}
                description={
                  <div className={styles["twiceTitle"]}>{item.description}</div>
                }
              />
            ))}
          </Steps>
        )}
        <div className={styles["steps-content"]}>
          {stepRender(current, selectvalue)}
        </div>
        <div className={styles["steps-action"]}>
          {current === 0 && (
            <>
              <></>
              {id !== "create" ? (
                <></>
              ) : (
                <Button type="primary" onClick={nextStep}>
                  下一步
                </Button>
              )}
              <Button onClick={saveInfo} style={{ marginLeft: "16px" }}>
                保存
              </Button>
            </>
          )}
          {current === 1 && (
            <Button
              type="primary"
              onClick={() => {
                // message.success("创建成功!");
                createSubmit();
                setCurrent(current + 1);
              }}
            >
              提交
            </Button>
          )}
          {current === 1 && id === "create" && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              上一步
            </Button>
          )}
          {current === 2 && (
            <Button style={{ margin: "0 8px" }} onClick={() => history.go(-1)}>
              返回
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page;
