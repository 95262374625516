import * as React from "react";
import { AllHTMLAttributes, useEffect, useState } from "react";
import { Input, Checkbox, Select, Radio, CheckboxOptionType } from "antd";
const { Option } = Select
const { TextArea } = Input;

type FormProps = {
  type: string,
  placeholder?: string,
  optionNameList?: string[],
  optionValueList?: string[],
  regularValue?:string
}

type SelectProps = {
  label: string;
  value: string;
}

export type FormDataProps = {
  name: string
  key: string
  type: number //(文本:text,单选框:radio,多选框:checkbox,选择框:select)
  optionName: string
  optionValue: string
  maxLength: number
  placeHolder: string
  checkValue: boolean
}




const FormTool: React.FC<FormProps> = (props) => {
  const { type, placeholder, optionNameList = [], optionValueList = [], ...restProps } = props
  // console.log(restProps);
  
  // switch (type) {
  //   case '1':
  //     return <Input placeholder={placeholder} {...restProps} />
  //   case '2':
  //     return <TextArea style={{ height: 100 }} placeholder={placeholder} {...restProps} />
  //   case '3':
  //     let options2: SelectProps[] = []
  //     optionNameList.map((item, idx) => (
  //       options2.push({ label: item + '', value: optionValueList[idx] + '' })
  //     ))
  //     return <Radio.Group {...restProps} options={options2} />
  //   case '4':
  //     let options: SelectProps[] = []
  //     optionNameList.map((item, idx) => (
  //       options.push({ label: item, value: optionValueList[idx] })
  //     ))
  //     return <Checkbox.Group options={options} {...restProps} />
  //   case '5':
  //     let options3: SelectProps[] = []
  //     optionNameList.map((item, idx) => (
  //       options3.push({ label: item + '', value: optionValueList[idx] + '' })
  //     ))
  //     return <Select placeholder={placeholder} options={options3}  {...restProps} />
  //   default:
  //     return null
  // }
  if(type==="1"){
    if(props.regularValue!==''){
      return <Input placeholder={placeholder} {...restProps} disabled={true}/>
    }else{
      return <Input placeholder={placeholder} {...restProps} />
    }

  }else if(type==="2"){
      return <TextArea style={{ height: 100 }} placeholder={placeholder} {...restProps} />
  }else if(type==="3"){
      let options2: SelectProps[] = []
      optionNameList.map((item, idx) => (
        options2.push({ label: item + '', value: optionValueList[idx] + '' })
      ))
      return <Radio.Group {...restProps} options={options2} />
  }else if(type==="4"){
      let options: SelectProps[] = []
      optionNameList.map((item, idx) => (
        options.push({ label: item, value: optionValueList[idx] })
      ))
      return <Checkbox.Group options={options} {...restProps} />
  }else if(type==="5"){
      let options3: SelectProps[] = []
      optionNameList.map((item, idx) => (
        options3.push({ label: item + '', value: optionValueList[idx] + '' })
      ))
      return <Select placeholder={placeholder} options={options3}  {...restProps} />
  }else{
    return null
  }
}

export default FormTool
