import { isEmpty } from 'lodash'
import { message } from 'antd'

export const handleIndicatorImportJson = (json, type, DEFAULT_SCHEMA = DEFAULT_SCHEMA) => {
  return new Promise((resolve) => {
    let tips = []
    const traverseJson = (defaultJson, jsonData = {}) => {
      Object.keys(defaultJson || {}).forEach(key => {
        console.log(key,jsonData,defaultJson);
        if (!jsonData[key]) {
          tips.push(`当前缺少${key}参数`)
        }else if (key !== 'data' && jsonData[key].type !== defaultJson[key].type) {
          tips.push(`${key}参数的类型为${defaultJson[key].type}`)
        } else {
          if (defaultJson[key].properties) {
            traverseJson(defaultJson[key].properties, (jsonData[key].type === 'array' ? jsonData[key].items.properties : (jsonData[key]?.properties)))
          }
          jsonData[key].disabled = defaultJson[key].disabled
          if (type !== 'schema') {
            jsonData[key].required = defaultJson[key].required
            jsonData[key].types = defaultJson[key].types
          }
        }
      })
    }
    const filterValueNull = (jsonData) => {
      jsonData && Object.keys(jsonData).forEach(key => {
        // if (jsonData[key].type === 'null') {
        //   tips.push(`${key}参数的值不能为null`)
        // }
        jsonData[key].properties && filterValueNull(jsonData[key].properties)
      })
    }
    filterValueNull(json.properties)
    Object.assign(json, {
      required: DEFAULT_SCHEMA.required,
      disabled: DEFAULT_SCHEMA.disabled,
      defaultData: DEFAULT_SCHEMA.defaultData
    })
    traverseJson(DEFAULT_SCHEMA.properties, json.properties)
    isEmpty(tips) ? resolve(json) : tips.map(i => message.warn(i))
  })
}