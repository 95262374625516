import { useEffect, useReducer, useState } from "react";
import {
  getEditServiceStart,
  getEditServiceFirst,
  getEditServiceSecond,
  getEditServiceThird,
  editServiceManage
} from "../../../../api/serviceManagement";
// import {getApiDetail, getDefproperties, getDefprotocal, getDefservie, startEditApi} from "@/services/protocolEditors";
import { Badge, Button, message } from "antd";
import qs from 'qs';
import * as React from "react";
import { useHistory } from 'react-router-dom'

export interface nameAgentType {
  // contactor: string;
  // contactorMobile: string;
  // serviceAsName: string;
  // serviceDesc: string;
  // serviceName: string;
  // serviceSubordinateSys: string;
  // serviceTagList: string[],
  // serviceVersion: string;
  // belongSystem: number;
  // sourceApiCode: string;
  // relSysCode: string[];
  // effectiveTime: any;
  // relationCategorys: number[];
  // requestSignVerification: boolean;
  // requestEncryptAndDecrypt: boolean;
  // responseSignVerification: boolean;
  // responseEncryptAndDecrypt: boolean;
  // maxQps: number;
  apiName: string;
  apiVersion: string;
  apiDesc: string;
  requestDecryption: boolean;
  responseDecryption: boolean;
  requestDataDecryption: boolean;
  responseDataDecryption: boolean;
  maxQps: number;
  uuid: string;
  id?: number;
  resOneMin?: number;
  resOneMax?: number;
  qpsOne?: number;
  resTwoMin?: number;
  resTwoMax?: number;
  qpsTwo?: number;
  resThreeMin?: number;
  resThreeMax?: number;
  qpsThree?: number;
  fuseOneMin?: number;
  fuseOneError?: number;
  fuseOneSecond?: number;
  fuseTwoMin?: number;
  fuseTwoError?: number;
  fuseTwoSecond?: number;
  fuseThreeMin?: number;
  fuseThreeError?: number;
  fuseThreeSecond?: number;
  numOrPctOne?: string;
  numOrPctTwo?: string;
  numOrPctThree?: string;
  authMode?: string;
}

export interface frontendConfigType {
  protocolIn: 'soap' | 'rest';
  apiPath: string;
  requestMethod: string;
  operation: string;
  timeOut: string | number;
  metaJson: string;
  uuid: string;
  wsdlUrl: string;
  file: string;
  wdlsType: string;
  ipName: string;
  sourceMetaJson: string;
  apiInterval: string;
  connectType: string;
  addressPort: string;
  connentor: string;
  accessProtocol: string;
  isMock: boolean;
  mockJson: string;
  requestPath: string;
}

export interface backendConfigType {
  connectorTypeId: string;
  dataSystem: any;
  connectorId: string;
  route: string;
  useTls: boolean;
}

interface relationCategoryType {
  rootName: string;
  name: string;
}

export type StateType = {
  uuid?: string;
  currentStep: 'nameAgent' | 'backendConfig' | 'frontendConfig' | 'publishingService';
  loading: boolean;
  nameAgent: any;
  backendConfig: Partial<backendConfigType>;
  frontendConfig: Partial<frontendConfigType>;
  apiRequestStructureDataAll?: any;
  detailInfo?: { appApi: any, appApiLimiting: any, relationCategorys: relationCategoryType[], relationSubSys: string[]; defApiArgsDTO: any; defApiProtocalDTO: any };
  categoryForm?: any;
  modifyPropertyTip?: any;
  extraButton?: React.ReactNode;
  mockState?: boolean;
  apiState?: number;
  connectType?: any;
  diapatchServiceStatus?: boolean;
  connectId?: number | string
}
type ActionType<T> = {
  type: T;
  //payload?: Partial<StateType[keyof StateType]>
  payload?: any;
}

export const initState: StateType = {
  currentStep: "nameAgent",
  connectType: [],
  connectId: '',
  nameAgent: {},
  frontendConfig: { protocolIn: 'rest', metaJson: JSON.stringify({}) },
  backendConfig: {},
  loading: false,
  diapatchServiceStatus: true
}

enum ActionNameEnum {
  NEXT_STEP = "NEXT_STEP",
  NAME_AGENT_SUBMIT = "NAME_AGENT_SUBMIT",
  RESTRICTING_ACCESS_SUBMIT = 'RESTRICTING_ACCESS_SUBMIT',
  PROTOCOL_TYPE_CHANGE = 'PROTOCOL_TYPE_CHANGE',
  UUID_DISPATCH = 'UUID_DISPATCH',
  EDIT_PAGE_INFO = 'EDIT_PAGE_INFO',
  EMPTY_STATE = 'EMPTY_STATE',
  BEGIN_QUERY = 'BEGIN_QUERY',
  ERROR_QUERY = 'ERROR_QUERY',
  MODIFY_EDIT_TIP = 'MODIFY_EDIT_TIP',
  UPDATE_MOCK_STATE = 'UPDATE_MOCK_STATE',
  UPDATE_REGISTER = 'UPDATE_REGISTER',
  ADD_EXTRA_BTN = 'ADD_EXTRA_BTN',
  DISPATCH_CONNECTOR_TYPE = 'DISPATCH_CONNECTOR_TYPE',
  DISPATCH_SERVICE_SUCCESS = 'DISPATCH_SERVICE_SUCCESS',
  TRANSFER_CONNECTOR_ID = 'TRANSFER_CONNECTOR_ID'
}

type ActionName =
  'NAME_AGENT_SUBMIT'
  | 'UPDATE_MOCK_STATE'
  | 'ADD_EXTRA_BTN'
  |
  'NEXT_STEP'
  | 'RESTRICTING_ACCESS_SUBMIT'
  | 'PROTOCOL_TYPE_CHANGE'
  |
  'UUID_DISPATCH'
  | 'EDIT_PAGE_INFO'
  | 'EMPTY_STATE'
  | 'BEGIN_QUERY'
  | 'ERROR_QUERY'
  | 'MODIFY_EDIT_TIP'
  | 'UPDATE_REGISTER'
  | 'DISPATCH_CONNECTOR_TYPE'
  | 'DISPATCH_SERVICE_SUCCESS'
  | 'TRANSFER_CONNECTOR_ID'

type ReducerType = (S: StateType, A: ActionType<ActionName>) => StateType
// @ts-ignore
const reducer: ReducerType = (state?: StateType, action: ActionType<ActionName>) => {
  switch (action.type) {
    case ActionNameEnum.NEXT_STEP:
      return { ...state, currentStep: action.payload, loading: false }
    case ActionNameEnum.NAME_AGENT_SUBMIT:
      return { ...state, nameAgent: action.payload, loading: false }
    case ActionNameEnum.RESTRICTING_ACCESS_SUBMIT:
      return { ...state, frontendConfig: action.payload, loading: false }
    case ActionNameEnum.PROTOCOL_TYPE_CHANGE:
      // @ts-ignore
      return { ...state, backendConfig: { ...state.backendConfig, ...action.payload }, loading: false }
    case ActionNameEnum.UUID_DISPATCH:
      return { ...state, uuid: action.payload, loading: false }
    case ActionNameEnum.EDIT_PAGE_INFO:
      return { ...state, ...action.payload, loading: false }
    case ActionNameEnum.EMPTY_STATE:
      return initState
    case ActionNameEnum.BEGIN_QUERY:
      return { ...state, loading: true }
    case ActionNameEnum.ERROR_QUERY:
      return { ...state, loading: false }
    case ActionNameEnum.MODIFY_EDIT_TIP:
      return { ...state, modifyPropertyTip: action.payload }
    case ActionNameEnum.UPDATE_MOCK_STATE:
      return { ...state, mockState: action.payload }
    case ActionNameEnum.UPDATE_REGISTER:
      return { ...state, ...action.payload }
    case ActionNameEnum.DISPATCH_CONNECTOR_TYPE:
      return { ...state, connectType: action.payload }
    case ActionNameEnum.DISPATCH_SERVICE_SUCCESS:
      return { ...state, diapatchServiceStatus: action.payload }
    case ActionNameEnum.TRANSFER_CONNECTOR_ID:
      return { ...state, connectId: action.payload }
  }
  return state;
}

//@ts-ignore
export const createExtraButton = (history) => <Button style={{ marginRight: 16 }} onClick={() => {
  history.push({ pathname: '/servicemanagement', state: history.location.state })
}}>返回列表</Button>
//@ts-ignore
const usePublishAgent = (urlInfo, type,referer) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initState,
    currentStep: type === 'detail' ? "publishingService" : initState.currentStep
  })
  const history = useHistory();

  //@ts-ignore
  const getEditInfo = async (uuid) => {
    const [step1, step2, step3] = await Promise.all([getEditServiceFirst({ uuid, "requestType": 1 }), getEditServiceSecond({ uuid, "requestType": 2 }), getEditServiceThird({ uuid, "requestType": 3 })])
    dispatch({
      type: 'EDIT_PAGE_INFO', payload: {
        uuid,
        nameAgent: step1.code === 200 ? { ...step1.data, ...step1.data.serviceLimiting } : {},
        backendConfig: step2.code === 200 ? step2.data : state.backendConfig,
        frontendConfig: step3.code === 200 ? step3.data : state.frontendConfig,
        currentStep: state.currentStep,
        loading: false,
        extraButton: createExtraButton(history),
        apiState: urlInfo?.state
      }
    })
  }

  const getDetail = () => {
    dispatch({ type: 'BEGIN_QUERY' })
    // getApiDetail({appApiId:urlInfo?.appApiId}).then(res => {
    //   if (res.code === '200') {
    //     dispatch({type: 'EDIT_PAGE_INFO', payload: {detailInfo: res.data}})
    //   } else {
    //     message.warn(res.message)
    //   }
    // })
  }
  const startGetEditInfo = () => {
    dispatch({ type: 'BEGIN_QUERY' })
    getEditServiceStart({ id: urlInfo?.appApiId }).then(async (res) => {
      if (res.code == 200) {
        const { data = {} } = res;
        getEditInfo(data?.uuid);
      }
    })
  }
  const startGray = () =>{
    // console.log(urlInfo);
    dispatch({ type: 'BEGIN_QUERY' })
    getEditServiceStart({ dpAddress: urlInfo?.appApiId }).then(async (res) => {
      if (res.code == 200) {
        const { data = {} } = res;
        getEditInfo(data?.uuid);
      }
    })
  }
  useEffect(() => {
    // console.log(referer);
    
    //获取详情和编辑信息
    if (type === 'detail') {
      getDetail()
    } else if (referer==="apiListEdit") {
      startGetEditInfo()
    }else if(referer==="apiGray"){
      startGray()
    }
  }, [])

  return { state, dispatch, getDetail }
}
export default usePublishAgent;
