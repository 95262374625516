import { getClient, postClient } from "./requestClient";
import {POST_Service_health , GET_log_list , GET_serviceManage_statistics,GET_service_statistics, GET_service_aggregation,GET_report_aggregation,GET_report_credentia} from "./index";

//审批资源
export const postServicehealth = async (params: any) =>
  await postClient<any>(POST_Service_health, params);

  //审批资源
export const getloglist = async (params: any) =>
await getClient<any>(GET_log_list, params);

export const getserviceManage = async () =>
await getClient<any>(GET_serviceManage_statistics);

export const getreportcredentia = async () =>
await getClient<any>(GET_report_credentia);

export const getreportaggregation = async () =>
await getClient<any>(GET_report_aggregation);

export const getserviceaggregation = async () =>
await getClient<any>(GET_service_aggregation);

export const getservicestatistics = async () =>
await getClient<any>(GET_service_statistics);