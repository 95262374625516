import * as React from "react";
import { AllHTMLAttributes, useEffect, useState } from "react";
import {
  Button,
  Switch,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Radio,
} from "antd";
import { FormItemProps } from "antd/es/form";
import { PublishContext } from "@/pages/Service/PublishServe";
import {
  addServiceFirst,
  getConnectorType,
  getConnectorList,
} from "../../../../api/serviceManagement";
import DetailCard from "../../../../component/DetailCard";
import { getconnectorlist ,getprotocol} from "../../../../api/connector";
import {
  currentLimiting,
  TipCom,
  labels,
  currentFusingLimiting,
  authModes,
} from "../utils-data/commonData";
type NameAgentProps = {
  appApiId: any;
  referer: string;
} & AllHTMLAttributes<HTMLElement> &
  FormItemProps;
const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const NameAgent: React.FC<any> = (props) => {
  const { appApiId, referer } = props;
  const [form] = Form.useForm();
  const { state, dispatch, history } = React.useContext(PublishContext);
  const { nameAgent, currentStep, extraButton, loading, uuid } = state;
  const [disable, setdisable] = useState<boolean>(false);
  useEffect(() => {
    getConnectorTypeData();
  }, []);
  useEffect(() => {
    if (referer === "apiGray") {
      setdisable(true);
    } else {
      setdisable(false);
    }
  }, [referer]);
  useEffect(() => {
    const {
      apiName,
      apiVersion,
      apiDesc,
      requestDecryption,
      requestDataDecryption,
      responseDecryption,
      responseDataDecryption,
      maxQps,
      resOneMin,
      resOneMax,
      qpsOne,
      resTwoMin,
      resTwoMax,
      qpsTwo,
      resThreeMin,
      resThreeMax,
      qpsThree,
      fuseOneMin,
      fuseOneError,
      fuseOneSecond,
      fuseTwoMin,
      fuseTwoError,
      fuseTwoSecond,
      fuseThreeMin,
      fuseThreeError,
      fuseThreeSecond,
      numOrPctOne,
      numOrPctTwo,
      numOrPctThree,
      authMode = 1,
      requestEncrypt,
      requestSign,
      responseEncrypt,
      responseSign
    } = nameAgent;
    console.log(nameAgent);
    form.setFieldsValue({
      apiName,
      apiVersion,
      apiDesc,
      requestDecryption,
      requestDataDecryption,
      responseDecryption,
      responseDataDecryption,
      maxQps,
      resOneMin,
      resOneMax,
      qpsOne,
      resTwoMin,
      resTwoMax,
      qpsTwo,
      resThreeMin,
      resThreeMax,
      qpsThree,
      fuseOneMin,
      fuseOneError,
      fuseOneSecond,
      fuseTwoMin,
      fuseTwoError,
      fuseTwoSecond,
      fuseThreeMin,
      fuseThreeError,
      fuseThreeSecond,
      numOrPctOne,
      numOrPctTwo,
      numOrPctThree,
      authMode,
      requestEncrypt,
      requestSign,
      responseEncrypt,
      responseSign
    });
  }, [nameAgent]);

  const getConnectorTypeData = () => {
    getprotocol().then((res) => {
      if (res.code === 200) {
        dispatch &&
          dispatch({ type: "DISPATCH_CONNECTOR_TYPE", payload: res.data });
      }
    });
  };

  const onNextStep = () => {
    form.validateFields().then((values) => {
      dispatch && dispatch({ type: "BEGIN_QUERY" });
      // values['authMode'] = authModes[values['authMode'] - 1]
      values["serviceLimiting"] = JSON.parse(JSON.stringify(values));
      if (appApiId) {
        values["serviceLimiting"]["id"] = nameAgent.id;
      }
      addServiceFirst(appApiId ? { uuid, ...values } : values).then((res) => {
        if (res.code === 200) {
          dispatch &&
            dispatch({ type: "UUID_DISPATCH", payload: res.data?.uuid });
          dispatch && dispatch({ type: "NEXT_STEP", payload: "backendConfig" });
        }
      });
    });
  };

  const FusingRender = (props: { index: any; idx: number }) => {
    const { index, idx, ...restProps } = props;
    const keys = ["One", "Two", "Three"];
    return (
      <>
        {index === 0 && (
          <InputNumber
            {...restProps}
            placeholder={"请输入1~60之间的数字"}
            min={0}
            maxLength={9}
            style={{ width: 180 }}
            disabled={disable}
          />
        )}
        {index === 1 && (
          <>
            <InputNumber
              {...restProps}
              placeholder={"请输入1~9999之间的数字"}
              min={0}
              maxLength={9}
              style={{ width: 190 }}
              disabled={disable}
            />
            <Form.Item
              label=""
              name={`numOrPct${keys[idx]}`}
              style={{ display: "inline-block", marginBottom: 0 }}
              initialValue={"num"}
            >
              <Select
                style={{ width: 60, marginLeft: 10 }}
                defaultValue={"num"}
                disabled={disable}
              >
                <Option value="num">次</Option>
                <Option value="pct">%</Option>
              </Select>
            </Form.Item>
          </>
        )}
        {index === 2 && (
          <InputNumber
            {...restProps}
            placeholder={"请输入60~3600之间的数字"}
            min={0}
            maxLength={9}
            style={{ width: 220 }}
            disabled={disable}
          />
        )}
      </>
    );
  };

  return (
    <div style={{ display: currentStep === "nameAgent" ? "block" : "none" }}>
      <Spin spinning={loading}>
        <Form {...layout} style={{ marginTop: 30 }} form={form}>
          <DetailCard title={"基本信息"}>
            <div className="name-agent-layout">
              <div className="name-agent-box">
                <Form.Item
                  label={"API名称"}
                  name="apiName"
                  rules={[{ required: true, message: "请输入API名称" }]}
                >
                  <Input placeholder={"请输入API名称"} maxLength={23} disabled={disable}/>
                </Form.Item>
                <Form.Item
                  label={"版本号"}
                  name="apiVersion"
                  rules={[{ required: true, message: "请输入版本号" }]}
                >
                  <Input placeholder={"请输入版本号"} maxLength={23} disabled={disable}/>
                </Form.Item>
                <Form.Item
                  label={"API说明"}
                  name="apiDesc"
                  rules={[{ required: true, message: "请填写API说明" }]}
                >
                  <Input.TextArea
                    placeholder={"请填写API说明"}
                    maxLength={130}
                    disabled={disable}
                  />
                </Form.Item>
              </div>
            </div>
          </DetailCard>
          <DetailCard title={"鉴权模式"}>
            <Form.Item
              label={""}
              name="authMode"
              // rules={[{ required: true }]}
            >
              <Radio.Group style={{ paddingLeft: 60 }}>
                {authModes.map((item, idx) => (
                  <Radio value={idx + 1} disabled={disable}>{item}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </DetailCard>
          <DetailCard title={"安全配置信息"}>
            <div className="name-agent-layout">
              <div className="name-agent-box">
                <Form.Item
                  label={"请求加签验签"}
                  name="requestSign"
                  valuePropName="checked"
                >
                  <Switch disabled={disable}/>
                </Form.Item>
                <Form.Item
                  label={"请求报文加密解密"}
                  name="requestEncrypt"
                  valuePropName="checked"
                >
                  <Switch disabled={disable}/>
                </Form.Item>
              </div>
              <div className="name-agent-box">
                <Form.Item
                  label={"响应加签验签"}
                  name="responseSign"
                  valuePropName="checked"
                >
                  <Switch disabled={disable}/>
                </Form.Item>
                <Form.Item
                  label={"响应报文加密解密"}
                  name="responseEncrypt"
                  valuePropName="checked"
                >
                  <Switch disabled={disable}/>
                </Form.Item>
              </div>
            </div>
          </DetailCard>
          <DetailCard title={"性能设置"}>
            <div className="name-agent-layout">
              <div className="name-agent-box">
                <Form.Item
                  label={"最大QPS"}
                  name="maxQps"
                  // rules={[{ required: true, message: "请输入最大QPS" }]}
                >
                  <InputNumber
                    controls={false}
                    min={1}
                    style={{ width: "80%" }}
                    disabled={disable}
                  />
                </Form.Item>
                <Form.Item
                  label={"预计响应时间"}
                  name="resTime"
                  // rules={[{ message: '请输入预计响应时间' }]}
                >
                  <InputNumber
                    controls={false}
                    min={1}
                    style={{ width: "80%" }}
                    disabled={disable}
                  />
                  <span style={{ marginLeft: 10 }}>ms</span>
                </Form.Item>
                <Form.Item
                  label={"超时时间"}
                  name="timeOut"
                  // rules={[{ message: '请输入超时时间' }]}
                >
                  <InputNumber
                    controls={false}
                    min={1}
                    style={{ width: "80%" }}
                    disabled={disable}
                  />
                  <span style={{ marginLeft: 10 }}>ms</span>
                </Form.Item>
              </div>
            </div>
          </DetailCard>
          <DetailCard
            title={<div>流量控制{TipCom("limiting")}</div>}
            className="restricting-access-section"
          >
            {labels.map((i, idx) => (
              <div
                className={"limiting-config-row"}
                style={{ paddingLeft: 60 }}
                key={idx}
              >
                {Object.keys(currentLimiting(idx)).map((key, kInd) => (
                  <div className={"limiting-config-row-cell"} key={key + idx}>
                    <Form.Item
                      name={key}
                      labelCol={{ span: 0 }}
                      wrapperCol={{ span: 0 }}
                      label={currentLimiting(idx)[key].label}
                      initialValue={nameAgent[key]}
                      rules={[
                        {
                          // message: '请输入',
                          validator: (rule, value) => {
                            let hasValue = false;
                            let hasValueNumber = 0;
                            let hasError = "";
                            let arr = Object.keys(currentLimiting(idx));
                            arr.map((k, ind) => {
                              if (form.getFieldValue(k)) hasValue = true;
                              if (
                                kInd == 1 &&
                                form.getFieldValue(arr[0]) &&
                                form.getFieldValue(arr[1])
                              ) {
                                hasError =
                                  form.getFieldValue(arr[0]) >
                                  form.getFieldValue(arr[1])
                                    ? form.getFieldValue(arr[0])
                                    : "";
                              }
                            });
                            //小于idx级别都必须输入
                            if (
                              idx < Object.keys(currentLimiting(idx)).length
                            ) {
                              for (let i = 3; i > idx; i--) {
                                let arrs = Object.keys(currentLimiting(i));
                                arrs.map((k, ind) => {
                                  if (form.getFieldValue(k)) hasValue = true;
                                });
                              }
                            }
                            if (!value && hasValue) {
                              return Promise.reject("请输入");
                            } else {
                              if (hasError)
                                return Promise.reject(
                                  `输入值应大于${hasError}`
                                );
                              return Promise.resolve();
                            }
                          },
                        },
                        {
                          pattern: /[0-9.]$/,
                          message: "请输入数字",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder={"请输入"}
                        min={0}
                        maxLength={9}
                        style={{ width: 130 }}
                        disabled={disable}
                      />
                    </Form.Item>
                    {currentLimiting(idx)[key].unit && (
                      <span className={"form-item-unit"}>
                        {currentLimiting(idx)[key].unit}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </DetailCard>
          <DetailCard
            title={<div>熔断控制{TipCom("limiting-fusing")}</div>}
            className="restricting-access-section"
          >
            {["一"].map((i, idx) => (
              <div
                className={"limiting-config-row"}
                style={{ paddingLeft: 60 }}
                key={idx}
              >
                {Object.keys(currentFusingLimiting(idx)).map((key, index) => (
                  <div className={"limiting-config-row-cell"} key={key + idx}>
                    <Form.Item
                      name={key}
                      labelCol={{ span: 0 }}
                      wrapperCol={{ span: 0 }}
                      label={currentFusingLimiting(idx)[key].label}
                      initialValue={nameAgent[key]}
                      rules={[
                        {
                          message: "请输入",
                          validator: (rule, value) => {
                            let hasValue = false;
                            Object.keys(currentFusingLimiting(idx)).map((k) => {
                              if (form.getFieldValue(k)) hasValue = true;
                            });

                            if (!value && hasValue) {
                              return Promise.reject("请输入");
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                        {
                          pattern: /[0-9.]$/,
                          message: "请输入数字",
                        },
                      ]}
                    >
                      <FusingRender index={index} idx={idx} />
                    </Form.Item>
                    {currentFusingLimiting(idx)[key].unit && (
                      <span className={"form-item-unit"}>
                        {currentFusingLimiting(idx)[key].unit}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </DetailCard>
        </Form>
        <div className="publish-agent-action">
          {extraButton}
          <Button style={{ width: 100 }} type={"primary"} onClick={onNextStep}>
            下一步
          </Button>
        </div>
      </Spin>
    </div>
  );
};
export default NameAgent;
