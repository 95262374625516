import {
  GET_USER_LIST,
  POST_USER_CREATE,
  POST_USER_DISABLE,
  POST_USER_ENABLE,
  POST_USER_LOGIN,
  POST_USER_REFRESH_TOKEN,
  GET_USER_LOGOUT,
  GET_USER_USERINFO,
  GET_ROLE_LIST,
  POST_ROLE_OPERATE,
  GET_USER_ROLE,
  POST_USER_DELETE,
  // GET_SUBUSER_DELETE,
  GET_SUBUSER_DETAIL,
  GET_USER_INFO
} from "./index";
import { getClient, postClient ,putClient,deleteClient} from "./requestClient";

export interface IRuserlist {
  code: number;
  message: string;
  data: any;
  paginate?: {
    page: number;
    page_size: number;
    total: number;
    total_pages: number;
  };
}
export interface IRolelist {
  id: string,
  name: string
}

export interface IRuserrole {
  roleList: IRroleloginlist[]
}
export interface IRroleloginlist {
  id: string,
  name: string
}

export interface IRroleloginlists {
  userId: '',
  role: {
    id: string,
    name: string
  }
}



//用户列表
export const getUserlist = async (params: any) =>
  await getClient<any>(GET_USER_LIST, params);

//创建用户
export const postUsercreate = async (params: any) =>
  await postClient(POST_USER_CREATE, params);

//停用用户
export const postUserdisable = async (params: any) =>
  await postClient(POST_USER_DISABLE, params);

// 启用用户
export const postUserenable = async (params: any) =>
  await postClient(POST_USER_ENABLE, params);

// 登入
export const postUserlogin = async (params: any) =>
  await postClient<any>(POST_USER_LOGIN, params);

// 退出登录

export const getUserlogout = async (params: any) =>
  await postClient(GET_USER_LOGOUT, params);
//刷新token
export const postUserrefreshtoken = async (params: any) =>
  await postClient(POST_USER_REFRESH_TOKEN, params);

//登录用户信息
export const getUserinfo = async (params: any) =>
  await getClient<any>(GET_USER_USERINFO, params);

//获取所有角色
export const getRolelist = async (params: any) =>
  await getClient<IRolelist[]>(GET_ROLE_LIST, params);

//更改角色
export const postroleoperate = async (params: any) =>
  await postClient(POST_ROLE_OPERATE, params);

// 获取所有角色
export const getuserRole = async (params: any) =>
  await getClient<IRroleloginlists>(GET_USER_ROLE, params);

//删除子用户
export const getSubuserdelete = async (params: any) =>
  await postClient<any>(POST_USER_DELETE, params);
  //子用户详情
export const getSubuserdetail = async (params: any) =>
await getClient<any>(GET_SUBUSER_DETAIL, params);

//用户详情
export const getuserinfo = async (params: any) =>
await getClient<any>(GET_USER_INFO, params);

//删除用户
export const deleteUser = async (params: any) =>
await deleteClient(`/api/account/${params}`);

  //修改用户
  export const putUser = async (params: any) =>
  await putClient(POST_USER_CREATE, params);