import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Table,
  Space,
  Popconfirm,
  message,
  Dropdown,
  Menu,
  Divider,
  Modal,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import qs from "qs";
import {
  getServiceType,
  getServiceList,
  deleteServiceItem,
  changeStatus,
} from "../../api/serviceManagement";
import {getprotocol} from "../../api/connector"
import "./index.scss";
import {
  DownOutlined,
  SmileOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const ServiceManagement: React.FC = () => {
  const states:any = {
    0: "已下架",
    1: "已上架",
  };
  // const menu = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: <a>1st menu item</a>,
  //       },
  //       {
  //         key: "2",
  //         label: <a>3rd menu item (disabled)</a>,
  //       },
  //     ]}
  //   />
  // );
  const showConfirm = (record:any) => {
    confirm({
      title: "目前暂无灰度版本，是否创建灰度版本?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        history.push({
          pathname: "/graylevel",
          state: {
            id: record.grayScaleId,
            type: record.grayScaleState,
            dpAddress: record.dpAddress,
          },
        });
      },
      onCancel() {},
    });
  };
  const menu = (record: any) => {
    return (
      <Menu>
        {record.status == 0 ? (
          <Menu.Item onClick={() => showConfirmup(record.id, 1)}>
            上架
          </Menu.Item>
        ) : (
          <Menu.Item onClick={() => showConfirmdown(record.id, 0)}>
            下架
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            if (record.isGenGrayScale) {
              history.push({
                pathname: "/graylevel",
                state: {
                  id: record.grayScaleId,
                  type: record.grayScaleState,
                  dpAddress: record.dpAddress,
                },
              });
            } else {
              showConfirm(record);
            }
          }}
        >
          灰度发布
        </Menu.Item>
      </Menu>
    );
  };
  const { Option } = Select;
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const history = useHistory();
  const [typeList, setTypeList] = useState([]);
  const [pageParam, setPageParam] = useState({
    page: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [listload, setlistload] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const columns: any = [
    {
      title: "序号",
      dataIndex: "dataIndex",
      width: 80,
      render: (text: any, record: any, index: any) =>
        (pageParam.page - 1) * pageParam.pageSize + (index + 1),
    },
    {
      title: "DataAddress",
      dataIndex: "dpAddress",

      width: 250,
      render: (text: any, record: any) => (
        <>
          <span>{text}</span>
          {record.isGenGrayScale && (
            <span
              style={{
                background: "#85AAFF",
                padding: "0px 4px",
                borderRadius: "4px",
                color: "white",
                textAlign: "center",
                marginLeft: "2px",
              }}
            >
              灰度
            </span>
          )}
        </>
      ),
    },
    {
      title: "API名称",
      dataIndex: "apiName",
    },
    {
      title: "版本号",
      dataIndex: "apiVersion",
    },
    {
      title: "鉴权模式",
      dataIndex: "authMode",
      render: (text: any) => (
        <>
          {text === 1 && "public"}
          {text === 2 && "private"}
        </>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (text: string) => {
        return text == "1" ? "已上架" : "已下架";
      },
    },
    {
      title: "后端服务类型",
      dataIndex: "connectorType",
    },
    {
      title: "最后发布时间",
      dataIndex: "updateTime",
    },
    {
      title: "操作",
      dataIndex: "id",
      fixed: "right",
      width: 400,
      // align: "center" as "center",
      render: (id: number, record: any) => (
        <>
          <Link
            to={{
              pathname: "/service/detail",
              search: qs.stringify({
                id: record.id,
              }),
            }}
          >
            <Button type="link">详情</Button>
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: "/service/publish-serve",
              search: qs.stringify({
                appApiId: record.id,
                referer: "apiListEdit",
              }),
            }}
          >
            <Button type="link">编辑</Button>
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title="您确定要删除本条记录吗?"
            onConfirm={() => onRemove(id)}
            placement="topLeft"
            okText="是"
            cancelText="否"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Dropdown overlay={menu(record)}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                更多
                <DownOutlined style={{ transform: "scale(0.7)" }} />
              </Space>
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  const showConfirmup = (id: any, type: any) => {
    confirm({
      title: "确认上架吗？",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        onChangeStatus(id, type);
      },
      onCancel() {},
    });
  };
  const showConfirmdown = (id: any, type: any) => {
    confirm({
      title: "下架后DataAddress将不可调用，还要继续操作吗？",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        onChangeStatus(id, type);
      },
      onCancel() {},
    });
  };
  useEffect(() => {
    getServiceTypeList();
    // getList();
  }, []);

  useEffect(() => {
    getList();
  }, [pageParam.page, pageParam.pageSize]);

  const getServiceTypeList = () => {
    getprotocol().then((res) => {
      setTypeList(res?.data);
    });
  };

  const onRemove = (id: number) => {
    deleteServiceItem({ id }).then((res) => {
      if (res.code === 200) {
        message.success("删除成功!");
        getList();
      }
    });
  };

  const onChangeStatus = (id: number, status: number) => {
    changeStatus({ id, status }).then((res) => {
      if (res.code === 200) {
        message.success(`${status == 1 ? "上架" : "下架"}成功!`);
        getList();
      }
    });
  };

  const getList = (values = {}) => {
    setlistload(true);
    getServiceList({
      ...values,
      dpAddress: form.getFieldValue("dpAddress") || undefined,
      connectorType: form.getFieldValue("connectorType"),
      ...pageParam,
    }).then((res: any) => {
      if (res.code == 200) {
        setlistload(false);
        setServiceList(res?.data);
        setTotal(res.pagination.total);
      }
    });
  };

  const onSearch = () => {
    form.validateFields().then((values) => {
      setPageParam({
        page: 1,
        pageSize: 10,
      });
      getList(values);
    });
  };

  const onReset = () => {
    form.resetFields();
    setPageParam({
      page: 1,
      pageSize: 10,
    });
    getList();
  };

  return (
    <div style={{ padding: "20px" }} className="center-service-management">
      <div className="center-service-management_title">
        <div className="center-service-management_title_split"></div>
        <div>服务管理</div>
      </div>
      <div style={{ height: 50, marginTop: "20px" }}>
        <Form form={form} layout="inline" style={{ float: "left" }}>
          <Form.Item name="dpAddress" label="DataAddress">
            <Input allowClear placeholder="请输入DataAddress" />
          </Form.Item>
          <Form.Item name="status" label="API状态">
            <Select allowClear placeholder="请选择" style={{ width: 100 }}>
              <Option value={""}>全部</Option>
              {Object.keys(states).map((item: any, idx: any) => {
                return <Option value={item}>{states[idx]}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item name="connectorType" label="后端服务类型">
            <Select allowClear placeholder="请选择" style={{ width: 180 }}>
              {typeList.map((item: any) => {
                return <Option value={item.id}>{item.protocol}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "16px" }}
              type="primary"
              onClick={onSearch}
            >
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </Form.Item>
        </Form>
        <Button style={{ float: "right" }} type="primary">
          <Link
            to={{
              pathname: "/service/publish-serve",
              search: qs.stringify({
                menuType: "BUSINESS",
                backPathname: "/biz/api-list/index",
                referer: "apiListNew",
              }),
            }}
          >
            + 新建服务
          </Link>
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          scroll={{ x: 1500 }}
          loading={listload}
          dataSource={serviceList}
          pagination={{
            total,
            pageSize: pageParam.pageSize,
            current: pageParam.page,
            showSizeChanger: true,
            showTotal: (total) => `共 ${total}条记录`,
            onChange: (page, size) => setPageParam({ page, pageSize: size }),
          }}
        />
      </div>
    </div>
  );
};
export default ServiceManagement;
