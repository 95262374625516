import React, { useState, useEffect } from "react";
import { Divider, Badge, Switch, Alert, message } from "antd";
import SwitchTag from "./component/SwitchTag";
import styles from "./index.module.scss";
import { getswitchInfo, postswitchInfo } from "../../api/management";
const GatewaySwitch: React.FC = () => {
  const [wgSwitch, setwgSwitch] = useState(false);
  const [qpsSwitch, setqpsSwitch] = useState(false);
  const [xlSwitch, setxlSwitch] = useState(false);
  const [rdSwitch, setrdSwitch] = useState(false);
  const [hbSwitch, sethbSwitch] = useState(false);
  const [MockSwitch, setMockSwitch] = useState(false);
  const wgSwitchMethods = (e: any) => {
    setwgSwitch(e);
    if(e){
      postswitchInfo({
        mainSwitch: e ? 1 : 0,
        qpsSwitch: qpsSwitch ? 1 : 0,
        rateLimitSwitch: xlSwitch ? 1 : 0,
        nameLimitSwitch: hbSwitch ? 1 : 0,
        fuseSwitch: rdSwitch ? 1 : 0,
        mockSwitch: MockSwitch ? 1 : 0,
      }).then((res: any) => {
        if (res.code === 200) {
        }
      });
    }else{
      postswitchInfo({
        mainSwitch: 0,
        qpsSwitch: 0,
        rateLimitSwitch: 0,
        nameLimitSwitch: 0,
        fuseSwitch:0,
        mockSwitch:0,
      }).then((res: any) => {
        if(res.code===200){
        }
      });
    }

  };
  const qpsSwitchMethods = (e: any) => {
    setqpsSwitch(e);
    postswitchInfo({
      mainSwitch: wgSwitch ? 1 : 0,
      qpsSwitch: e ? 1 : 0,
      rateLimitSwitch: xlSwitch ? 1 : 0,
      nameLimitSwitch: hbSwitch ? 1 : 0,
      fuseSwitch: rdSwitch ? 1 : 0,
      mockSwitch: MockSwitch ? 1 : 0,
    }).then((res: any) => {
      if (res.code === 200) {
      }
    });
  };
  const xlSwitchMethods = (e: any) => {
    setxlSwitch(e);
    postswitchInfo({
      mainSwitch: wgSwitch ? 1 : 0,
      qpsSwitch: qpsSwitch ? 1 : 0,
      rateLimitSwitch: e ? 1 : 0,
      nameLimitSwitch: hbSwitch ? 1 : 0,
      fuseSwitch: rdSwitch ? 1 : 0,
      mockSwitch: MockSwitch ? 1 : 0,
    }).then((res: any) => {
      if (res.code === 200) {
      }
    });
  };
  const hbSwitchMethods = (e: any) => {
    sethbSwitch(e);
    postswitchInfo({
      mainSwitch: wgSwitch ? 1 : 0,
      qpsSwitch: qpsSwitch ? 1 : 0,
      rateLimitSwitch: xlSwitch ? 1 : 0,
      nameLimitSwitch: e ? 1 : 0,
      fuseSwitch: rdSwitch ? 1 : 0,
      mockSwitch: MockSwitch ? 1 : 0,
    }).then((res: any) => {
      if (res.code === 200) {
      }
    });
  };
  const rdSwitchMethods = (e: any) => {
    setrdSwitch(e);
    postswitchInfo({
      mainSwitch: wgSwitch ? 1 : 0,
      qpsSwitch: qpsSwitch ? 1 : 0,
      rateLimitSwitch: xlSwitch ? 1 : 0,
      nameLimitSwitch: hbSwitch ? 1 : 0,
      fuseSwitch: e ? 1 : 0,
      mockSwitch: MockSwitch ? 1 : 0,
    }).then((res: any) => {
      if (res.code === 200) {
      }
    });
  };
  const MockSwitchMethods = (e: any) => {
    setMockSwitch(e);
    postswitchInfo({
      mainSwitch: wgSwitch ? 1 : 0,
      qpsSwitch: qpsSwitch ? 1 : 0,
      rateLimitSwitch: xlSwitch ? 1 : 0,
      nameLimitSwitch: hbSwitch ? 1 : 0,
      fuseSwitch: rdSwitch ? 1 : 0,
      mockSwitch: e ? 1 : 0,
    }).then((res: any) => {
      if (res.code === 200) {
      }
    });
  };
  const topSwitch = [
    {
      type: "top",
      text: "网关总开关",
      checked: wgSwitch,
      method: wgSwitchMethods,
      disable: false,
    },
  ];
  const bottomSwitch = [
    {
      type: "bottom",
      text: "总QPS限流",
      checked: qpsSwitch,
      method: qpsSwitchMethods,
      disable: wgSwitch === false ? true : false,
    },
    {
      type: "bottom",
      text: "限流策略开关",
      checked: xlSwitch,
      method: xlSwitchMethods,
      disable: wgSwitch === false ? true : false,
    },
    {
      type: "bottom",
      text: "黑白名单策略开关",
      checked: hbSwitch,
      method: hbSwitchMethods,
      disable: wgSwitch === false ? true : false,
    },
    {
      type: "bottom",
      text: "熔断策略开关",
      checked: rdSwitch,
      method: rdSwitchMethods,
      disable: wgSwitch === false ? true : false,
    },
    {
      type: "bottom",
      text: "Mock返回开关",
      checked: MockSwitch,
      method: MockSwitchMethods,
      disable: wgSwitch === false ? true : false,
    },
  ];
  useEffect(() => {
    console.log(1);

    getswitchInfo({}).then((res: any) => {
      // console.log(res);
      setwgSwitch(res.data.mainSwitch === 1 ? true : false);
      setqpsSwitch(res.data.qpsSwitch === 1 ? true : false);
      setxlSwitch(res.data.rateLimitSwitch === 1 ? true : false);
      sethbSwitch(res.data.nameLimitSwitch === 1 ? true : false);
      setrdSwitch(res.data.fuseSwitch === 1 ? true : false);
      setMockSwitch(res.data.mockSwitch === 1 ? true : false);
    });
  }, []);
  useEffect(() => {
    console.log(wgSwitch);
    
    if (wgSwitch === false) {
      setqpsSwitch(false);
      setxlSwitch(false);
      setrdSwitch(false);
      sethbSwitch(false);
      setMockSwitch(false);

    }
  }, [wgSwitch]);
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "2px",
            height: "20px",
            background: "#165DFF",
            marginRight: "10px",
          }}
        ></div>
        <div>网关开关设置</div>
      </div>
      <div style={{ marginTop: "40px" }}>
        {topSwitch.map((item: any, index) => {
          return (
            <SwitchTag
              text={item.text}
              type={item.type}
              checked={item.checked}
              method={item.method}
              key={index}
              disable={item.disable}
            />
          );
        })}
        <Alert
          style={{ marginLeft: "12px", marginTop: "16px" }}
          message="如果网关总开关设置为关闭，则网关策略开中的所有策略开关也将自动关闭"
          type="info"
          showIcon
        />
      </div>
      <Divider />
      <div>
        <div>
          <SwitchTag text={"安全配置信息"} type={"title"} />
        </div>
        <div className={styles["GatewaySwitch-bottomSwitch"]}>
          {bottomSwitch.map((item, index) => {
            return (
              <SwitchTag
                text={item.text}
                type={item.type}
                checked={item.checked}
                method={item.method}
                key={index}
                disable={item.disable}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GatewaySwitch;
