import {isEmpty} from 'lodash'
import {message} from 'antd'

const DEFAULT_SCHEMA = {
    "type": "object",
    "disabled": {
      "name": true,
      "type": true,
      "add": true,
      "del": true,
      "set": true
    },
    "defaultData": {
      "data": {
        "object": {
          "properties": {
            "indicatorCycle": {
              "type": "string",
              "title": "数据统计周期",
              "disabled": {
                "name": true,
                "type": true,
                "add": true,
                "del": true,
                "set": true,
                "required": true
              },
            },
            "areaCode": {
              "type": "string",
              "title": "地区编码",
              "disabled": {
                "name": true,
                "type": true,
                "add": true,
                "del": true,
                "set": true,
                "required": true
              },
            }
          },
          "required": [
            "indicatorCycle",
            "areaCode"
          ]
        },
        "array": {
          "items": {
            "type": "object",
            "properties": {
              "indicatorCycle": {
                "type": "string",
                "title": "数据统计周期",
                "disabled": {
                  "name": true,
                  "type": true,
                  "add": true,
                  "del": true,
                  "set": true,
                  "required": true
                },
              },
              "areaCode": {
                "type": "string",
                "title": "地区编码",
                "disabled": {
                  "name": true,
                  "type": true,
                  "add": true,
                  "del": true,
                  "set": true,
                  "required": true
                },
              }
            },
            "disabled": {
              "name": true,
              "type": true,
              "del": true,
              "set": true,
              "required": true
            },
            "required": [
              "indicatorCycle",
              "areaCode"
            ]
          }
        }
      }
    },
    "properties": {
      "code": {
        "type": "string",
        "title": "状态码",
        "disabled": {"name": true, "type": true, "add": true, "del": true, "set": true, "dimension": true}
      },
      "message": {
        "type": "string",
        "title": "错误信息",
        "disabled": {"name": true, "type": true, "add": true, "del": true, "set": true, "dimension": true}
      },
      "success": {
        "type": "boolean",
        "title": "是否成功",
        "disabled": {"name": true, "type": true, "add": true, "del": true, "set": true, "dimension": true}
      },
      "data": {
        "type": "object",
        "title": "返回数据",
        "properties": {
          "indicatorCycle": {
            "type": "string",
            "title": "数据统计周期",
            "disabled": {
              "name": true,
              "type": true,
              "add": true,
              "del": true,
              "set": true,
              "required": true
            },
          },
          "areaCode": {
            "type": "string",
            "title": "地区编码",
            "disabled": {
              "name": true,
              "type": true,
              "add": true,
              "del": true,
              "set": true,
              "required": true
            },
          }
        },
        "required": [
          "indicatorCycle",
          "areaCode"
        ],
        "disabled": {"name": true, "type": false, "add": true, "del": true, "set": true, "dimension": true},
        "types": ['array', 'object']
      }
    },
    "required": ["code", "message", "success", "data"]
  };

export const handleIndicatorImportJson = (json, type, DEFAULT_SCHEMA = DEFAULT_SCHEMA) => {
  return new Promise((resolve) => {
    let tips = []
    const traverseJson = (defaultJson, jsonData = {}) => {
      Object.keys(defaultJson).forEach(key => {
        if (!jsonData[key]) {
          tips.push(`当前缺少${key}参数`)
        } else if (key !== 'data' && jsonData[key].type !== defaultJson[key].type) {
          tips.push(`${key}参数的类型为${defaultJson[key].type}`)
        } else {
          if (defaultJson[key].properties) {
            traverseJson(defaultJson[key].properties, (jsonData[key].type === 'array' ? jsonData[key].items.properties : (jsonData[key]?.properties)))
          }
          jsonData[key].disabled = defaultJson[key].disabled
          if (type !== 'schema') {
            jsonData[key].required = defaultJson[key].required
            jsonData[key].types = defaultJson[key].types
          }
        }
      })
    }
    const filterValueNull = (jsonData) => {
      Object.keys(jsonData).forEach(key => {
        if (jsonData[key].type === 'null') {
          tips.push(`${key}参数的值不能为null`)
        }
        jsonData[key].properties && filterValueNull(jsonData[key].properties)
      })
    }
    filterValueNull(json.properties)
    Object.assign(json, {
      required: DEFAULT_SCHEMA.required,
      disabled: DEFAULT_SCHEMA.disabled,
      defaultData: DEFAULT_SCHEMA.defaultData
    })
    traverseJson(DEFAULT_SCHEMA.properties, json.properties)
    isEmpty(tips) ? resolve(json) : tips.map(i => message.warn(i))
  })
}