import React, { useState, useEffect, PropsWithChildren, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Button, Tabs, Row, Col } from "antd";
import {
  getTopicDetail,
  getTopicSubInfo
} from "@/api/topic";
import moment from "moment"

import ParseRequestJsonTable from '../components/parse-request-json-table'
type Props = {};
const DetailRender: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const data: any = Object.prototype.toString.call(children) === '[object Array]' ? children : [children]
  return <>
    {
      data.map((it: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
        <Col md={8} sm={24} key={index}>
          {it}
        </Col>
      ))
    }
  </>
}
const TopicDetail: React.FC = () => {
  interface routerHeader {
    id: any;
  }
  const { id } = useParams<routerHeader>();  
  const [nowType, setnowType] = useState<any>();
  const history = useHistory();
  const [topicInfo, setTopicInfo] = useState<any>({})
  const [subInfo, setSubInfo] = useState<any>({})
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };
  const authModes = ['public', 'private']
  useEffect(() => {
    setnowType(1)
    getDetail()
  }, []);
  const getDetail = useCallback(()=>{
    getTopicDetail({id}).then((res: any)=>{
      res.data.accessLevel = res.data.accessLevel === 1 ? 'public(公开授权订阅)' : 'private(不开放订阅权限)'
      setTopicInfo(res.data)
      if(res.data.isSubscribe) {
        getSubInfo()
      }
    })
  }, [id, nowType])
  const getSubInfo = useCallback(()=>{
    getTopicSubInfo({id}).then((res:any)=>{
      setSubInfo(res.data)
    })
  },[id])
  return (
      <div style={{ padding: "20px" }}>
        <div>
          <div
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "20px",
                background: "#165DFF",
                marginRight: "10px",
              }}
            ></div>
            <div>Topic详情</div>
          </div>
        </div>
        <Tabs
          activeKey={nowType + ""}
          onChange={(e) => {
            setnowType(e);
          }}
          style={{marginTop: "20px"}}
        >
          <Tabs.TabPane tab="基本信息" key="1"></Tabs.TabPane>
          {
            topicInfo.isSubscribe && <Tabs.TabPane tab="订阅信息" key="2"></Tabs.TabPane>
          }
        </Tabs>
        <div
          style={{
            width: "100%",
          }}
        >
          {
            nowType == 1 ?
            <div>基本信息</div>
            :
            <div>订阅信息</div>
          }
          <div style={{
              marginTop: "10px",
              padding: "20px",
              border: "1px solid #F1F1F1",
              borderRadius: "4px"
            }}
          >
            <div style={{              
              display:"flex",
              alignItems:"center",
              marginBottom: "14px"}}>
              <div style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "10px",
                borderRadius: "50%"}}></div>
              {
                nowType == 1 ?
                <div>基本信息</div>
                :
                <div>订阅信息</div>
              }
            </div>
            <Row>
              {
                nowType == 1 ?
                <DetailRender>
                  <Form.Item {...formLayout} label="Topic code">
                    {topicInfo.topicCode}
                  </Form.Item>
                  <Form.Item {...formLayout} label="Topic名称">
                    {topicInfo.topicName}
                  </Form.Item>
                  <Form.Item {...formLayout} label="发布方">
                    {topicInfo.createName}
                  </Form.Item>
                  <Form.Item {...formLayout} label="延时时长">
                    {topicInfo.delayTime}秒
                  </Form.Item>
                  <Form.Item {...formLayout} label="消息保存时长">
                    {topicInfo.expireTime}秒
                  </Form.Item>
                  <Form.Item {...formLayout} label="开放订阅">
                    {topicInfo.accessLevel}
                  </Form.Item>
                  <Form.Item {...formLayout} label="最近更新时间">
                    { moment(topicInfo.updateAt).format("YYYY-MM-DD HH:mm:ss") }
                  </Form.Item>
                  <Form.Item {...formLayout} label="Topic说明">
                    {topicInfo.topicDescription}
                  </Form.Item>
                </DetailRender>
                :
                <DetailRender>
                  <Form.Item {...formLayout} label="订阅编号">
                    {subInfo.subscriberId}
                  </Form.Item>
                  <Form.Item {...formLayout} label="订阅方式">
                    {subInfo.subscriberType === 1 ? "Http" : "WebSocket" }
                  </Form.Item>
                  {
                    subInfo.subscriberType === 1 &&
                    <Form.Item {...formLayout} label="回调地址">
                      {subInfo.callbackUrl}
                    </Form.Item>
                  }
                  <Form.Item {...formLayout} label="重试次数">
                    { subInfo.retryNum }
                  </Form.Item>
                  <Form.Item {...formLayout} label="重试间隔时间">
                    { subInfo.retryInterval + 's' }
                  </Form.Item>
                  <Form.Item {...formLayout} label="订阅时间">
                   { moment(subInfo.createAt).format("YYYY-MM-DD HH:mm:ss") }
                  </Form.Item>
                </DetailRender>
            }
          </Row>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "20px"
          }}
        >
          <div>消息内容</div>
          <div style={{
              marginTop: "10px",
              padding: "20px",
              border: "1px solid #F1F1F1",
              borderRadius: "4px"
            }}
          >
            <div style={{
              display:"flex",
              alignItems:"center",
              marginBottom: "14px"
            }}>
              <div style={{
                width: "6px",
                height: "6px",
                background: "#165DFF",
                marginRight: "10px",
                borderRadius: "50%"}}></div>
              <div>消息内容</div>
            </div>
            <ParseRequestJsonTable
              method={topicInfo?.requestMethod || topicInfo?.method}
              type={'response'}
              jsonData={
                (() => {
                  let param: any = {}
                  try {
                    param = JSON.parse(topicInfo?.bodyStruct || '{}')
                  } catch (error) {
                  }

                  return param?.APIResponseStructure || {}
                })()
              }
              protocolIn={topicInfo?.accessProtocol}
            />
          </div>
        </div>
        <div style={{
          textAlign: "center",
          marginTop: "20px"
        }}>
          <Button onClick={() => {
            history.goBack();
          }}>返回</Button>
        </div>
      </div>
  );
};
export default TopicDetail;
