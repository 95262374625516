// api地址
export const GET = "/api";

//用户列表
export const GET_USER_LIST = "/api/account";

//创建用户
export const POST_USER_CREATE = "/api/account";

//停用用户
export const POST_USER_DISABLE = "/api/user/disable";

//启用用户
export const POST_USER_ENABLE = "/api/user/enable";

//获取所有角色
export const GET_ROLE_LIST = "/api/role/list";

//更改角色
export const POST_ROLE_OPERATE = "/api/user/updateNoSub";

// 获取所有角色
export const GET_USER_ROLE = "/api/user/role";

// 登入
export const POST_USER_LOGIN = "/api/login";

// 退出登录
export const GET_USER_LOGOUT = "/api/logout";

// 角色详情
export const GET_USER_INFO = "/api/user/info";

//审批资源
export const POST_APPROVE = "/api/approve";

//审批列表
export const GET_APPROVELIST = "/api/approve/list/my";
//刷新token
export const POST_USER_REFRESH_TOKEN = "/api/refreshToken";

//登录用户信息
export const GET_USER_USERINFO = "/api/user/userInfo";

//修改密码
export const POST_USER_MODIFY_PWD = "/api/user/modify/pwd";

// 资源管理
export const GET_RESOURCE_LIST = "/api/resource/list/all";
export const GET_RESOURCE_LIST_MY = "/api/resource/list/my";
export const POST_RESOURCE_CREATE = "/api/resource/create";
export const GET_RESOURCE_DETAIL = "/api/resource/detail";
export const POST_RESOURCE_DISABLE = "/api/resource/disable";
export const POST_RESOURCE_ENABLE = "/api/resource/enable";
export const POST_RESOURCE_MODIFY = "/api/resource/modify";

//apply申请列表
export const GET_APPLY_LIST = "/api/apply/list/my";

//申请资源
export const POST_APPLY = "/api/apply";

// 插件管理
//插件市场插件详情
export const GET_PLUGINS_INFO = "/api/plugins/info";

//插件安装
export const POST_PLUGINS_install = "/api/plugins/install";

//已安装插件详情
export const GET_PLUGINS_INSTALLINFO = "/api/plugins/installinfo";

//已安装插件列表
export const GET_PLUGINS_INSTALLLIST = "/api/plugins/installList";

// 插件市场列表
export const GET_PLUGINS_LIST = "/api/plugins/list";

//卸载插件
export const POST_PLUGINS_UNINSTALL = "/api/plugins/uninstall";

//更改插件pod数量
export const POST_PLUGINS_UPDATA = "/api/plugins/update";

//创建场景
export const POST_CREATE_SCENE = "/api/scenario/create";
//更新场景
export const POST_MODIFY_SCENE = "/api/scenario/modify";
//场景列表
export const GET_SCENARIO_LIST = "/api/scenario/list";
//场景详情
export const GET_SCENARIO_DETAIL = "/api/scenario/detail";
//获取类目
export const GET_CREATE_CATEGORY = "/api/category/search";
//系统创建
export const POST_CREATE_SYSTEM = "/api/system/create";
//创建平台
export const POST_CREATE_PLATFORM = "/api/platform/create";
//创建接口资源
export const POST_CREATE_RESOURCE = "/api/resource/sample/create";
//系统列表
export const GET_SYSTEM_LIST = "/api/system/list";
//我的系统列表
export const GET_MYSYSTEM_LIST = "/api/mysystem/list";
//平台列表
export const GET_PLATFORM_LIST = "/api/platform/list";
//我的平台列表
export const GET_MYPLATFORM_LIST = "/api/myplatform/list";
//所有系统/平台列表
export const GET_SYSTEMPLATFORM_LIST = "/api/statSystemPlatform/list";
//更新系统
export const POST_SYSTEM_MODIFY = "/api/system/modify";
//更新平台
export const POST_PLATFORM_MODIFY = "/api/platform/modify";
//健康检测
export const GET_HEALTH_CHECK = "/api/system/health/check";

//删除场景
export const GET_SCENARIO_DELETE = "/api/scenario/delete";
//删除系统
export const GET_SYSTEM_DELETE = "/api/system/delete";
//删除平台
export const GET_PLATFORM_DELETE = "/api/platform/delete";

//创建类目
export const POST_CATEGORY_CREATE = "/api/category/create";
//类目菜单列表
export const GET_CATEGORY_SEARCH = "/api/category/search";

//拖动编辑or更新类目
export const POST_CATEGORY_MODIFY = "/api/category/modify";

//删除类目
export const GET_CATEGORY_DELETE = "/api/category/delete";

//类目右侧表格
export const GET_PLANTFORM_LIST = "/api/platform/list";

//删除用户
export const GET_SUBUSER_DELETE = "/api/subUser/delete";

//用户详情
export const GET_SUBUSER_DETAIL = "/api/subUser/detail";

//获取所有权限组
export const GET_PERMISSIONGROUP_LIST = "/api/permissionGroup/list";

//创建权限组
export const GET_PERMISSIONGROUP_CREATE = "/api/permissionGroup/create";

//删除权限组
export const GET_PERMISSIONGROUP_DELETE = "/api/permissionGroup/delete";

//更新权限组
export const GET_PERMISSIONGROUP_MODIFY = "/api/permissionGroup/modify";

//绑定用户和权限组
export const POST_PERMISSION_BINDUSER =
  "/api/permissionGroup/bindUserPermissionGroup";

//解绑用户和权限组
export const POST_PERMISSION_UNBINDUSER =
  "/api/permissionGroup/unbindUserPermissionGroup";

//获取所有权限
export const GET_PREMISSION_TREE = "/api/permission/tree";

//通过roleld查询权限组集合
export const GET_PGLIST_ROLEID = "/api/permissionGroup/GetPGListByRoleId";

//添加权限入权限组
export const POST_ADD_PERMISSION = "/api/permissionGroup/updatePermissions";

//移除权限出权限组
export const POST_REMOVE_PERMISSION = "/api/permissionGroup/removePermissions";

//接口创建第一步
export const POST_INTERFACE_CREATE = "/api/interface/create";

//创建接口HTTP版本
export const POST_INTERFACE_HTTPCREATE = "/api/interface/version/create";

//系统id&name列表
export const GET_SYSTEM_IDLIST = "/api/system/idName/list";

//接口列表
export const GET_INTERFACE_LIST = "/api/interface/list";

//接口板本列表
export const GET_VERSION_LIST = "/api/interface/version/list";

//删除接口
export const POST_INTERFACE_REMOVE = "/api/interface/remove";

//修改接口
export const POST_INTERFACE_MODIFY = "/api/interface/modify";

//删除接口版本
export const POST_VERSION_REMOVE = "/api/interface/version/remove";

//协议列表
export const GET_SYSTEM_PROTOCOL = "/api/system/protocol";

//修改接口版本
export const POST_VERSION_MODIFY = "/api/interface/version/modify";

//接口database查询
export const GET_DATABASE_LIST = "/api/interface/version/database/list";

//删除用户
export const POST_USER_DELETE = "/api/user/delete";

//黑白名单列表
export const GET_inventory_list = "/api/ip/inventory/list";

//黑白名单策略select
export const GET_strategy_list = "/api/ip/strategy/list";

//黑白名单删除
export const POST_inventory_remove = "/api/ip/inventory/remove";

//新增黑白名单记录
export const POST_inventory_create = "/api/ip/inventory/create";

//获取黑白名单当前策略
export const GET_current_strategy = "/api/ip/current/strategy";

//获取connector列表
export const GET_connector_list = "/api/connector/list2";

//删除connector插件
export const POST_connector_romove = "/api/connector/remove";

//获取协议select列表
export const GET_protocol_list = "/api/protocol/list";

//创建connector
export const POST_connector_create = "/api/connector/create";

//connector详情
export const POST_connector_detail = "/api/connector/detail";

//connector更新
export const POST_connector_update = "/api/connector/update";

//凭证详情
export const GET_credential_details = "/api/credential/details";

//凭证管理列表
export const POST_credential_list = "/api/credential/list";

//凭证管理延期
export const POST_retarded_expiry = "/api/retarded/expiry";

//创建凭证
export const POST_credential_create = "/api/credential/create";

//下载平台响应报文解密密钥
export const GET_download_decode = "/api/download/decode/key";

//下载平台响应验签秘钥
export const GET_download_examine = "/api/download/examine/key";

//上传请求报文解密密钥
export const POST_upload_decode = "/api/upload/decode/key";

//上传请求验签密钥
export const POST_upload_examine = "/api/upload/examine/key";

/**服务管理相关接口 */

//后端服务类型
export const GET_SRVICE_TYPE = "/api/protocol/list";

//后端服务列表
export const GET_SERVICE_LIST = "/api/serviceManage/list";

//后端其他服务列表
export const GET_SERVICE_OTHERLIST = "/api/serviceManage/otherServiceList";

//删除后端服务
export const DELETE_SERVICE_ITEM = "/api/serviceManage/delete";

//后端服务详情
export const DETAIL_SERVICE_ITEM = "/api/serviceManage/detail";

//状态更改
export const CHANGE_SERVICE_STATUS = "/api/serviceManage/changeStatus";

//所有协议类型
export const connector_protocol_list = "/api/connector/protocol/list";

//后端连接配置信息
export const INFO_CONNECT = "/api/connector/payload/detail";

//服务添加第一步
export const ADD_SERVICE_FIRST = "/api/serviceManage/addServiceFristCache";

//服务添加第二步
export const ADD_SERVICE_SECOND = "/api/serviceManage/addServiceSecondCache";

//服务添加第三步
export const ADD_SERVICE_THIRD = "/api/serviceManage/addServiceThirdCache";

//服务添加第四步
export const ADD_SERVICE_MANAGE = "/api/serviceManage/addServiceManage";

//获取connector类型
export const GET_CONNECTOR_TYPE = "/api/protocol/list";

//获取connector列表
export const GET_CONNECTOR_LIST = "/api/connector/list2";

//获取服务内容
export const GET_EDIT_SERVICE_START = "/api/serviceManage/editServiceStart";

//服务编辑第一步
export const GET_EDIT_SERVICE_FIRST = "/api/serviceManage/getServiceFrist";

//服务编辑第二步
export const GET_EDIT_SERVICE_SECOND = "/api/serviceManage/getServiceSecond";

//服务编辑第三步
export const GET_EDIT_SERVICE_THIRD = "/api/serviceManage/getServiceThird";

//服务编辑
export const EDIT_SERVICE_MANAGE = "/api/serviceManage/editServiceSave";

//切换策略
export const POST_strategy_switch = "/api/ip/strategy/switch";
//connector详情
export const GET_CONNECTOR_DETAIL = "/api/connector/detail";

//服务健康监控
export const POST_Service_health = "/api/check/dpAddressList";

//检测历史
export const GET_log_list = "/api/log/check/list";

//心跳检测charts
export const GET_serviceManage_statistics = "/api/serviceManage/statistics";
//我的已发布topic列表
export const GET_Topic_List = "/api/msgsynergy/topic/list";

//发布topic
export const POST_Topic = "/api/msgsynergy/topic/register";

//编辑Topic
export const PUT_Topic = "/api/msgsynergy/topic/modify";

//topic详情
export const GET_TOPIC_Detail = "/api/msgsynergy/topic";

//订阅Topic
export const POST_Subscribed_Topic = "/api/msgsynergy/topic/sub";

//取消订阅
export const DELETE_Sub = "/api/msgsynergy/topic/unsub";

//获取消息详情
export const GET_Msg_Detail = "/api/msgsynergy/msg";

//获取发送的消息
export const GET_Send_Msg = "/api/msgsynergy/msg/sended/list";

//获取消息轨迹
export const GET_Msg_Record = `/api/msgsynergy/msg`;

//获取消费的消息
export const GET_Consumed_Msg = "/api/msgsynergy/msg/consumed/list";

//获取订阅信息
export const GET_Sub_Info = "/api/msgsynergy/topic/sub";

//connector检测记录
export const GET_connector_log = "/api/connector/log";

//根据协议名称查询插件详情
export const GET_protocol_onlineConnector =
  "/api/connector/protocol/onlineConnector";

//网管开关设置
export const GET_switchInfo = "/api/switchInfo";

//中枢互联实例管理列表
export const GET_node_list = "/api/centralInterconnection/node/list";

//中枢互联资源
export const GET_all_listByCode = "/api/serviceManage/listByCode";

//手动添加中枢实例
export const POST_node_create = "/api/centralInterconnection/node/create";

//卸载节点
export const POST_centralInterconnection_delete =
  "/api/centralInterconnection/delete/node";

//审批单列表
export const GET_approvalList = "/api/centralInterconnection/approvalList";

//审批详情
export const POST_applyDetail = "/api/centralInterconnection/applyDetail";

//申请列表
export const GET_grant_approval = "/api/centralInterconnection/grant/approval";

//调用记录列表
export const GET_record_list = "/api/record/list";

//调用统计列表
export const GET__daily_list = "/api/report/daily/list";

//交易详情
export const GET_record_detail = "/api/record/detail";

//调用明细
export const GET_daily_details = "/api/report/daily/details";

//top5 凭证应用
export const GET_report_credentia = "/api/report/credential/called";

//接入放汇总
export const GET_report_aggregation = "/api/report/credential/aggregation";

//服务汇总
export const GET_service_aggregation = "api/report/service/aggregation";

//服务调用统计
export const GET_service_statistics = "/api/report/service/statistics";

//测试用例列表
export const GET_test_list = "/api/test/list";

//测试详情
export const GET_test_detail = "/api/test/detail";

//在线测试
export const POST_test_create = "/api/test/create";

//测试用例更新
export const POST_test_update = "/api/test/update";

//删除测试用例
export const POST_test_delete = "/api/test/delete";

//审批详情通过or不通过
export const POST_grant_approval = "/api/centralInterconnection/grant/approval";

//查询实例编码
export const GET_manage_getInstance = "/api/serviceManage/getInstance";

//申请实例
export const POST_grant_authorization =
  "/api/centralInterconnection/grant/authorization";

//审批单记录
export const GET_centralInterconnection_log = "/api/centralInterconnection/log";

//申请单统计
export const GET_centralInterconnection_statistics =
  "/api/centralInterconnection/statistics";

//对外服务详情
export const GET_serviceManage_detail = "/api/serviceManage/detail/findByCode";

//发起测试
export const POST_test_online = "/api/test/online";

//互联申请文件上传
export const POST_centralInterconnection_upload =
  "/api/centralInterconnection/upload";

//下载模板
export const GET_centralInterconnection_moban =
  "/api/centralInterconnection/moban";

//在线预览
export const GET_centralInterconnection_getUrl =
  "/api/centralInterconnection/getUrl";

//在线预览NEW
export const GET_new_getUrl = ""

//下载文件
export const GET_downLoad_enclosure = "/api/centralInterconnection/enclosure";

//获取灰度
export const GET_gray_scale = "/api/grayScale";

//创建灰度
export const POST_gray_scale = "/api/grayScale";

//创建灰度策略
export const POST_grayScale_strategy = "/api/grayScale/strategy";

//发布灰度服务配置(全量发布)
export const PUT_grayScale_release = "/api/grayScale/release";

//变更灰度状态(灰度发布)
export const PUT_grayScale_state = "/api/grayScale/state";

//删除灰度
export const DELETE_grayScale_id = "/api/grayScale";

//删除申请单
export const POST_delete_applyInfo =
  "/api/centralInterconnection/delete/applyInfo";

//聚合服务子服务列表
export const GET_serviceManageAggregation_query =
  "/api/serviceManageAggregation/query";

//聚合服务详情
export const GET_serviceManageAggregation_dtail = "/api/serviceManageAggregation"

//聚合服务addFirst
export const POST_firstCache= "/api/serviceManageAggregation/firstCache"

//聚合服务addSecond
export const POST_secondCache = "/api/serviceManageAggregation/secondCache"

//聚合服务addThird
export const POST_thirdCache = "/api/serviceManageAggregation/thirdCache"

//聚合服务Create
export const POST_Create = "/api/serviceManageAggregation"

//后端服务聚合详情
export const DETAIL_SERVICE_ITEMV2 = "/api/serviceManage/v2/detail";

//聚合服务编辑获取uuid
export const PSOT_editStart= "/api/serviceManageAggregation/editStart"

//聚合服务编辑step1
export const GET_editFirst = "/api/serviceManageAggregation/first"

//聚合服务编辑step2
export const GET_editSecond = "/api/serviceManageAggregation/second"

//聚合服务编辑step3
export const GET_editThird = "/api/serviceManageAggregation/third"

//聚合服务编辑提交
export const PUT_serviceManageAggregation = "/api/serviceManageAggregation"
